import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'priceList';

const priceListService = {
  getItems: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/data`,
      data,
    });
  },

  updateItem: (data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}`,
      data,
    });
  },

  createItem: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  deleteItem: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  getItemPrice: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/itemprice`,
      data,
    });
  },
};
export default priceListService;
