import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import Yup from '../../utils/yup';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import useUsers from '../../context/UsersContext';
import useConfig from '../../context/ConfigContext';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI, FormBase, ButtonUI } from '../Interface';
import DocumentFields from './DocumentFields';
import DocumentReports from './DocumentReports';
import { cleanValues, generateAutocompleteItems } from '../../utils/helper';
import documentService from '../../service/newDoc.service';
import masterDataService from '../../service/masterData.service';
import DocumentTabs from './DocumentTabs';
import documentTypeService from '../../service/newDocumentType.service';
import { DocumentDirection } from '../../config/DocumentDirection';
import { CategoryOptionType } from '../../config/vatCodes';
import { useLoader } from '../../provider/LoaderProvider';

const DocumentPage = ({ docTypeId }) => {
  const { getUser, user } = useUsers();
  const { config, getConfig } = useConfig();
  const { showLoader, hideLoader } = useLoader();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autocompleteData, setAutoCompleteData] = useState({});
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    company: [],
    project: [],
    item: [],
    qtyUnit: [],
    vat: [],
    warehouse: [],
  });
  const [documentData, setDocumentData] = useState({});
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyContacts, setCompanyContacts] = useState([]);

  const defaultDocumentLine = {
    lineStatus: 'O',
    itemCode: null,
    itemName: null,
    itemCodeInBp: null,
    qty: null,
    cplQty: null,
    opnQty: null,
    quantityUnitId: null,
    quantityUnitCode: null,
    quantityUnitName: null,
    whsCode: config.defaultWarehouse,
    toWhsCode: null,
    unitPrice: null,
    currencyCode: null,
    vatCode: null,
    vatPercent: null,
    vatValue: null,
    netPrice: null,
    grossPrice: null,
    dueDate: dayjs(),
    vatValueInOwnCur: null,
    netPriceInOwnCur: null,
    grossPriceInOwnCur: null,
    weightChangeNumber: null,
    weight: null,
    projectId: null,
    costCenterCode: null,
    itemReference: null,
    internalNote: '',
    bpNote: '',
  };

  const defaultValues = {
    docId: 0,
    docTypeId: parseInt(docTypeId), // paraméterből gyün
    docStatus: 'O',
    stornoId: null,
    docDate: dayjs(),
    issueDate: dayjs(),
    dueDate: dayjs(),
    companyId: null,
    companyCode: '',
    companyName: '',
    companyContactId: null,
    currencyCode: null,
    exchangeRate: null,
    paymentMethodId: null,
    reference: '',
    projectId: null,
    costCenterCode: null,
    salesOrPurchasePersonId: null,
    billingAdressId: null,
    billingCountry: '',
    billingProvince: '',
    billingPostCode: '',
    billingCity: '',
    billingStreetAddress: '',
    shippingAdressId: null,
    shippingCountry: '',
    shippingProvince: '',
    shippingPostCode: '',
    shippingCity: '',
    shippingStreetAddress: '',
    deliveryMethodId: null,
    incotermCode: null,
    whsCode: null,
    toWhsCode: null,
    priceListId: null,
    langCode: null,
    hunTaxNumber: null,
    euTaxNumber: null,
    foreignTaxNumber: null,
    sumInDocCur: null,
    netInDocCur: null,
    VATInDocCur: null,
    roundingInDocCur: null,
    sumInOwnCur: null,
    netInOwnCur: null,
    VATInOwnCur: null,
    roundingInOwnCur: null,
    note: '',
    internalNote: '',
    createdBy: user.userId,
    docLine: [],
  };

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
    if (!Object.keys(config).length) getConfig();

    initDefaultValues();
    setOperatorsDisabled(true);
  }, []);

  useEffect(() => {
    if (Object.keys(config).length > 0) {
      defaultValues.whsCode = config.defaultWarehouse;
      setInitValues(defaultValues);
      //setFieldValue('whsCode', config.defaultWarehouse);
    }
  }, [config]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (selectedValues) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      defaultValues.whsCode = config?.defaultWarehouse;
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    showLoader();
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        documentTypeService
          .getDocumentType(docTypeId)
          .then((data) => resolve(data))
          .catch((e) => reject(e));
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .companyToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .projectToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .itemsFromTableToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .quantityUnitToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .warehouseToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .currencyToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .paymentMethodToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .salesOrPurchasePersonToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .costCenterToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .deliveryMethodToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .incotermToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .priceListToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .languageToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .usersToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .vatCodeToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          company: resp[1],
          project: resp[2],
          item: resp[3],
          qtyUnit: resp[4],
          warehouse: resp[5],
          currency: resp[6],
          paymentMethod: resp[7],
          salesOrPurchasePerson: resp[8],
          costCenter: resp[9],
          deliveryMethod: resp[10],
          incoterm: resp[11],
          priceList: resp[12],
          language: resp[13],
          users: resp[14],
          vatCode: resp[15],
        };

        setAutoCompleteData(aData);

        const docData = resp[0];
        setDocumentData(docData);

        const filteredCompany = aData.company.filter((c) => {
          if (docData.direction === DocumentDirection.INCOMING) {
            return c.active && c.supplier;
          } else if (docData.direction === DocumentDirection.OUTGOING) {
            return c.active && c.customer;
          }
          return false;
        });

        const filteredCurrency = aData.currency.filter((c) => c.active);
        const filteredPaymentMethod = aData.paymentMethod.filter((p) => !p.deletedBy);
        const filteredSalesOrPurchasePerson = aData.salesOrPurchasePerson.filter((p) => {
          if (docData.direction === DocumentDirection.INCOMING) {
            return p.active && p.purchaser;
          } else if (docData.direction === DocumentDirection.OUTGOING) {
            return p.active && p.salesPerson;
          }
          return false;
        });
        const filteredCostCenter = aData.costCenter.filter((cc) => !cc.deletedBy);

        const filteredVat = aData.vatCode.filter((v) => {
          if (docData.direction === DocumentDirection.INCOMING) {
            return v.active && v.category === CategoryOptionType.B;
          } else if (docData.direction === DocumentDirection.OUTGOING) {
            return v.active && v.category === CategoryOptionType.K;
          }
          return false;
        });

        const acOptions = {
          company: generateAutocompleteItems(filteredCompany, ['code', 'name'], 'id'),
          project: generateAutocompleteItems(aData.project, 'projectName', 'projectId'),
          item: generateAutocompleteItems(aData.item, ['itemCode', 'itemName'], 'itemCode'),
          qtyUnit: generateAutocompleteItems(aData.qtyUnit, 'name', 'id'),
          warehouse: generateAutocompleteItems(aData.warehouse, 'code', 'code'),
          currency: generateAutocompleteItems(filteredCurrency, 'code', 'code'),
          paymentMethod: generateAutocompleteItems(filteredPaymentMethod, 'name', 'id'),
          salesOrPurchasePerson: generateAutocompleteItems(filteredSalesOrPurchasePerson, 'name', 'id'),
          costCenter: generateAutocompleteItems(filteredCostCenter, ['code', 'name'], 'code'),
          deliveryMethod: generateAutocompleteItems(aData.deliveryMethod, 'name', 'id'),
          incoterm: generateAutocompleteItems(aData.incoterm, ['code', 'name'], 'code'),
          priceList: generateAutocompleteItems(aData.priceList, 'name', 'id'),
          language: generateAutocompleteItems(aData.language, ['code', 'name'], 'code'),
          users: generateAutocompleteItems(aData.users, 'userName', 'userId'),
          vatCode: generateAutocompleteItems(filteredVat, 'code', 'code'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const valuesToForm = () => {
    const {
      docId,
      docTypeId,
      docStatus,
      stornoId,
      docDate,
      issueDate,
      dueDate,
      companyId,
      companyCode,
      companyName,
      companyContactId,
      currencyCode,
      exchangeRate,
      paymentMethodId,
      reference,
      projectId,
      costCenterCode,
      salesOrPurchasePersonId,
      billingAdressId,
      billingCountry,
      billingProvince,
      billingPostCode,
      billingCity,
      billingStreetAddress,
      shippingAdressId,
      shippingCountry,
      shippingProvince,
      shippingPostCode,
      shippingCity,
      shippingStreetAddress,
      deliveryMethodId,
      incotermCode,
      whsCode,
      toWhsCode,
      priceListId,
      langCode,
      hunTaxNumber,
      euTaxNumber,
      foreignTaxNumber,
      sumInDocCur,
      netInDocCur,
      VATInDocCur,
      roundingInDocCur,
      sumInOwnCur,
      netInOwnCur,
      VATInOwnCur,
      roundingInOwnCur,
      note,
      internalNote,
      createdBy,
      docLine,
    } = selectedValues;

    setInitValues({
      docId,
      docTypeId,
      docStatus,
      stornoId,
      docDate: dayjs(docDate),
      issueDate: dayjs(issueDate),
      dueDate: dayjs(dueDate),
      companyId,
      companyCode,
      companyName,
      companyContactId,
      currencyCode,
      exchangeRate,
      paymentMethodId,
      reference: reference ?? '',
      projectId,
      costCenterCode,
      salesOrPurchasePersonId,
      billingAdressId,
      billingCountry: billingCountry ?? '',
      billingProvince: billingProvince ?? '',
      billingPostCode: billingPostCode ?? '',
      billingCity: billingCity ?? '',
      billingStreetAddress: billingStreetAddress ?? '',
      shippingAdressId,
      shippingCountry: shippingCountry ?? '',
      shippingProvince: shippingProvince ?? '',
      shippingPostCode: shippingPostCode ?? '',
      shippingCity: shippingCity ?? '',
      shippingStreetAddress: shippingStreetAddress ?? '',
      deliveryMethodId,
      incotermCode,
      whsCode,
      toWhsCode,
      priceListId,
      langCode,
      hunTaxNumber: hunTaxNumber ?? '',
      euTaxNumber: euTaxNumber ?? '',
      foreignTaxNumber: foreignTaxNumber ?? '',
      sumInDocCur,
      netInDocCur,
      VATInDocCur,
      roundingInDocCur,
      sumInOwnCur,
      netInOwnCur,
      VATInOwnCur,
      roundingInOwnCur,
      note: note ?? '',
      internalNote: internalNote ?? '',
      createdBy,
      docLine: docLine.map((dl) => ({
        id: uuidv4(),
        docId: dl.docId,
        lineNum: dl.lineNum,
        lineStatus: dl.lineStatus,
        itemCode: dl.itemCode,
        itemName: dl.itemName,
        itemCodeInBp: dl.itemCodeInBp,
        qty: dl.qty,
        cplQty: dl.cplQty,
        opnQty: dl.opnQty,
        quantityUnitId: dl.quantityUnitId,
        quantityUnitCode: dl.quantityUnitCode,
        quantityUnitName: dl.quantityUnitName,
        whsCode: dl.whsCode,
        toWhsCode: dl.toWhsCode,
        unitPrice: dl.unitPrice,
        currencyCode: dl.currencyCode,
        vatCode: dl.vatCode,
        vatPercent: dl.vatPercent,
        vatValue: dl.vatValue,
        netPrice: dl.netPrice,
        grossPrice: dl.grossPrice,
        dueDate: dayjs(dl.dueDate),
        vatValueInOwnCur: dl.vatValueInOwnCur,
        netPriceInOwnCur: dl.netPriceInOwnCur,
        grossPriceInOwnCur: dl.grossPriceInOwnCur,
        weightChangeNumber: dl.weightChangeNumber,
        weight: dl.weight,
        projectId: dl.projectId,
        costCenterCode: dl.costCenterCode,
        itemReference: dl.itemReference,
        internalNote: dl.internalNote,
        bpNote: dl.bpNote,
      })),
    });
  };

  const validationSchema = Yup.object().shape({
    docStatus: Yup.string().required(),
    stornoId: Yup.string().nullable(),
    docDate: Yup.string().required(),
    issueDate: Yup.string().required(),
    dueDate: Yup.string().required(),
    companyId: Yup.string().nullable(),
    companyCode: Yup.string().nullable(),
    companyName: Yup.string().nullable(),
    companyContactId: Yup.string().nullable(),
    currencyCode: Yup.string().nullable(),
    exchangeRate: Yup.number()
      .nullable()
      .when('currencyCode', {
        is: (currencyCode) => config?.currencyCode !== currencyCode,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      }),
    paymentMethodId: Yup.string().nullable(),
    reference: Yup.string().nullable(),
    projectId: Yup.string().nullable(),
    costCenterCode: Yup.string().nullable(),
    salesOrPurchasePersonId: Yup.string().nullable(),
    billingAdressId: Yup.string().nullable(),
    billingCountry: Yup.string().nullable(),
    billingProvince: Yup.string().nullable(),
    billingPostCode: Yup.string().nullable(),
    billingCity: Yup.string().nullable(),
    billingStreetAddress: Yup.string().nullable(),
    shippingAdressId: Yup.string().nullable(),
    shippingCountry: Yup.string().nullable(),
    shippingProvince: Yup.string().nullable(),
    shippingPostCode: Yup.string().nullable(),
    shippingCity: Yup.string().nullable(),
    shippingStreetAddress: Yup.string().nullable(),
    deliveryMethodId: Yup.string().nullable(),
    incotermCode: Yup.string().nullable(),
    whsCode: Yup.string().nullable(),
    toWhsCode: Yup.string().nullable(),
    priceListId: Yup.string().nullable(),
    langCode: Yup.string().nullable(),
    hunTaxNumber: Yup.string()
      .nullable()
      .matches(/^\d{8}-\d{1,2}-\d{2}$/, {
        message: 'A magyar adószám formátuma helytelen. Helyes formátum például: 12345678-1-23',
        excludeEmptyString: true,
      }),
    euTaxNumber: Yup.string()
      .nullable()
      .matches(/^[A-Z]{2}\d{8,12}$/, {
        message: 'Az EU adószám formátuma helytelen. Helyes formátum például: HU12345678',
        excludeEmptyString: true,
      }),
    foreignTaxNumber: Yup.string().nullable(),
    note: Yup.string().nullable(),
    internalNote: Yup.string().nullable(),
    docLine: Yup.array().of(
      Yup.object().shape({
        lineStatus: Yup.string().required(),
        itemCode: Yup.string().required(),
        itemName: Yup.string().nullable(),
        itemCodeInBp: Yup.string().nullable(),
        qty: Yup.number().positive().required(),
        quantityUnitId: Yup.string().nullable(),
        whsCode: Yup.string().nullable(),
        toWhsCode: Yup.string().nullable(),
        unitPrice: Yup.string().nullable(),
        currencyCode: Yup.string().nullable(),
        vatCode: Yup.string().nullable(),
        vatPercent: Yup.string().nullable(),
        vatValue: Yup.string().nullable(),
        netPrice: Yup.string().nullable(),
        grossPrice: Yup.string().nullable(),
        dueDate: Yup.string().required(),
        vatValueInOwnCur: Yup.string().nullable(),
        netPriceInOwnCur: Yup.string().nullable(),
        grossPriceInOwnCur: Yup.string().nullable(),
        weightChangeNumber: Yup.string().nullable(),
        weight: Yup.string().nullable(),
        projectId: Yup.string().nullable(),
        costCenterCode: Yup.string().nullable(),
        itemReference: Yup.string().nullable(),
        internalNote: Yup.string().nullable(),
        bpNote: Yup.string().nullable(),
      })
    ),
  });

  const insertObject = (values) => {
    const insertObject = {
      ...cleanValues(values),
      createdBy: user.userId,
      docDate: dayjs(values.docDate).startOf('day').add(12, 'hour').toISOString(),
      issueDate: dayjs(values.issueDate).startOf('day').add(12, 'hour').toISOString(),
      dueDate: dayjs(values.dueDate).startOf('day').add(12, 'hour').toISOString(),
    };

    documentService
      .createDocument(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data);
        setRowSelectionModel([data.docId]);
      })

      .finally(() => {
        //@TODO loader
      });
  };

  const updateObject = (values) => {
    const updData = {
      ...cleanValues(values),
      updatedBy: user.userId,
      docDate: dayjs(values.docDate).startOf('day').add(12, 'hour').toISOString(),
      issueDate: dayjs(values.issueDate).startOf('day').add(12, 'hour').toISOString(),
      dueDate: dayjs(values.dueDate).startOf('day').add(12, 'hour').toISOString(),
    };

    documentService.updateDocument(updData, selectedValues.docId).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
      setSelectedValues(data);
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      insertObject(values);
    }
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    documentService.deleteDocument(deleteData, selectedValues.docId, docTypeId).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues({});
    });
  };

  const printClick = () => {
    if (documentData?.printReport?.length === 0) {
      return addNotification({
        content: 'Nem áll rendelkezésre nyomtatási kép!',
        type: NotificationType.WARNING,
      });
    }

    addNotification({
      content: 'Nyomtatás elindult!',
      type: NotificationType.INFO,
    });

    documentService.printDocument({
      docId: selectedValues.id,
      printReportId: documentData?.printReport[0]?.id,
    });
  };

  return (
    <>
      <FormBase
        name="Document"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <div className="flex items-center flex-wrap">
                <FormHeaderUI
                  addClick={() => {
                    setSelectedValues({});
                    setViewField(false);
                    setAddField(false);
                  }}
                  addDisabled={!viewField}
                  modClick={() => {
                    setViewField(false);
                    setEditButtonvisible(false);
                  }}
                  modDisabled={!viewField || operatorsDisabled}
                  deleteClick={() => {
                    setShowDeleteDialog(true);
                  }}
                  deleteDisabled={!viewField || operatorsDisabled}
                  copyClick={() => {
                    setViewField(false);
                    setAddField(false);
                  }}
                  copyDisabled={!viewField || operatorsDisabled}
                />

                <ButtonUI
                  text="Nyomtatás"
                  size="sm"
                  className="bg-labelColor py-3"
                  onClick={() => printClick()}
                  disabled={!viewField || operatorsDisabled}
                />
              </div>

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ resetForm }) => (
                  <Form>
                    <DocumentFields
                      viewField={viewField}
                      selectedValues={selectedValues}
                      autocompleteOptions={autocompleteOptions}
                      autocompleteData={autocompleteData}
                      documentData={documentData}
                      selectedCompanyState={{ selectedCompany, setSelectedCompany }}
                      companyContactsState={{ companyContacts, setCompanyContacts }}
                      docTypeId={docTypeId}
                    />

                    <DocumentTabs
                      viewField={viewField}
                      setRefetchTable={setRefetchTable}
                      selectedValues={selectedValues}
                      user={user}
                      disabled={viewField}
                      defaultField={defaultDocumentLine}
                      autocompleteOptions={autocompleteOptions}
                      autocompleteData={autocompleteData}
                      documentData={documentData}
                      selectedCompanyState={{ selectedCompany, setSelectedCompany }}
                      companyContactsState={{ companyContacts, setCompanyContacts }}
                      docTypeId={docTypeId}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);
                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <>
            <DocumentReports
              refetch={{ refetchTable, setRefetchTable }}
              values={{ selectedValues, setSelectedValues }}
              viewField={viewField}
              operatorsDisabled={operatorsDisabled}
              rowSelection={{ rowSelectionModel, setRowSelectionModel }}
              autocompleteOptions={autocompleteOptions}
              docTypeId={docTypeId}
            />
          </>
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

export default DocumentPage;
