import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, SelectUI } from '../Interface';
import { Type, TypeOption } from '../../config/kshClassification';

export const KshClassificationFields = ({ viewField }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();

  useEffect(() => {
    if (!values.type) {
      setFieldValue('type', Type.VTSZ);
    }
  }, [values.type]);

  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="md:col-span-2 col-span-4">
        <Field
          name="type"
          component={SelectUI}
          fullWidth
          option={TypeOption}
          value={values.type ?? ''}
          label={<FormLabelUI text="Típus" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.type} />}
          disabled={viewField}
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('type', value ?? null).then(() => {
              validateField('type');
            });
          }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="number"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Szám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.number} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.number && values.number !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Megnevezés" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name && values.name !== '' }}
        />
      </div>
    </div>
  );
};
