import React, { useEffect, useMemo, useState } from 'react';

import { ColorPicker } from '../../config/ColorPicker';
import { dataGridPremiumColumns } from '../../utils/helper';
import productionStatusService from '../../service/productionStatus.service';

import AddDialog from './AddDialog';
import { DataGridIdentifiers, ColorPickerUI, DatagridPremiumUi, CheckboxUI } from '../Interface';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

export const ProductionStatusPage = (props) => {
  const [addModalShow, setAddModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [refetchTable, setRefetchTable] = useState(false);
  const [rows, setRows] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'visOrder', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  const getList = (gridState) => {
    productionStatusService.getRows(gridState).then((data) => {
      setRows(data);
    });
  };

  const addDefaultValue = async (code) => {
    if (code !== null) {
      productionStatusService.updateDefaultValue(code).then(() => {
        addNotification({
          content: 'Alapértelmezett érték frissítve!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
      });
    }
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'defaultValue',
        headerName: 'Alapértelmezett státusz',
        type: 'boolean',
        filterable: false,
        renderCell: (data) => {
          return (
            <CheckboxUI
              checked={data.row.defaultValue}
              onChange={() => {
                addDefaultValue(data.row.code);
              }}
            />
          );
        },
      },
      {
        field: 'code',
        headerName: 'Kód',
        type: 'string',
      },
      {
        field: 'name',
        headerName: 'Megnevezés',
        type: 'string',
      },
      {
        field: 'color',
        type: 'string',
        headerName: 'Beállított szín',
        renderCell: (params) => {
          const colorItem = ColorPicker.find((col) => col.colorName === params.value);
          return (
            <div className="pt-1">
              <ColorPickerUI disabled color={colorItem ? colorItem.colorCode : '#FFFFFF'} />
            </div>
          );
        },
      },
      {
        field: 'visOrder',
        headerName: 'Sorrend',
        type: 'number',
      },
    ]);
  }, []);

  const handleCellClick = (param, event) => {
    if (param.field === 'color' || param.field === 'name') {
      if (!props.viewField) {
        setSelectedItem(param.row);
        setAddModalShow(true);
      }
    } else {
      return;
    }
  };

  return (
    <>
      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
        <DatagridPremiumUi
          datagridIdentifier={DataGridIdentifiers.productionStatusTable}
          datagridInitialState={initialState}
          dataLoader={getList}
          refetch={{ refetchTable, setRefetchTable }}
          rowCount={rows.rowCount}
          columns={[...columns]}
          rows={rows.rows}
          getRowId={(row) => row.code}
          disableRowSelectionOnClick={true}
          onCellClick={handleCellClick}
          paginationAndfilterMode="server"
        />
      </div>

      <AddDialog
        show={addModalShow}
        row={{ setRows, rows }}
        onHide={() => {
          setAddModalShow(false);
          setSelectedItem({});
        }}
        setRefetchTable={setRefetchTable}
        selectedItem={selectedItem}
      />
    </>
  );
};
