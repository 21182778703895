import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, AutoCompleteSelectUI } from '../Interface';

const FormFields = ({ viewField, autoCompleteOptions, selectedValues }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();

  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Telephely neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name && values.name !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="nameInPrint"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Nyomtatásban megjelenő név" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.nameInPrint} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.nameInPrint && values.nameInPrint !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="countryCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Ország" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.countryCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('countryCode', newVal?.value ?? null).then(() => {
              validateField('countryCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.countryCode !== '' }}
          selectedValue={values.countryCode}
          selectedLabelValue={selectedValues?.country?.name}
          dataset={autoCompleteOptions.country}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="province"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Megye" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.province} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.province && values.province !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="postCode"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Irányítószám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.postCode} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.postCode && values.postCode !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="city"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Város" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.city} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.city && values.city !== '' }}
        />
      </div>

      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="streetAddress"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Utca, házszám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.streetAddress} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.streetAddress && values.streetAddress !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="website"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Weboldal" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.website} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.website && values.website !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="email"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Email cím" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.email} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.email && values.email !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="phoneNumber"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Telefonszám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.phoneNumber} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.phoneNumber && values.phoneNumber !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="GLN"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="GLN" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.GLN} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.GLN && values.GLN !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="other"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Egyéb" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.other} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.other && values.other !== '' }}
        />
      </div>
    </div>
  );
};

export default FormFields;
