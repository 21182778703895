import React, { useState } from 'react';
import { Box, Chip, IconButton, Typography } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ColorPicker } from '../../config/ColorPicker';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';

import { ButtonUI, ConfirmDialogUI, LinearStaticProgressUI } from '../Interface';
import Fields from './Fields';
import productionService from '../../service/production.service';
import { styled } from '@mui/material/styles';

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: 'white',
  background: '#4B50ED',
  borderRadius: '7px',
  '&:hover': {
    background: '#4B50ED',
  },
  '&.Mui-disabled': {
    background: '#7D81F2 !important',
    color: '#D6D6D6',
  },
}));

const JobMonitorCard = ({ data, handleNavigate, getProductionById, setProdOperations, params, previousJobs }) => {
  const bgColor = ColorPicker.find((color) => color.colorName === data?.productionStatus?.color);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogData, setConfirmDialogData] = useState(null);

  const onGoingStatus = params.JOBMONITOR_PLAY_STATUS ? params.JOBMONITOR_PLAY_STATUS : '';
  const interruptedStatus = params.JOBMONITOR_PAUSED_STATUS ? params.JOBMONITOR_PAUSED_STATUS : '';
  const finishedStatus = params.JOBMONITOR_STOPPED_STATUS ? params.JOBMONITOR_STOPPED_STATUS : '';

  const { totalReportedQty, totalReportedTimeSpent } = data.productionReport.reduce(
    (acc, report) => {
      if (report.prodOperationId === data.id) {
        acc.totalReportedQty += Number(report.quantity);
        acc.totalReportedTimeSpent += Number(report.timeSpent);
      }
      return acc;
    },
    { totalReportedQty: 0, totalReportedTimeSpent: 0 }
  );

  const productionQuantity = Number(data.production.productionQuantity);
  const preparedness = productionQuantity > 0 ? Math.round((totalReportedQty / productionQuantity) * 100) : 0;

  const handleStatusUpdate = (productionStatusId) => {
    productionService
      .updateStatus({ id: data.id, productionStatusId })
      .then((updatedData) => {
        setProdOperations((prevState) => {
          if (productionStatusId === finishedStatus || (previousJobs && productionStatusId === onGoingStatus)) {
            const filteredRows = prevState.rows.filter((row) => row.id !== updatedData.id);
            return { ...prevState, rows: filteredRows };
          } else {
            const newRows = prevState.rows.map((row) => {
              if (row.id === updatedData.id) {
                return { ...row, ...updatedData };
              }
              return row;
            });
            return { ...prevState, rows: newRows };
          }
        });
      })
      .finally(() => {});
  };

  const openConfirmDialog = (productionStatusId) => {
    let message = '';
    switch (productionStatusId) {
      case onGoingStatus:
        message = `Biztosan elindítja a ${data.label} gyártási műveletet?`;
        break;
      case interruptedStatus:
        message = `Biztosan megállítja a ${data.label} gyártási műveletet?`;
        break;
      case finishedStatus:
        message = `Biztosan befejezi a ${data.label} gyártási műveletet?`;
        break;
      default:
        message = `Biztosan végre akarja hajtani ezt a műveletet?`;
        break;
    }

    setConfirmDialogData({
      productionStatusId,
      action: () => {
        handleStatusUpdate(productionStatusId);
        setShowConfirmDialog(false);
      },
      message: message,
    });
    setShowConfirmDialog(true);
  };

  return (
    <Box
      className="min-h-[200px] my-5 rounded-md p-4 shadow-md"
      sx={{ backgroundColor: bgColor?.jobMonitorBgColor, '&:hover': { background: bgColor?.jobMonitorHoverColor } }}
    >
      <div className="flex justify-between w-full flex-wrap">
        <div className="flex items-center gap-3 flex-wrap">
          <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{data.label}</Typography>
          <Chip
            label={data?.productionStatus?.name}
            size="small"
            sx={{ backgroundColor: bgColor?.jobMonitorChipColor }}
          />
          <Typography>{data.production.articleName}</Typography>
        </div>
        <div className="w-[400px] my-2">
          <LinearStaticProgressUI bgColor={bgColor?.jobMonitorChipColor} value={preparedness} />
        </div>
      </div>
      <div className="flex justify-end gap-3">
        <ButtonUI
          text="Lejelentés"
          className="bg-white text-black py-2.5"
          onClick={() => handleNavigate(data.id)}
          size="sm"
        />

        <Box
          className="bg-white text-black px-2 rounded pt-[0.4rem] cursor-pointer"
          onClick={() => getProductionById(data.productionId)}
        >
          <RemoveRedEyeIcon sx={{ fontSize: '1.4rem' }} />
        </Box>

        <Box>
          <CustomIconButton
            disabled={onGoingStatus === data.productionStatusId}
            onClick={() => openConfirmDialog(onGoingStatus)}
          >
            <PlayArrowOutlinedIcon sx={{ fontSize: '1.4rem' }} />
          </CustomIconButton>
        </Box>

        <Box>
          <CustomIconButton
            disabled={(onGoingStatus || interruptedStatus) !== data.productionStatusId}
            onClick={() => openConfirmDialog(interruptedStatus)}
          >
            <PauseOutlinedIcon sx={{ fontSize: '1.4rem' }} />
          </CustomIconButton>
        </Box>

        <Box>
          <CustomIconButton
            disabled={!(onGoingStatus === data.productionStatusId || interruptedStatus === data.productionStatusId)}
            onClick={() => openConfirmDialog(finishedStatus)}
          >
            <StopCircleOutlinedIcon sx={{ fontSize: '1.4rem' }} />
          </CustomIconButton>
        </Box>
      </div>
      <Fields
        data={data}
        bgColor={bgColor}
        totalReportedQty={totalReportedQty}
        totalReportedTimeSpent={totalReportedTimeSpent}
        params={params}
      />
      <ConfirmDialogUI
        message={confirmDialogData?.message}
        handleChangeElement={confirmDialogData?.action}
        show={showConfirmDialog}
        onHide={() => setShowConfirmDialog(false)}
      />
    </Box>
  );
};

export default JobMonitorCard;
