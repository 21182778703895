import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import DialogUI from '../Interface/DialogUI';
import historyService from '../../service/history.service';

const HistoryDialog = ({ formId, lineId, open, handleClose, fieldConfig = [] }) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    if (formId && lineId && open) {
      getHistories();
    }
  }, [formId, lineId, open]);

  const getHistories = () => {
    historyService.getHistoriesById(formId, lineId).then((data) => {
      setHistory(data);
    });
  };

  const getModifiedFields = (row) => {
    const modifiedFields = { ...row };

    const formatIfDate = (value) => {
      if (dayjs(value).isValid()) {
        return dayjs(value).format('YYYY.MM.DD.');
      }
      return value;
    };

    modifiedFields.oldValue = formatIfDate(row.oldValue);
    modifiedFields.newValue = formatIfDate(row.newValue);

    const fieldSetting = fieldConfig.find((config) => config.field === row.key);

    if (fieldSetting) {
      const { objKey } = fieldSetting;

      modifiedFields.oldValue = row.oldValue?.[objKey];
      modifiedFields.newValue = row.newValue?.[objKey];
    }

    if (row.key === 'dynamicProperties') {
      const oldVal =
        row.oldValue?.textVal ||
        row.oldValue?.numberVal ||
        row.oldValue?.longTextVal ||
        (row.oldValue?.dateVal ? dayjs(row.oldValue?.dateVal).format('YYYY.MM.DD.') : null) ||
        row.oldValue?.comboBoxVal;

      const newVal =
        row.newValue?.textVal ||
        row.newValue?.numberVal ||
        row.newValue?.longTextVal ||
        (row.newValue?.dateVal ? dayjs(row.newValue?.dateVal).format('YYYY.MM.DD.') : null) ||
        row.newValue?.comboBoxVal;

      modifiedFields.oldValue = oldVal;
      modifiedFields.newValue = newVal;
      modifiedFields.label = row.oldValue?.property?.name;
    }

    return modifiedFields;
  };

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent="Módosítások áttekintése"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1100px',
          },
        },
      }}
    >
      <div className="max-h-[750px] overflow-y-scroll overflow-x-hidden custom-scrollbar mt-5">
        <table className="border w-full">
          <thead className="bg-tableHeaderColor">
            <tr className="grid grid-cols-7">
              <th className="border p-3 col-span-1">Módosította</th>
              <th className="border p-3 col-span-1">Mező</th>
              <th className="border p-3 col-span-1">Módosítás dátuma</th>
              <th className="border p-3 col-span-2">Régi érték</th>
              <th className="border p-3 col-span-2">Új érték</th>
            </tr>
          </thead>
          <tbody>
            {history.map((row, index) => {
              const modRow = getModifiedFields(row);
              return (
                <tr key={row.index} className={`grid grid-cols-7 ${index % 2 === 0 ? 'bg-gray-100 ' : ''}`}>
                  <td className="border py-1 col-span-1 text-center">{modRow.user}</td>
                  <td className="border py-1 col-span-1 text-center">{modRow.label}</td>
                  <td className="border py-1 col-span-1 text-center">
                    {dayjs(row.updatedAt).format('YYYY.MM.DD HH:mm')}
                  </td>
                  <td
                    className={`border py-1 col-span-2 overflow-hidden break-words whitespace-pre-line ${modRow.changeType === 'deleted' ? 'text-red-700' : ''}`}
                  >
                    {modRow.oldValue}
                  </td>
                  <td
                    className={`border py-1 col-span-2 overflow-hidden break-words whitespace-pre-line ${modRow.changeType === 'created' ? 'text-green-700' : ''}`}
                  >
                    {modRow.newValue}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </DialogUI>
  );
};
export default HistoryDialog;
