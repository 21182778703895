import React, { useCallback } from 'react';
import { Field, useFormikContext } from 'formik';
import { InputUI } from '../Interface';

const ItemPriceField = ({ row, index, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const priceFieldName = `itemPrice[${index}].price`;

  const handleChange = useCallback(
    (e) => {
      let value = e.target.value;
      if (row.currency.amountRounding === 0) {
        value = value.includes('.') ? value.substring(0, value.indexOf('.')) : value;
      } else {
        const regex = new RegExp(`^\\d*\\.?\\d{0,${row.currency.amountRounding}}$`);
        if (!regex.test(value)) {
          return;
        }
      }
      setFieldValue(priceFieldName, value);
    },
    [priceFieldName, setFieldValue, row.currency.amountRounding]
  );

  return (
    <Field
      name={priceFieldName}
      as={InputUI}
      fullWidth
      variant="standard"
      onChange={handleChange}
      type="number"
      {...props}
    />
  );
};

export default ItemPriceField;
