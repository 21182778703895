import React from 'react';
import { Field, useFormikContext } from 'formik';
import { FormErrorUI, FormLabelUI, InputUI } from '../Interface';

const DocumentDescription = ({ viewField }) => {
  const { values, errors } = useFormikContext();
  return (
    <div>
      <div className="col-span-full relative">
        <div className="mt-2">
          <div className="col-span-4 md:col-span-1">
            <Field
              type="text"
              name="note"
              as={InputUI}
              className={`${viewField ? 'rounded-md' : ''} resize-none h-36, p-2`}
              fullWidth
              label={<FormLabelUI text="Megjegyzés" />}
              variant="standard"
              helperText={<FormErrorUI message={errors.note} />}
              disabled={viewField}
              InputLabelProps={{ shrink: Boolean(values.note) }}
              multiline
              rows={4}
            />
          </div>
          <div className="col-span-4 md:col-span-1">
            <Field
              type="text"
              name="internalNote"
              as={InputUI}
              className={`${viewField ? 'rounded-md' : ''} resize-none h-36, p-2`}
              fullWidth
              label={<FormLabelUI text="Belső megjegyzés" />}
              variant="standard"
              helperText={<FormErrorUI message={errors.internalNote} />}
              disabled={viewField}
              InputLabelProps={{ shrink: Boolean(values.internalNote) }}
              multiline
              rows={4}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentDescription;
