import { useState, useEffect, useMemo } from 'react';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import documentService from '../../service/newDoc.service';
import dayjs from 'dayjs';

const DocumentReports = ({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autocompleteOptions,
  docTypeId,
}) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues, selectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'docNum', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  const [gridState, setGridState] = useState(initialState);

  useEffect(() => {
    getList();
  }, [docTypeId, gridState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = () => {
    documentService.getDocuments(gridState, docTypeId).then((data) => {
      setReports(data);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'docNum',
        headerName: 'Bizonylatszám',
        type: 'string',
      },
      {
        field: 'companyId',
        headerName: 'Cég',
        type: 'singleSelect',
        sortable: false,
        renderCell: (data) => {
          return data.row.company?.name;
        },
        valueOptions: autocompleteOptions.company,
      },

      {
        field: 'docDate',
        headerName: 'Teljesítés dátuma',
        type: 'date',
        valueGetter: (_data, row) => {
          return new Date(row.docDate);
        },
        renderCell: (data) => {
          return dayjs(data.row.docDate).format('YYYY-MM-DD');
        },
      },
      {
        field: 'issueDate',
        headerName: 'Kiállítás dátuma',
        type: 'date',
        valueGetter: (_data, row) => {
          return new Date(row.issueDate);
        },
        renderCell: (data) => {
          return dayjs(data.row.issueDate).format('YYYY-MM-DD');
        },
      },

      {
        field: 'dueDate',
        headerName: 'Határidő',
        type: 'date',
        valueGetter: (_data, row) => {
          return new Date(row.dueDate);
        },
        renderCell: (data) => {
          return dayjs(data.row.dueDate).format('YYYY-MM-DD');
        },
      },

      {
        field: 'projectId',
        headerName: 'Projekt',
        type: 'singleSelect',
        sortable: false,
        renderCell: (data) => {
          return data.row.project?.projectName;
        },
        valueOptions: autocompleteOptions.project,
      },
    ]);
  }, [autocompleteOptions]);

  return (
    <>
      {Object.keys(autocompleteOptions).length > 0 && (
        <DatagridPremiumUi
          datagridIdentifier={DataGridIdentifiers.documentReports}
          datagridInitialState={initialState}
          dataLoader={getList}
          rows={reports.rows}
          refetch={{ refetchTable, setRefetchTable }}
          rowCount={reports.rowCount}
          columns={[...columns]}
          onRowClick={(rowData) => {
            if (!viewField) return;
            setSelectedValues(rowData.row);
          }}
          paginationAndfilterMode="server"
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          disableRowSelectionOnClick={!viewField}
          onGridStateChange={setGridState}
          getRowId={(row) => row.docId}
        />
      )}
    </>
  );
};

export default DocumentReports;
