import { useState } from 'react';
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid-premium';
import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';

import { dataGridPremiumColumns } from '../../utils/helper';

import {
  DatagridPremiumUi,
  DataGridIdentifiers,
  FormErrorUI,
  InputUI,
  SelectUI,
  AddButtonUi,
  CheckboxUI,
} from '../Interface';

function EditToolbar(props) {
  const { setRowModesModel, disabled, push, defaultContact } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    defaultContact.id = uuidv4();
    await push(defaultContact);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultContact.id]: { mode: GridRowModes.Edit },
    }));

    const fieldsToValidate = [
      `contact.${values.contact.length ?? 0}.name`,
      `contact.${values.contact.length ?? 0}.role`,
      `contact.${values.contact.length ?? 0}.email`,
      `contact.${values.contact.length ?? 0}.phoneNumber`,
      `contact.${values.contact.length ?? 0}.phoneNumber2`,
      `contact.${values.contact.length ?? 0}.comment`,
      `contact.${values.contact.length ?? 0}.default`,
      `contact.${values.contact.length ?? 0}.active`,
      `contact.${values.contact.length ?? 0}.other`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
    }
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
}

const CompanyContact = ({ params, disabled, defaultContact }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();
  const [rowModesModel, setRowModesModel] = useState({});

  const handleDefaultChange = (id, checked, dataApi) => {
    const currentIndex = values.contact.findIndex((c) => c.id === id);

    if (checked) {
      values.contact.forEach((contact, index) => {
        if (contact.id !== id && contact.default) {
          setFieldValue(`contact.${index}.default`, false);
          dataApi.setEditCellValue({
            id: contact.id,
            field: 'default',
            value: false,
          });
        }
      });

      setFieldValue(`contact.${currentIndex}.default`, true);
      dataApi.setEditCellValue({
        id: id,
        field: 'default',
        value: true,
      });
    } else {
      setFieldValue(`contact.${currentIndex}.default`, false);
      dataApi.setEditCellValue({
        id: id,
        field: 'default',
        value: false,
      });
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.contact.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    const fieldsToValidate = [
      `contact.${valueIndex}.name`,
      `contact.${valueIndex}.role`,
      `contact.${valueIndex}.email`,
      `contact.${valueIndex}.phoneNumber`,
      `contact.${valueIndex}.phoneNumber2`,
      `contact.${valueIndex}.comment`,
      `contact.${valueIndex}.default`,
      `contact.${valueIndex}.active`,
      `contact.${valueIndex}.other`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
      if (errors?.contact?.[valueIndex]?.[field.split('.').pop()]) {
        return;
      }
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const roleOptionParam = params.PROJECT_CONTACT_ROLE_OPTIONS;
  const roleOptions = roleOptionParam ? JSON.parse(roleOptionParam) : [];

  const roleOption = roleOptions && roleOptions.length > 0;

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridPremiumColumns([
      {
        field: 'name',
        headerName: 'Név',
        type: 'string',
        editable: true,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`contact.${valueIndex}.name`}
              as={InputUI}
              variant="standard"
              fullWidth
              helperText={<FormErrorUI message={errors?.contact?.[valueIndex]?.name} />}
              onChange={(e) => {
                setFieldValue(`contact.${valueIndex}.name`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`contact.${valueIndex}.name`)}
            />
          );
        },
      },
      {
        field: 'role',
        headerName: 'Szerep',
        type: 'string',
        editable: true,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              sx={
                roleOption && {
                  height: '19px',
                  '&.MuiInputBase-root': { paddingBottom: '15px' },
                }
              }
              name="role"
              as={roleOption ? SelectUI : InputUI}
              helperText={<FormErrorUI message={errors?.contact?.[valueIndex]?.role} />}
              variant="standard"
              option={roleOptions}
              value={data.row.role ?? ''}
              onChange={(e) => {
                setFieldValue(`contact.${valueIndex}.role`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`contact.${valueIndex}.role`)}
            />
          );
        },
      },
      {
        field: 'phoneNumber',
        headerName: 'Telefonszám',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`contact.${valueIndex}.phoneNumber`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.contact?.[valueIndex]?.phoneNumber} />}
              onChange={(e) => {
                setFieldValue(`contact.${valueIndex}.phoneNumber`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`contact.${valueIndex}.phoneNumber`)}
            />
          );
        },
      },
      {
        field: 'email',
        headerName: 'Email',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`contact.${valueIndex}.email`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.contact?.[valueIndex]?.email} />}
              onChange={(e) => {
                setFieldValue(`contact.${valueIndex}.email`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`contact.${valueIndex}.email`)}
            />
          );
        },
      },
      {
        field: 'phoneNumber2',
        headerName: 'Telefonszám2',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`contact.${valueIndex}.phoneNumber2`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.contact?.[valueIndex]?.phoneNumber2} />}
              onChange={(e) => {
                setFieldValue(`contact.${valueIndex}.phoneNumber2`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`contact.${valueIndex}.phoneNumber2`)}
            />
          );
        },
      },
      {
        field: 'comment',
        headerName: 'Megjegyzés',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`contact.${valueIndex}.comment`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.contact?.[valueIndex]?.comment} />}
              onChange={(e) => {
                setFieldValue(`contact.${valueIndex}.comment`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`contact.${valueIndex}.comment`)}
            />
          );
        },
      },
      {
        field: 'default',
        headerName: 'Alaértelmezett',
        type: 'boolean',
        editable: true,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          return (
            <Field
              name={`contact.${valueIndex}.default`}
              component={CheckboxUI}
              helperText={<FormErrorUI message={errors?.contact?.[valueIndex]?.default} />}
              checked={values.contact?.[valueIndex]?.default}
              variant="standard"
              onChange={(e) => handleDefaultChange(data.id, e.target.checked, data.api)}
              onBlur={async () => await validateField(`contact.${valueIndex}.default`)}
            />
          );
        },
      },
      {
        field: 'active',
        type: 'boolean',
        headerName: 'Aktív',
        editable: true,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          return (
            <Field
              name={`contact.${valueIndex}.active`}
              component={CheckboxUI}
              helperText={<FormErrorUI message={errors?.contact?.[valueIndex]?.active} />}
              checked={values.contact?.[valueIndex]?.active}
              variant="standard"
              onChange={(e) => {
                setFieldValue(`contact.${valueIndex}.active`, e.target.checked ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.checked,
                });
              }}
              onBlur={async () => await validateField(`contact.${valueIndex}.active`)}
            />
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <FieldArray name="contact">
            {({ remove, push }) => (
              <DatagridPremiumUi
                datagridIdentifier={DataGridIdentifiers.CompanyContact}
                height={400}
                columns={columns(setFieldValue, validateField, errors, remove)}
                rows={values?.contact ?? []}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultContact,
                    setRowModesModel,
                  },
                }}
                onCellDoubleClick={(_params, event) => {
                  event.defaultMuiPrevented = true;
                }}
                getRowHeight={() => 'auto'}
              />
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default CompanyContact;
