import React, { useEffect, useState } from 'react';
import { Typography } from '@material-tailwind/react';
import { IconButton } from '@mui/material';
import EastIcon from '@mui/icons-material/East';

import sapResourcePlannerService from '../../../service/sapResourcePlanner.service';
import { DataGridUI, DataGridIdentifiers, DebouncedInputUI, DatagridPremiumUi } from '../../Interface';
import { dataGridColumns, dataGridPremiumColumns } from '../../../utils/helper';
import DependecyListSelectedOpPanel from './DependecyListSelectedOpPanel';
import { GridToolbarContainer } from '@mui/x-data-grid-premium';

const Toolbar = () => {
  return <GridToolbarContainer />;
};

const DependencyList = ({ task, getProductionOrdersWithSapProDependencies, getProductionOrderById }) => {
  const defaultGridState = { rows: [], rowCount: 0 };
  const [operations, setOperations] = useState(defaultGridState);
  const [selectedOperation, setSelectedOperation] = useState({});
  const [dependencies, setDependencies] = useState(defaultGridState);
  // @TODO ki kell deríteni, hogy ennek a selectjével mit tudok kezdeni
  const [selectedDependency, setSelectedDependency] = useState({});
  const [filter, setFilter] = useState('');

  useEffect(() => {
    if (task) {
      getSapDependencyList();
      setFilter(task.Project);
      setSelectedOperation({});
      setSelectedDependency({});
    }
  }, [task]);

  useEffect(() => {
    setOperations(defaultGridState);
    setDependencies(defaultGridState);
  }, []);

  useEffect(() => {
    if (filter) {
      sapResourcePlannerService
        .getSapProdOperationForDependency(filter)
        .then((data) => setOperations({ rows: data, rowCount: data?.length }));
    } else {
      setOperations(defaultGridState);
    }
  }, [filter]);

  useEffect(() => {
    if (Object.keys(selectedDependency).length > 0) {
      setSelectedOperation(selectedDependency?.prodOrder);
    }
  }, [selectedDependency]);

  const getSapDependencyList = () => {
    //getProductionOrdersWithSapProDependencies();
    if (task.DocEntry !== undefined && task.DocEntry !== null && task.LineNum !== undefined && task.LineNum !== null) {
      sapResourcePlannerService.getSapDependencyList(task.DocEntry, task.LineNum).then((data) => {
        setDependencies({ rows: data, rowCount: data?.length });
        setSelectedOperation({});
        setSelectedDependency({});
      });
    }
  };

  const operationGridColumns = dataGridPremiumColumns([
    {
      field: 'DocNum',
      headerName: 'GYU',
      flex: 1,
    },
    {
      field: 'U_QNT_RAJZSZAM',
      headerName: 'Rajzszám',
      flex: 1,
    },
    {
      field: 'Project',
      headerName: 'Projekt',
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        return (
          <div>
            {params.row.Project}

            <IconButton
              onClick={(e) => {
                const lineId = `${params.row.DocEntry}_${params.row.LineNum}`;
                getProductionOrderById(lineId);
              }}
              sx={{ marginLeft: '1rem' }}
            >
              <EastIcon
                sx={{
                  color: '#cebb0d',
                  fontWeight: 'bold',
                  background: '#eee',
                  border: '1px solid #ccc',
                  borderRadius: '2px',
                }}
              />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: 'ItemCode',
      headerName: 'Művelet',
      flex: 1,
    },
    {
      field: 'U_QNT_GEP',
      headerName: 'Gép',
      flex: 1,
    },
    {
      field: 'U_QNT_MEGJ',
      headerName: 'Megjegyzés',
      flex: 1,
    },
  ]);

  const dependenciesGridColumns = dataGridPremiumColumns([
    {
      field: 'Project',
      headerName: 'Projekt',
      flex: 1,
      renderCell: (params) => {
        return (
          <div>
            {params.row.prodOrder.Project}

            <IconButton
              onClick={(e) => {
                const lineId = `${params.row.prodOrder.DocEntry}_${params.row.prodOrder.LineNum}`;
                getProductionOrderById(lineId);
              }}
              sx={{ marginLeft: '1rem' }}
            >
              <EastIcon
                sx={{
                  color: '#cebb0d',
                  fontWeight: 'bold',
                  background: '#eee',
                  border: '1px solid #ccc',
                  borderRadius: '2px',
                }}
              />
            </IconButton>
          </div>
        );
      },
    },
    {
      field: 'operation',
      headerName: 'Művelet',
      valueGetter: (data, row) => {
        return row.prodOrder.ItemCode;
      },
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Típus',
      flex: 0.5,
    },
  ]);

  return (
    <div className=" grid-cols-4 gap-x-4 gap-y-2 mx-2 md:grid">
      {!Object.keys(selectedOperation).length && (
        <>
          <div className="col-span-4 mt-2">
            <Typography>
              <h4 className="font-semibold">Művelet</h4>
            </Typography>
          </div>
          <div className="col-span-4 mt-2 dependencyList">
            <DatagridPremiumUi
              datagridIdentifier={DataGridIdentifiers.operations}
              rows={operations.rows}
              columns={operationGridColumns}
              height={300}
              hideFooter
              onRowClick={(rowData) => {
                setSelectedOperation(rowData.row);
              }}
              slots={{
                toolbar: Toolbar,
              }}
            />
          </div>
        </>
      )}
      {Object.keys(selectedOperation).length > 0 && (
        <DependecyListSelectedOpPanel
          parent={task}
          selectedOperationState={{ selectedOperation, setSelectedOperation }}
          selectedDependencyState={{ selectedDependency, setSelectedDependency }}
          getSapDependencyList={getSapDependencyList}
        />
      )}
      <div className="col-span-4 mt-2">
        <Typography>
          <h4 className="font-semibold">Függőségek</h4>
        </Typography>
      </div>
      <div className="col-span-4 mt-2 dependencyList">
        <DatagridPremiumUi
          datagridIdentifier={DataGridIdentifiers.dependencies}
          rows={dependencies.rows}
          columns={dependenciesGridColumns}
          height={300}
          getRowClassName={(params) => {
            return params.row.parentId ? '' : 'highlight-empty-parent';
          }}
          onRowClick={(rowData) => {
            if (rowData.row.parentId) {
              setSelectedDependency(rowData.row);
            }
          }}
          slots={{
            toolbar: Toolbar,
          }}
          disableRowSelectionOnClick
          onCellClick={(params, event) => {
            if (params.field === 'Project') {
              event.stopPropagation();
            }
          }}
          hideFooter
        />
      </div>
    </div>
  );
};

export default DependencyList;
