import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, DatePickerUI, AutoCompleteSelectUI } from '../Interface';

export const ExchangeRateFields = ({ viewField, selectedValues, addField, autocompleteOptions }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();
  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="date"
          name="rateDate"
          component={DatePickerUI}
          value={values.rateDate}
          fullWidth
          label={<FormLabelUI text="Árfolyam dátum" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.rateDate} />}
          onChange={(date) => {
            setFieldValue('rateDate', date).then(() => {
              validateField('rateDate');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.rateDate !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="currencyCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Pénznem" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.currencyCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('currencyCode', newVal?.value ?? null).then(() => {
              validateField('currencyCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.currencyCode !== '' }}
          selectedValue={values.currencyCode}
          dataset={autocompleteOptions.currency}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="rate"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Árfolyam" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.rate} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.rate && values.rate !== '' }}
        />
      </div>
    </div>
  );
};

export default ExchangeRateFields;
