import React from 'react';
import { useFormikContext } from 'formik';
import { formatCurrency } from '../../utils/currencyHelper';

const DocumentSum = () => {
  const { values } = useFormikContext();

  return (
    <div>
      <div className="col-span-full relative">
        <div className="mt-2">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <div>
                <label>Összesen:</label>
                <div>{formatCurrency(values.sumInDocCur)}</div>
              </div>
              <div>
                <label>Összesen nettó:</label>
                <div>{formatCurrency(values.netInDocCur)}</div>
              </div>
              <div>
                <label>Összesen Áfa:</label>
                <div>{formatCurrency(values.VATInDocCur)}</div>
              </div>
              <div>
                <label>Kerekítés:</label>
                <div>{formatCurrency(values.roundingInDocCur)}</div>
              </div>
            </div>
            <div>
              <div>
                <label>Összesen saját pénznemben:</label>
                <div>{formatCurrency(values.sumInOwnCur)}</div>
              </div>
              <div>
                <label>Összesen nettó saját pénznemben:</label>
                <div>{formatCurrency(values.netInOwnCur)}</div>
              </div>
              <div>
                <label>Összesen Áfa saját pénznemben:</label>
                <div>{formatCurrency(values.VATInOwnCur)}</div>
              </div>
              <div>
                <label>Kerekítés saját pénznemben:</label>
                <div>{formatCurrency(values.roundingInOwnCur)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentSum;
