import { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';
import PrintIcon from '@mui/icons-material/Print';
import dayjs from 'dayjs';
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid-premium';
import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { dataGridPremiumColumns } from '../../../utils/helper';
import {
  DataGridIdentifiers,
  FormErrorUI,
  InputUI,
  AddButtonUi,
  AutoCompleteSelectUI,
  DatagridPremiumUi,
  CheckboxUI,
  DatePickerUI,
  DateTimePickerUI,
  OrderArrowsUI,
} from '../../Interface';
import { NotificationType } from '../../../config';
import addNotification from '../../../utils/addNotification';
import { render } from '@testing-library/react';
import { formatNumber, formatCurrency } from '../../../utils/currencyHelper';
import { DependenceTypeList } from '../../../config/config';

const EditToolbar = (props) => {
  const { setRowModesModel, disabled, push, defaultField } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    const newVisOrder = (values.productionOperation?.length || 0) + 1;

    defaultField.id = uuidv4();
    defaultField.visOrder = newVisOrder;
    await push(defaultField);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultField.id]: { mode: GridRowModes.Edit },
    }));

    const fieldsToValidate = [
      `productionOperation.${values.productionOperation.length ?? 0}.operationId`,
      `productionOperation.${values.productionOperation.length ?? 0}.resourceOneId`,
      `productionOperation.${values.productionOperation.length ?? 0}.productionStatusId`,
      `productionOperation.${values.productionOperation.length ?? 0}.startDate`,
      `productionOperation.${values.productionOperation.length ?? 0}.dependence`,
      `productionOperation.${values.productionOperation.length ?? 0}.description`,
      `productionOperation.${values.productionOperation.length ?? 0}.quantityUnitId`,
      `productionOperation.${values.productionOperation.length ?? 0}.plannedQty`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
    }
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
};

const ProductionOperations = ({
  disabled,
  defaultField,
  autocompleteOptions,
  sourceData,
  calcOperationBaseRate,
  autocompleteData,
  params,
}) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();

  const [lastChangedIndex, setLastChangedIndex] = useState(null);
  const [rowModesModel, setRowModesModel] = useState({});

  useEffect(() => {
    if (lastChangedIndex !== null) {
      calcOperationBaseRate(lastChangedIndex);
    }
  }, [values?.productionOperation?.[lastChangedIndex]?.plannedQty, lastChangedIndex]);

  useEffect(() => {}, [values.plannedQty]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.productionOperation.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    const fieldsToValidate = [
      `productionOperation.${valueIndex}.operationId`,
      `productionOperation.${valueIndex}.resourceOneId`,
      `productionOperation.${valueIndex}.productionStatusId`,
      `productionOperation.${valueIndex}.startDate`,
      `productionOperation.${valueIndex}.dependence`,
      `productionOperation.${valueIndex}.description`,
      `productionOperation.${valueIndex}.quantityUnitId`,
      `productionOperation.${valueIndex}.plannedQty`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);

      if (errors?.productionOperation?.[valueIndex]?.[field.split('.').pop()]) {
        return;
      }
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleOrderChange = (id, currentOrder, direction) => {
    let newOrder = currentOrder + (direction === 'up' ? -1 : 1);
    const maxOrder = Math.max(...values.productionOperation.map((item) => item.visOrder));
    const minOrder = Math.min(...values.productionOperation.map((item) => item.visOrder));

    if (newOrder < minOrder) newOrder = minOrder;
    if (newOrder > maxOrder) newOrder = maxOrder;

    while (!values.productionOperation.some((item) => item.visOrder === newOrder)) {
      newOrder += direction === 'up' ? -1 : 1;
      if (newOrder < minOrder || newOrder > maxOrder) {
        newOrder = direction === 'up' ? minOrder : maxOrder;
        break;
      }
    }

    if (newOrder !== currentOrder) {
      const updatedList = values.productionOperation
        .map((item) => {
          if (item.id === id) {
            return { ...item, visOrder: newOrder };
          } else if (item.visOrder === newOrder) {
            return { ...item, visOrder: currentOrder };
          }
          return item;
        })
        .sort((a, b) => a.visOrder - b.visOrder);

      setFieldValue('productionOperation', updatedList);
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridPremiumColumns([
      {
        field: 'label',
        headerName: 'Művelet azon.',
        type: 'string',
      },
      {
        field: 'operationId',
        headerName: 'Művelet',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const findedOperation = autocompleteOptions.operation.find((item) => item.value === data.row.operationId);
          return findedOperation?.label;
        },

        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedOperation = autocompleteOptions.operation.find((item) => item.value === data.row.operationId);

          const dataset = autocompleteOptions.operation;

          return (
            <Field
              name={`productionOperation.${valueIndex}.operationId`}
              variant="standard"
              component={AutoCompleteSelectUI}
              fullWidth
              dataset={dataset}
              selectedValue={values.productionOperation?.[valueIndex]?.operationId}
              selectedLabelValue={findedOperation?.label}
              helperText={<FormErrorUI message={errors?.productionOperation?.[valueIndex]?.operationId} />}
              onChange={(_e, newVal) => {
                setFieldValue(`productionOperation.${valueIndex}.operationId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
                const selectedOperation = autocompleteData.operation.find((i) => i.operationId === newVal?.value);
                if (selectedOperation) {
                  setFieldValue(
                    `productionOperation.${valueIndex}.resourceOneId`,
                    selectedOperation?.resourceOneId ?? null
                  );
                  data.api.setEditCellValue({
                    id: data.id,
                    field: 'resourceOneId',
                    value: selectedOperation?.resourceOneId ?? null,
                  });
                }
              }}
              onBlur={async () => await validateField(`productionOperation.${valueIndex}.operationId`)}
            />
          );
        },
      },
      {
        field: 'resourceOneId',
        headerName: 'Erőforrás',
        type: 'string',
        editable: true,
        disableOpenOnFocus: true,
        sortable: false,
        renderCell: (data) => {
          const findedReesourceOne = autocompleteOptions.resourceOne.find(
            (item) => item.value === data.row.resourceOneId
          );
          return findedReesourceOne?.label;
        },

        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedReesourceOne = autocompleteOptions.resourceOne.find(
            (item) => item.value === data.row.resourceOneId
          );

          const operationLine = values.productionOperation?.[valueIndex];

          const findedOperation = sourceData?.sourceListOperation?.find(
            (so) => so.operationId === operationLine.operationId
          );

          const dataset = autocompleteOptions.resourceOne;

          return (
            <Field
              name={`productionOperation.${valueIndex}.resourceOneId`}
              variant="standard"
              component={AutoCompleteSelectUI}
              fullWidth
              dataset={dataset}
              selectedValue={values.productionOperation?.[valueIndex]?.resourceOneId}
              selectedLabelValue={findedReesourceOne?.label}
              helperText={<FormErrorUI message={errors?.productionOperation?.[valueIndex]?.resourceOneId} />}
              onChange={async (_e, newVal) => {
                await setFieldValue(`productionOperation.${valueIndex}.resourceOneId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              onBlur={async () => await validateField(`productionOperation.${valueIndex}.resourceOneId`)}
            />
          );
        },
      },
      {
        field: 'productionStatusId',
        headerName: 'Gyártás státusz',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const findedProdStatus = autocompleteOptions.prodStatus.find(
            (item) => item.value === data.row.productionStatusId
          );
          return findedProdStatus?.label;
        },

        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedProdStatus = autocompleteOptions.prodStatus.find(
            (item) => item.value === data.row.productionStatusId
          );

          return (
            <Field
              name={`productionOperation.${valueIndex}.productionStatusId`}
              variant="standard"
              component={AutoCompleteSelectUI}
              fullWidth
              dataset={autocompleteOptions.prodStatus}
              value={findedProdStatus}
              helperText={<FormErrorUI message={errors?.productionOperation?.[valueIndex]?.productionStatusId} />}
              onChange={(_e, newVal) => {
                setFieldValue(`productionOperation.${valueIndex}.productionStatusId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              onBlur={async () => await validateField(`productionOperation.${valueIndex}.productionStatusId`)}
            />
          );
        },
      },
      {
        field: 'startDate',
        headerName: 'Gy. terv. kezdete',
        type: 'date',
        editable: true,
        sortable: false,
        type: 'dateTime',
        valueGetter: (_data, row) => {
          return new Date(row.startDate);
        },
        renderCell: (data) => {
          return dayjs(data.row.startDate).format('YYYY.MM.DD. HH:mm');
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          return (
            <Field
              name={`productionOperation.${valueIndex}.startDate`}
              component={DateTimePickerUI}
              helperText={<FormErrorUI message={errors?.productionOperation?.[valueIndex]?.startDate} />}
              variant="standard"
              value={dayjs(data.row?.startDate)}
              onChange={(newDate) => {
                const dateObject = newDate.toDate();
                setFieldValue(`productionOperation.${valueIndex}.startDate`, dateObject);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: dateObject,
                });
              }}
              onBlur={async () => await validateField(`productionOperation.${valueIndex}.startDate`)}
            />
          );
        },
      },
      {
        field: 'dependence',
        headerName: 'Függőség',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedDependence = DependenceTypeList.find(
            (u) => u.value === values.productionOperation?.[valueIndex]?.dependence
          );

          return findedDependence?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedDependence = DependenceTypeList.find(
            (u) => u.value === values.productionOperation?.[valueIndex]?.dependence
          );
          return (
            <Field
              name={`productionOperation.${valueIndex}.dependence`}
              type="text"
              variant="standard"
              as={AutoCompleteSelectUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.productionOperation?.[valueIndex]?.dependence} />}
              onChange={(_e, newVal) => {
                setFieldValue(`productionOperation.${valueIndex}.dependence`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              onBlur={async () => await validateField(`productionOperation.${valueIndex}.dependence`)}
              value={findedDependence}
              isOptionEqualToValue={(option, value) => option.value === value}
              dataset={DependenceTypeList}
            />
          );
        },
      },
      {
        field: 'baseRate',
        headerName: 'Alaphányad',
        type: 'string',
        sortable: false,
        renderCell: (data) => formatNumber(data.row.baseRate),
      },
      {
        field: 'plannedQty',
        headerName: 'Terv. mennyiség',
        type: 'string',
        editable: true,
        sortable: false,
        valueGetter: (data, row) => {
          const valueIndex = getValueIndex(values, row.id);
          return values?.productionOperation?.[valueIndex]?.plannedQty;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`productionOperation.${valueIndex}.plannedQty`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.productionOperation?.[valueIndex]?.plannedQty} />}
              onChange={(e) => {
                setFieldValue(`productionOperation.${valueIndex}.plannedQty`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
                setLastChangedIndex(valueIndex);
              }}
              onBlur={async () => await validateField(`productionOperation.${valueIndex}.plannedQty`)}
            />
          );
        },
      },
      {
        field: 'quantityUnitId',
        headerName: 'Mennyiségi egység',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const findedQtyUnit = autocompleteOptions.quantityUnit.find((item) => item.value === data.row.quantityUnitId);
          return findedQtyUnit?.label;
        },

        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedQtyUnit = autocompleteOptions.quantityUnit.find((item) => item.value === data.row.quantityUnitId);

          return (
            <Field
              disabled
              name={`productionOperation.${valueIndex}.quantityUnitId`}
              variant="standard"
              component={AutoCompleteSelectUI}
              fullWidth
              dataset={autocompleteOptions.quantityUnit}
              value={findedQtyUnit}
              helperText={<FormErrorUI message={errors?.productionOperation?.[valueIndex]?.quantityUnitId} />}
              onChange={(_e, newVal) => {
                setFieldValue(`productionOperation.${valueIndex}.quantityUnitId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              onBlur={async () => await validateField(`productionOperation.${valueIndex}.quantityUnitId`)}
            />
          );
        },
      },
      {
        field: 'realTimeSpent',
        headerName: 'Tény ráfordítás',
        type: 'string',
        editable: false,
        sortable: false,
        valueGetter: (data, row) => {
          const valueIndex = getValueIndex(values, row.id);

          const operationData = values?.productionOperation?.[valueIndex];

          const foundedQuantityUnit = autocompleteData.quantityUnit.find(
            (unit) => unit.id === values.productionOperation?.[valueIndex]?.quantityUnitId
          );

          if (operationData?.productionReport) {
            const sum = operationData.productionReport.reduce((acc, current) => {
              const valueToAdd =
                foundedQuantityUnit?.code === params.QUANTITY_UNIT_HOUR_CODE
                  ? parseFloat(current.timeSpent)
                  : current.timeSpentInMin;

              return acc + valueToAdd;
            }, 0);

            return Math.round(sum * 100) / 100;
          }

          return 0;
        },
      },
      {
        field: 'realQuantityUnitId',
        headerName: 'Mennyiségi egység',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const findedQtyUnit = autocompleteOptions.quantityUnit.find((item) => item.value === data.row.quantityUnitId);
          return findedQtyUnit?.label;
        },

        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedQtyUnit = autocompleteOptions.quantityUnit.find((item) => item.value === data.row.quantityUnitId);

          return (
            <Field
              disabled
              name={`productionOperation.${valueIndex}.quantityUnitId`}
              variant="standard"
              component={AutoCompleteSelectUI}
              fullWidth
              dataset={autocompleteOptions.quantityUnit}
              value={findedQtyUnit}
              helperText={<FormErrorUI message={errors?.productionOperation?.[valueIndex]?.quantityUnitId} />}
              onChange={(_e, newVal) => {
                setFieldValue(`productionOperation.${valueIndex}.quantityUnitId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              onBlur={async () => await validateField(`productionOperation.${valueIndex}.quantityUnitId`)}
            />
          );
        },
      },
      {
        field: 'visOrder',
        flex: 0.2,
        headerName: 'Sorrend',
        renderCell: (params) => {
          const handleUpClick = () => handleOrderChange(params.row.id, params.row.visOrder, 'up');
          const handleDownClick = () => handleOrderChange(params.row.id, params.row.visOrder, 'down');
          return <OrderArrowsUI onUpClick={handleUpClick} onDownClick={handleDownClick} disabled={disabled} />;
        },
        sortable: false,
      },
      {
        field: 'description',
        headerName: 'Megjegyzés',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`productionOperation.${valueIndex}.description`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.productionOperation?.[valueIndex]?.description} />}
              onChange={(e) => {
                setFieldValue(`productionOperation.${valueIndex}.description`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`productionOperation.${valueIndex}.description`)}
            />
          );
        },
      },
      {
        field: 'chk',
        type: 'actions',
        cellClassName: 'actions',
        headerName: 'Lejelentési pont',
        sortable: false,
        getActions: ({ id, row }) => {
          return [
            <Field
              name="a"
              type="checkbox"
              as={CheckboxUI}
              fullWidth
              //label={<FormLabelUI text="Lejelentési pont" />}
              disabled={disabled}
            />,
          ];
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
      {
        field: 'print',
        type: 'actions',
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              icon={<PrintIcon />}
              label="Save"
              onClick={() =>
                addNotification({
                  content: 'Nyomtatás elindult!',
                  type: NotificationType.INFO,
                })
              }
              color="inherit"
            />,
          ];
        },
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <FieldArray name="productionOperation">
            {({ remove, push }) => (
              <DatagridPremiumUi
                datagridIdentifier={DataGridIdentifiers.ProductionFormTable}
                height={480}
                columns={columns(setFieldValue, validateField, errors, remove)}
                rows={values?.productionOperation ?? []}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultField,
                    setRowModesModel,
                  },
                }}
                onCellDoubleClick={(_params, event) => {
                  event.defaultMuiPrevented = true;
                }}
                headerFilters={false}
                getRowHeight={() => 'auto'}
              />
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default ProductionOperations;
