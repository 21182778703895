export const FormIdentifiers = Object.freeze({
  Project: 'Project',
  ResourceOne: 'ResourceOne',
  SimpleTask: 'SimpleTask',
  SapShiftLeaderReport: 'SapShiftLeaderReport',
  SourceList: 'SourceList',
  VatCode: 'VatCode',
  Country: 'Country',
  Currency: 'Currency',
  Language: 'Language',
  SupplierComplaint: 'SupplierComplaint',
});
