import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from '@mui/material';

const SwitchUI = (props) => {
  const { disabled, label } = props;

  return <FormControlLabel control={<Switch disabled={disabled} {...props} />} label={label} />;
};

export default SwitchUI;
