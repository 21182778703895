import React from 'react';
import { Field, useFormikContext } from 'formik';
import { AutoCompleteSelectUI, FormErrorUI, FormLabelUI, InputUI } from '../Interface';
import { DocumentDirection } from '../../config/DocumentDirection';
const DocumentAdditional = ({ viewField, autocompleteOptions, selectedValues, autocompleteData, documentData }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();

  const handleFieldChange = async (field, newVal) => {
    const selected = newVal?.value ?? null;
    await setFieldValue(field, selected);
    await validateField(field);
  };

  return (
    <div>
      <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="paymentMethodId"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Fizetési mód" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.paymentMethodId} />}
            InputLabelProps={{ shrink: Boolean(values.paymentMethodId) }}
            onChange={(_e, newVal) => handleFieldChange('paymentMethodId', newVal)}
            selectedValue={values.paymentMethodId}
            selectedLabelValue={selectedValues?.paymentMethod?.name}
            dataset={autocompleteOptions?.paymentMethod}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="projectId"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text="Projekt" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.projectId} />}
            onChange={(_e, newVal) => handleFieldChange('projectId', newVal)}
            disabled={viewField}
            InputLabelProps={{ shrink: Boolean(values.projectId) }}
            selectedValue={values.projectId}
            selectedLabelValue={selectedValues?.project?.projectName}
            dataset={autocompleteOptions?.project}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="costCenterCode"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Költséghely" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.costCenterCode} />}
            InputLabelProps={{ shrink: Boolean(values.costCenterCode) }}
            onChange={(_e, newVal) => handleFieldChange('costCenterCode', newVal)}
            selectedValue={values.costCenterCode}
            selectedLabelValue={selectedValues?.costCenter?.name}
            dataset={autocompleteOptions?.costCenter}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="salesOrPurchasePersonId"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={viewField}
            label={
              <FormLabelUI text={documentData.direction === DocumentDirection.INCOMING ? 'Beszerző' : 'Értékesítő'} />
            }
            variant="standard"
            helperText={<FormErrorUI message={errors.salesOrPurchasePersonId} />}
            InputLabelProps={{ shrink: Boolean(values.salesOrPurchasePersonId) }}
            onChange={(_e, newVal) => handleFieldChange('salesOrPurchasePersonId', newVal)}
            selectedValue={values.salesOrPurchasePersonId}
            selectedLabelValue={
              autocompleteData?.salesOrPurchasePerson?.find((p) => p.id === values.salesOrPurchasePersonId)?.name
            }
            dataset={autocompleteOptions?.salesOrPurchasePerson}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="langCode"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Nyelv" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.langCode} />}
            InputLabelProps={{ shrink: Boolean(values.langCode) }}
            onChange={(_e, newVal) => handleFieldChange('langCode', newVal)}
            selectedValue={values.langCode}
            selectedLabelValue={autocompleteData?.language?.find((p) => p.code === values.langCode)?.name}
            dataset={autocompleteOptions?.language}
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
        <div className="col-span-4 md:col-span-1">
          <Field
            type="text"
            name="hunTaxNumber"
            as={InputUI}
            fullWidth
            disabled={true}
            label={<FormLabelUI text="Magyar adószám" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.hunTaxNumber} />}
            InputLabelProps={{ shrink: Boolean(values.hunTaxNumber) }}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          <Field
            type="text"
            name="euTaxNumber"
            as={InputUI}
            fullWidth
            disabled={true}
            label={<FormLabelUI text="Eu adószám" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.euTaxNumber} />}
            InputLabelProps={{ shrink: Boolean(values.euTaxNumber) }}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          <Field
            type="text"
            name="foreignTaxNumber"
            as={InputUI}
            fullWidth
            disabled={true}
            label={<FormLabelUI text="Külföldi adószám" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.foreignTaxNumber} />}
            InputLabelProps={{ shrink: Boolean(values.foreignTaxNumber) }}
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="createdBy"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={true}
            label={<FormLabelUI text="Létrehozta" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.createdBy} />}
            InputLabelProps={{ shrink: Boolean(values.createdBy) }}
            onChange={(_e, newVal) => handleFieldChange('createdBy', newVal)}
            selectedValue={values.createdBy}
            selectedLabelValue={autocompleteData?.users?.find((p) => p.userId === values.createdBy)?.userName}
            dataset={autocompleteOptions?.users}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentAdditional;
