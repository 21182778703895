import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';

import {
  InputUI,
  FormErrorUI,
  FormLabelUI,
  CheckboxUI,
  RadioGroupUI,
  SelectUI,
  AutoCompleteSelectUI,
} from '../Interface';
import { ItemGroupType, ItemGroupTypeList, StockArticleTypeList } from '../../config/itemGroup';

export const ItemGroupFields = ({ viewField, selectedValues, autoCompleteOptions }) => {
  const { values, errors, validateField, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.type !== ItemGroupType.STOCKARTICLE) {
      setFieldValue('stockItemType', '');
      validateField('stockItemType');
    }
  }, [values.type, setFieldValue, validateField]);

  return (
    <div className="grid grid-cols-5 gap-x-4 mx-4 mb-5">
      <div className="md:col-span-3 col-span-5">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Megnevezés" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name && values.name !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-5">
        <Field
          type="autocomplete"
          name="quantityUnitId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Alapértelmezett mennyiségi egység" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.quantityUnitId} />}
          onChange={(_e, newVal) => {
            setFieldValue('quantityUnitId', newVal?.value ?? null).then(() => {
              validateField('quantityUnitId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.quantityUnitId !== '' }}
          selectedValue={values.quantityUnitId}
          selectedLabelValue={selectedValues?.quantityUnit?.name}
          dataset={autoCompleteOptions.quantityUnit}
        />
      </div>
      <div className="md:col-span-5 col-span-5 mt-5">
        <Field
          type="radio"
          name="type"
          as={RadioGroupUI}
          row
          label={<FormLabelUI text="Típus" />}
          helperText={<FormErrorUI message={errors.type} />}
          disabled={viewField}
          value={values.type}
          option={ItemGroupTypeList}
        />
      </div>
      <div className={values.type === ItemGroupType.STOCKARTICLE ? 'col-span-5 md:col-span-2' : 'hidden'}>
        <Field
          name="stockItemType"
          component={SelectUI}
          fullWidth
          option={StockArticleTypeList}
          value={values.stockItemType ?? ''}
          variant="standard"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('stockItemType', value ?? null).then(() => {
              validateField('stockItemType');
            });
          }}
          label={<FormLabelUI text="Készletcikk fajta" />}
          helperText={<FormErrorUI message={errors.stockItemType} />}
          disabled={viewField}
        />
      </div>
      <div className="col-span-5 md:col-span-5 mt-5">
        <Field
          name="inPurchase"
          as={CheckboxUI}
          fullWidth
          checked={values.inPurchase ?? false}
          variant="standard"
          onChange={(e) => {
            setFieldValue('inPurchase', e.target.checked ?? null).then(() => {
              validateField('inPurchase');
            });
          }}
          label={<FormLabelUI text="Beszerzésben részt vesz" />}
          helperText={<FormErrorUI message={errors.inPurchase} />}
          disabled={viewField}
        />
        <Field
          name="inSales"
          as={CheckboxUI}
          fullWidth
          checked={values.inSales ?? false}
          variant="standard"
          onChange={(e) => {
            setFieldValue('inSales', e.target.checked ?? null).then(() => {
              validateField('inSales');
            });
          }}
          label={<FormLabelUI text="Értékesítésben részt vesz" />}
          helperText={<FormErrorUI message={errors.inSales} />}
          disabled={viewField}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="groupPrefix"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Cikkcsoport prefix" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.groupPrefix} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.groupPrefix && values.groupPrefix !== '' }}
        />
      </div>
    </div>
  );
};
