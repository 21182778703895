import React, { createContext, useContext, useReducer } from 'react';
import { configReducer } from '../reducer/configReducer';

const ConfigContext = createContext({});

export const ConfigProvider = ({ children }) => {
  const [state, dispatch] = useReducer(configReducer, {});

  const setConfig = (config) => {
    return dispatch({ type: 'SET', payload: config });
  };

  const getConfig = () => {
    return dispatch({ type: 'GET' });
  };

  const value = {
    config: state,
    setConfig,
    getConfig,
  };

  return <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>;
};

const useConfig = () => {
  return useContext(ConfigContext);
};

export default useConfig;
