import React, { useEffect, useMemo, useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import AirlinesIcon from '@mui/icons-material/Airlines';
import AddBoxIcon from '@mui/icons-material/AddBox';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import AssistantIcon from '@mui/icons-material/Assistant';
import BedroomBabyIcon from '@mui/icons-material/BedroomBaby';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Brightness1Icon from '@mui/icons-material/Brightness1';

import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import usePages from '../../context/PageContext';
import useMenus from '../../context/MenuContext';
import { v4 as uuidv4 } from 'uuid';

export const Widget3Page = () => {
  const { setPages } = usePages();
  const navigate = useNavigate();
  const { menus, getSubMenuNamesByUrls, getMenus } = useMenus();
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);
  const [selectedGrandchild, setSelectedGrandchild] = useState(null);

  useEffect(() => {
    getMenus();
  }, []);

  const cards = [
    {
      id: uuidv4(),
      title: 'Alapozó és talajelemek',
      icon: <AssistantIcon sx={{ fontSize: '2.5rem' }} />,
      children: [
        {
          id: uuidv4(),
          title: 'Alaplemez',
          icon: <AirplaneTicketIcon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Betonalap',
          icon: <AirlinesIcon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Pilléralapok',
          icon: <AddBoxIcon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Talajszigetelés',
          icon: <AnalyticsIcon sx={{ fontSize: '2.5rem' }} />,
        },
      ],
    },
    {
      id: uuidv4(),
      title: 'Tartószerkezeti elemek',
      icon: <ArrowDropDownCircleIcon sx={{ fontSize: '2.5rem' }} />,
      children: [
        {
          id: uuidv4(),
          title: 'Falak',
          icon: <AutoStoriesIcon sx={{ fontSize: '2.5rem' }} />,
          children: [
            {
              id: uuidv4(),
              title: 'Teherhordó falak',
              icon: <BedroomBabyIcon sx={{ fontSize: '2.5rem' }} />,
            },
            {
              id: uuidv4(),
              title: 'Válaszfalak',
              icon: <BookmarkIcon sx={{ fontSize: '2.5rem' }} />,
            },
          ],
        },
        {
          id: uuidv4(),
          title: 'Pillér',
          icon: <Brightness1Icon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Gerendák',
          icon: <AssistantIcon sx={{ fontSize: '2.5rem' }} />, // AssistantIcon reused in different section
        },
        {
          id: uuidv4(),
          title: 'Keresztgerendák és bordák',
          icon: <ArrowDropDownCircleIcon sx={{ fontSize: '2.5rem' }} />, // ArrowDropDownCircleIcon reused in different section
        },
      ],
    },
    {
      id: uuidv4(),
      title: 'Födémek és tetőszerkezetek',
      icon: <AddBoxIcon sx={{ fontSize: '2.5rem' }} />,
      children: [
        {
          id: uuidv4(),
          title: 'Födémek',
          icon: <AnalyticsIcon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Tető',
          icon: <AutoStoriesIcon sx={{ fontSize: '2.5rem' }} />,
        },
      ],
    },
    {
      id: uuidv4(),
      title: 'Nyílászárók',
      icon: <BookmarkIcon sx={{ fontSize: '2.5rem' }} />,
      children: [
        {
          id: uuidv4(),
          title: 'Ablakok',
          icon: <BedroomBabyIcon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Ajtók',
          icon: <Brightness1Icon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Bejárati kapuk',
          icon: <AssistantIcon sx={{ fontSize: '2.5rem' }} />,
        },
      ],
    },
    {
      id: uuidv4(),
      title: 'Belső burkolóelemek',
      icon: <AirplaneTicketIcon sx={{ fontSize: '2.5rem' }} />,
      children: [
        {
          id: uuidv4(),
          title: 'Padlóburkolatok',
          icon: <AirlinesIcon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Falburkolatok',
          icon: <AddBoxIcon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Álmennyezetek',
          icon: <AnalyticsIcon sx={{ fontSize: '2.5rem' }} />,
        },
      ],
    },
    {
      id: uuidv4(),
      title: 'Hőszigetelő és vízszigetelő elemek',
      icon: <ArrowDropDownCircleIcon sx={{ fontSize: '2.5rem' }} />,
      children: [
        {
          id: uuidv4(),
          title: 'Hőszigetelés',
          icon: <AutoStoriesIcon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Vízszigetelés',
          icon: <BedroomBabyIcon sx={{ fontSize: '2.5rem' }} />,
        },
      ],
    },
    {
      id: uuidv4(),
      title: 'Előregyártott elemek',
      icon: <BookmarkIcon sx={{ fontSize: '2.5rem' }} />,
      children: [
        {
          id: uuidv4(),
          title: 'Betonpanelek',
          icon: <Brightness1Icon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Acélszerkezetek',
          icon: <AssistantIcon sx={{ fontSize: '2.5rem' }} />,
        },
      ],
    },
    {
      id: uuidv4(),
      title: 'Külső és belső díszítő elemek',
      icon: <AirplaneTicketIcon sx={{ fontSize: '2.5rem' }} />,
      children: [
        {
          id: uuidv4(),
          title: 'Homlokzatburkolatok',
          icon: <AirlinesIcon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Díszítő elemek',
          icon: <AddBoxIcon sx={{ fontSize: '2.5rem' }} />,
        },
      ],
    },
    {
      id: uuidv4(),
      title: 'Infrastruktúra-elemek',
      icon: <AnalyticsIcon sx={{ fontSize: '2.5rem' }} />,
      children: [
        {
          id: uuidv4(),
          title: 'Csatornázás',
          icon: <AutoStoriesIcon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Elektromos és vízvezeték-rendszerek',
          icon: <BedroomBabyIcon sx={{ fontSize: '2.5rem' }} />,
        },
      ],
    },
    {
      id: uuidv4(),
      title: 'Kiegészítő és segédanyagok',
      icon: <BookmarkIcon sx={{ fontSize: '2.5rem' }} />,
      children: [
        {
          id: uuidv4(),
          title: 'Ragasztók, tömítőanyagok',
          icon: <Brightness1Icon sx={{ fontSize: '2.5rem' }} />,
        },
        {
          id: uuidv4(),
          title: 'Rögzítők, csavarok, szegek',
          icon: <AssistantIcon sx={{ fontSize: '2.5rem' }} />,
        },
      ],
    },
  ];

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setSelectedChild(null);
    setSelectedGrandchild(null);
  };

  const handleChildClick = (child, event) => {
    setSelectedChild(child);
    setSelectedGrandchild(null);
  };

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['resourceOne']);
  }, [menus]);

  const handleNavigate = () => {
    setPages({ subMenuName: submenuNames });
    navigate(`/app/resourceOne`, { state: { from: 'widget3' } });
  };
  return (
    <div className="bg-gray-100 px-6 pt-5" style={{ minHeight: 'calc(100vh - 65px)' }}>
      {!selectedCard && (
        <div className="flex gap-3 flex-wrap justify-center">
          {cards.map((card) => (
            <div
              key={card.id}
              className="shadow-lg h-[200px] w-[230px] bg-gray-300 cursor-pointer flex flex-col items-center justify-center px-2 text-center"
              onClick={() => handleCardClick(card)}
            >
              <div className="text-xl mb-2">{card.title}</div>
              <div className="text-labelColor">{card.icon}</div>
            </div>
          ))}
        </div>
      )}
      {selectedCard && !selectedChild && (
        <div>
          <div className="text-center mb-3">
            <IconButton size="small" onClick={() => setSelectedCard(null)}>
              <div className="text-sm font-medium py-1.5 px-2 rounded flex items-center justify-center gap-1 bg-ganttHoverButtonColor text-white">
                <KeyboardBackspaceIcon className="text-xl" />
                <span className="text-base">Vissza</span>
              </div>
            </IconButton>
          </div>
          <div className="flex gap-3 flex-wrap justify-center">
            {selectedCard.children.map((child) => (
              <div
                key={child.id}
                className="shadow-lg h-[200px] w-[230px] bg-gray-300 cursor-pointer flex flex-col items-center justify-center px-2 text-center"
                onClick={(e) =>
                  child.children && child.children.length > 0 ? handleChildClick(child, e) : handleNavigate(child, e)
                }
              >
                <div className="text-xl mb-2">{child.title}</div>
                <div className="text-labelColor">{child.icon}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      {selectedChild && !selectedGrandchild && (
        <div>
          <div className="text-center mb-3">
            <IconButton size="small" onClick={() => setSelectedChild(null)}>
              <div className="text-sm font-medium py-1.5 px-2 rounded flex items-center justify-center gap-1 bg-ganttHoverButtonColor text-white">
                <KeyboardBackspaceIcon className="text-xl" />
                <span className="text-base">Vissza</span>
              </div>
            </IconButton>
          </div>
          <div className="flex gap-3 flex-wrap justify-center">
            {selectedChild?.children?.map((grandChild) => (
              <div
                key={grandChild.id}
                className="shadow-lg h-[200px] w-[230px] bg-gray-300 cursor-pointer flex flex-col items-center justify-center px-2 text-center"
                onClick={() => handleNavigate(grandChild)}
              >
                <div className="text-xl mb-2">{grandChild.title}</div>
                <div className="text-labelColor">{grandChild.icon}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
