export const DocStatus = {
  OPENED: 'O',
  CLOSED: 'C',
  CANCELED: 'S',
  CANCELLATION: 'R',
  DELETED: 'D',
  PLANNED: 'P',
};

export const DocStatusList = [
  { label: 'Nyitott', value: DocStatus.OPENED },
  { label: 'Zárt', value: DocStatus.CLOSED },
  { label: 'Stornózott', value: DocStatus.CANCELED },
  { label: 'Stornó', value: DocStatus.CANCELLATION },
  { label: 'Törölt', value: DocStatus.DELETED },
  { label: 'Tervezett', value: DocStatus.PLANNED },
];

export const DocLineStatus = {
  OPENED: 'O',
  CLOSED: 'C',
};

export const DocLineStatusList = [
  { label: 'Nyitott', value: DocLineStatus.OPENED },
  { label: 'Zárt', value: DocLineStatus.CLOSED },
];
