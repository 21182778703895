import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';

import useUsers from '../../context/UsersContext';
import { useLoader } from '../../provider/LoaderProvider';
import masterDataService from '../../service/masterData.service';
import simpletaskService from '../../service/simpletask.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

import FormFields from './FormFields';
import { FormButtonPanelUI } from '../Interface';
import useMenus from '../../context/MenuContext';
import dayjs from 'dayjs';

export const SimpleTaskPhoneForm = (_props) => {
  const { menus, getMenus, getMenuSubMenuId } = useMenus();
  const { getUser, user } = useUsers();
  const { showLoader, hideLoader } = useLoader();
  const [submenu, setSubmenu] = useState(null);

  const [defaultValues, setDefaultValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});

  useEffect(() => {
    getMenus();
    if (!Object.keys(user).length) getUser();

    const def = {
      simpleTaskName: '',
      simpleTaskTypeId: null,
      priorId: null,
      simpleTaskRespPersonlist: [],
    };

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    setSubmenu(getMenuSubMenuId('simpleTask'));
  }, [menus]);

  useEffect(() => {
    if (submenu) initDefaultValues();
  }, [submenu]);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          simpleTaskTypeId: autocompleteData?.type?.defaultValue ?? null,
          priorId: autocompleteData?.priority?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          type: autocompleteData?.type?.defItem,
          priority: autocompleteData?.priority?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
      } else {
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    showLoader();
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .typeToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .priorityToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          type: resp[0],
          status: resp[1],
          priority: resp[2],
        };
        setAutocompleteData(aData);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const validationSchema = Yup.object().shape({
    simpleTaskName: Yup.string().required(),
    simpleTaskTypeId: Yup.string().required(),
    priorId: Yup.string().required(),
  });

  const valuesToForm = () => {
    const { simpleTaskName, simpleTaskTypeId, priorId } = selectedValues;

    setInitValues({
      simpleTaskName,
      simpleTaskTypeId,
      priorId,
    });
  };

  const handleInsertObject = (values, { resetForm }) => {
    showLoader();
    const insertObject = {
      ...values,
      userId: user.userId,
      createdBy: user.userId,
      statusId: autocompleteData.status?.defaultValue,
      startDate: dayjs(values.startDate).toISOString(),
      endDate: dayjs(values.endDate).toISOString(),
    };

    simpletaskService
      .createSimpleTask(insertObject)
      .then(() => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        resetForm();
      })

      .finally(() => {
        hideLoader();
      });
  };

  return (
    <div className="bg-gray-100 h-full w-full fixed top-0">
      <div className="h-auto bg-white py-10 px-5 w-96 mx-auto rounded-lg md:w-2/3 mt-32">
        <Formik
          initialValues={initValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={handleInsertObject}
          enableReinitialize
          //validateOnMount={false}
        >
          {({ values, setFieldValue, errors, validateField }) => (
            <Form>
              <FormFields
                selectedValues={selectedValues}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                validateField={validateField}
                submenu={submenu}
              />

              <FormButtonPanelUI />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
