import React from 'react';
import { Box, Chip, Typography } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ColorPicker } from '../../config/ColorPicker';

import Fields from './Fields';

const JobMonitorCard = ({ data, getProductionById, onDragStart, type }) => {
  const bgColor = ColorPicker.find((color) => color.colorName === data?.productionStatus?.color);

  return (
    <Box
      className="min-h-[200px] mb-3 rounded-md p-4 shadow-md"
      sx={{ backgroundColor: bgColor?.jobMonitorBgColor, '&:hover': { background: bgColor?.jobMonitorHoverColor } }}
      onDragStart={(event) => onDragStart(event, data)}
      draggable
    >
      <div className="flex justify-between w-full flex-wrap">
        <div className="flex items-center gap-3 flex-wrap">
          <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{data.label}</Typography>
          <Chip
            label={data?.productionStatus?.name}
            size="small"
            sx={{ backgroundColor: bgColor?.jobMonitorChipColor }}
          />
          {type !== 'RESOURCECOLUMN' && <Typography>{data.production?.articleNumber}</Typography>}
        </div>
        <Box
          className="bg-white text-black px-2 py-1.5 rounded pt-[0.4rem] cursor-pointer"
          onClick={() => getProductionById(data.productionId)}
        >
          <RemoveRedEyeIcon sx={{ fontSize: '1.4rem' }} />
        </Box>
      </div>
      <Fields data={data} bgColor={bgColor} type={type} />
    </Box>
  );
};

export default JobMonitorCard;
