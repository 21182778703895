import React, { useEffect, useState } from 'react';

import useUsers from '../../context/UsersContext';
import useConfig from '../../context/ConfigContext';

import { Box } from '@mui/material';
import { sxConfig } from './ProductionGenerator.style';
import ProductionGeneratorTable from './ProductionGeneratorTable';
import ProductionGeneratorTree from './ProductionGeneratorTree';
import ProductionGeneratorNewProdTable from './ProductionGeneratorNewProdTable';

const ProductionGeneratorPage = () => {
  const { getUser, user } = useUsers();
  const { config, getConfig } = useConfig();

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addedItem, setAddedItem] = useState(null);
  const [removedItem, setRemovedItem] = useState(null);
  const [sourceData, setSourceData] = useState([]);
  const [refetchTable, setRefetchTable] = useState(false);

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
    if (!Object.keys(config).length) getConfig();
  }, []);

  return (
    <>
      <Box sx={sxConfig.containerBox}>
        <>
          <Box sx={sxConfig.formBox}>
            <ProductionGeneratorTable
              selectedRowsState={{ selectedRows, setSelectedRows }}
              selectedItemState={{ selectedItem, setSelectedItem }}
              removedItemState={{ removedItem, setRemovedItem }}
              addedItemState={{ addedItem, setAddedItem }}
              refetchState={{ refetchTable, setRefetchTable }}
            />
            <ProductionGeneratorTree
              selectedRowsState={{ selectedRows, setSelectedRows }}
              selectedItemState={{ selectedItem, setSelectedItem }}
              sourceDataState={{ sourceData, setSourceData }}
            />
            <ProductionGeneratorNewProdTable
              removedItemState={{ removedItem, setRemovedItem }}
              addedItemState={{ addedItem, setAddedItem }}
              sourceDataState={{ sourceData, setSourceData }}
              refetchState={{ refetchTable, setRefetchTable }}
              selectedItemState={{ selectedItem, setSelectedItem }}
              selectedRowsState={{ selectedRows, setSelectedRows }}
            />
          </Box>
        </>
      </Box>
    </>
  );
};

export default ProductionGeneratorPage;
