import { Card, CardBody, Typography, Chip } from '@material-tailwind/react';
import AutoCompleteSelectUI from '../Interface/AutoCompleteSelectUI';
import { ColorPicker } from '../../config/ColorPicker';
import taskListService from '../../service/taskList.service';
import useParam from '../../context/ParamContext';
import dayjs from 'dayjs';

export default function TaskListTodoCard({ task, setTaskListTodo, autoCompleteOptions, user }) {
  const { params } = useParam();
  const finishedStatus = params.TASKLIST_TODO_STATUS_UPDATE ? parseInt(params.TASKLIST_TODO_STATUS_UPDATE) : null;

  const colorCardBackground = ColorPicker.find((colbg) => colbg.colorName === task.status?.color);

  const handleStatusUpdate = (statusId) => {
    const values = {
      id: task.id,
      statusId,
      preparedness: finishedStatus && finishedStatus === statusId ? 100 : task.preparedness,
    };
    taskListService
      .updateStatus(values)
      .then((data) => {
        setTaskListTodo((prev) => {
          const newArray = [...prev];
          const index = prev.findIndex((t) => t.id === task.id);
          newArray[index] = data;
          return newArray;
        });
      })
      .finally(() => {
        //@TODO loader
      });
  };

  return (
    <Card className="w-[450px]" style={{ background: colorCardBackground?.colorBgCode }}>
      <CardBody>
        <Typography variant="h5" color="blue-gray" className="items-center flex gap-6">
          {task.name}
          <Chip
            variant="ghost"
            value={`${task.preparedness ?? 0} %`}
            className="rounded-full"
            style={{
              background: task.preparedness < 100 ? 'rgba(254, 215, 170, 0.6)' : 'rgba(187, 247, 208, 1)',
              color: task.preparedness < 100 ? 'rgba(249, 115, 22, 1)' : 'rgba(22, 163, 74, 1)',
            }}
            size="sm"
          />
        </Typography>
        <div className="flex gap-5 my-3">
          <div>
            <Typography variant="caption" style={{ fontSize: '0.75rem' }}>
              Kezdő dátum
            </Typography>
            <Typography variant="body1">{dayjs(task.startDate).format('YYYY-MM-DD')}</Typography>
          </div>
          <div>
            <Typography variant="caption" style={{ fontSize: '0.75rem' }}>
              Időintervallum
            </Typography>
            <Typography variant="body1">{task?.duration} nap</Typography>
          </div>
        </div>
        <AutoCompleteSelectUI
          label={<span className="text-labelColor">Státusz</span>}
          onChange={(_e, newVal) => {
            handleStatusUpdate(newVal.value);
          }}
          selectedValue={task.statusId}
          selectedLabelValue={task.status?.itemName}
          dataset={autoCompleteOptions.status}
          disableClearable
        />
      </CardBody>
    </Card>
  );
}
