import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { Box, Chip, Stack, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';

const MAX_FILE_SIZE = 8 * 1024 * 1024;

const sx_config = {
  root: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px dashed ${theme.palette.primary.main}`,
    transition: theme.transitions.create(['border', 'background-color'], {
      duration: theme.transitions.duration.complex,
    }),
    height: theme.spacing(16),
    width: '100%',
    cursor: 'pointer',
    textAlign: 'center',
    '&:focus': {
      outline: 'none',
    },
    '&.active.accept': {
      backgroundColor: theme.palette.success.light,
    },
    '&.active.reject': {
      borderColor: theme.palette.error.main,
    },
  }),
  disabled: {
    cursor: 'default',
  },
  enabled: {
    '&:hover': {
      borderColor: (theme) => theme.palette.primary.main,
    },
  },
};

const FileUploadUI = ({ accept, disabled, onFilesChange, single = false, ...props }) => {
  const [files, setFiles] = useState([]);

  const updateFiles = (newFiles) => {
    setFiles(newFiles);
    onFilesChange(newFiles);
  };

  const handleRejected = (rejectedFiles) => {
    addNotification({
      content: 'Sikertelen hozzáadás!',
      type: NotificationType.ERROR,
    });
  };

  const handleAccepted = (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => {
      const isOversized = file.size > MAX_FILE_SIZE;
      return { file, isOversized };
    });

    if (single) {
      // Ha csak egy fájl engedélyezett, felülírjuk a korábbi fájlokat
      updateFiles(newFiles.slice(0, 1));
    } else {
      // Több fájl engedélyezett, hozzáadjuk az új fájlokat
      updateFiles([...files, ...newFiles]);
    }

    addNotification({
      content: 'Fájl hozzáadása sikeres!',
      type: NotificationType.SUCCESS,
    });
  };

  const handleDelete = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    updateFiles(updatedFiles);
  };

  return (
    <>
      <Dropzone
        multiple={!single} // Ha `single` true, akkor csak egy fájl engedélyezett
        onDropRejected={handleRejected}
        onDropAccepted={handleAccepted}
        accept={accept}
        disabled={disabled}
        {...props}
      >
        {({ getRootProps, getInputProps }) => (
          <Box display={'block'} sx={{ m: 2 }}>
            <Box {...getRootProps()} sx={[sx_config.root, disabled ? sx_config.disabled : sx_config.enabled]}>
              <div>
                <input {...getInputProps()} />
                <CloudUploadIcon fontSize="large" color="primary" />
                <Box sx={{ fontWeight: 'bold', color: 'text.secondary' }}>
                  {single ? 'Húzd ide a fájlt, vagy kattints!' : 'Húzd ide a fájlokat, vagy kattints!'}
                </Box>
              </div>
            </Box>
          </Box>
        )}
      </Dropzone>

      <Box display="flex" gap={3} justifyContent="center" sx={{ m: 2 }}>
        <Stack direction="row" spacing={1}>
          {files.map((fileObj, index) => (
            <Box key={index}>
              {fileObj.isOversized && (
                <Typography color="error" variant="caption" display="block" gutterBottom>
                  Túl nagy a fájl (max: 8 MB)
                </Typography>
              )}
              <Chip
                label={fileObj.file.name}
                onDelete={() => {
                  handleDelete(index);
                }}
              />
            </Box>
          ))}
        </Stack>
      </Box>
    </>
  );
};

export default FileUploadUI;
