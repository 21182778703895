import React, { useEffect, useState } from 'react';
import ItemPriceTable from './ItemPriceTable';
import masterDataService from '../../service/masterData.service';

const ItemPrice = (props) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getPriceLists();
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const getPriceLists = () => {
    masterDataService.priceListToForm().then((data) => {
      setRows(data);
    });
  };

  return rows.length > 0 && <ItemPriceTable {...props} rows={rows} />;
};

export default ItemPrice;
