import { useState } from 'react';
import { Field, useFormikContext } from 'formik';

import { TabsUI, FormLabelUI, FormErrorUI, InputUI, DeleteDialogUI, FileTabUI } from '../Interface';
import DynamicPropertyMain from '../DynamicProperty/DynamicPropertyMain';
import CompanyContact from './CompanyContact';
import CompanyHistory from './CompanyHistory';
import ExcelImportTab from '../Interface/ExcelImportTab';
import CompanyCustomerFields from './CompanyCustomerFields';
import CompanySupplierFields from './CompanySupplierFields';
import CompanyAddress from './CompanyAddress';
import companyService from '../../service/company.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';

const DescriptionPanel = ({ disabled, errors, values }) => {
  return (
    <div>
      <div className="col-span-full relative">
        <div className="mt-2">
          <Field
            type="text"
            name="description"
            as={InputUI}
            className={`${disabled ? 'rounded-md' : ''} resize-none h-36, p-2`}
            fullWidth
            label={<FormLabelUI text="Megjegyzés" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.description} />}
            disabled={disabled}
            InputLabelProps={{ shrink: values.description !== '' }}
            multiline
            rows={10}
          />
        </div>
      </div>
    </div>
  );
};

export default function FormTabs(props) {
  const { disabled, selectedValues, setSelectedValues, user, setRefetchTable } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [removeElement, setRemoveElement] = useState(null);
  const [deleteDialog, setDeletDialog] = useState(false);

  const handleUpload = (files) => {
    if (!selectedValues.id) {
      return addNotification({
        content: 'Nincs kiválasztott elem!',
        type: NotificationType.ERROR,
      });
    }

    const uploadPromises = files.map((file) =>
      companyService.uploadFile(file, selectedValues.id, user.userId).then((data) => {
        setSelectedValues((prev) => ({ ...prev, companyAttachment: data }));
      })
    );

    Promise.all(uploadPromises)
      .then(() => {
        setRefetchTable(true);
      })
      .catch((error) => {
        console.error('Fájl feltöltési hiba:', error);
      });
  };

  const handleDelete = (data) => {
    setRemoveElement(data.id);
    setDeletDialog(true);
  };

  const hideDeleteDialog = () => {
    setDeletDialog(false);
    setRefetchTable(true);
  };

  const handleRemoveElement = (data) => {
    companyService.deleteFile(removeElement, user.userId).then((data) => {
      setRefetchTable(true);
      setSelectedValues((prev) => ({ ...prev, companyAttachment: data }));
      hideDeleteDialog();
    });
  };

  const tabs = [
    {
      id: 0,
      name: 'Leírás',
      content: <DescriptionPanel {...props} />,
    },
    {
      id: 1,
      name: 'Tulajdonságok',
      content: <DynamicPropertyMain {...props} />,
    },
    {
      id: 3,
      name: 'Szállítói adatok',
      content: <CompanySupplierFields {...props} viewField={disabled} />,
    },
    {
      id: 2,
      name: 'Vevői adatok',
      content: <CompanyCustomerFields {...props} viewField={disabled} />,
    },
    {
      id: 4,
      name: 'Üzleti partner kapcsolattartók',
      content: <CompanyContact {...props} />,
    },
    {
      id: 5,
      name: 'Üzleti partner címek',
      content: <CompanyAddress {...props} />,
    },
    {
      id: 6,
      name: 'Csatolmányok',
      content: (
        <FileTabUI
          disabled={disabled}
          handleUpload={handleUpload}
          handleDelete={handleDelete}
          savedFiles={selectedValues.companyAttachment ?? []}
        />
      ),
    },
    {
      id: 7,
      name: 'Cég történet',
      content: <CompanyHistory {...props} />,
    },
    {
      id: 8,
      name: 'Adat import',
      content: <ExcelImportTab {...props} />,
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
      <DeleteDialogUI handleRemoveElement={handleRemoveElement} show={deleteDialog} onHide={() => hideDeleteDialog()} />
    </div>
  );
}
