import React from 'react';

import { useSimpleTask } from '../../hooks/useSimpleTask';
import SimpleTaskReports from './SimpleTaskReports';
import { SimpleTaskForm } from './SimpleTaskForm';
import { FormBase } from '../Interface';

export const SimpleTaskMain = () => {
  const simpleTaskState = useSimpleTask();

  return (
    <FormBase
      name="SimpleTask"
      leftPanelContent={<SimpleTaskForm simpleTaskState={simpleTaskState} />}
      rightPanelContent={!simpleTaskState.loading && <SimpleTaskReports simpleTaskState={simpleTaskState} />}
    ></FormBase>
  );
};
