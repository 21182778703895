import React, { useEffect, useState } from 'react';
import { ButtonUI, CheckboxGroupUI, FileUploadUI, FormLabelUI, SelectUI } from '../Interface';
import { SourceListImportType, SourceListImportTypeList } from '../../config/sourceListImport';
import { Box, Typography } from '@mui/material';
import sourcelistService from '../../service/sourcelist.service';
import useUsers from '../../context/UsersContext';
import useParam from '../../context/ParamContext';

const SourceListImportDialog = ({ autocompleteOptions, onClose, refetch, getItemsToForm }) => {
  const { setRefetchTable } = refetch;
  const { getUser, user } = useUsers();
  const { params, getParam } = useParam();

  const [uploadState, setUploadState] = useState({
    files: [],
    type: SourceListImportType.SOURCELIST,
    operation: [],
    itemGroup: null,
  });

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
    getParam();
  }, []);

  const [error, setError] = useState(null);

  const handleFilesChange = (newFiles) => {
    setUploadState((prevState) => ({
      ...prevState,
      files: newFiles.map((file) => file.file),
    }));
  };

  const handleSubmit = () => {
    if (!uploadState.itemGroup) {
      setError('A cikkcsoport kiválasztása kötelező!');
      return;
    }

    setError(null);
    sourcelistService.importExcel({ ...uploadState, userId: user.userId }).then((data) => {
      setRefetchTable(true);
      onClose();
      setUploadState({
        files: [],
        type: SourceListImportType.SOURCELIST,
        operation: [],
        itemGroup: null,
      });
      getItemsToForm();
    });
  };

  return (
    <Box mt={2}>
      <SelectUI
        label={<FormLabelUI text="Import típusa" />}
        option={SourceListImportTypeList}
        value={uploadState.type}
        onChange={(e) => {
          const value = e.target.value;
          setUploadState((prevState) => ({
            ...prevState,
            type: value,
          }));
        }}
      />

      {uploadState.type === SourceListImportType.SOURCELIST && params.SOURCELIST_IMPORT_CREATE_OPERATION === 'Y' && (
        <CheckboxGroupUI
          label="Műveletek"
          options={autocompleteOptions.operation}
          value={uploadState.operation}
          onChange={(values) => {
            setUploadState((prevState) => ({
              ...prevState,
              operation: values,
            }));
          }}
          mode="multi"
          row
        />
      )}
      <SelectUI
        label={<FormLabelUI text="Cikkcsoport" />}
        option={autocompleteOptions.itemGroup}
        value={uploadState.itemGroup}
        onChange={(e) => {
          const value = e.target.value;
          setUploadState((prevState) => ({
            ...prevState,
            itemGroup: value,
          }));
        }}
      />
      {error && (
        <Typography color="error" variant="caption" display="block" gutterBottom>
          {error}
        </Typography>
      )}
      <FileUploadUI
        onFilesChange={handleFilesChange}
        accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] }}
        single
      />
      <Box display="flex" gap={3} justifyContent="center">
        <ButtonUI
          text="Feltöltés"
          className="bg-success flex gap-2 items-center"
          type="button"
          disabled={!uploadState.files.length || !uploadState.itemGroup}
          onClick={handleSubmit}
        />

        <ButtonUI text="Mégse" className="bg-danger flex items-center gap-2" type="button" onClick={() => onClose()}>
          Mégse
        </ButtonUI>
      </Box>
    </Box>
  );
};

export default SourceListImportDialog;
