import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import masterDataService from '../../service/masterData.service';
import { generateAutocompleteItems } from '../../utils/helper';
import usePages from '../../context/PageContext';
import useMenus from '../../context/MenuContext';
import useUsers from '../../context/UsersContext';
import useParam from '../../context/ParamContext';
import { useLoader } from '../../provider/LoaderProvider';
import productionService from '../../service/production.service';
import jobMonitorService from '../../service/jobMonitor.service';

import { AutoCompleteSelectUI, DebouncedInputUI, FormLabelUI, SwitchUI } from '../Interface';
import JobMonitorCard from './JobMonitorCard';
import ProductDialog from './ProductDialog';
import useSaveView from '../../hooks/useSaveView';
import useView from '../../hooks/useView';

export const JobMonitorPage = () => {
  const navigate = useNavigate();
  const { getUser, user } = useUsers();
  const { getParam, params } = useParam();
  const { showLoader, hideLoader } = useLoader();
  const saveView = useSaveView(user);
  const jobMonitorFilter = useView('jobMonitorFilter', user);
  const { menus, getMenus, getMenuSubMenuId, getSubMenuNamesByUrls } = useMenus();
  const { setPages } = usePages();
  const [prodOperations, setProdOperations] = useState({ rows: [], rowCount: 0 });
  const [submenu, setSubmenu] = useState(null);
  const [previousJobs, setPreviousJobs] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [production, setProduction] = useState({});
  const [productDialogIsOpen, setProductDialogIsOpen] = useState(false);
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    resourceOne: [],
    operations: [],
  });
  const [query, setQuery] = useState({
    filter: {
      filterModel: {
        items: [],
        logicOperator: 'and',
      },
    },
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  useEffect(() => {
    getMenus();
    getParam();
    if (!Object.keys(user).length) getUser();
  }, []);

  useEffect(() => {
    if (Object.keys(user).length > 0 && query.filter.filterModel.items.length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (submenu) {
      initDefaultValues();
    }
  }, [submenu]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('production');
    setSubmenu(submenuId);
  }, [menus]);

  useEffect(() => {
    if (Object.keys(jobMonitorFilter).length > 0) {
      if ('previousJobs' in jobMonitorFilter) {
        updateQueryForStatusId(jobMonitorFilter.previousJobs);
        setPreviousJobs(jobMonitorFilter.previousJobs);
      } else {
        updateQueryForStatusId(false);
      }
      const filters = Object.entries(jobMonitorFilter).map(([field, value]) => ({ field, value }));
      if (filters.length > 0) {
        handleFilterChange(filters);
      }
    } else {
      updateQueryForStatusId(false);
    }
  }, [jobMonitorFilter, params]);

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['productionreport']);
  }, [menus]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceOneToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .operationToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          resourceOne: resp[0],
          operations: resp[1],
        };

        const acOptions = {
          resourceOne: generateAutocompleteItems(aData.resourceOne, 'name', 'id'),
          operations: generateAutocompleteItems(aData.operations, 'operationName', 'operationId'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {});
  };

  const getList = () => {
    showLoader();
    jobMonitorService
      .getProductionOperations(query)
      .then((data) => {
        setProdOperations((prev) => ({
          rows: query.pagination.paginationModel.page === 0 ? data.rows : [...prev.rows, ...data.rows],
          rowCount: data.rowCount,
        }));
        setHasMore(prodOperations.rows.length < data.rowCount);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const updateQueryForStatusId = (usePreviousJobs) => {
    if (!params || !params.JOBMONITOR_STATUSES) {
      return;
    }

    const statusConfig = JSON.parse(params.JOBMONITOR_STATUSES);
    const statusIds = usePreviousJobs ? statusConfig.forPreviousWork : statusConfig.noPreviousWork;
    const newItems = query.filter.filterModel.items
      .filter((item) => item.field !== 'productionStatusId')
      .concat({
        field: 'productionStatusId',
        operator: 'isAnyOf',
        value: statusIds,
      });

    if (JSON.stringify(newItems) !== JSON.stringify(query.filter.filterModel.items)) {
      setQuery((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          filterModel: {
            items: newItems,
          },
        },
        pagination: {
          paginationModel: {
            pageSize: prev.pagination.paginationModel.pageSize,
            page: 0,
          },
        },
      }));
    }
  };

  const handleFilterChange = (field, value) => {
    setQuery((prev) => {
      let newItems = prev.filter.filterModel.items;

      if (Array.isArray(field)) {
        newItems = newItems.filter((item) => !field.some((f) => f.field === item.field));

        const validFilters = field
          .filter((f) => f.value !== null && f.value !== undefined)
          .map((f) => ({
            field: f.field,
            operator: 'is',
            value: f.value?.value ?? f.value,
          }));
        newItems = newItems.concat(validFilters);
      } else {
        newItems = newItems.filter((item) => item.field !== field);
        if (value !== null && value !== undefined) {
          newItems.push({ field, operator: 'is', value });
        }
      }

      return {
        ...prev,
        filter: {
          filterModel: {
            items: newItems,
          },
        },
      };
    });
  };

  const handleNavigate = (prodOperationId) => {
    setPages({ subMenuName: submenuNames });
    navigate(`/app/productionReport/${prodOperationId}`);
  };

  const fetchMoreData = () => {
    setQuery((prev) => ({
      ...prev,
      pagination: {
        paginationModel: {
          ...prev.pagination.paginationModel,
          page: prev.pagination.paginationModel.page + 1,
        },
      },
    }));
  };

  const getProductionById = (id) => {
    productionService.getProductionById(id).then((data) => {
      setProduction(data);
      setProductDialogIsOpen(true);
    });
  };

  return (
    <div className="bg-[#CDDFF7] min-h-full px-6">
      <div className="flex justify-between gap-5 flex-wrap">
        <div className="flex gap-5 flex-wrap">
          <div className="w-[250px]">
            <AutoCompleteSelectUI
              label={<FormLabelUI text="Művelet kereső" />}
              dataset={autocompleteOptions.operations}
              onChange={(_e, newVal, reason) => {
                handleFilterChange('operationId', reason === 'clear' ? null : newVal?.value);
                saveView('jobMonitorFilter', { operationId: newVal });
              }}
              selectedValue={query.filter.filterModel.items.find((item) => item.field === 'operationId')?.value}
              selectedLabelValue={jobMonitorFilter?.operationId?.label}
            />
          </div>
          <div className="w-[250px]">
            <AutoCompleteSelectUI
              label={<FormLabelUI text="Erőforrás kereső" />}
              dataset={autocompleteOptions.resourceOne}
              onChange={(_e, newVal, reason) => {
                handleFilterChange('resourceOneId', reason === 'clear' ? null : newVal?.value);
                saveView('jobMonitorFilter', { resourceOneId: newVal });
              }}
              selectedValue={query.filter.filterModel.items.find((item) => item.field === 'resourceOneId')?.value}
              selectedLabelValue={jobMonitorFilter?.resourceOneId?.label}
            />
          </div>
        </div>
        <div className="flex gap-5 flex-wrap">
          <div className="w-[250px]">
            <DebouncedInputUI
              label="Keresés a gyártásban"
              debounceMs={800}
              setQuickFilterSearchValue={(newVal) => {
                handleFilterChange('workingNumber', newVal);
                saveView('jobMonitorFilter', { workingNumber: newVal });
              }}
              quickFilterSearchValue={
                query.filter.filterModel.items.find((item) => item.field === 'workingNumber')?.value
              }
            />
          </div>
          <div className="pt-4">
            <SwitchUI
              label="Korábbi JOB-ok"
              checked={previousJobs}
              onClick={() => {
                setPreviousJobs(!previousJobs);
                saveView('jobMonitorFilter', { previousJobs: !previousJobs });
              }}
            />
          </div>
        </div>
      </div>
      <InfiniteScroll dataLength={prodOperations.rows.length} next={fetchMoreData} hasMore={hasMore}>
        {prodOperations?.rows.length > 0 &&
          prodOperations.rows.map((operation) => (
            <JobMonitorCard
              key={operation.id}
              data={operation}
              handleNavigate={handleNavigate}
              getProductionById={getProductionById}
              setProdOperations={setProdOperations}
              params={params}
              previousJobs={previousJobs}
            />
          ))}
      </InfiniteScroll>
      <ProductDialog
        open={productDialogIsOpen}
        handleClose={() => setProductDialogIsOpen(false)}
        production={production}
      />
    </div>
  );
};
