import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import exchangeRateService from '../../service/exchangeRate.service';

export const ExchangeRateReports = ({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autocompleteOptions,
}) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'rateDate', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    exchangeRateService.getItems(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = dataGridPremiumColumns([
    {
      field: 'rateDate',
      headerName: 'Dátum',
      type: 'date',
      valueGetter: (_data, row) => {
        return new Date(row.rateDate);
      },
      renderCell: (data) => {
        return dayjs(data.row.rateDate).format('YYYY.MM.DD');
      },
    },
    {
      field: 'currencyCode',
      headerName: 'Pénznem',
      type: 'singleSelect',
      sortable: false,
      valueOptions: autocompleteOptions?.currency,
    },

    {
      field: 'rate',
      headerName: 'Árfolyam',
      type: 'number',
    },
  ]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.exchangeRate}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      getRowId={(row) => `${dayjs(row.rateDate).format('YYYYMMDD')}_${row.currencyCode}`}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
    />
  );
};

export default ExchangeRateReports;
