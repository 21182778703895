import { useState, useEffect, useMemo } from 'react';

import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import { dataGridColumns, dataGridPremiumColumns } from '../../utils/helper';
import companyLocationService from '../../service/companyLocation.service';

export default function CompanyLocationTable({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autoCompleteOptions,
}) {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'name', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    companyLocationService.getCompanyLocationReport(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'name',
        headerName: 'Telephely neve',
        type: 'string',
      },
      {
        field: 'nameInPrint',
        headerName: 'Nyomtatásban megjelenő név',
        type: 'string',
      },
      {
        field: 'country',
        headerName: 'Ország',
        type: 'singleSelect',
        valueOptions: autoCompleteOptions.country,
      },
      {
        field: 'province',
        headerName: 'Megye',
        type: 'string',
      },
      {
        field: 'postCode',
        headerName: 'Irányítószám',
        type: 'string',
      },
      {
        field: 'city',
        headerName: 'Város',
        type: 'string',
      },
      {
        field: 'streetAddress',
        headerName: 'Utca, házszám',
        type: 'string',
      },
      {
        field: 'website',
        headerName: 'Weboldal',
        type: 'string',
      },
      {
        field: 'email',
        headerName: 'Email cím',
        type: 'string',
      },
      {
        field: 'phoneNumber',
        headerName: 'Telefonszám',
        type: 'string',
      },
      {
        field: 'GLN',
        headerName: 'GLN',
        type: 'string',
      },
      {
        field: 'other',
        headerName: 'Egyéb',
        type: 'string',
      },
    ]);
  }, [autoCompleteOptions]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.CompanyLocationTable}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
    />
  );
}
