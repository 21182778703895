import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import dayjs from 'dayjs';
import Yup from '../../utils/yup.js';
import utc from 'dayjs/plugin/utc';

import addNotification from '../../utils/addNotification.jsx';
import { NotificationType } from '../../config/index.js';
import useMenus from '../../context/MenuContext.js';
import useUsers from '../../context/UsersContext.js';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI, FormBase } from '../Interface/index.js';
import { TemplateItemFields } from './TemplateItemFields.jsx';
import masterDataService from '../../service/masterData.service.js';
import { generateAutocompleteItems } from '../../utils/helper.js';
import TemplateItemOperation from './TemplateItemOperation.jsx';
import TemplateItemReports from './TemplateItemReports.jsx';
import templateItemService from '../../service/templateItem.service.js';
import useConfig from '../../context/ConfigContext.js';

export const TemplateItemPage = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const { config, getConfig } = useConfig();
  const [refetchTable, setRefetchTable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [canEdit, setCanEdit] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState({
    quantityUnit: [],
    operation: [],
  });

  const defaultTemplateItemOperation = {
    id: null,
    isNew: true,
    operationId: null,
    quantityUnitId: config?.defOperationQtyUnit,
    neededQty: '',
  };

  const def = {
    name: '',
    templateItemOperation: [],
  };

  useEffect(() => {
    getMenus();
    if (!Object.keys(user).length) getUser();
    initDefaultValues();
    setDefaultValues(def);
    getConfig();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('templateItem');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .quantityUnitToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .operationToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          quantityUnit: resp[0],
          operation: resp[1],
        };

        const acOptions = {
          quantityUnit: generateAutocompleteItems(aData.quantityUnit, 'name', 'id'),
          operation: generateAutocompleteItems(aData.operation, 'operationName', 'operationId'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    templateItemOperation: Yup.array().of(
      Yup.object().shape({
        operationId: Yup.string().required(),
        quantityUnitId: Yup.string().required(),
        neededQty: Yup.number().required().positive(),
      })
    ),
  });

  const valuesToForm = () => {
    const { name, templateItemOperation } = selectedValues;

    setInitValues({
      name,
      templateItemOperation: templateItemOperation.map((item) => ({
        id: item.id,
        operationId: item.operationId,
        quantityUnitId: item.quantityUnitId,
        neededQty: item.neededQty,
      })),
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    templateItemService.updateTemplateItem(updData, selectedValues.id).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    templateItemService
      .createTemplateItem(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setSelectedValues(data);
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    templateItemService.deleteTemplateItem(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(def);
    });
  };

  return (
    <>
      <FormBase
        name="TaskListTemplate"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                addClick={() => {
                  setSelectedValues(def);
                  setViewField(false);
                  setAddField(false);
                }}
                addDisabled={!viewField || !canEdit}
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled || !canEdit}
                deleteClick={() => {
                  setShowDeleteDialog(true);
                }}
                deleteDisabled={!viewField || operatorsDisabled || !canEdit}
                copyClick={() => {
                  setViewField(false);
                  setAddField(false);
                }}
                copyDisabled={!viewField || operatorsDisabled || !canEdit}
              />

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, errors, resetForm }) => (
                  <Form>
                    <TemplateItemFields viewField={viewField} values={values} errors={errors} />
                    <TemplateItemOperation
                      user={user}
                      disabled={viewField}
                      defaultField={defaultTemplateItemOperation}
                      autoCompleteOptions={autoCompleteOptions}
                    />
                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);

                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <TemplateItemReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            rowSelection={{ rowSelectionModel, setRowSelectionModel }}
          />
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
