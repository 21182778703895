import request from '../utils/Request';
import { Methods, NotificationType } from '../config';
import { downloadFile } from '../utils/download';
import { ResponseType } from '../config/responetype';
import addNotification from '../utils/addNotification';

const baseURL = 'sourcelist';

const sourcelistService = {
  getSourcelists: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/sourcelists`,
      data,
    });
  },

  updateSourcelist: (data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}`,
      data,
    });
  },

  createSourcelist: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  deleteSourcelist: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  getSourcelistById: (id) => {
    return request({
      resource: `${baseURL}/${id}`,
    });
  },

  getSourcelistByItemCode: (itemCode) => {
    return request({
      resource: `${baseURL}/itemcode/${itemCode}`,
    });
  },

  createOperationMachine: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/operationmachine`,
      data,
    });
  },

  updateOperationMachine: (data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/operationmachine`,
      data,
    });
  },

  deleteOperationMachine: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/operationmachine/${id}`,
      data,
    });
  },

  getTreeData: (itemCode) => {
    return request({
      resource: `${baseURL}/treeData/${itemCode}`,
    });
  },

  updateMachineNeededQty: (data) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/updateMachineNeededQty`,
      data,
    });
  },

  importExcel: async (data) => {
    const formData = new FormData();

    const { files, type, operation, itemGroup, userId } = data;
    console.log(files[0]);
    formData.append('files', files[0]);
    formData.append('type', type);
    formData.append('operation', operation);
    formData.append('itemGroup', itemGroup);
    formData.append('userId', userId);

    const resp = await request({
      method: Methods.POST,
      resource: `${baseURL}/excelimport`,
      formData: true,
      data: formData,
      responseType: 'blob',
    });

    if (resp?.type === ResponseType.xlsx) {
      addNotification({
        content: 'Az import sikertelen!',
        type: NotificationType.ERROR,
      });

      downloadFile(resp, `ERR_${files[0].name}`);
      return Promise.reject(new Error('Error in uploaded file!'));
    }

    const reader = new FileReader();
    reader.readAsText(resp);
    reader.onload = () => {
      const jsonData = JSON.parse(reader.result);
      if (jsonData?.error?.message) {
        return addNotification({
          content: jsonData.error.message,
          type: NotificationType.ERROR,
        });
      }
      return jsonData.data;
    };
  },
};
export default sourcelistService;
