import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import Yup from '../../utils/yup';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import _ from 'lodash';
import dayjs from 'dayjs';
import productionService from '../../service/production.service';
import { useLoader } from '../../provider/LoaderProvider';
import { ProductionInfoPanel } from './InfoPanel';
import { ProductionSearch } from './ProductionSearch';
import { ProductionTabs } from './FormTabs';
import { OperationReportTable } from './OperationReportTable';
import { FormButtonPanelUI } from '../Interface';
import { ProductionReportDialog } from './ProductionReportDialog';
import useUsers from '../../context/UsersContext';
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import useMenus from '../../context/MenuContext';
import usePages from '../../context/PageContext';
import useParam from '../../context/ParamContext';

export const ProductionReportForm = () => {
  const navigate = useNavigate();
  const { prodOperationId } = useParams();
  const { setPages } = usePages();
  const { showLoader, hideLoader } = useLoader();
  const { menus, getSubMenuNamesByUrls } = useMenus();
  const { user, getUser } = useUsers();
  const { params, getParam } = useParam();
  const [activeTab, setActiveTab] = useState(0);
  const [productionList, setProductionList] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [refetchTable, setRefetchTable] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});
  const [filterFormState, setFilterFormState] = useState({
    searchQuery: '',
  });

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
    getParam();
  }, []);

  useEffect(() => {
    if (productionList.length > 1) {
      setShowDialog(true);
    } else {
      setShowDialog(false);
    }
  }, [productionList]);

  useEffect(() => {
    if (prodOperationId) {
      getProductionInfoById();
    }
  }, [prodOperationId]);

  useEffect(() => {
    const def = {
      date: dayjs(new Date()),
      quantity: '',
      scrapQuantity: '',
      timeSpent: '',
      timeSpentInMin: '',
      userId: null,
      startDate: null,
      endDate: null,
    };

    if (isProductionListObject()) {
      setDefaultSelectedValues({ user: user });
    } else {
      setDefaultSelectedValues({});
      setDefaultValues(def);
    }
  }, [productionList]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
      } else {
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  useEffect(() => {
    if (user) {
      setDefaultValues((prev) => {
        return { ...prev, userId: user?.userId };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          user: user,
        };
      });
    }
  }, [user]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['jobMonitor']);
  }, [menus]);

  const validationSchema = Yup.object().shape({
    quantity: Yup.number().positive().nullable(),
    scrapQuantity: Yup.number().positive().nullable(),
    timeSpent: Yup.number().nullable(),
    timeSpentInMin: Yup.number().nullable(),
    userId: Yup.string().required(),
  });

  const valuesToForm = () => {
    const { date, quantity, scrapQuantity, timeSpent, timeSpentInMin, userId, startDate, endDate, createdBy } =
      selectedValues;

    setInitValues({
      date: dayjs(date),
      quantity,
      scrapQuantity,
      timeSpent,
      timeSpentInMin,
      userId,
      startDate,
      endDate,
      createdBy,
    });
  };

  const handleInsertObject = (values) => {
    showLoader();
    if (!values.quantity && !values.scrapQuantity && !values.timeSpent && !values.timeSpentInMin) {
      addNotification({
        content: 'Legalább egy mező kitöltése kötelező!',
        type: NotificationType.WARNING,
      });
      hideLoader();
      return;
    }
    const prodOperationId = productionList.prodOperationId;
    const insertObject = {
      ...values,
      id: prodOperationId,
      date: dayjs(values.date).toISOString(),
      quantity: parseFloat(values.quantity),
      scrapQuantity: parseFloat(values.scrapQuantity),
      timeSpent: parseFloat(values.timeSpent),
      timeSpentInMin: parseInt(values.timeSpentInMin),
      createdBy: user.userId,
    };

    productionService
      .createProductionReport(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setInitValues({});
        setSelectedValues({});
      })

      .finally(() => {
        hideLoader();
      });
  };

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const isProductionListObject = () => {
    return !Array.isArray(productionList) && Object.keys(productionList).length > 0;
  };

  const handleSelectionChange = (selectedRowData) => {
    setProductionList(selectedRowData);
    setFilterFormState({ searchQuery: selectedRowData.operationIdlabel });
    setShowDialog(false);
  };

  const getProductionInfoById = () => {
    productionService.getProductionInfoById(prodOperationId).then((data) => {
      setProductionList(data);
      setFilterFormState((prev) => ({
        ...prev,
        searchQuery: data.operationIdlabel,
      }));
    });
  };

  const handleBack = () => {
    setPages({ subMenuName: submenuNames });
    navigate(`/app/jobMonitor`);
  };

  return (
    <div className="p-4 bg-gray-100">
      <div className="bg-white p-3 rounded-lg pb-5">
        {prodOperationId && (
          <IconButton size="small" onClick={handleBack}>
            <div className="text-sm font-medium py-1.5 px-2 rounded flex items-center justify-center gap-1 bg-ganttHoverButtonColor text-white">
              <KeyboardBackspaceIcon className="text-xl" />
              <span className="text-base">Vissza</span>
            </div>
          </IconButton>
        )}
        <ProductionSearch
          setProductionList={setProductionList}
          refetch={{ refetchTable, setRefetchTable }}
          filter={{ filterFormState, setFilterFormState }}
        />
        <ProductionInfoPanel productionList={productionList} params={params} />
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 pt-5">
        <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto">
          <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg">
            <Formik
              initialValues={initValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              onSubmit={handleInsertObject}
              enableReinitialize
            >
              {({ values, setFieldValue, errors, validateField, resetForm }) => (
                <Form>
                  <ProductionTabs
                    activeTab={activeTab}
                    onTabChange={handleTabChange}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    validateField={validateField}
                    productionList={productionList}
                    user={user}
                    params={params}
                  />
                  {isProductionListObject() && (
                    <FormButtonPanelUI
                      addClick={() => {
                        setSelectedValues(defaultSelectedValues);
                        setInitValues(defaultValues);
                        resetForm();
                      }}
                    />
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {activeTab === 0 && (
          <OperationReportTable
            productionList={productionList}
            refetch={{ refetchTable, setRefetchTable }}
            params={params}
          />
        )}

        <ProductionReportDialog
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          production={{ productionList, setProductionList }}
          onSelectionChange={handleSelectionChange}
        />
      </div>
    </div>
  );
};
