import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import masterDataService from '../../service/masterData.service';
import useParam from '../../context/ParamContext';
import operationGroupService from '../../service/operationGroup.service';
import usePages from '../../context/PageContext';
import { useNavigate } from 'react-router-dom';
import useMenus from '../../context/MenuContext';
import { CheckboxUI } from '../Interface';
import useUsers from '../../context/UsersContext';
import jobMonitorService from '../../service/jobMonitor.service';
import OpWidgetJobmonitorCard2 from '../Jobmonitor/OpWidgetJobmonitor/OpWidgetJobmonitorCard2';
import userService from '../../service/user.service';
import OperationWidgetDialog from './OperationWidgetDialog';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';

const baseStyle = {
  background: 'white',
  height: '150px',
  width: '200px',
  cursor: 'pointer',
  textAlign: 'center',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  overflow: 'auto',

  '&&:hover': {
    background: '#CDDFE8',
  },
};

const categoryCardStyle = {
  ...baseStyle,
  boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  padding: '0.8rem 0.5rem 0rem 0.5rem',
};

const OperationWidgetPage2 = () => {
  const { setPages } = usePages();
  const navigate = useNavigate();
  const { menus, getSubMenuNamesByUrls, getMenus } = useMenus();
  const { getParam, params } = useParam();
  const { user, getUser } = useUsers();
  const [operationGroup, setOperationGroup] = useState([]);
  const [operations, setOperations] = useState([]);
  const [prodOperations, setProdOperations] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [autoCompleteData, setAutoCompleteData] = useState({});
  const [showWorkingUsers, setShowWorkingUsers] = useState(false);
  const [showNotWorkingUsers, setShowNotWorkingUsers] = useState(false);
  const [filter, setFilter] = useState({
    userId: null,
    pinCode: '',
  });
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    getParam();
    getMenus();
    getUser();
  }, []);

  useEffect(() => {
    if (filter.userId) {
      getOperationGroups();
      getProductionOperationsByUserId();
    } else {
      setProdOperations([]);
      setShowDialog(true);
    }
  }, [filter.userId, refetch]);

  useEffect(() => {
    if (filter.pinCode) {
      getUserByPinCode();
    } else {
      setProdOperations([]);
    }
  }, [filter.pinCode]);

  useEffect(() => {
    initDefaultValues();
  }, [refetch]);

  const getProductionOperationsByUserId = () => {
    jobMonitorService
      .getProductionOperations({
        filter: {
          filterModel: {
            items: [{ field: 'userId', value: filter.userId, operator: 'is' }],
            logicOperator: 'and',
          },
        },
      })
      .then((data) => {
        setProdOperations(data);
        setRefetch(false);
      });
  };

  const getOperationGroups = () => {
    masterDataService.operationGroupToForm().then((data) => {
      setOperationGroup(data);
    });
  };

  const getUserByPinCode = () => {
    userService.getUserByPinCode(filter.pinCode).then((data) => {
      if (Object.keys(data).length > 0) {
        setFilter((prev) => ({
          ...prev,
          userId: data.userId,
        }));
        setShowDialog(false);
      } else {
        addNotification({
          content: 'Nem található felhasználó ezzel a PIN kóddal!',
          type: NotificationType.ERROR,
        });
      }
    });
  };

  //@TODO ha bővül egyéb mezővel, a usert külön kell venni, mert arra van refetch dependency!
  const initDefaultValues = () => {
    masterDataService
      .usersToForm()
      .then((data) => {
        const aData = {
          user: data,
        };
        setAutoCompleteData(aData);
      })
      .finally(() => {});
  };

  const getOperationsForCategory = (id) => {
    operationGroupService.getOperationGroupLinkRow(id).then((data) => {
      setOperations(data);
    });
  };

  const findedUser = autoCompleteData?.user?.find((user) => user.userId === filter.userId);

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['opWidgetJobmonitor']);
  }, [menus]);

  const handleNavigate = (operationId) => {
    if (findedUser.resourceOneId) {
      setPages({ subMenuName: submenuNames });
      navigate(`/app/opWidgetJobmonitor2/${operationId}/${filter.userId}/${findedUser?.resourceOneId}`, {
        state: { from: 'operationWidget2', userId: filter.userId },
      });
    } else {
      addNotification({
        content: 'A felhasználóhoz nincs erőforrás hozzárendelve!',
        type: NotificationType.ERROR,
      });
    }
  };

  const notWorkingUsers = autoCompleteData?.user?.filter((u) => u.productionReport.length === 0);

  const getWorkingUsers = (groupId) => {
    if (showWorkingUsers) {
      const operationsInGroup = operationGroup
        .find((group) => group.id === groupId)
        ?.operationGroupLink.map((link) => link.operationId);

      const workingUsers = autoCompleteData?.user
        ?.filter((user) => user.productionReport.some((report) => operationsInGroup?.includes(report.operationId)))
        .map((user) => <div key={user.userId}>{user.userName}</div>);

      return <li>{workingUsers}</li>;
    }
  };

  const filteredOperations =
    params.OPERATION_AND_OP_GROUP_USER_LINK === 'OPERATION'
      ? operations.filter((operation) =>
          operation.operation.operationUserLink.some((link) => link.userId === filter.userId)
        )
      : operations;

  const filteredOperationGroups =
    params.OPERATION_AND_OP_GROUP_USER_LINK === 'OPERATIONGROUP'
      ? operationGroup.filter((group) => group.operationGroupUserLink.some((link) => link.userId === filter.userId))
      : operationGroup;

  return (
    <div className="bg-gray-100 px-6 pt-5" style={{ minHeight: 'calc(100vh - 65px)' }}>
      <div className="flex gap-2 overflow-auto mb-2 custom-scrollbar">
        {prodOperations?.rows?.map((prodOp) => (
          <OpWidgetJobmonitorCard2
            data={prodOp}
            userId={filter.userId}
            setRefetch={setRefetch}
            rows={prodOperations?.rows}
          />
        ))}
      </div>
      <div className="border-2 border-black mb-2"></div>
      <div className="flex justify-center">
        <div>
          <div className="flex gap-5 items-end mb-5">
            <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
              {findedUser && `${findedUser?.nickName} - (${findedUser?.userName})`}
            </Typography>
            <div className="relative top-2">
              <CheckboxUI
                label="Nem bejelentkezett dolgozók"
                onChange={() => setShowNotWorkingUsers(!showNotWorkingUsers)}
              />
            </div>
            <div className="relative top-2">
              <CheckboxUI label="Bejelentkezett dolgozók" onChange={() => setShowWorkingUsers(!showWorkingUsers)} />
            </div>
            {filter.userId && (
              <Typography
                sx={{ fontSize: '1.5rem', fontWeight: 'bold', cursor: 'pointer' }}
                onClick={() => setFilter((prev) => ({ ...prev, userId: null, pinCode: '' }))}
              >
                Kijelentkezés
              </Typography>
            )}
          </div>
          {operations.length === 0 ? (
            <div>
              <div className="flex flex-wrap gap-3">
                {filteredOperationGroups.map((group) => (
                  <Box sx={{ ...categoryCardStyle }} onClick={() => getOperationsForCategory(group.id)}>
                    {group.name}
                    <ul className="font-light normal-case">{getWorkingUsers(group.id)}</ul>
                  </Box>
                ))}
              </div>
            </div>
          ) : (
            <div className="text-center">
              <IconButton size="small" onClick={() => setOperations([])}>
                <div className="text-sm font-medium py-1.5 px-2 rounded flex items-center justify-center gap-1 bg-ganttHoverButtonColor text-white">
                  <KeyboardBackspaceIcon className="text-xl" />
                  <span className="text-base">Vissza</span>
                </div>
              </IconButton>
              <div className="flex gap-5 pt-2 justify-center">
                {filteredOperations.map((operation, index) => (
                  <Box
                    key={index}
                    sx={{
                      ...baseStyle,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    }}
                    onClick={() => handleNavigate(operation.operationId)}
                  >
                    {operation.operation.operationName}
                  </Box>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="border-2 mt-2 border-black"></div>
      <div className="flex gap-2 mt-3 flex-wrap">
        {showNotWorkingUsers &&
          notWorkingUsers?.map((notWorkingUser) => (
            <div key={notWorkingUser.userId} className="bg-red-200 py-2 px-3 rounded-md">
              {notWorkingUser.userName}
            </div>
          ))}
      </div>
      <OperationWidgetDialog
        open={showDialog}
        handleClose={() => setShowDialog(false)}
        filterState={{ filter, setFilter }}
      />
    </div>
  );
};

export default OperationWidgetPage2;
