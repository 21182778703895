import React from 'react';
import { TabsUI } from '../Interface';
import { OperationFormFields } from './OperationFormFields';

export const ProductionTabs = ({
  activeTab,
  onTabChange,
  values,
  setFieldValue,
  errors,
  validateField,
  productionList,
  user,
  params,
}) => {
  const tabs = [
    {
      id: 0,
      name: 'Művelet lejelentés',
      content: (
        <OperationFormFields
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          validateField={validateField}
          productionList={productionList}
          user={user}
          params={params}
        />
      ),
    },
  ];

  const handleTabClick = (tabIndex) => {
    onTabChange(tabIndex);
  };

  return (
    <div className="mt-3">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
};
