import React, { useState, useEffect } from 'react';
import { AutoCompleteSelectUI, DataGridUI, DataGridIdentifiers, DebouncedInputUI, FormLabelUI } from '../Interface';
import { dataGridColumns, generateFilter } from '../../utils/helper';
import statusService from '../../service/status.service';

const StatusReports = ({ statusState }) => {
  const { refetchTable, setRefetchTable, selectedValues, setSelectedValues, operatorsDisabled, viewField } =
    statusState;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    selectListTitle: '',
    subMenuId: '',
  });

  const conditions = {
    like: ['selectListTitle'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'archive',
        value: 'N',
      },
    ];

    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  useEffect(() => {
    if (!Object.keys(selectedValues).length) {
      setRowSelectionModel([]);
    }
  }, [selectedValues]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    statusService.getStatus(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const columns = dataGridColumns([
    {
      field: 'selectListTitle',
      headerName: 'Címke',
      flex: 1,
    },
    {
      field: 'subMenuName',
      headerName: 'Form megjelenési helye',
      flex: 1,
      valueGetter: (data) => {
        return data.row.subMenu?.subMenuName;
      },
    },
  ]);

  return (
    <div className="flex  overflow-hidden m-4 ">
      <div className="h-full w-full  overflow-x-auto ">
        <div className="grid grid-cols-4 gap-x-4 mx-4">
          <div className="col-span-2 md:col-span-1">
            <AutoCompleteSelectUI
              id="Submenus"
              label={<FormLabelUI text="Form" />}
              table="submenus"
              listType={{ id: 'subMenuId', name: 'subMenuName' }}
              onChange={(_e, newVal, reason) => {
                if (reason === 'clear') {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    subMenuId: '',
                  }));
                } else {
                  setFilterFormState((prevState) => ({
                    ...prevState,
                    subMenuId: newVal.value,
                  }));
                }
              }}
              selectedValue={filterFormState.subMenuId}
            />
          </div>
          <div className="col-span-2 md:col-span-1">
            <DebouncedInputUI
              label={<FormLabelUI text="Címke neve" />}
              debounceMs={800}
              setQuickFilterSearchValue={(newValue) => {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  selectListTitle: newValue,
                }));
              }}
              quickFilterSearchValue={filterFormState.selectListTitle}
            />
          </div>
        </div>
        <div className="inline-block w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
          <DataGridUI
            datagridIdentifier={DataGridIdentifiers.StatusReports}
            initialState={query}
            rows={reports.rows}
            rowCount={reports.rowCount}
            columns={columns}
            onRowClick={(rowData) => {
              if (!viewField) return;
              setSelectedValues(rowData.row);
            }}
            paginationMode="server"
            onPaginationModelChange={onPaginitonChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            disableRowSelectionOnClick={!viewField}
            name={'Status'}
            height={750}
          />
        </div>
      </div>
    </div>
  );
};

export default StatusReports;
