import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import jobMonitorService from '../../../service/jobMonitor.service';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import useParam from '../../../context/ParamContext';
import OpWidgetJobmonitorCard2 from './OpWidgetJobmonitorCard2';
import usePages from '../../../context/PageContext';
import useMenus from '../../../context/MenuContext';
import { IconButton } from '@mui/material';
import masterDataService from '../../../service/masterData.service';
import { generateAutocompleteItems } from '../../../utils/helper';
import { AutoCompleteSelectUI, FormLabelUI, SelectUI } from '../../Interface';
import { opWidgetFilter, opWidgetFilterList } from '../../../config/operationWidget';
import dayjs from 'dayjs';

export const OpWidgetJobmonitorPage2 = () => {
  const { operationId, userId, resourceOneId } = useParams();
  const { setPages } = usePages();
  const navigate = useNavigate();
  const { menus, getSubMenuNamesByUrls } = useMenus();
  const { getParam, params } = useParam();
  const [prodOperations, setProdOperations] = useState({ rows: [], rowCount: 0 });
  const [hasMore, setHasMore] = useState(true);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState({
    groupReporting: [],
  });
  const [selectedGroupReporting, setSelectedGroupReporting] = useState({ ids: [] });
  const [refetch, setRefetch] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);
  const [filter, setFilter] = useState({
    filterVal: opWidgetFilter.ALLMYTODAY,
  });
  const [query, setQuery] = useState({
    filter: {
      filterModel: {
        items: [],
        logicOperator: 'and',
      },
    },
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const parsedUserId = userId ? parseInt(userId) : null;
  const parsedOperationId = operationId ? parseInt(operationId) : null;
  const parsedResourceOneId = resourceOneId ? parseInt(resourceOneId) : null;

  useEffect(() => {
    getParam();
  }, []);

  useEffect(() => {
    if (Object.keys(params).length > 0 && filter.filterVal) {
      const statusConfig = JSON.parse(params.JOBMONITOR_STATUSES);
      const statusIds = statusConfig.noPreviousWork;

      if (filter.filterVal === opWidgetFilter.ALLMYTODAY) {
        handleFilterChange(
          [
            { field: 'resourceOneId', operator: 'is', value: parsedResourceOneId },
            { field: 'startDate', operator: 'is', value: dayjs.utc().startOf('day').toDate() },
            { field: 'operationId', operator: 'is', value: parsedOperationId },
            { field: 'productionStatusId', operator: 'isAnyOf', value: statusIds },
          ],
          null,
          null,
          true
        );
      } else if (filter.filterVal === opWidgetFilter.ALLTODAY) {
        handleFilterChange(
          [
            { field: 'startDate', operator: 'is', value: dayjs.utc().startOf('day').toDate() },
            { field: 'operationId', operator: 'is', value: parsedOperationId },
            { field: 'productionStatusId', operator: 'isAnyOf', value: statusIds },
          ],
          null,
          null,
          true
        );
      } else if (filter.filterVal === opWidgetFilter.ALLMY) {
        handleFilterChange(
          [
            { field: 'resourceOneId', operator: 'is', value: parsedResourceOneId },
            { field: 'operationId', operator: 'is', value: parsedOperationId },
            { field: 'productionStatusId', operator: 'isAnyOf', value: statusIds },
          ],
          null,
          null,
          true
        );
      } else {
        handleFilterChange(
          [
            { field: 'operationId', operator: 'is', value: parsedOperationId },
            { field: 'productionStatusId', operator: 'isAnyOf', value: statusIds },
          ],
          null,
          null,
          true
        );
      }
    }
  }, [params, filter.filterVal]);

  useEffect(() => {
    if (selectedGroupReporting.ids.length > 0) {
      setCheckedIds(findedGroups.map((group) => group.id));
    } else {
      setCheckedIds([]);
    }
  }, [selectedGroupReporting.ids]);

  useEffect(() => {
    if (query.filter.filterModel.items.length > 0) {
      getList();
    }
  }, [query, refetch]);

  useEffect(() => {
    if (operationId && resourceOneId) {
      getGroupReportingOptions();
    }
  }, [operationId, resourceOneId]);

  const getGroupReportingOptions = () => {
    masterDataService.groupReportingToForm(operationId, resourceOneId).then((data) => {
      if (data.length > 0) {
        const transformed = generateAutocompleteItems(data, ['reference', 'finishedItemCode', 'name'], 'id');

        setAutoCompleteOptions((prev) => ({
          ...prev,
          groupReporting: transformed,
        }));
      }
    });
  };

  const getList = () => {
    jobMonitorService
      .getProductionOperations(query)
      .then((data) => {
        setProdOperations((prev) => ({
          rows: query.pagination.paginationModel.page === 0 ? data.rows : [...prev.rows, ...data.rows],
          rowCount: data.rowCount,
        }));
        setHasMore(prodOperations.rows.length < data.rowCount);
        setRefetch(false);
      })
      .finally(() => {});
  };

  const handleFilterChange = (fieldOrFilters, operator = null, value = null, clearExisting = false) => {
    setQuery((prev) => {
      const filtersToAdd = Array.isArray(fieldOrFilters)
        ? fieldOrFilters
        : [{ field: fieldOrFilters, operator, value }];

      const validFilters = filtersToAdd
        .filter((f) => f.value !== null && f.value !== undefined)
        .map((f) => ({
          field: f.field,
          operator: f.operator ?? operator,
          value: f.value?.value ?? f.value,
        }));

      return {
        ...prev,
        filter: {
          filterModel: {
            items: clearExisting
              ? validFilters
              : [
                  ...prev.filter.filterModel.items.filter((item) => !filtersToAdd.some((f) => f.field === item.field)),
                  ...validFilters,
                ],
          },
        },
      };
    });
  };

  const fetchMoreData = () => {
    setQuery((prev) => ({
      ...prev,
      pagination: {
        paginationModel: {
          ...prev.pagination.paginationModel,
          page: prev.pagination.paginationModel.page + 1,
        },
      },
    }));
  };

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['operationWidget2']);
  }, [menus]);

  const handleBack = () => {
    setPages({ subMenuName: submenuNames[1] });
    navigate(`/app/operationWidget2`);
  };

  const selectedValues = selectedGroupReporting.ids.map((item) => item.value);

  const findedGroups = useMemo(() => {
    if (!selectedValues.length || !prodOperations?.rows?.length) return [];

    const matchedOperations = prodOperations?.rows?.filter((operation) =>
      selectedValues.includes(operation.productionId)
    );

    if (!matchedOperations.length) return [];

    return prodOperations?.rows?.filter((operation) =>
      matchedOperations.some(
        (match) =>
          operation.production?.finishedItemCode === match.production?.finishedItemCode &&
          operation.production?.company?.name === match.production?.company?.name &&
          operation.production?.reference === match.production?.reference &&
          operation.operation?.groupReporting === true
      )
    );
  }, [selectedValues, prodOperations]);

  const handleCheckboxChange = (isChecked) => {
    if (isChecked) {
      setCheckedIds(findedGroups.map((group) => group.id));
    } else {
      setCheckedIds([]);
    }
  };

  const filteredReports = findedGroups
    ?.filter((group) =>
      group.productionReport.some(
        (report) =>
          checkedIds.includes(report.prodOperationId) && report.startDate && !report.endDate && report.userId === userId
      )
    )
    .map((group) =>
      group.productionReport.filter(
        (report) =>
          checkedIds.includes(report.prodOperationId) && report.startDate && !report.endDate && report.userId === userId
      )
    )
    .flat()
    .map((report) => report.id);

  const extendedRows = prodOperations.rows.flatMap((row) => {
    const relatedMultiResources = row.productionOperationMultiResource.map((multiResource) => ({
      ...row,
      isMultiResource: true,
      multiResourceId: multiResource.id,
    }));

    return [row, ...relatedMultiResources];
  });

  return (
    <div className="bg-[#CDDFF7] min-h-full px-6 py-2">
      <div className="pb-5 px-3 flex gap-5">
        {parsedOperationId && (
          <IconButton size="small" onClick={handleBack}>
            <div className="text-sm font-medium py-1.5 px-2 rounded flex items-center justify-center gap-1 bg-ganttHoverButtonColor text-white mt-2">
              <KeyboardBackspaceIcon className="text-xl" />
              <span className="text-base">Vissza</span>
            </div>
          </IconButton>
        )}
        <div className="w-[350px]">
          <AutoCompleteSelectUI
            dataset={autoCompleteOptions.groupReporting}
            label={<FormLabelUI text="Csoportos lejelentés" />}
            onChange={(_e, newVal) => {
              setSelectedGroupReporting((prev) => ({
                ...prev,
                ids: newVal,
              }));
            }}
            selectedValue={selectedGroupReporting.ids}
            freeSolo
            multiple
            getOptionLabel={(option) => option.label}
          />
        </div>
        <div className="w-[300px]">
          <SelectUI
            option={opWidgetFilterList}
            label={<FormLabelUI text="Szűrés" />}
            onChange={(e) =>
              setFilter((prev) => ({
                ...prev,
                filterVal: e.target.value,
              }))
            }
            value={filter.filterVal}
          />
        </div>
      </div>
      <InfiniteScroll dataLength={prodOperations.rows.length} next={fetchMoreData} hasMore={hasMore}>
        <div className="flex gap-5 justify-center flex-wrap">
          {extendedRows.length > 0 &&
            extendedRows.map((operation) => (
              <OpWidgetJobmonitorCard2
                key={`${operation.id}-${operation.isMultiResource ? 'multi' : 'single'}`}
                data={operation}
                setRefetch={setRefetch}
                userId={parsedUserId}
                findedGroups={findedGroups}
                checkedIds={checkedIds}
                handleCheckboxChange={handleCheckboxChange}
                filteredReports={filteredReports}
                rows={extendedRows}
                params={params}
              />
            ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};
