import React from 'react';
import { Typography, Accordion, AccordionActions, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';

const baseStyle = {
  caption: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
};

const Fields = ({ data, type }) => {
  return (
    <div className="flex flex-wrap mt-5 gap-x-10 gap-y-3">
      {type === 'RESOURCECOLUMN' && (
        <div>
          <Typography>{data.production.articleNumber}</Typography>
        </div>
      )}
      <div>
        <Typography>{data.operation.operationName}</Typography>
      </div>
      <div>
        <Typography variant="caption" sx={{ ...baseStyle.caption }}>
          Vevőhivatkozási szám
        </Typography>
        <Typography>{data.production?.reference}</Typography>
      </div>
      {type === 'RESOURCECOLUMN' ? (
        <Accordion sx={{ width: '100%', background: 'none' }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography component="span">További információk</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="mb-2">
              <Typography variant="caption" sx={{ ...baseStyle.caption }}>
                Gyártandó mennyiség
              </Typography>
              <Typography>{`${data.production.productionQuantity} ${data.production.quantityUnit.code}`}</Typography>
            </div>
            <div className="mb-2">
              <Typography variant="caption" sx={{ ...baseStyle.caption }}>
                Vevő
              </Typography>
              <Typography>{data.production?.company?.name}</Typography>
            </div>
            <div className="mb-2">
              <Typography variant="caption" sx={{ ...baseStyle.caption }}>
                GY tervezett kezdete
              </Typography>
              <Typography>{dayjs(data.startDate).format('YYYY.MM.DD HH:mm')}</Typography>
            </div>
          </AccordionDetails>
        </Accordion>
      ) : (
        <>
          <div>
            <Typography variant="caption" sx={{ ...baseStyle.caption }}>
              Gyártandó mennyiség
            </Typography>
            <Typography>{`${data.production.productionQuantity} ${data.production.quantityUnit.code}`}</Typography>
          </div>
          <div>
            <Typography variant="caption" sx={{ ...baseStyle.caption }}>
              Vevő
            </Typography>
            <Typography>{data.production?.company?.name}</Typography>
          </div>
          <div>
            <Typography variant="caption" sx={{ ...baseStyle.caption }}>
              GY tervezett kezdete
            </Typography>
            <Typography>{dayjs(data.startDate).format('YYYY.MM.DD HH:mm')}</Typography>
          </div>
        </>
      )}
    </div>
  );
};

export default Fields;
