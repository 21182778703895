import { useState, useEffect, useMemo } from 'react';

import { dataGridPremiumColumns, generateColumnsFromDynProps } from '../../utils/helper';

import { DataGridIdentifiers, OrderArrowsUI, DatagridPremiumUi } from '../Interface';
import projectService from '../../service/project.service';

export default function ProjectReports({ projectState }) {
  const {
    setRefetchTable,
    setSelectedValues,
    viewField,
    operatorsDisabled,
    refetchTable,
    rowSelectionModel,
    setRowSelectionModel,
    dynProps,
    autoCompleteOptions,
  } = projectState;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'projectName', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const orderProject = (projectId, newOrder) => {
    projectService.orderProject(projectId, { newOrder }).then(() => {
      setRefetchTable(true);
    });
  };

  const handleOrderChange = (projectId, direction) => {
    orderProject(projectId, direction);
  };

  const getList = (gridState) => {
    projectService.getProjects(gridState).then((data) => {
      setReports(data);
    });
  };

  const showActive = (params) => {
    const { active } = params.row;
    if (active === true) {
      return 'Igen';
    } else if (active === false) {
      return 'Nem';
    }
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'projectName',
        headerName: 'Projekt neve',
        type: 'string',
      },
      {
        field: 'companyId',
        type: 'singleSelect',
        headerName: 'Cég',
        renderCell: (data) => {
          return data.row.company?.name;
        },
        valueOptions: autoCompleteOptions.company,
      },
      {
        field: 'statusId',
        type: 'singleSelect',
        headerName: 'Státusz',
        renderCell: (data) => {
          return data.row.status?.itemName;
        },
        valueOptions: autoCompleteOptions.status,
      },
      {
        field: 'projectStartDate',
        type: 'date',
        headerName: 'Kezdő időpont',
        valueGetter: (_data, row) => {
          return new Date(row.projectStartDate);
        },
      },
      {
        field: 'projectEndDate',
        type: 'date',
        headerName: 'Befejező időpont',
        valueGetter: (_data, row) => {
          return new Date(row.projectEndDate);
        },
      },
      {
        field: 'projectPrepare',
        headerName: '%',
        type: 'number',
        sortable: false,
        renderCell: (data) => {
          const preparedness = data.row.projectPrepare;
          return preparedness > 0 ? `${preparedness}%` : '';
        },
      },
      {
        field: 'visOrder',
        headerName: 'Sorrend',
        type: 'action',
        renderCell: (params) => {
          const disabled = params?.api?.getSortModel()[0]?.field !== 'visOrder';
          const isSortedDescending = params.api.getSortModel()[0]?.sort === 'desc';

          const handleUpClick = () => {
            if (isSortedDescending) {
              handleOrderChange(params.row.projectId, 'down');
            } else {
              handleOrderChange(params.row.projectId, 'up');
            }
          };

          const handleDownClick = () => {
            if (isSortedDescending) {
              handleOrderChange(params.row.projectId, 'up');
            } else {
              handleOrderChange(params.row.projectId, 'down');
            }
          };

          return <OrderArrowsUI onUpClick={handleUpClick} onDownClick={handleDownClick} disabled={disabled} />;
        },
      },
      {
        field: 'orderNumber',
        headerName: 'Rendelési szám',
        type: 'string',
      },
      {
        field: 'active',
        headerName: 'Aktív',
        type: 'string',
        renderCell: (data) => {
          return showActive(data);
        },
      },
      ...generateColumnsFromDynProps(dynProps ?? []),
    ]);
  }, [autoCompleteOptions, dynProps]);

  return (
    <>
      {Object.keys(autoCompleteOptions).length > 0 && Object.keys(dynProps).length > 0 && (
        <DatagridPremiumUi
          datagridIdentifier={DataGridIdentifiers.Projects}
          datagridInitialState={initialState}
          dataLoader={getList}
          rows={reports.rows}
          refetch={{ refetchTable, setRefetchTable }}
          rowCount={reports.rowCount}
          columns={[...columns]}
          onRowClick={(rowData) => {
            if (!viewField) return;
            setSelectedValues(rowData.row);
          }}
          paginationAndfilterMode="server"
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          getRowId={(row) => row.projectId}
          rowSelectionModel={rowSelectionModel}
          disableRowSelectionOnClick={!viewField}
          gridExport={true}
        />
      )}
    </>
  );
}
