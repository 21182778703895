import { DialogUI } from '../Interface';
import { useProduction } from '../../hooks/useProduction';
import { useEffect } from 'react';
import { ProductionPage } from '../Production/NewProduction/ProductionPage';

const ProductDialog = ({ open, handleClose, production }) => {
  const productionState = useProduction();

  useEffect(() => {
    if (production) {
      productionState.setSelectedValues(production);
    }
  }, [production, productionState]);

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent={'Gyártás adatok'}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1300px',
          },
        },
      }}
    >
      <div className="mt-5">
        <ProductionPage
          dialog={{
            handleClose: handleClose,
            isView: true,
          }}
          productionState={productionState}
        />
      </div>
    </DialogUI>
  );
};

export default ProductDialog;
