import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import useSaveView from '../../hooks/useSaveView';
import { AutoCompleteSelectUI, DebouncedInputUI } from '../Interface';
import AddIcon from '@mui/icons-material/Add';
import useUsers from '../../context/UsersContext';

const GanttFilter = ({
  ganttFilter,
  filter,
  setSimpleTaskDialogOpen,
  setCreateProductDialogOpen,
  setProductDrawerOpen,
}) => {
  const { getUser, user } = useUsers();
  const saveView = useSaveView(user);
  const { filterFormState, setFilterFormState } = filter;

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
  }, []);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    const newViewSettings = { ...ganttFilter, [name]: checked };
    saveView('ganttFilter', newViewSettings);
  };
  return (
    <div className="flex items-center">
      <div className="flex gap-2">
        <FormControlLabel
          className="text-[#667280]"
          control={
            <Checkbox
              name="production"
              checked={ganttFilter?.production || false}
              onChange={handleCheckboxChange}
              sx={{
                color: '#667280',
                '&.Mui-checked': {
                  color: '#667280',
                },
              }}
            />
          }
          label="Gyártás"
        />
        <FormControlLabel
          className="text-[#667280] text-lg"
          control={
            <Checkbox
              name="task"
              checked={ganttFilter?.task || false}
              onChange={handleCheckboxChange}
              sx={{
                color: '#667280',
                '&.Mui-checked': {
                  color: '#667280',
                },
              }}
            />
          }
          label="Feladatok"
        />
      </div>
      <div className="flex items-center">
        <div className="w-[130px]">
          <AutoCompleteSelectUI
            fullWidth
            className="ganttfilter relative -top-2 -left-2"
            table="production"
            label={<span className="text-[#667280] text-lg">Munkaszám</span>}
            listType={{ id: 'id', name: 'workingNumber' }}
            selectedValue={filterFormState.productionId}
            onChange={(_e, newVal, reason) => {
              if (reason === 'clear') {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  productionId: '',
                }));
              } else {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  productionId: newVal.value,
                }));
              }
            }}
            conditions={[`production.deletedBy IS NULL`]}
          />
        </div>
        <div className="w-[150px]">
          <AutoCompleteSelectUI
            fullWidth
            className="ganttfilter relative -top-2 left-[10px]"
            label={<span className="text-[#667280] text-lg">Művelet</span>}
            table="operation"
            listType={{ id: 'operationId', name: 'operationName' }}
            selectedValue={filterFormState.operationId}
            onChange={(_e, newVal, reason) => {
              if (reason === 'clear') {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  operationId: '',
                }));
              } else {
                setFilterFormState((prevState) => ({
                  ...prevState,
                  operationId: newVal.value,
                }));
              }
            }}
            conditions={[`operation.archive = 'N'`]}
          />
        </div>
        <div className="w-[150px]">
          <DebouncedInputUI
            className="ganttfilter relative -top-2 left-10"
            label={<span className="text-[#667280] text-lg">Vevőhiv. szám</span>}
            debounceMs={800}
            setQuickFilterSearchValue={(newValue) => {
              setFilterFormState((prevState) => ({
                ...prevState,
                reference: newValue,
              }));
            }}
            quickFilterSearchValue={filterFormState.reference}
          />
        </div>
        <div onClick={() => setSimpleTaskDialogOpen(true)} className="cursor-pointer">
          <div className="bg-[#bbf7d04d] text-[#16a34a] text-sm font-medium relative left-14 py-2 px-2 rounded flex items-center justify-center gap-1 hover:bg-[#bbf7d0]">
            <AddIcon className="text-xl" />
            <span className="text-base">Karbantartás</span>
          </div>
        </div>
        <div onClick={() => setCreateProductDialogOpen(true)} className="cursor-pointer">
          <div className="bg-[#bbf7d04d] text-[#16a34a] text-sm font-medium relative left-16  py-2 px-2 rounded flex items-center justify-center gap-1 hover:bg-[#bbf7d0]">
            <AddIcon className="text-xl" />
            <span className="text-base">Gyártás</span>
          </div>
        </div>
        <div onClick={() => setProductDrawerOpen(true)} className="cursor-pointer w-[100px]">
          <div className="bg-[#6f9ad833] text-bold text-[#014AB1] text-sm font-medium relative left-[72px]  py-2 px-5 rounded flex items-center justify-center gap-1 hover:bg-[#6f9ad880]">
            <span className="text-base">Gyártástár</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GanttFilter;
