import React from 'react';
import { Field, useFormikContext } from 'formik';
import { AutoCompleteSelectUI, FormErrorUI, FormLabelUI } from '../Interface';

const UserDataFields = ({ selectedValues, disabled, submenuNames, autoCompleteOptions }) => {
  const { values, setFieldValue, validateField, errors } = useFormikContext();

  return (
    <div className="grid grid-cols-4 gap-x-4 mb-5">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="resourceOneId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text={`${submenuNames[0]} hozzárendelése`} />}
          variant="standard"
          helperText={<FormErrorUI message={errors.resourceOneId} />}
          onChange={(_e, newVal) => {
            setFieldValue('resourceOneId', newVal?.value ?? null).then(() => {
              validateField('resourceOneId');
            });
          }}
          disabled={disabled}
          InputLabelProps={{ shrink: values.resourceOneId !== '' }}
          selectedValue={values.resourceOneId}
          selectedLabelValue={selectedValues?.resourceOne?.name}
          dataset={autoCompleteOptions.resourceOne}
        />
      </div>
    </div>
  );
};

export default UserDataFields;
