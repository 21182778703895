import React from 'react';
import { Field } from 'formik';
import { AutoCompleteSelectUI, CheckboxUI, FormErrorUI, FormLabelUI } from '../Interface';

const CompanySupplierFields = ({
  viewField,
  values,
  errors,
  setFieldValue,
  validateField,
  autocompleteOptions,
  supplierVatCode,
  purchasePerson,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-3 mx-4 mb-5">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="supplierPriceListId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Árlista" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.supplierPriceListId} />}
          onChange={(_e, newVal) => {
            setFieldValue('supplierPriceListId', newVal?.value ?? null).then(() => {
              validateField('supplierPriceListId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.supplierPriceListId !== '' }}
          selectedValue={values.supplierPriceListId}
          dataset={autocompleteOptions.priceList}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="supplierPaymentMethodId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Fizetési mód" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.supplierPaymentMethodId} />}
          onChange={(_e, newVal) => {
            setFieldValue('supplierPaymentMethodId', newVal?.value ?? null).then(() => {
              validateField('supplierPaymentMethodId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.supplierPaymentMethodId !== '' }}
          selectedValue={values.supplierPaymentMethodId}
          dataset={autocompleteOptions.paymentMethod}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="supplierCurrencyCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Pénznem" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.supplierCurrencyCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('supplierCurrencyCode', newVal?.value ?? null).then(() => {
              validateField('supplierCurrencyCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.supplierCurrencyCode !== '' }}
          selectedValue={values.supplierCurrencyCode}
          dataset={autocompleteOptions.currency}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="supplierVatCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Áfakulcs" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.supplierVatCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('supplierVatCode', newVal?.value ?? null).then(() => {
              validateField('supplierVatCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.supplierVatCode !== '' }}
          selectedValue={values.supplierVatCode}
          dataset={supplierVatCode}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="supplierDeliveryMethodId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Szállítási mód" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.supplierDeliveryMethodId} />}
          onChange={(_e, newVal) => {
            setFieldValue('supplierDeliveryMethodId', newVal?.value ?? null).then(() => {
              validateField('supplierDeliveryMethodId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.supplierDeliveryMethodId !== '' }}
          selectedValue={values.supplierDeliveryMethodId}
          dataset={autocompleteOptions.deliveryMethod}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="supplierIncotermCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Fuvarparitás" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.supplierIncotermCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('supplierIncotermCode', newVal?.value ?? null).then(() => {
              validateField('supplierIncotermCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.supplierIncotermCode !== '' }}
          selectedValue={values.supplierIncotermCode}
          dataset={autocompleteOptions.incoterm}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="purchasePersonId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Beszerző" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.purchasePersonId} />}
          onChange={(_e, newVal) => {
            setFieldValue('purchasePersonId', newVal?.value ?? null).then(() => {
              validateField('purchasePersonId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.purchasePersonId !== '' }}
          selectedValue={values.purchasePersonId}
          dataset={purchasePerson}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
    </div>
  );
};

export default CompanySupplierFields;
