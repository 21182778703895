import React from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { hourToMin } from '../../utils/helper';

const baseStyle = {
  caption: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
};

const Fields = ({ data, totalReportedQty, totalReportedTimeSpent, params }) => {
  return (
    <div className="flex flex-wrap mt-10 mb-5 gap-x-20 gap-y-5">
      <div>
        <Typography variant="caption" sx={{ ...baseStyle.caption }}>
          Cikkszám
        </Typography>
        <Typography>{data.production.articleNumber}</Typography>
      </div>
      <div>
        <Typography variant="caption" sx={{ ...baseStyle.caption }}>
          Gyártandó mennyiség
        </Typography>
        <Typography>{`${data.production.productionQuantity} ${data.production.quantityUnit.code}`}</Typography>
      </div>
      <div>
        <Typography variant="caption" sx={{ ...baseStyle.caption }}>
          Vevő
        </Typography>
        <Typography>{data.production?.company?.name}</Typography>
      </div>
      <div>
        <Typography variant="caption" sx={{ ...baseStyle.caption }}>
          Vevői rendelés szám
        </Typography>
        <Typography>{data.production?.baseDocNum}</Typography>
      </div>
      <div>
        <Typography variant="caption" sx={{ ...baseStyle.caption }}>
          Művelet neve
        </Typography>
        <Typography>{data.operation.operationName}</Typography>
      </div>
      <div>
        <Typography variant="caption" sx={{ ...baseStyle.caption }}>
          GY tervezett kezdete
        </Typography>
        <Typography>{dayjs(data.startDate).format('YYYY.MM.DD HH:mm')}</Typography>
      </div>
      <div>
        <Typography variant="caption" sx={{ ...baseStyle.caption }}>
          Erőforrás
        </Typography>
        <Typography>{data.resourceOne.name}</Typography>
      </div>
      <div>
        <Typography variant="caption" sx={{ ...baseStyle.caption }}>
          Mennyiség
        </Typography>
        <Typography>{`${totalReportedQty} ${data.production.quantityUnit.code}`}</Typography>
      </div>
      <div>
        <Typography variant="caption" sx={{ ...baseStyle.caption }}>
          Tervezett idő
        </Typography>
        <Typography>{`${data.plannedQty} ${data.quantityUnit.code}`}</Typography>
      </div>
      <div>
        <Typography variant="caption" sx={{ ...baseStyle.caption }}>
          Tény idő
        </Typography>
        <Typography>
          {params.QUANTITY_UNIT_HOUR_CODE === data.quantityUnit.code
            ? `${Math.round(totalReportedTimeSpent * 100) / 100} ${data.quantityUnit.code}`
            : `${hourToMin(totalReportedTimeSpent)} ${data.quantityUnit.code}`}
        </Typography>
      </div>
    </div>
  );
};

export default Fields;
