import React, { useEffect, useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import huLocale from '@fullcalendar/core/locales/hu';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { isEmpty, isEqual } from 'lodash';

import useParam from '../../context/ParamContext';
import useSaveView from '../../hooks/useSaveView';
import useUsers from '../../context/UsersContext';
import useView from '../../hooks/useView';
import { useLoader } from '../../provider/LoaderProvider';
import sapResourcePlannerService from '../../service/sapResourcePlanner.service';
import SapProductOrderDialog from './SapProductOrderDialog';
import { ColorPicker } from '../../config/ColorPicker';
import EventChip from '../TaskGantt/EventChip';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import {
  validateDependencies,
  collectRelatedRecords,
  collectRelatedRecordsData,
  mapDependencies,
} from './relationHelper';

const SapResourcePlanner = () => {
  const { getUser, user } = useUsers();
  const saveView = useSaveView(user);
  const sapGanttView = useView('sapGantt', user);
  const { getParam } = useParam();
  const { showLoader, hideLoader } = useLoader();
  const calendarRef = useRef(null);

  const [tasks, setTasks] = useState({ resources: [], events: [], backgroundEvents: [] });
  const [productionOrdersWithSapProDependencies, setProductionOrdersWithSapProDependencies] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [task, setTask] = useState({});
  const [dateRange, setDateRange] = useState({});
  const [userTimezone, setUserTimezone] = useState(null);

  const [timeInterval, setTimeInterval] = useState({});
  const [intervalButtons, setIntervalButtons] = useState({});
  const [calendarViews, setCalendarViews] = useState({});
  const [selectedView, setSelectedView] = useState(null);
  const [relatedResources, setRelatedResources] = useState([]);

  const [isInit, setIsInit] = useState(true);

  const refreshCalendar = () => {
    const calendarApi = calendarRef.current.getApi();
    setTimeout(() => {
      calendarApi.render();
    }, 100);
  };

  useEffect(() => {
    refreshCalendar();
  }, [tasks.events]);

  const slotIntervals = {
    halfHour: { hours: 0.5 },
    hour: { hours: 1 },
    day: { days: 1 },
    week: { weeks: 1 },
    month: { months: 1 },
  };

  const calendarHourViews = {
    resourceTimelineDay: {
      slotLabelFormat: [{ hour: 'numeric', minute: '2-digit' }],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [
        { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' },
        { hour: 'numeric', minute: '2-digit' },
      ],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [
        { day: 'numeric', weekday: 'long' },
        { hour: 'numeric', minute: '2-digit' },
      ],
    },
  };

  const calendarDayViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'long' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ day: 'numeric', weekday: 'short' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'short' }, { day: 'numeric' }],
    },
  };

  const calendarWeekViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ week: 'numeric' }],
    },
  };

  const calendarMonthViews = {
    resourceTimelineDay: {
      slotLabelFormat: [],
    },
    resourceTimelineWeek: {
      slotLabelFormat: [],
    },
    resourceTimelineMonth: {
      slotLabelFormat: [],
    },
    resourceTimelineYear: {
      slotLabelFormat: [{ month: 'long' }],
    },
  };

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    setUserTimezone(dayjs.tz.guess());

    getResources();
    getParam();
    getUser();
  }, []);

  useEffect(() => {
    if (dateRange.startDate && dateRange.endDate && !isInit) {
      // @TODO promiseArray
      getProductionOrders();
      getActiveTimes();
    }
  }, [dateRange, isInit]);

  useEffect(() => {
    if (Object.keys(task).length > 0) {
      getProductionOrdersWithSapProDependencies();
    } else {
      setProductionOrdersWithSapProDependencies([]);
    }
  }, [task]);

  useEffect(() => {
    if (selectedView) {
      setIntervalButtons(generateButtons(selectedView));
      setIsInit(false);
    }
  }, [selectedView]);

  const getResources = () => {
    showLoader();
    sapResourcePlannerService
      .getResources()
      .then((data) => {
        setTasks((prevState) => ({ ...prevState, resources: data }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getActiveTimes = () => {
    showLoader();
    sapResourcePlannerService
      .getActiveTimeByRange(dateRange)
      .then((data) => {
        setTasks((prevState) => ({ ...prevState, backgroundEvents: data }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getProductionOrders = (sapProdData) => {
    showLoader();
    sapResourcePlannerService
      .getProductionOrder(dateRange)
      .then((data) => {
        const relatedRes = [];
        const events = data.map((event) => {
          const haveOverlap = data.find((refEvent) => {
            return (
              dayjs(event.start).isBefore(refEvent.end) &&
              dayjs(event.end).isAfter(refEvent.start) &&
              event.resourceId === refEvent.resourceId &&
              event.id !== refEvent.id
            );
          });

          // Check if the task is valid and related
          if (Object.keys(task).length > 0) {
            const related = collectRelatedRecords(sapProdData, `${task.DocEntry}_${task.LineNum}`);
            if (related.includes(event.id)) {
              relatedRes.push(event.resourceId);
              return { ...event, ...getColor('red') };
            }
          }

          // If there is an overlap, set color to orange
          if (haveOverlap) {
            return { ...event, ...getColor('orange') };
          }

          // Set color based on U_QNT_PLANSTATUS
          return { ...event, ...getColor(event.eventData?.U_QNT_PLANSTATUS === 'KEZDO' ? 'blue' : 'green') };
        });

        setRelatedResources(relatedRes);

        setTasks((prevState) => ({ ...prevState, events }));
      })
      .finally(() => {
        hideLoader();
      });
  };

  const getProductionOrdersWithSapProDependencies = (start = undefined, end = undefined) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoader();
        const startDate = dayjs(start ?? task.COMBINED_START)
          .add(-15, 'day')
          .startOf('day');
        const endDate = dayjs(end ?? task.COMBINED_END)
          .add(15, 'day')
          .endOf('day');
        const data = await sapResourcePlannerService.getProductionOrder({
          startDate,
          endDate,
          withSapProDependencies: true,
        });

        setProductionOrdersWithSapProDependencies(data);
        hideLoader();
        getProductionOrders(data);
        resolve(data);
      } catch (e) {
        reject(e);
      }
    });
  };

  const getProductionOrderById = (id) => {
    //showLoader();
    sapResourcePlannerService
      .getProductionOrderById(id)
      .then((data) => {
        setTask(data);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const getColor = (colorName) => {
    const colorObj = ColorPicker.find((color) => color.colorName === colorName);
    return {
      backgroundColor: colorObj ? colorObj.colorBgCode : '#fff',
      textColor: colorObj ? colorObj.textColor : '#000',
    };
  };

  const handleDatesSet = (dateInfo) => {
    const startDate = dayjs(dateInfo.start).startOf('day');
    const endDate = dayjs(dateInfo.end).endOf('day');

    if (dateRange.startDate?.isSame(startDate, 'day') && dateRange.endDate?.isSame(endDate, 'day')) {
      return;
    }
    setDateRange({ startDate, endDate });

    if (selectedView !== dateInfo.view.type && !isInit) {
      setSelectedView(dateInfo.view.type);
    }
    if (/*Object.keys(sapGanttView).length > 0 && */ !isInit) {
      saveCurrentView();
    }
  };

  const updateEvent = async (resizeInfo) => {
    const { event, revert, oldEvent } = resizeInfo;

    getProductionOrderById(event.id);
    const sapProdData = await getProductionOrdersWithSapProDependencies(event.start, event.end);
    showLoader();
    const endDifferent = dayjs(event.end).diff(oldEvent.end, 'm');

    const newEndDate = dayjs(event.end).toISOString();
    const newStartDate = dayjs(event.start).toISOString();
    const resource = event.getResources()[0].id;

    const prodOrderId = event.id;
    const updatedEventData = {
      id: prodOrderId,
      startDate: newStartDate,
      endDate: newEndDate,
      machineId: resource,
      different: endDifferent,
    };

    const relatedIds = collectRelatedRecords(sapProdData, prodOrderId);
    const filteredDepStructure = relatedIds
      .map((id) => sapProdData.find((item) => item.id === id))
      .filter((item) => item !== undefined);
    const vTasks = mapDependencies(filteredDepStructure);

    const relatedRecordsData = collectRelatedRecordsData(sapProdData, prodOrderId);

    const checkData = [];

    if (!isEmpty(relatedRecordsData)) {
      relatedRecordsData.forEach((e) => {
        const findedVData = vTasks.find((v) => v.itemId === e.id);

        if (!e.isParent) {
          checkData.push({
            ...findedVData,
            _end: dayjs(findedVData._end).add(endDifferent, 'm').toDate(),
            _start: dayjs(findedVData._start).add(endDifferent, 'm').toDate(),
          });
        } else {
          checkData.push({
            ...findedVData,
          });
        }
      });
    }

    let vTask = vTasks.find((v) => v.itemId === prodOrderId);
    const updatedTask = {
      ...vTask,
      _end: dayjs(event.end).toDate(),
      _start: dayjs(event.start).toDate(),
    };
    checkData.push(updatedTask);

    const checkObj = {
      task: updatedTask,
      start: dayjs(event.start).toDate(),
      end: dayjs(event.end).toDate(),
      different: endDifferent,
    };
    let prodOrderData = filteredDepStructure.find((f) => f.id === prodOrderId);

    const parent = prodOrderData.dependencies.find((f) => f.parent)?.parent;
    if (parent) {
      const parentId = `${parent.docEntry}_${parent.lineNum}`;
      const parentOrderData = filteredDepStructure.find((f) => f.id === parentId);
      prodOrderData.parent = parentOrderData;
    }

    const valid = validateDependencies([checkObj], checkData, prodOrderData);

    if (valid) {
      //@TODO try catch

      updateBatch(updatedEventData, sapProdData).catch(revert());
    } else {
      hideLoader();
      revert();
    }
  };

  const updateBatch = (updatedEventData, sapProdData = undefined) => {
    return new Promise((resolve, reject) => {
      const { id, startDate, endDate, machineId, different, AdditionalQuantity, U_QNT_PLANSTATUS } = updatedEventData;
      const batchData = [{ id, startDate, endDate, machineId, AdditionalQuantity, U_QNT_PLANSTATUS }];

      const relatedRecordsData = collectRelatedRecordsData(sapProdData ?? productionOrdersWithSapProDependencies, id);

      if (!isEmpty(relatedRecordsData)) {
        const relatedWithoutParent = relatedRecordsData.filter((f) => !f.isParent);

        relatedWithoutParent.forEach((e) => {
          batchData.push({
            id: e.id,
            startDate: dayjs(e.start).add(different, 'm').toISOString(),
            endDate: dayjs(e.end).add(different, 'm').toISOString(),
            machineId: e.resourceId,
          });
        });
      }
      showLoader();
      sapResourcePlannerService
        .updateProductionOrderBatch(batchData)
        .then(() => {
          addNotification({
            content: 'Sikeres módosítás!',
            type: NotificationType.SUCCESS,
          });
          getProductionOrdersWithSapProDependencies();
          resolve();
        })
        .catch((e) => {
          reject(e);
          hideLoader();
        });
    });
  };

  const timeIntervalButtons = {
    halfHour: {
      text: 'Fél óra',
      click: () => {
        setTimeInterval(slotIntervals.halfHour);
        setCalendarViews(calendarHourViews);
      },
    },
    hour: {
      text: 'Óra',
      click: () => {
        setTimeInterval(slotIntervals.hour);
        setCalendarViews(calendarHourViews);
      },
    },
    day: {
      text: 'Nap',
      click: () => {
        setTimeInterval(slotIntervals.day);
        setCalendarViews(calendarDayViews);
      },
    },
    week: {
      text: 'Hét',
      click: () => {
        setTimeInterval(slotIntervals.week);
        setCalendarViews(calendarWeekViews);
      },
    },
    month: {
      text: 'Hónap',
      click: () => {
        setTimeInterval(slotIntervals.month);
        setCalendarViews(calendarMonthViews);
      },
    },
  };

  const generateButtons = (viewName) => {
    let retVal = {};
    switch (viewName) {
      case 'resourceTimelineDay':
        retVal = 'halfHour,hour,day';
        if (!isInit) {
          setTimeInterval(slotIntervals.halfHour);
          setCalendarViews(calendarHourViews);
        }

        break;
      case 'resourceTimelineWeek':
        retVal = 'halfHour,hour,day,week';
        if (!isInit) {
          setTimeInterval(slotIntervals.halfHour);
          setCalendarViews(calendarHourViews);
        }
        break;
      case 'resourceTimelineMonth':
        retVal = 'hour,day,week,month';
        if (!isInit) {
          setTimeInterval(slotIntervals.hour);
          setCalendarViews(calendarHourViews);
        }
        break;
      case 'resourceTimelineYear':
        retVal = 'day,week,month';
        if (!isInit) {
          setTimeInterval(slotIntervals.day);
          setCalendarViews(calendarDayViews);
        }
        break;
    }
    return retVal;
  };

  useEffect(() => {
    if (
      Object.keys(timeInterval ?? {}).length > 0 &&
      sapGanttView.timeInterval &&
      !isEqual(timeInterval, sapGanttView.timeInterval) &&
      Object.keys(user).length > 0 &&
      !isInit
    ) {
      saveCurrentView();
    }
  }, [timeInterval]);

  useEffect(() => {
    if (Object.keys(user).length > 0 && calendarRef && Object.keys(sapGanttView).length > 0 && isInit) {
      const calendar = calendarRef?.current.calendar;

      setTimeInterval(sapGanttView.timeInterval);
      setCalendarViews(sapGanttView.calendarViews);
      setSelectedView(sapGanttView.type);
      calendar.gotoDate(sapGanttView.date);
      calendar.changeView(sapGanttView.type, sapGanttView.date);
    } else if (Object.keys(user).length > 0 && calendarRef && !Object.keys(sapGanttView).length > 0 && isInit) {
      setTimeInterval(slotIntervals.halfHour);
      setCalendarViews(calendarHourViews);
      setSelectedView('resourceTimelineDay');
    }
  }, [user]);

  const saveCurrentView = () => {
    if (calendarRef?.current && Object.keys(user).length > 0 && Object.keys(dateRange).length > 0 && !isInit) {
      const currentView = calendarRef?.current.calendar.view;

      saveView('sapGantt', {
        type: selectedView,
        date: currentView.currentStart,
        timeInterval: timeInterval,
        calendarViews,
      });

      //console.log('Nézet elmentve:', currentView.type, currentView.currentStart, timeInterval);
    }
  };

  const resourceColumns = [
    {
      field: '',
      headerClassNames: 'hide',
      headerContent: 'Erőforrás',
    },
    {
      field: '',
      headerContent: '',
      headerClassNames: 'hide',
      cellContent: function (_arg) {
        return '';
      },
      cellClassNames: function (arg) {
        const classNames = ['extraCol'];

        const hasRelated = relatedResources.includes(arg.resource._resource.id);
        if (hasRelated) {
          classNames.push('bgRed');
        }

        return classNames;
      },
    },
  ];

  return (
    <div className="sapresourcecalendar">
      <FullCalendar
        ref={calendarRef}
        customButtons={timeIntervalButtons}
        slotMinWidth={70}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        initialView="resourceTimelineDay"
        resourceOrder="order,id"
        resources={tasks.resources}
        locales={[huLocale]}
        locale="hu"
        slotLabelFormat={[
          { month: 'long', year: 'numeric', weekday: 'long' },
          { hour: 'numeric', minute: '2-digit' },
        ]}
        datesSet={handleDatesSet}
        events={[...tasks.events, ...tasks.backgroundEvents]}
        editable={true}
        nowIndicator={true}
        eventDurationEditable={false}
        eventDrop={updateEvent}
        eventClick={(clickInfo) => {
          if (clickInfo.event.display !== 'background') {
            const taskId = clickInfo.event.id;
            getProductionOrderById(taskId);
            setDialogOpen(true);
          }
        }}
        eventContent={(eventInfo) => {
          if (eventInfo.event.display !== 'background') {
            return <EventChip eventInfo={eventInfo} />;
          }
        }}
        headerToolbar={{
          left: `prev,next,today ${intervalButtons}`,
          center: 'title',
          right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear',
        }}
        views={calendarViews}
        slotDuration={timeInterval}
        timeZone={userTimezone}
        resourceAreaWidth="15%"
        resourceAreaHeaderContent="Erőforrás"
        resourceAreaColumns={resourceColumns}
        scrollTimeReset={false}
        eventDidMount={(info) => {
          info.el.addEventListener('contextmenu', (e) => {
            e.preventDefault();
            const { id } = info.event._def.extendedProps.eventData;
            getProductionOrderById(id);
          });
        }}
      />
      <SapProductOrderDialog
        open={dialogOpen}
        task={task}
        getProductionOrderById={getProductionOrderById}
        productionOrdersWithSapProDependencies={productionOrdersWithSapProDependencies}
        getProductionOrdersWithSapProDependencies={getProductionOrdersWithSapProDependencies}
        updateBatch={updateBatch}
        handleClose={() => {
          setDialogOpen(false);
        }}
        getProductionOrders={getProductionOrders}
      />
    </div>
  );
};

export default SapResourcePlanner;
