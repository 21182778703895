import React, { useEffect, useMemo, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import masterDataService from '../../service/masterData.service';
import useParam from '../../context/ParamContext';
import operationGroupService from '../../service/operationGroup.service';
import usePages from '../../context/PageContext';
import { useLocation, useNavigate } from 'react-router-dom';
import useMenus from '../../context/MenuContext';
import { ButtonUI, CheckboxUI } from '../Interface';
import { generateAutocompleteItems } from '../../utils/helper';
import useUsers from '../../context/UsersContext';
import jobMonitorService from '../../service/jobMonitor.service';
import OpWidgetJobmonitorCard from '../Jobmonitor/OpWidgetJobmonitor/OpWidgetJobmonitorCard';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import userService from '../../service/user.service';
import OperationWidgetDialog from './OperationWidgetDialog';

const baseStyle = {
  background: 'white',
  height: '150px',
  width: '200px',
  cursor: 'pointer',
  textAlign: 'center',
  textTransform: 'uppercase',
  fontWeight: 'bold',

  '&&:hover': {
    background: '#CDDFE8',
  },
};

const categoryCardStyle = {
  ...baseStyle,
  border: '2px solid black',
  padding: '0.8rem 0.5rem 0rem 0.5rem',
};

const categoryBiggerCardStyle = {
  ...baseStyle,
  border: '2px solid black',
  padding: '0.8rem 0.5rem 0rem 0.5rem',
  height: '150px',
  width: '100%',
  marginBottom: '0.5rem',
};

const OperationWidgetPage = () => {
  const { setPages } = usePages();
  const navigate = useNavigate();
  const location = useLocation();
  const { menus, getSubMenuNamesByUrls, getMenus } = useMenus();
  const { getParam, params } = useParam();
  const { user, getUser } = useUsers();
  const [operationGroup, setOperationGroup] = useState([]);
  const [operations, setOperations] = useState([]);
  const [prodOperations, setProdOperations] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [autoCompleteData, setAutoCompleteData] = useState({});
  const [showWorkingUsers, setShowWorkingUsers] = useState(false);
  const [showNotWorkingUsers, setShowNotWorkingUsers] = useState(false);
  const [filter, setFilter] = useState({
    userId: null,
    pinCode: '',
  });
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    getParam();
    getMenus();
    getUser();
    getOperationGroups();
  }, []);

  useEffect(() => {
    if (filter.userId) {
      getProductionOperationsByUserId();
    } else if (location?.state?.userId) {
      setFilter((prev) => ({ ...prev, userId: location?.state?.userId }));
      navigate(location.pathname, { replace: true });
    } else {
      setProdOperations([]);
      setShowDialog(true);
    }
  }, [filter.userId, refetch]);

  useEffect(() => {
    if (filter.pinCode) {
      getUserByPinCode();
    } else {
      setProdOperations([]);
    }
  }, [filter.pinCode]);

  useEffect(() => {
    initDefaultValues();
  }, [refetch]);

  const getProductionOperationsByUserId = () => {
    jobMonitorService
      .getProductionOperations({
        filter: {
          filterModel: {
            items: [{ field: 'userId', value: filter.userId, operator: 'is' }],
            logicOperator: 'and',
          },
        },
      })
      .then((data) => {
        setProdOperations(data);
        setRefetch(false);
      });
  };

  const getUserByPinCode = () => {
    userService.getUserByPinCode(filter.pinCode).then((data) => {
      if (Object.keys(data).length > 0) {
        setFilter((prev) => ({
          ...prev,
          userId: data.userId,
        }));
        setShowDialog(false);
      } else {
        setFilter((prev) => ({
          ...prev,
          pinCode: '',
        }));
        addNotification({
          content: 'Nem található felhasználó ezzel a PIN kóddal!',
          type: NotificationType.ERROR,
        });
      }
    });
  };

  const getOperationGroups = () => {
    masterDataService.operationGroupToForm().then((data) => {
      setOperationGroup(data);
    });
  };

  //@TODO ha bővül egyéb mezővel, a usert külön kell venni, mert arra van refetch dependency!
  const initDefaultValues = () => {
    masterDataService
      .usersToForm()
      .then((data) => {
        const aData = {
          user: data,
        };
        setAutoCompleteData(aData);
      })
      .finally(() => {});
  };

  const parseOperationWidgetGroup = () => {
    if (!params || !params.OPERATION_WIDGET_GROUP) {
      return;
    } else {
      return JSON.parse(params.OPERATION_WIDGET_GROUP);
    }
  };

  const getOperationGroupNameById = (id) => {
    const item = operationGroup.find((group) => group.id === id);
    return item?.name;
  };

  const getOperationGroupByKey = (key) => {
    const operationConfig = parseOperationWidgetGroup();
    if (!operationConfig) return;

    return getOperationGroupNameById(operationConfig[key]);
  };

  const getOperationsForCategory = (key) => {
    if (filter.userId) {
      const operationConfig = parseOperationWidgetGroup();
      if (!operationConfig) return;

      operationGroupService.getOperationGroupLinkRow(operationConfig[key]).then((data) => {
        setOperations(data);
      });
    } else {
      addNotification({
        content: 'Nincs kiválasztott felhasználó!',
        type: NotificationType.ERROR,
      });
    }
  };

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['opWidgetJobmonitor']);
  }, [menus]);

  const handleNavigate = (operationId) => {
    setPages({ subMenuName: submenuNames });
    if (operationId) {
      navigate(`/app/opWidgetJobmonitor/${operationId}/${filter.userId}`, {
        state: { from: 'operationWidget', userId: filter.userId },
      });
    } else {
      navigate(`/app/opWidgetJobmonitor`, {
        state: { from: 'operationWidget', userId: filter.userId, meo: true },
      });
    }
  };

  const notWorkingUsers = autoCompleteData?.user?.filter((u) => u.productionReport.length === 0);

  const getWorkingUsers = (key) => {
    if (showWorkingUsers) {
      const group = parseOperationWidgetGroup();
      if (!group) return;

      const groupId = group[key];
      const operationsInGroup = operationGroup
        .find((group) => group.id === groupId)
        ?.operationGroupLink.map((link) => link.operationId);

      const workingUsers = autoCompleteData?.user
        ?.filter((user) => user.productionReport.some((report) => operationsInGroup?.includes(report.operationId)))
        .map((user) => <div key={user.userId}>{user.userName}</div>);

      return <li>{workingUsers}</li>;
    }
  };

  const findedUser = autoCompleteData?.user?.find((user) => user.userId === filter.userId);

  return (
    <div className="bg-gray-100 px-6 pt-5" style={{ minHeight: 'calc(100vh - 65px)' }}>
      <div className="flex gap-2 overflow-auto mb-2 custom-scrollbar">
        {prodOperations?.rows?.map((prodOp) => (
          <OpWidgetJobmonitorCard data={prodOp} userId={filter.userId} setRefetch={setRefetch} />
        ))}
      </div>
      <div className="border-2 border-black mb-2"></div>
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-3">
          <>
            <Box sx={{ ...categoryBiggerCardStyle }} onClick={() => getOperationsForCategory('10')}>
              {getOperationGroupByKey('10')}
            </Box>
            <Box sx={{ ...categoryBiggerCardStyle }} onClick={() => getOperationsForCategory('11')}>
              {getOperationGroupByKey('11')}
            </Box>
            <Box sx={{ ...categoryBiggerCardStyle }} onClick={() => getOperationsForCategory('12')}>
              {getOperationGroupByKey('12')}
            </Box>
            <Box sx={{ ...categoryBiggerCardStyle }} onClick={() => getOperationsForCategory('13')}>
              {getOperationGroupByKey('13')}
            </Box>
          </>
        </div>
        <div className="col-span-9">
          <div className="flex justify-between mb-5">
            <div className="flex items-center gap-5">
              <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                {findedUser && `${findedUser?.nickName} - (${findedUser?.userName})`}
              </Typography>
              <div>
                <CheckboxUI
                  label="Nem bejelentkezett dolgozók"
                  onChange={() => setShowNotWorkingUsers(!showNotWorkingUsers)}
                />
              </div>
              <div>
                <CheckboxUI label="Bejelentkezett dolgozók" onChange={() => setShowWorkingUsers(!showWorkingUsers)} />
              </div>
            </div>
            <div>
              {filter.userId && (
                <ButtonUI
                  text="Kijelentkezés"
                  className="text-xl cursor-pointer bg-danger"
                  onClick={() => setFilter((prev) => ({ ...prev, userId: null, pinCode: '' }))}
                />
              )}
            </div>
          </div>
          {operations.length === 0 ? (
            <div>
              <div className="flex justify-center">
                <div>
                  <Box
                    sx={{ ...categoryCardStyle, height: '50px', background: '#C2C2C2', overflow: 'auto' }}
                    onClick={() => getOperationsForCategory('1')}
                  >
                    {getOperationGroupByKey('1')}
                    <ul className="font-light normal-case">{getWorkingUsers('1')}</ul>
                  </Box>
                  <Box
                    sx={{ ...categoryCardStyle, height: '100px', background: '#C2C2C2', overflow: 'auto' }}
                    onClick={() => getOperationsForCategory('2')}
                  >
                    {getOperationGroupByKey('2')}
                    <ul className="font-light normal-case">{getWorkingUsers('2')}</ul>
                  </Box>
                </div>
                <Box sx={{ ...categoryCardStyle, width: '220px' }} onClick={() => getOperationsForCategory('3')}>
                  <div className="grid grid-cols-4">
                    <Box
                      sx={{
                        ...categoryCardStyle,
                        height: '130px',
                        gridColumn: 'span 2',
                        border: 'none',
                        maxWidth: '110px',
                        textAlign: 'start',
                        padding: 0,
                        background: 'inherit',
                        overflow: 'auto',
                      }}
                    >
                      {getOperationGroupByKey('3')}
                      <ul className="font-light normal-case">{getWorkingUsers('3')}</ul>
                    </Box>
                    <Box
                      sx={{
                        ...categoryCardStyle,
                        height: '50px',
                        width: '75px',
                        gridColumn: 'span 1',
                        transform: 'rotate(90deg)',
                        borderLeft: 'none',
                        background: 'inherit',
                      }}
                    >
                      Laser
                    </Box>
                    <Box
                      sx={{
                        ...categoryCardStyle,
                        height: '50px',
                        width: '75px',
                        gridColumn: 'span 1',
                        transform: 'rotate(90deg)',
                        borderLeft: 'none',
                        background: 'inherit',
                      }}
                    >
                      Dekor
                    </Box>
                  </div>
                </Box>
                <Box sx={{ ...categoryCardStyle, overflow: 'auto' }} onClick={() => getOperationsForCategory('4')}>
                  {getOperationGroupByKey('4')}
                  <ul className="font-light normal-case">{getWorkingUsers('4')}</ul>
                </Box>
                <Box sx={{ ...categoryCardStyle, overflow: 'auto' }} onClick={() => getOperationsForCategory('5')}>
                  {getOperationGroupByKey('5')}
                  <ul className="font-light normal-case">{getWorkingUsers('5')}</ul>
                </Box>
                <Box sx={{ ...categoryCardStyle, overflow: 'auto' }} onClick={() => getOperationsForCategory('6')}>
                  {getOperationGroupByKey('6')}
                  <ul className="font-light normal-case">{getWorkingUsers('6')}</ul>
                </Box>
                <Box
                  sx={{ ...categoryCardStyle, overflow: 'auto', marginLeft: '1rem' }}
                  onClick={() => handleNavigate()}
                >
                  {getOperationGroupByKey('14')}
                </Box>
              </div>
              <Typography sx={{ fontSize: '2rem', textTransform: 'uppercase', textAlign: 'center', marginTop: '2rem' }}>
                Udvar
              </Typography>
              <div className="flex pt-5 justify-center">
                <Box sx={{ ...categoryCardStyle, overflow: 'auto' }} onClick={() => getOperationsForCategory('15')}>
                  {getOperationGroupByKey('15')}
                  <ul className="font-light normal-case">{getWorkingUsers('15')}</ul>
                </Box>
                <Box sx={{ ...categoryCardStyle, overflow: 'auto' }} onClick={() => getOperationsForCategory('7')}>
                  {getOperationGroupByKey('7')}
                  <ul className="font-light normal-case">{getWorkingUsers('7')}</ul>
                </Box>
                <Box sx={{ ...categoryCardStyle, overflow: 'auto' }} onClick={() => getOperationsForCategory('8')}>
                  {getOperationGroupByKey('8')}
                  <ul className="font-light normal-case">{getWorkingUsers('8')}</ul>
                </Box>
                <Box
                  onClick={() => getOperationsForCategory('9')}
                  sx={{
                    ...categoryCardStyle,
                    width: '150px',
                    height: '150px',
                    transform: 'rotate(90deg)',
                    position: 'relative',
                    paddingTop: '6rem',
                    background: '#C2C2C2',
                    border: 'none',

                    '::before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      background: '#F5F5F5',
                      height: '100%',
                      width: '100%',
                      clipPath: 'polygon(0 0, 100% 0, 100% 55%, 0 36%)',
                    },
                  }}
                >
                  {getOperationGroupByKey('9')}
                  <ul className="font-light normal-case">{getWorkingUsers('9')}</ul>
                </Box>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <IconButton size="small" onClick={() => setOperations([])}>
                <div className="text-sm font-medium py-2 px-3 rounded flex items-center justify-center gap-1 bg-ganttHoverButtonColor text-white mt-2">
                  <KeyboardBackspaceIcon sx={{ fontSize: '2rem' }} />
                  <span className="text-2xl">Vissza</span>
                </div>
              </IconButton>
              <div className="flex gap-5 pt-2 justify-center flex-wrap">
                {operations.map((operation, index) => (
                  <Box
                    key={index}
                    sx={{
                      ...baseStyle,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    }}
                    onClick={() => handleNavigate(operation.operationId)}
                  >
                    {operation.operation.operationName}
                  </Box>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="border-2 border-black"></div>
      <div className="flex gap-2 mt-3 flex-wrap">
        {showNotWorkingUsers &&
          notWorkingUsers?.map((notWorkingUser) => (
            <div key={notWorkingUser.userId} className="bg-red-200 py-2 px-3 rounded-md">
              {notWorkingUser.userName}
            </div>
          ))}
      </div>
      <OperationWidgetDialog
        open={showDialog}
        handleClose={() => setShowDialog(false)}
        filterState={{ filter, setFilter }}
      />
    </div>
  );
};

export default OperationWidgetPage;
