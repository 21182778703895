import React, { useMemo, useState } from 'react';
import productionService from '../../service/production.service';
import { ButtonUI, DataGridIdentifiers, DatagridPremiumUi, DateTimePickerUI } from '../Interface';
import { dataGridPremiumColumns } from '../../utils/helper';
import dayjs from 'dayjs';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';

const ProductionStore = ({ user, refetch }) => {
  const [rows, setRows] = useState({ rows: [], rowCount: 0 });
  const { productionChanged, setProductionChanged } = refetch;

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'baseDocNum', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  const getProdOperations = (gridState) => {
    productionService.getProductionsForStore(gridState).then((data) => {
      setRows(data);
    });
  };

  const updateProduction = (id, startDate) => {
    const values = {
      id,
      updatedBy: user.userId,
      startDate,
    };
    productionService.updateProductionFromStore(values).then(() => {
      setProductionChanged(true);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'workingNumber',
        headerName: 'GYU. szám',
        type: 'string',
      },
      {
        field: 'baseDocNum',
        headerName: 'Vevői rendelés szám',
        type: 'string',
        renderCell: (data) => {
          return data.row?.baseDocNum && data.row?.baseLine
            ? `${data.row?.baseDocNum ?? ''} - ${data.row?.baseLine ?? ''}`
            : '';
        },
      },
      {
        field: 'reference',
        headerName: 'Vevőhivatkozási szám',
        type: 'string',
      },
      {
        field: 'articleNumber',
        headerName: 'Cikkszám',
        type: 'string',
      },
      {
        field: 'articleName',
        headerName: 'Cikknév',
        type: 'string',
      },
      {
        field: 'productionQuantity',
        headerName: 'Tervezett mennyiség',
        type: 'string',
      },
      {
        field: 'quantityUnitId',
        headerName: 'Mennyiségi egység',
        type: 'string',
        renderCell: (data) => {
          return data.row?.quantityUnit?.code;
        },
      },
      {
        field: 'companyName',
        headerName: 'Cég',
        type: 'string',
        renderCell: (data) => {
          return data.row?.company?.name;
        },
      },
      {
        field: 'startDate',
        headerName: 'GY. tervezett kezdete',
        type: 'dateTime',
        editable: true,
        valueGetter: (_data, row) => {
          return new Date(row?.startDate);
        },
        renderCell: (data) => {
          return dayjs(data?.row?.startDate).format('YYYY.MM.DD HH:mm');
        },
        renderEditCell: (data) => {
          return (
            <DateTimePickerUI
              variant="standard"
              value={dayjs(data.row?.startDate)}
              onChange={(newDate) => {
                if (!newDate) {
                  addNotification({
                    content: 'A mező értéke nem törölhető!',
                    type: NotificationType.ERROR,
                  });
                } else {
                  const dateObject = newDate?.toDate();
                  data.api.setEditCellValue({
                    id: data.id,
                    field: data.field,
                    value: dateObject,
                  });
                }
              }}
            />
          );
        },
      },
      {
        field: 'button',
        type: 'string',
        filterable: false,
        headerName: 'Tervezés',
        renderCell: (params) => {
          return (
            <ButtonUI
              text="Tervezés"
              size="sm"
              className="py-1.5 px-2.5 bg-success"
              onClick={() => updateProduction(params.row.id, params.row.startDate)}
            />
          );
        },
      },
    ]);
  }, []);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.productionStore}
      datagridInitialState={initialState}
      dataLoader={getProdOperations}
      refetch={{ refetchTable: productionChanged, setRefetchTable: setProductionChanged }}
      rowCount={rows.rowCount}
      columns={[...columns]}
      rows={rows.rows}
      paginationAndfilterMode="server"
      hideFooter
    />
  );
};

export default ProductionStore;
