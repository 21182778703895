export const sxConfig = {
  containerBox: {
    height: { xs: 'auto', md: '92%', lg: '93%' },
    bgcolor: 'grey.100',
  },
  formBox: {
    p: 1,
    backgroundColor: (theme) => theme.palette.common.white,
    overflow: 'auto',
    boxShadow: 1,
    borderRadius: 2,
    height: { xs: 'auto', md: '100%' },
  },
};
