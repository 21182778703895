import React, { useState, useEffect } from 'react';
import { CheckboxUI } from '../Interface';
import operationGroupService from '../../service/operationGroup.service';

const OperationGroupLinks = ({ operationGroupLink, selectedValues, disabled, setRefetchTable }) => {
  const [links, setLinks] = useState(operationGroupLink);

  useEffect(() => {
    setLinks(operationGroupLink);
  }, [operationGroupLink]);

  const updateOperationGroupLink = (operationGroupId, isChecked) => {
    const updatedLinks = links.map((link) => (link.id === operationGroupId ? { ...link, checked: isChecked } : link));
    setLinks(updatedLinks);

    operationGroupService.updateOperationGroupLink(selectedValues.operationId, operationGroupId).then(() => {
      setRefetchTable(true);
    });
  };

  return (
    <div>
      {links.map((groupLink) => (
        <div key={groupLink.id}>
          <CheckboxUI
            sx={{ margin: 0, padding: '0.2rem' }}
            disabled={disabled}
            onChange={(e) => updateOperationGroupLink(groupLink.id, e.target.checked)}
            checked={groupLink.checked || false}
          />
          {groupLink.name}
        </div>
      ))}
    </div>
  );
};

export default OperationGroupLinks;
