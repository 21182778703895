import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, AutoCompleteSelectUI } from '../Interface';
import { DependenceTypeList } from '../../config/config';

const ProductionSettingFields = ({ viewField, autoCompleteOptions, formData }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();
  return (
    <div className="grid grid-cols-6 gap-x-4 mx-4 mb-5">
      <div className="md:col-span-3 xl:col-span-1 col-span-6">
        <Field
          type="autocomplete"
          name="defOperationQtyUnit"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Alapértelmezett művelet mennyiségi egység" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.defOperationQtyUnit} />}
          onChange={(_e, newVal) => {
            setFieldValue('defOperationQtyUnit', newVal?.value ?? null).then(() => {
              validateField('defOperationQtyUnit');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.defOperationQtyUnit !== '' }}
          selectedValue={values.defOperationQtyUnit}
          selectedLabelValue={formData?.operationQtyUnit?.name}
          dataset={autoCompleteOptions.operationQtyUnit}
        />
      </div>
    </div>
  );
};

export default ProductionSettingFields;
