export const DocumentDirection = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING',
  INTERNAL: 'INTERNAL',
};

export const DocumentDirectionField = [
  { label: 'Kimenő', value: DocumentDirection.OUTGOING },
  { label: 'Bejövő', value: DocumentDirection.INCOMING },
  { label: 'Belső', value: DocumentDirection.INTERNAL },
];
