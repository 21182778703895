import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'kanban';

const kanbanService = {
  getItemsByType: (type, data, allColumns) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/${type}/${allColumns}`,
      data,
    });
  },

  getStatusByType: (type) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/statusForType/${type}`,
    });
  },

  updateItemsByType: (type, id, statusId, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/${type}/${id}/${statusId}`,
      data,
    });
  },

  getItemById: (type, id) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/${type}/${id}`,
    });
  },

  createMessage: (data, type) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/${type}`,
      data,
    });
  },

  updateMessage: (data, type, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${type}/${id}`,
      data,
    });
  },

  updateReadAt: (id, type) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/${id}/${type}`,
    });
  },
};
export default kanbanService;
