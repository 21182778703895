import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import companyLocationService from '../../service/companyLocation.service';
import useMenus from '../../context/MenuContext';
import { DeleteDialogUI, FormBase, FormButtonPanelUI, FormHeaderUI } from '../Interface';
import FormFields from './FormFields';
import CompanyLocationTable from './CompanyLocationTable';
import useUsers from '../../context/UsersContext';
import { isEqual } from 'lodash';
import masterDataService from '../../service/masterData.service';
import { generateAutocompleteItems } from '../../utils/helper';

export const CompanyLocationForm = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [refetchTable, setRefetchTable] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState({
    country: [],
  });
  const [loading, setLoading] = useState(true);

  const def = {
    name: '',
    nameInPrint: '',
    countryCode: '',
    province: '',
    postCode: '',
    city: '',
    streetAddress: '',
    website: '',
    email: '',
    phoneNumber: '',
    GLN: '',
    other: '',
  };

  useEffect(() => {
    getMenus();
    if (!Object.keys(user).length) getUser();

    setDefaultValues(def);
    setDefaultSelectedValues(def);
    initDefaultValues();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('companyLocation');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    nameInPrint: Yup.string().nullable(),
    countryCode: Yup.string().nullable(),
    province: Yup.string().nullable(),
    postCode: Yup.string().nullable(),
    city: Yup.string().nullable(),
    streetAddress: Yup.string().nullable(),
    website: Yup.string().nullable(),
    email: Yup.string().email().nullable(),
    phoneNumber: Yup.string().nullable(),
    GLN: Yup.string().nullable(),
    other: Yup.string().nullable(),
  });

  const initDefaultValues = () => {
    masterDataService
      .countryToForm()
      .then((data) => {
        const aData = {
          country: data,
        };

        const acOptions = {
          country: generateAutocompleteItems(aData.country, 'name', 'code'),
        };
        setAutoCompleteOptions(acOptions);
        setLoading(false);
      })
      .finally(() => {});
  };

  const valuesToForm = () => {
    const {
      name,
      nameInPrint,
      countryCode,
      province,
      postCode,
      city,
      streetAddress,
      website,
      email,
      phoneNumber,
      GLN,
      other,
    } = selectedValues;

    setInitValues({
      name,
      nameInPrint,
      countryCode,
      province,
      postCode,
      city,
      streetAddress,
      website,
      email,
      phoneNumber,
      GLN,
      other,
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const handleInsertObject = (values) => {
    const insertObject = { ...values, createdBy: user.userId };

    companyLocationService
      .createCompanyLocation(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setSelectedValues(data);
        setRowSelectionModel(data.id);
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {});
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    companyLocationService.updateCompanyLocation(updData, selectedValues.id).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    companyLocationService.deleteCompanyLocation(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues({});
      setInitValues(def);
    });
  };

  return (
    <>
      <FormBase
        name="CompanyLocation"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                addClick={() => {
                  setSelectedValues(defaultSelectedValues);
                  setViewField(false);
                  setAddField(false);
                }}
                addDisabled={!viewField || !canEdit}
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled || !canEdit}
                deleteClick={() => {
                  setShowDeleteDialog(true);
                }}
                deleteDisabled={!viewField || operatorsDisabled || !canEdit}
                copyClick={() => {
                  setViewField(false);
                  setAddField(false);
                }}
                copyDisabled={!viewField || operatorsDisabled || !canEdit}
              />

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ resetForm }) => (
                  <Form>
                    <FormFields
                      viewField={viewField}
                      autoCompleteOptions={autoCompleteOptions}
                      selectedValues={selectedValues}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);

                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          !loading && (
            <CompanyLocationTable
              refetch={{ refetchTable, setRefetchTable }}
              values={{ selectedValues, setSelectedValues }}
              viewField={viewField}
              operatorsDisabled={operatorsDisabled}
              rowSelection={{ rowSelectionModel, setRowSelectionModel }}
              autoCompleteOptions={autoCompleteOptions}
            />
          )
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
