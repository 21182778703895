import React, { useState, useEffect, useMemo } from 'react';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import { AreaTypeList, GroupTypeList } from '../../config/companyGroup';
import companyGroupService from '../../service/companyGroup.service';

export const CompanyGroupReports = ({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autoCompleteOptions,
}) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'name', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    companyGroupService.getRows(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'groupType',
        headerName: 'Csoportosítás típusa',
        type: 'string',
        type: 'singleSelect',
        valueOptions: GroupTypeList,
      },
      {
        field: 'name',
        headerName: 'Csoport név',
        type: 'string',
      },
      {
        field: 'priceListId',
        headerName: 'Árlista',
        type: 'singleSelect',
        valueOptions: autoCompleteOptions.priceList,
      },
      {
        field: 'paymentMethodId',
        headerName: 'Fizetési mód',
        type: 'singleSelect',
        valueOptions: autoCompleteOptions.paymentMethod,
      },
      {
        field: 'currencyCode',
        headerName: 'Pénznem',
        type: 'singleSelect',
        valueOptions: autoCompleteOptions.currency,
      },
      {
        field: 'vatCode',
        headerName: 'Áfakulcs',
        type: 'singleSelect',
        valueOptions: autoCompleteOptions.vatCodes,
      },
      {
        field: 'area',
        headerName: 'Terület',
        type: 'singleSelect',
        valueOptions: AreaTypeList,
      },
    ]);
  }, [autoCompleteOptions]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.companyGroup}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
    />
  );
};
