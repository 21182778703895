import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';

import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid-premium';
import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { dataGridPremiumColumns } from '../../utils/helper';
import {
  DataGridIdentifiers,
  FormErrorUI,
  InputUI,
  AddButtonUi,
  AutoCompleteSelectUI,
  DatagridPremiumUi,
} from '../Interface';

const EditToolbar = (props) => {
  const { setRowModesModel, disabled, push, defaultField } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    defaultField.id = uuidv4();
    await push(defaultField);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultField.id]: { mode: GridRowModes.Edit },
    }));

    const fieldsToValidate = [
      `templateItemOperation.${values.templateItemOperation.length ?? 0}.operationId`,
      `templateItemOperation.${values.templateItemOperation.length ?? 0}.quantityUnitId`,
      `templateItemOperation.${values.templateItemOperation.length ?? 0}.neededQty`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
    }
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
};

const TemplateItemOperation = ({ disabled, defaultField, autoCompleteOptions }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();

  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.templateItemOperation.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    const fieldsToValidate = [
      `templateItemOperation.${valueIndex}.operationId`,
      `templateItemOperation.${valueIndex}.quantityUnitId`,
      `templateItemOperation.${valueIndex}.neededQty`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
      if (errors?.templateItemOperation?.[valueIndex]?.[field.split('.').pop()]) {
        return;
      }
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridPremiumColumns([
      {
        field: 'operationId',
        headerName: 'Művelet',
        editable: true,
        type: 'singleSelect',
        valueOptions: autoCompleteOptions.operation,
        renderCell: (data) => {
          const findedOperation = autoCompleteOptions?.operation.find((item) => item.value === data.row?.operationId);
          return findedOperation?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedOperation = autoCompleteOptions.operation.find((item) => item.value === data.row.operationId);

          return (
            <Field
              name={`templateItemOperation.${valueIndex}.operationId`}
              component={AutoCompleteSelectUI}
              helperText={<FormErrorUI message={errors?.templateItemOperation?.[valueIndex]?.operationId} />}
              selectedValue={values.templateItemOperation?.[valueIndex]?.operationId}
              selectedLabelValue={findedOperation?.label}
              onChange={(_e, newVal) => {
                setFieldValue(`templateItemOperation.${valueIndex}.operationId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              dataset={autoCompleteOptions.operation}
              onBlur={async () => await validateField(`templateItemOperation.${valueIndex}.operationId`)}
            />
          );
        },
      },
      {
        field: 'neededQty',
        type: 'string',
        headerName: 'Szükséges mennyiség',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`templateItemOperation.${valueIndex}.neededQty`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.templateItemOperation?.[valueIndex]?.neededQty} />}
              onChange={(e) => {
                setFieldValue(`templateItemOperation.${valueIndex}.neededQty`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`templateItemOperation.${valueIndex}.neededQty`)}
            />
          );
        },
      },

      {
        field: 'quantityUnitId',
        headerName: 'Mennyiségi egység',
        editable: true,
        type: 'singleSelect',
        renderCell: (data) => {
          const findedQuantityUnit = autoCompleteOptions?.quantityUnit.find(
            (item) => item.value === data.row?.quantityUnitId
          );
          return findedQuantityUnit?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedQuantityUnit = autoCompleteOptions.quantityUnit.find(
            (item) => item.value === data.row.quantityUnitId
          );

          return (
            <Field
              name={`templateItemOperation.${valueIndex}.quantityUnitId`}
              component={AutoCompleteSelectUI}
              helperText={<FormErrorUI message={errors?.templateItemOperation?.[valueIndex]?.quantityUnitId} />}
              selectedValue={values.templateItemOperation?.[valueIndex]?.quantityUnitId}
              selectedLabelValue={findedQuantityUnit?.label}
              onChange={(_e, newVal) => {
                setFieldValue(`templateItemOperation.${valueIndex}.quantityUnitId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              dataset={autoCompleteOptions.quantityUnit}
              disabled
              onBlur={async () => await validateField(`templateItemOperation.${valueIndex}.quantityUnitId`)}
            />
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg mb-5 px-3">
          <FieldArray name="templateItemOperation">
            {({ remove, push }) => (
              <DatagridPremiumUi
                datagridIdentifier={DataGridIdentifiers.templateItemOperation}
                getRowHeight={() => 'auto'}
                height={380}
                headerFilters={false}
                columns={columns(setFieldValue, validateField, errors, remove)}
                rows={values?.templateItemOperation ?? []}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultField,
                    setRowModesModel,
                  },
                }}
                onCellDoubleClick={(_params, event) => {
                  event.defaultMuiPrevented = true;
                }}
              />
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default TemplateItemOperation;
