import React from 'react';
import { Field, useFormikContext } from 'formik';

import { CheckboxUI, FormErrorUI, FormLabelUI, InputUI, SelectUI } from '../../Interface';
import { DocumentDirectionField } from '../../../config/DocumentDirection';

const DocumentTypeFields = ({ viewField }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5 items-center">
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="documentTypeId"
          as={InputUI}
          disabled={true}
          fullWidth
          label={<FormLabelUI text="Obj. kód" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.documentTypeId} />}
          InputLabelProps={{ shrink: values.documentTypeId !== '' }}
        />
      </div>

      <div className="col-span-4 md:col-span-3">
        <Field
          type="text"
          name="name"
          as={InputUI}
          disabled={true}
          fullWidth
          label={<FormLabelUI text="Bizonylattípus neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          InputLabelProps={{ shrink: values.name !== '' }}
        />
      </div>

      <div className="col-span-4 md:col-span-1">
        <Field
          name="direction"
          component={SelectUI}
          fullWidth
          option={DocumentDirectionField}
          value={values.direction ?? ''}
          variant="standard"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('direction', value ?? null).then(() => {
              validateField('direction');
            });
          }}
          label={<FormLabelUI text="Irány" />}
          helperText={<FormErrorUI message={errors.direction} />}
          disabled={true}
          InputLabelProps={{ shrink: values.direction !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          name="inventoryAccounting"
          type="checkbox"
          as={CheckboxUI}
          fullWidth
          label={<FormLabelUI text="Készletkönyvelés" />}
          disabled={true}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          name="active"
          type="checkbox"
          as={CheckboxUI}
          fullWidth
          label={<FormLabelUI text="Aktív" />}
          disabled={viewField}
        />
      </div>
    </div>
  );
};

export default DocumentTypeFields;
