import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { AddButtonUi, InputUI, OrderArrowsUI } from '../../Interface';

const CheckListItemTable = ({ items, setItems, user, disabled }) => {
  const [editingRows, setEditingRows] = useState(new Set());
  const [editingValues, setEditingValues] = useState({});

  const handleDelete = (id) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
    setEditingRows((prev) => new Set([...prev].filter((rowId) => rowId !== id)));
  };

  const handleEdit = (id) => {
    setEditingRows((prev) => new Set([...prev, id]));
    setEditingValues((prev) => ({ ...prev, [id]: items.find((item) => item.id === id)?.name || '' }));
  };

  const handleEditSave = (id) => {
    if (!editingValues[id]?.trim()) return;
    const updatedItems = items.map((item) => (item.id === id ? { ...item, name: editingValues[id] } : item));
    setItems(updatedItems);
    setEditingRows((prev) => new Set([...prev].filter((rowId) => rowId !== id)));
    setEditingValues((prev) => ({ ...prev, [id]: '' }));
  };

  const handleAddNewItem = () => {
    const newVisOrder = (items.length || 0) + 1;
    const newItem = { id: uuidv4(), name: '', createdBy: user.userId, visOrder: newVisOrder };
    setItems((prevItems) => [...prevItems, newItem]);
    setEditingRows((prev) => new Set([...prev, newItem.id]));
    setEditingValues((prev) => ({ ...prev, [newItem.id]: '' }));
  };

  const handleOrderChange = (id, currentOrder, direction) => {
    const newOrder = direction === 'up' ? currentOrder - 1 : currentOrder + 1;

    if (newOrder < 1 || newOrder > items.length) return;

    const updatedItems = items.map((item) => {
      if (item.id === id) {
        return { ...item, visOrder: newOrder };
      } else if (item.visOrder === newOrder) {
        return { ...item, visOrder: currentOrder };
      }
      return item;
    });

    const sortedItems = updatedItems.sort((a, b) => a.visOrder - b.visOrder);

    setItems(sortedItems);
  };

  return (
    <div>
      <div className="mb-4">
        <AddButtonUi onClick={handleAddNewItem} disabled={disabled} />
      </div>
      <TableContainer component={Paper} className="h-[350px] custom-scrollbar">
        <Table>
          <TableHead className="bg-tableHeaderColor">
            <TableRow>
              <TableCell style={{ width: '600px' }}>Checklista neve</TableCell>
              <TableCell>Sorrend</TableCell>
              <TableCell>Szerkesztés</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              '& .MuiTableCell-body': {
                padding: '10px 0px 10px 15px',
              },
            }}
          >
            {items.map((item, index) => (
              <TableRow key={item.id} className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}>
                <TableCell style={{ width: '600px' }}>
                  {editingRows.has(item.id) ? (
                    <InputUI
                      value={editingValues[item.id] || ''}
                      onChange={(e) => setEditingValues((prev) => ({ ...prev, [item.id]: e.target.value }))}
                      onBlur={() => handleEditSave(item.id)}
                      variant="standard"
                    />
                  ) : (
                    item.name
                  )}
                </TableCell>
                <TableCell>
                  <OrderArrowsUI
                    onUpClick={() => handleOrderChange(item.id, item.visOrder, 'up')}
                    onDownClick={() => handleOrderChange(item.id, item.visOrder, 'down')}
                  />
                </TableCell>
                <TableCell>
                  {editingRows.has(item.id) ? (
                    <IconButton sx={{ margin: 0, padding: 0 }}>
                      <CheckIcon onClick={() => handleEditSave(item.id)} />
                    </IconButton>
                  ) : (
                    <IconButton sx={{ margin: 0, padding: 0 }}>
                      <EditIcon onClick={() => handleEdit(item.id)} />
                    </IconButton>
                  )}
                  <IconButton sx={{ margin: 0, padding: 0, marginLeft: '0.5rem' }}>
                    <DeleteIcon onClick={() => handleDelete(item.id)} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CheckListItemTable;
