import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { FormGroup, FormHelperText } from '@mui/material';

const CheckboxGroupUI = ({ label, options, value, onChange, helperText, disabled, mode = 'single', ...props }) => {
  const handleCheckboxChange = (event) => {
    const { checked, name } = event.target;

    if (mode === 'single') {
      const newValue = checked ? name : null;
      onChange(newValue);
    } else if (mode === 'multi') {
      const newValues = checked ? [...(value || []), name] : value.filter((item) => item !== name);
      onChange(newValues);
    }
  };

  return (
    <FormControl component="fieldset" disabled={disabled} sx={{ marginTop: 1 }}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup {...props}>
        {options.map((item, index) => (
          <FormControlLabel
            key={index}
            control={
              <Checkbox
                name={item.value}
                checked={
                  mode === 'single'
                    ? String(value) === String(item.value)
                    : value?.some((val) => String(val) === String(item.value))
                }
                onChange={handleCheckboxChange}
              />
            }
            label={item.label}
          />
        ))}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CheckboxGroupUI;
