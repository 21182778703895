import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'formPrintTemplate';

const formPrintTemplateService = {
  getTemplates: () => {
    return request({
      resource: `${baseURL}/`,
    });
  },
  uploadFile: (file, id, userId) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('userId', userId);

    return request({
      method: Methods.POST,
      resource: `${baseURL}/uploadFile/${id}`,
      formData: true,
      data: formData,
    });
  },

  deleteFile: (id, userId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/deleteFile/${id}`,
      data: { userId },
    });
  },
};

export default formPrintTemplateService;
