import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'kshClassification';

const kshClassificationService = {
  getRows: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/rows`,
      data,
    });
  },

  updateData: (data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}`,
      data,
    });
  },

  createData: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  deleteData: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },
};
export default kshClassificationService;
