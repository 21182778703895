import request from '../utils/Request';
import { Methods } from '../config';
import { downloadFile } from '../utils/download';

const baseURL = 'production';

const productionService = {
  getProductions: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productions`,
      data,
    });
  },

  getProductionsForStore: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/prodForStore`,
      data,
    });
  },

  createProduction: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateProduction: (data, productionId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${productionId}`,
      data,
    });
  },

  deleteProduction: (data, productionId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${productionId}`,
      data,
    });
  },

  getProductionInfo: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productionreport/search`,
      data,
    });
  },

  createProductionReport: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productionreport`,
      data,
    });
  },

  updateProductionReport: (data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/prod/prodReport`,
      data,
    });
  },

  getProductionReports: (prodOperationId, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/productionreports/${prodOperationId}`,
      data,
    });
  },

  getProductionInfoById: (id) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/productionInfo/${id}`,
    });
  },

  getProductionById: (id) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/${id}`,
    });
  },

  printDocument: async (data) => {
    const pdf = await request({
      responseType: 'blob',
      method: Methods.POST,
      resource: `${baseURL}/print`,
      data,
    });

    return downloadFile(pdf, pdf.filename);
  },

  updateStatus: (data) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/status`,
      data,
    });
  },

  updateProdOperationResource: (id, resourceOneId) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/resource/${id}/${resourceOneId}`,
    });
  },

  updateProductionFromStore: (data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/production/prodStore`,
      data,
    });
  },
};

export default productionService;
