import { useState, useEffect, useMemo } from 'react';

import { dataGridPremiumColumns } from '../../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../../Interface';
import documentTypeService from '../../../service/documentType.service';
import { DocumentDirectionField } from '../../../config/DocumentDirection';

const DocumentTypeReports = ({ refetch, values, viewField, operatorsDisabled, rowSelection }) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'name', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    documentTypeService.getDocumentTypes(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'name',
        headerName: 'Bizonylattípus neve',
        type: 'string',
      },
      {
        field: 'prefix',
        headerName: 'Prefix',
        type: 'string',
        sortable: false,
      },
      {
        field: 'direction',
        headerName: 'Irány',
        type: 'string',
        sortable: false,
        renderCell: (data) => {
          const findedDirection = DocumentDirectionField.find((direction) => direction.value === data.row.direction);
          return findedDirection?.label;
        },
      },
    ]);
  }, []);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.documentTypes}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
    />
  );
};

export default DocumentTypeReports;
