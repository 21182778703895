import { useCallback, useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';

import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid-premium';

import { dataGridColumns, dataGridPremiumColumns } from '../../utils/helper';
import {
  DataGridIdentifiers,
  FormErrorUI,
  AddButtonUi,
  AutoCompleteSelectUI,
  DatagridPremiumUi,
  CheckboxUI,
  InputUI,
  OrderArrowsUI,
} from '../Interface';
import { Box, Typography } from '@mui/material';
import SourceListMachineDialog from './SourceListMachineDialog';
import sourcelistService from '../../service/sourcelist.service';
import useUsers from '../../context/UsersContext';
import { DependenceTypeList } from '../../config/config';

const EditToolbar = (props) => {
  const { setRowModesModel, disabled, push, defaultField } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    const newVisOrder = (values.sourceListOperation?.length || 0) + 1;

    defaultField.id = uuidv4();
    defaultField.isNew = true;
    defaultField.visOrder = newVisOrder;
    await push(defaultField);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultField.id]: { mode: GridRowModes.Edit },
    }));

    const fieldsToValidate = [
      `sourceListOperation.${values.sourceListOperation.length ?? 0}.operationId`,
      `sourceListOperation.${values.sourceListOperation.length ?? 0}.dependence`,
      `sourceListOperation.${values.sourceListOperation.length ?? 0}.quantityUnitId`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
    }
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
};

const DetailToolbar = (props) => {
  const { handleClick, disabled } = props;

  return (
    <GridToolbarContainer
      sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
    >
      <AddButtonUi disabled={disabled} onClick={handleClick} />
      <Box sx={{ width: 400, margin: '0 auto' }}>
        <Typography variant="h6" align="center" sx={{}}>
          Erőforrás szükséglet
        </Typography>
      </Box>
    </GridToolbarContainer>
  );
};

const SourceListOperation = ({
  disabled,
  defaultField,
  autocompleteOptions,
  setRefetchTable,
  selectedValuesState,
  autocompleteData,
}) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();
  const { setSelectedValues } = selectedValuesState;
  const { getUser, user } = useUsers();

  const [rowModesModel, setRowModesModel] = useState({});
  const [addModalShow, setAddModalShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [machineOperation, setMachineOperation] = useState({});

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
  }, []);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.sourceListOperation.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    const fieldsToValidate = [
      `sourceListOperation.${valueIndex}.operationId`,
      `sourceListOperation.${valueIndex}.dependence`,
      `sourceListOperation.${valueIndex}.quantityUnitId`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
      if (errors?.sourceListOperation?.[valueIndex]?.[field.split('.').pop()]) {
        return;
      }
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleOrderChange = (id, currentOrder, direction) => {
    let newOrder = currentOrder + (direction === 'up' ? -1 : 1);
    const maxOrder = Math.max(...values.sourceListOperation.map((item) => item.visOrder));
    const minOrder = Math.min(...values.sourceListOperation.map((item) => item.visOrder));

    if (newOrder < minOrder) newOrder = minOrder;
    if (newOrder > maxOrder) newOrder = maxOrder;

    while (!values.sourceListOperation.some((item) => item.visOrder === newOrder)) {
      newOrder += direction === 'up' ? -1 : 1;
      if (newOrder < minOrder || newOrder > maxOrder) {
        newOrder = direction === 'up' ? minOrder : maxOrder;
        break;
      }
    }

    if (newOrder !== currentOrder) {
      const updatedList = values.sourceListOperation
        .map((item) => {
          if (item.id === id) {
            return { ...item, visOrder: newOrder };
          } else if (item.visOrder === newOrder) {
            return { ...item, visOrder: currentOrder };
          }
          return item;
        })
        .sort((a, b) => a.visOrder - b.visOrder);

      setFieldValue('sourceListOperation', updatedList);
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridPremiumColumns([
      {
        field: 'operationId',
        headerName: 'Név',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedOperation = autocompleteOptions.operation.find(
            (u) => u.value === values.sourceListOperation?.[valueIndex]?.operationId
          );

          return findedOperation?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedOperation = autocompleteOptions.operation.find(
            (u) => u.value === values.sourceListOperation?.[valueIndex]?.operationId
          );
          return (
            <Field
              name={`sourceListOperation.${valueIndex}.operationId`}
              type="text"
              variant="standard"
              as={AutoCompleteSelectUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.sourceListOperation?.[valueIndex]?.operationId} />}
              onChange={(_e, newVal) => {
                setFieldValue(`sourceListOperation.${valueIndex}.operationId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
                const selectedOperation = autocompleteData.operation.find((i) => i.operationId === newVal?.value);
                if (selectedOperation) {
                  setFieldValue(
                    `sourceListOperation.${valueIndex}.firstResource`,
                    selectedOperation?.resourceOneId ?? null
                  );
                  data.api.setEditCellValue({
                    id: data.id,
                    field: 'firstResource',
                    value: selectedOperation?.resourceOneId ?? null,
                  });
                }
              }}
              onBlur={async () => await validateField(`sourceListOperation.${valueIndex}.operationId`)}
              value={findedOperation}
              isOptionEqualToValue={(option, value) => option.value === value}
              dataset={autocompleteOptions.operation}
            />
          );
        },
      },
      {
        field: 'dependence',
        headerName: 'Függőség',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedDependence = DependenceTypeList.find(
            (u) => u.value === values.sourceListOperation?.[valueIndex]?.dependence
          );

          return findedDependence?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedDependence = DependenceTypeList.find(
            (u) => u.value === values.sourceListOperation?.[valueIndex]?.dependence
          );
          return (
            <Field
              name={`sourceListOperation.${valueIndex}.dependence`}
              type="text"
              variant="standard"
              as={AutoCompleteSelectUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.sourceListOperation?.[valueIndex]?.dependence} />}
              onChange={(_e, newVal) => {
                setFieldValue(`sourceListOperation.${valueIndex}.dependence`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              onBlur={async () => await validateField(`sourceListOperation.${valueIndex}.dependence`)}
              value={findedDependence}
              isOptionEqualToValue={(option, value) => option.value === value}
              dataset={DependenceTypeList}
            />
          );
        },
      },
      {
        field: 'visOrder',
        flex: 0.2,
        headerName: 'Sorrend',
        renderCell: (params) => {
          const handleUpClick = () => handleOrderChange(params.row.id, params.row.visOrder, 'up');
          const handleDownClick = () => handleOrderChange(params.row.id, params.row.visOrder, 'down');
          return <OrderArrowsUI onUpClick={handleUpClick} onDownClick={handleDownClick} disabled={disabled} />;
        },
        sortable: false,
      },
      {
        field: 'firstResource',
        headerName: 'Erőforrás',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedOperation = autocompleteOptions.resource.find(
            (u) => u.value === values.sourceListOperation?.[valueIndex]?.firstResource
          );

          return findedOperation?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedOperation = autocompleteOptions.resource.find(
            (u) => u.value === values.sourceListOperation?.[valueIndex]?.firstResource
          );
          return (
            <Field
              type="autocomplete"
              name={`sourceListOperation.${valueIndex}.firstResource`}
              component={AutoCompleteSelectUI}
              fullWidth
              variant="standard"
              helperText={<FormErrorUI message={errors?.sourceListOperation?.[valueIndex]?.firstResource} />}
              InputLabelProps={{ shrink: values.resourceId !== '' }}
              onChange={async (_e, newVal) => {
                await setFieldValue(`sourceListOperation.${valueIndex}.firstResource`, newVal?.value ?? null);
                await validateField(`sourceListOperation.${valueIndex}.firstResource`);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              onBlur={async () => await validateField(`sourceListOperation.${valueIndex}.firstResource`)}
              dataset={autocompleteOptions.resource}
              selectedLabelValue={findedOperation?.label}
              selectedValue={values.sourceListOperation?.[valueIndex].firstResource}
              //disabled={!data.row.isNew}
            />
          );
        },
      },
      {
        field: 'firstResourceNeededQuantity',
        headerName: 'Ráfordítás',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          return (
            <Field
              type="number"
              as={InputUI}
              variant="standard"
              name={`sourceListOperation.${valueIndex}.firstResourceNeededQuantity`}
              id="firstResourceNeededQuantity"
              onChange={(e) => {
                setFieldValue(`sourceListOperation.${valueIndex}.firstResourceNeededQuantity`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              helperText={
                <FormErrorUI message={errors?.sourceListOperation?.[valueIndex]?.firstResourceNeededQuantity} />
              }
              //disabled={!data.row.isNew}
            />
          );
        },
      },
      {
        field: 'quantityUnitId',
        headerName: 'Mennyiségi egység',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedOperation = autocompleteOptions.quantityUnit.find(
            (u) => u.value === values.sourceListOperation?.[valueIndex]?.quantityUnitId
          );

          return findedOperation?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedOperation = autocompleteOptions.quantityUnit.find(
            (u) => u.value === values.sourceListOperation?.[valueIndex]?.quantityUnitId
          );
          return (
            <Field
              type="autocomplete"
              name={`sourceListOperation.${valueIndex}.quantityUnitId`}
              component={AutoCompleteSelectUI}
              fullWidth
              variant="standard"
              helperText={<FormErrorUI message={errors?.sourceListOperation?.[valueIndex]?.quantityUnitId} />}
              InputLabelProps={{ shrink: values.resourceId !== '' }}
              onChange={async (_e, newVal) => {
                await setFieldValue(`sourceListOperation.${valueIndex}.quantityUnitId`, newVal?.value ?? null);
                await validateField(`sourceListOperation.${valueIndex}.quantityUnitId`);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              onBlur={async () => await validateField(`sourceListOperation.${valueIndex}.quantityUnitId`)}
              dataset={autocompleteOptions.quantityUnit}
              selectedLabelValue={findedOperation?.label}
              selectedValue={values.sourceListOperation?.[valueIndex].quantityUnitId}
              disabled
            />
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
      {
        field: 'chk',
        type: 'actions',
        cellClassName: 'actions',
        headerName: 'Lejelentési pont',
        sortable: false,
        getActions: ({ id, row }) => {
          return [<Field name="a" type="checkbox" as={CheckboxUI} fullWidth disabled={disabled} />];
        },
      },
    ]);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <div className="align-middle">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <FieldArray name="sourceListOperation">
              {({ remove, push }) => (
                <DatagridPremiumUi
                  datagridIdentifier={DataGridIdentifiers.sourceListOperation}
                  height={480}
                  columns={columns(setFieldValue, validateField, errors, remove)}
                  rows={values?.sourceListOperation ?? []}
                  rowModesModel={rowModesModel}
                  onRowModesModelChange={handleRowModesModelChange}
                  onRowEditStop={handleRowEditStop}
                  slots={{
                    toolbar: EditToolbar,
                  }}
                  slotProps={{
                    toolbar: {
                      disabled,
                      push,
                      defaultField,
                      setRowModesModel,
                    },
                  }}
                  onCellDoubleClick={(_params, event) => {
                    event.defaultMuiPrevented = true;
                  }}
                  headerFilters={false}
                  getRowHeight={() => 'auto'}
                />
              )}
            </FieldArray>
          </div>
        </div>
      </div>
      <SourceListMachineDialog
        show={addModalShow}
        onHide={() => {
          setAddModalShow(false);
          setSelectedItem({});
          setMachineOperation({});
        }}
        setRefetchTable={setRefetchTable}
        selectedItem={selectedItem}
        autocompleteOptions={autocompleteOptions}
        machineOperation={machineOperation}
        selectedValuesState={selectedValuesState}
      />
    </>
  );
};

export default SourceListOperation;
