import dayjs from 'dayjs';
import {
  getGridStringOperators,
  getGridDateOperators,
  getGridSingleSelectOperators,
  getGridBooleanOperators,
  getGridNumericOperators,
} from '@mui/x-data-grid-premium';
import { PropertyType } from '../config/property';
import { getDynVals } from './DynHelper';

export const objectToArray = (obj) => {
  return Object.entries(obj).map(([key, value]) => ({ field: key, value }));
};

export const generateFilter = (formValues, conditions) => {
  const { like, gte, lte } = conditions;

  const values = objectToArray(formValues);
  const retVal = values.filter((value) => {
    if (value.value !== '' && value.value !== null) {
      if (like?.includes(value.field)) {
        value.like = true;
      } else if (gte?.includes(value.field)) {
        value.gte = true;
      } else if (lte?.includes(value.field)) {
        value.lte = true;
      }
      return value;
    }
    return false;
  });

  return retVal;
};

export const dataGridColumns = (gridCols) => {
  return gridCols.map((col) => {
    return {
      ...col,
      filterable: false,
      headerClassName: 'bg-tableHeaderColor',
    };
  });
};

export const dataGridPremiumColumns = (gridCols) => {
  return gridCols.map((col) => {
    let filterOperators;

    switch (col.type) {
      case 'string':
        filterOperators = getGridStringOperators().filter(
          (op) => !(op?.value === 'isEmpty') && !(op?.value === 'isNotEmpty')
        );
        break;
      case 'number':
        filterOperators = getGridNumericOperators().filter(
          (op) => !(op?.value === 'isEmpty') && !(op?.value === 'isNotEmpty')
        );
        break;
      case 'date':
        filterOperators = getGridDateOperators().filter(
          (op) => !(op?.value === 'isEmpty') && !(op?.value === 'isNotEmpty')
        );
        break;
      case 'dateTime':
        filterOperators = getGridDateOperators().filter(
          (op) => !(op?.value === 'isEmpty') && !(op?.value === 'isNotEmpty')
        );
        break;
      case 'boolean':
        filterOperators = getGridBooleanOperators().filter(
          (op) => !(op?.value === 'isEmpty') && !(op?.value === 'isNotEmpty')
        );
        break;
      case 'singleSelect':
        filterOperators = getGridSingleSelectOperators().filter(
          (op) => !(op?.value === 'isEmpty') && !(op?.value === 'isNotEmpty')
        );
        break;
      default:
        filterOperators = getGridStringOperators().filter(
          (op) => !(op?.value === 'isEmpty') && !(op?.value === 'isNotEmpty')
        );
    }

    return {
      ...col,
      groupable: false,
      aggregable: false,
      headerClassName: 'bg-tableHeaderColor',
      ...(col.isRequired && { filterOperators }),
    };
  });
};

export const generateColumnsFromDynProps = (dynProps) => {
  return dynProps.map((d) => {
    const column = {
      field: `dynamicProperty.dyn_${d.propertyId}`,
      headerName: d.property.name,
      width: 180,
      groupable: false,
      aggregable: false,
      sortable: false,
      headerClassName: 'bg-tableHeaderColor',
    };

    switch (d.property.type) {
      case PropertyType.DATE:
        column.type = 'date';
        column.valueGetter = (_data, row) => {
          const findedDynVal = row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          const date = findedDynVal?.dateVal ? dayjs(findedDynVal?.dateVal) : null;
          if (date && !date.isValid()) return null;
          return date?.toDate();
        };
        column.renderCell = (data) => {
          const findedDynVal = data.row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          return findedDynVal?.dateVal ? dayjs(findedDynVal?.dateVal).format('YYYY.MM.DD') : '';
        };

        break;
      case PropertyType.DECIMAL:
        column.type = 'number';
        column.valueGetter = (_data, row) => {
          const findedDynVal = row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          return findedDynVal?.numberVal ? parseFloat(findedDynVal.numberVal) : '';
        };
        break;
      case PropertyType.NUMBER:
        column.type = 'number';
        column.valueGetter = (_data, row) => {
          const findedDynVal = row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          return findedDynVal?.numberVal ? parseFloat(findedDynVal.numberVal) : '';
        };
        break;
      case PropertyType.LONGTEXT:
        column.type = 'string';
        column.valueGetter = (_data, row) => {
          const findedDynVal = row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          return findedDynVal?.longTextVal;
        };
        break;
      case PropertyType.COMBO:
        column.type = 'singleSelect';
        column.valueGetter = (_data, row) => {
          const findedDynVal = row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          const findedOption = findedDynVal?.property?.propertyOption.find((po) => po.id === findedDynVal.comboBoxVal);
          return findedOption?.id;
        };
        column.valueOptions = generateAutocompleteItems(d.property.propertyOption, 'name', 'id');

        break;
      case PropertyType.TEXT:
        column.type = 'string';
        column.valueGetter = (_data, row) => {
          const findedDynVal = row.dynamicProperties.find((f) => f.propertyId === d.propertyId);
          return findedDynVal?.textVal;
        };
        break;
    }

    return column;
  });
};

export const generateAutocompleteItems = (data = [], labelKey, valueKey) => {
  const labelIsArray = Array.isArray(labelKey);
  return data.map((d) => {
    const label = labelIsArray
      ? labelKey
          .map((key) => d[key])
          .filter(Boolean)
          .join(' - ')
      : `${d[labelKey]}`;
    return {
      label,
      value: d[valueKey],
    };
  });
};

export const cleanValues = (values) => {
  return Object.fromEntries(Object.entries(values).map(([key, value]) => [key, value === '' ? null : value]));
};

export const hourToMin = (value) => {
  return Math.floor(value * 60 * 100) / 100;
};

export const minToHour = (value) => {
  return Math.floor((value / 60) * 100) / 100;
};
