export const PaymentDeadlineBasisType = {
  DATEOFCOMPLETION: 'DATEOFCOMPLETION',
  EXHIBITIONDATE: 'EXHIBITIONDATE',
};

export const PaymentDeadlineBasisTypeList = [
  { label: 'Teljesítés dátuma', value: PaymentDeadlineBasisType.DATEOFCOMPLETION },
  { label: 'Kiállítás dátuma', value: PaymentDeadlineBasisType.EXHIBITIONDATE },
];

export const FromType = {
  MONTHSTART: 'MONTHSTART',
  MONTHEND: 'MONTHEND',
};

export const FromTypeList = [
  { label: 'Hónap vége', value: FromType.MONTHEND },
  { label: 'Hónap eleje', value: FromType.MONTHSTART },
];

export const InstantPaymentType = {
  TRUE: true,
  FALSE: false,
};

export const InstantPaymentTypeList = [
  { label: 'Igen', value: InstantPaymentType.TRUE },
  { label: 'Nem', value: InstantPaymentType.FALSE },
];
