import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import dayjs from 'dayjs';
import Yup from '../../utils/yup';
import utc from 'dayjs/plugin/utc';

import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import useMenus from '../../context/MenuContext';
import useUsers from '../../context/UsersContext';
import { generateAutocompleteItems } from '../../utils/helper';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI, FormBase } from '../Interface';
import sapShiftLeaderReportService from '../../service/sapShiftLeaderReport.service';
import SapShiftLeaderReportFields from './SapShiftLeaderReportFields';
import SapShiftLeaderReportReports from './SapShiftLeaderReportReports';
import { LeaderReportActivity, LeaderReportShift, LeaderReportType } from '../../config/sapShiftLeaderReport';

const SapShiftLeaderReportPage = () => {
  const { getMenus } = useMenus();
  const { getUser, user } = useUsers();
  const [refetchTable, setRefetchTable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autocompleteData, setAutoCompleteData] = useState({});
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    quantityUnit: [],
    items: [],
  });
  const [isInitLoad, setIsInitLoad] = useState(true);

  const def = {
    date: dayjs(),
    project: null,
    operation: null,
    workerId: null,
    workHours: '',
    machine: null,
    type: LeaderReportType.NORMAL,
    shift: LeaderReportShift.FIRST,
    activity: null,
    operationNote: null,
    operationDownTime: null,
  };

  useEffect(() => {
    getMenus();
    if (!Object.keys(user).length) getUser();

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(user).length > 0 && isInitLoad) {
      initDefaultValues();
      setIsInitLoad(false);
    }
  }, [user, isInitLoad]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve) => {
        sapShiftLeaderReportService.getSapProject().then((data) => {
          resolve(data);
        });
      })
    );

    promiseArray.push(
      new Promise((resolve) => {
        sapShiftLeaderReportService.getResources().then((data) => {
          resolve(data);
        });
      })
    );

    promiseArray.push(
      new Promise((resolve) => {
        sapShiftLeaderReportService.getEmployees().then((data) => {
          resolve(data);
        });
      })
    );

    Promise.all(promiseArray).then((resp) => {
      const aData = {
        project: resp[0],
        resource: resp[1],
        employee:
          user?.userDept?.dept && user?.userDept?.dept !== -2
            ? resp[2].filter((e) => e.dept === user.userDept.dept)
            : resp[2],
        operation: resp[1].reduce((acc, current) => {
          if (
            !acc.find((item) => item.resourceType === current.resourceType || item.resourceType === current.resourceId)
          ) {
            console.log(current);
            if (current.resourceType !== null) acc.push({ resourceType: current.resourceType });
            else acc.push({ resourceType: current.resourceId });
          }
          return acc;
        }, []),
      };

      setAutoCompleteData(aData);

      const acOptions = {
        project: generateAutocompleteItems(aData.project, 'Project', 'PrjCode'),
        resource: generateAutocompleteItems(aData.resource, 'resourceName', 'resourceId'),
        employee: generateAutocompleteItems(aData.employee, 'FullName', 'empid'),
        operation: generateAutocompleteItems(aData.operation, 'resourceType', 'resourceType'),
      };
      setAutoCompleteOptions(acOptions);
    });
  };

  const validationSchema = Yup.object().shape({
    date: Yup.string().required(),
    project: Yup.string()
      .when(['activity', 'operationDownTime'], {
        is: (activity, operationDownTime) => !activity && !operationDownTime,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      })
      .nullable(),
    operation: Yup.string()
      .when(['activity', 'operationDownTime'], {
        is: (activity, operationDownTime) => !activity && !operationDownTime,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      })
      .nullable(),
    machine: Yup.string()
      .when(['activity', 'operationDownTime', 'operation'], {
        is: (activity, operationDownTime, operation) =>
          !activity && !operationDownTime && !(operation?.startsWith('MEO') || operation === 'MINOSEGELLENORZES'),
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      })
      .nullable(),
    operationNote: Yup.string().nullable(),
    workerId: Yup.number().required(),
    workHours: Yup.number().required().positive(),
    type: Yup.string().required(),
    shift: Yup.string().required(),
    activity: Yup.string().nullable(),
    operationDownTime: Yup.string().nullable(),
  });

  const valuesToForm = () => {
    const {
      id,
      date,
      project,
      operation,
      workerId,
      workHours,
      machine,
      type,
      shift,
      activity,
      operationNote,
      operationDownTime,
    } = selectedValues;

    setInitValues({
      id,
      date: dayjs(date),
      project,
      operation,
      workerId,
      workHours,
      machine,
      type,
      shift,
      operationNote,
      activity,
      operationDownTime,
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const findedUser = autocompleteData.employee.find((e) => e.empid === values.workerId);
    const updData = {
      ...values,
      ...((values.activity === LeaderReportActivity.KFT || values.operationDownTime) && {
        project: findedUser?.CostCenter,
      }),
      updatedBy: user.userId,
    };

    sapShiftLeaderReportService.updateSapShiftLeaderReport(updData, selectedValues.id).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const findedUser = autocompleteData.employee.find((e) => e.empid === values.workerId);
    const insertObject = {
      ...values,
      ...((values.activity === LeaderReportActivity.KFT || values.operationDownTime) && {
        project: findedUser?.CostCenter,
      }),
      createdBy: user.userId,
    };

    sapShiftLeaderReportService.createSapShiftLeaderReport(insertObject).then((data) => {
      addNotification({
        content: 'Sikeres mentés!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRowSelectionModel(data.id);
      setRefetchTable(true);
      setAddField(true);
      setViewField(true);
    });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    sapShiftLeaderReportService.deleteSapShiftLeaderReport(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
      setInitValues(defaultValues);
    });
  };

  return (
    <>
      <FormBase
        name="SapShiftLeaderReport"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                addClick={() => {
                  setInitValues(defaultValues);
                  setSelectedValues(defaultValues);
                  setViewField(false);
                  setAddField(false);
                }}
                addDisabled={!viewField}
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled}
                deleteClick={() => {
                  setShowDeleteDialog(true);
                }}
                deleteDisabled={!viewField || operatorsDisabled}
                copyClick={() => {
                  setViewField(false);
                  setAddField(false);
                }}
                copyDisabled={!viewField || operatorsDisabled}
              />

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ resetForm }) => (
                  <Form>
                    <SapShiftLeaderReportFields
                      viewField={viewField}
                      selectedValues={selectedValues}
                      autocompleteOptions={autocompleteOptions}
                      autocompleteData={autocompleteData}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);
                        resetForm();
                        valuesToForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <SapShiftLeaderReportReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            autocompleteOptions={autocompleteOptions}
            user={user}
          />
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};

export default SapShiftLeaderReportPage;
