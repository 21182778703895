import React, { useState } from 'react';
import { TabsUI } from '../Interface';
import OperationUserLinks from './OperationUserLinks';

const OperationGroupTabs = (props) => {
  const { addField, params } = props;
  const [activeTab, setActiveTab] = useState(0);

  //@TODO: amennyiben bővül a tab, a map elől kivenni az ellenőrzéseket, és a mezőre tenni.
  const tabs = [
    {
      id: 0,
      name: 'Felhasználók',
      content: addField && <OperationUserLinks {...props} />,
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        {params.OPERATION_AND_OP_GROUP_USER_LINK === undefined ||
          params.OPERATION_AND_OP_GROUP_USER_LINK === '' ||
          (params.OPERATION_AND_OP_GROUP_USER_LINK === 'OPERATIONGROUP' && (
            <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
              {tabs.map((tab, index) => (
                <div key={index} name={tab.name}>
                  {tab.content}
                </div>
              ))}
            </TabsUI>
          ))}
      </div>
    </div>
  );
};

export default OperationGroupTabs;
