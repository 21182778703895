import { useState, useEffect, useMemo } from 'react';

import companyService from '../../service/company.service';
import { dataGridPremiumColumns, generateColumnsFromDynProps } from '../../utils/helper';

import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import { BpTypeList } from '../../config/company';

export default function CompanyReports({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  dynProps,
  autocompleteOptions,
  customerGroups,
  supplierGroups,
  customerVatCode,
  supplierVatCode,
  salesPerson,
  purchasePerson,
}) {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'name', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    companyService.getCompanies(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'code',
        headerName: 'Partner kód',
        type: 'string',
      },
      {
        field: 'name',
        headerName: 'Partner neve',
        type: 'string',
      },
      {
        field: 'shortName',
        headerName: 'Partner rövid neve',
        type: 'string',
      },
      {
        field: 'supplier',
        headerName: 'Szállító',
        type: 'boolean',
      },
      {
        field: 'customer',
        headerName: 'Vevő',
        type: 'boolean',
      },
      {
        field: 'lead',
        headerName: 'Lead',
        type: 'boolean',
      },
      {
        field: 'supplierGroupId',
        headerName: 'Szállító csoport',
        type: 'singleSelect',
        valueOptions: supplierGroups,
      },
      {
        field: 'customerGroupId',
        headerName: 'Vevő csoport',
        type: 'singleSelect',
        valueOptions: customerGroups,
      },
      {
        field: 'bpType',
        headerName: 'ÜP típus',
        type: 'singleSelect',
        valueOptions: BpTypeList,
      },
      {
        field: 'languageCode',
        headerName: 'Nyelv',
        type: 'singleSelect',
        valueOptions: autocompleteOptions.language,
      },
      {
        field: 'website',
        headerName: 'Weboldal',
        type: 'string',
      },
      {
        field: 'email',
        headerName: 'Email cím',
        type: 'string',
      },
      {
        field: 'phoneNumber',
        headerName: 'Telefonszám',
        type: 'string',
      },
      {
        field: 'GLN',
        headerName: 'GLN',
        type: 'string',
      },
      {
        field: 'hunTaxNumber',
        headerName: 'Magyar adószám',
        type: 'string',
      },
      {
        field: 'euTaxNumber',
        headerName: 'EU adószám',
        type: 'string',
      },
      {
        field: 'statusId',
        headerName: 'Státusz',
        type: 'singleSelect',
        valueOptions: autocompleteOptions.status,
      },
      {
        field: 'supplierPriceListId',
        headerName: 'Szállító árlista',
        type: 'singleSelect',
        valueOptions: autocompleteOptions.priceList,
      },
      {
        field: 'supplierPaymentMethodId',
        headerName: 'Szállító fizetési mód',
        type: 'singleSelect',
        valueOptions: autocompleteOptions.paymentMethod,
      },
      {
        field: 'supplierCurrencyCode',
        headerName: 'Szállító pénznem',
        type: 'singleSelect',
        valueOptions: autocompleteOptions.paymentMethod,
      },
      {
        field: 'supplierVatCode',
        headerName: 'Szállító áfakulcs',
        type: 'singleSelect',
        valueOptions: supplierVatCode,
      },
      {
        field: 'supplierDeliveryMethodId',
        headerName: 'Szállító szállítási mód',
        type: 'singleSelect',
        valueOptions: autocompleteOptions.deliveryMethod,
      },
      {
        field: 'supplierIncotermCode',
        headerName: 'Szállító fuvarparitás',
        type: 'singleSelect',
        valueOptions: autocompleteOptions.incoterm,
      },
      {
        field: 'purchasePersonId',
        headerName: 'Szállító értékesítő',
        type: 'singleSelect',
        valueOptions: purchasePerson,
      },
      {
        field: 'customerPriceListId',
        headerName: 'Vevő árlista',
        type: 'singleSelect',
        valueOptions: autocompleteOptions.priceList,
      },
      {
        field: 'customerPaymentMethodId',
        headerName: 'Vevő fizetési mód',
        type: 'singleSelect',
        valueOptions: autocompleteOptions.paymentMethod,
      },
      {
        field: 'customerCurrencyCode',
        headerName: 'Vevő pénznem',
        type: 'singleSelect',
        valueOptions: autocompleteOptions.paymentMethod,
      },
      {
        field: 'customerVatCode',
        headerName: 'Vevő áfakulcs',
        type: 'singleSelect',
        valueOptions: customerVatCode,
      },
      {
        field: 'customerDeliveryMethodId',
        headerName: 'Vevő szállítási mód',
        type: 'singleSelect',
        valueOptions: autocompleteOptions.deliveryMethod,
      },
      {
        field: 'customerIncotermCode',
        headerName: 'Vevő fuvarparitás',
        type: 'singleSelect',
        valueOptions: autocompleteOptions.incoterm,
      },
      {
        field: 'salesPersonId',
        headerName: 'Vevő értékesítő',
        type: 'singleSelect',
        valueOptions: salesPerson,
      },
      {
        field: 'active',
        headerName: 'Aktív',
        type: 'boolean',
      },

      ...generateColumnsFromDynProps(dynProps ?? []),
    ]);
  }, [autocompleteOptions, dynProps]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.Company}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
    />
  );
}
