import { Drawer } from '@mui/material';
import React from 'react';

const DrawerUI = (props) => {
  const { children, width } = props;
  return (
    <Drawer
      {...props}
      sx={{
        '& .MuiPaper-root': {
          width: width ?? '250px',
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default DrawerUI;
