import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'config';

const configService = {
  rowData: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  getData: () => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/data`,
    });
  },
};
export default configService;
