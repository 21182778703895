import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { AutoCompleteSelectUI, FormErrorUI, FormLabelUI, InputUI } from '../Interface';
import { AddressType } from '../../config/company';
import { generateAutocompleteItems } from '../../utils/helper';

const DocumenLogistics = ({
  viewField,
  autocompleteOptions,
  autocompleteData,
  selectedCompanyState,
  companyContactsState,
  selectedValues,
}) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();
  const { selectedCompany } = selectedCompanyState;
  const { companyContacts } = companyContactsState;

  const [shippingAdress, setShippingAdress] = useState([]);
  const [billingAdress, setBillingAdress] = useState([]);

  useEffect(() => {
    if (selectedCompany) {
      const sAdress = selectedCompany.companyAdress.filter((a) => a.adressType === AddressType.SHIPPING);
      setShippingAdress(sAdress);

      const bAdress = selectedCompany.companyAdress.filter((a) => a.adressType === AddressType.BILLING);
      setBillingAdress(bAdress);

      const defShipping = sAdress.find((a) => a.default);
      const defBilling = bAdress.find((a) => a.default);

      if (defShipping && !Object.keys(selectedValues).length) {
        setFieldValue('shippingAdressId', defShipping.id);
        setFieldValue('shippingCountry', defShipping.country.name);
        setFieldValue('shippingProvince', defShipping.province);
        setFieldValue('shippingPostCode', defShipping.postCode);
        setFieldValue('shippingCity', defShipping.city);
        setFieldValue('shippingStreetAddress', defShipping.streetAddress);
      }
      if (defBilling && !Object.keys(selectedValues).length) {
        setFieldValue('billingAdressId', defBilling.id);
        setFieldValue('billingCountry', defBilling.country.name);
        setFieldValue('billingProvince', defBilling.province);
        setFieldValue('billingPostCode', defBilling.postCode);
        setFieldValue('billingCity', defBilling.city);
        setFieldValue('billingStreetAddress', defBilling.streetAddress);
      }
    }
  }, [selectedCompany]);

  const handleFieldChange = async (field, newVal) => {
    const selected = newVal?.value ?? null;
    await setFieldValue(field, selected);
    await validateField(field);
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-x-4 gap-y-4 mx-4 mb-5">
        <div>
          <Field
            type="autocomplete"
            name="billingAdressId"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Számlázási cím" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.billingAdressId} />}
            InputLabelProps={{ shrink: Boolean(values.billingAdressId) }}
            onChange={(_e, newVal) => {
              handleFieldChange('billingAdressId', newVal);
              if (newVal) {
                const selected = billingAdress.find((p) => p.id === newVal.value);
                setFieldValue('billingCountry', selected?.country.name);
                setFieldValue('billingProvince', selected?.province);
                setFieldValue('billingPostCode', selected?.postCode);
                setFieldValue('billingCity', selected?.city);
                setFieldValue('billingStreetAddress', selected?.streetAddress);
              } else {
                setFieldValue('billingCountry', '');
                setFieldValue('billingProvince', '');
                setFieldValue('billingPostCode', '');
                setFieldValue('billingCity', '');
                setFieldValue('billingStreetAddress', '');
              }
            }}
            selectedValue={values.billingAdressId}
            selectedLabelValue={billingAdress?.find((p) => p.id === values.billingAdressId)?.name}
            dataset={generateAutocompleteItems(billingAdress, 'name', 'id')}
          />
          <Field
            name="billingCountry"
            as={InputUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Számlázási ország" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.billingCountry} />}
            InputLabelProps={{ shrink: Boolean(values.billingCountry) }}
          />
          <Field
            name="billingProvince"
            as={InputUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Számlázási tartomány" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.billingProvince} />}
            InputLabelProps={{ shrink: Boolean(values.billingProvince) }}
          />
          <Field
            name="billingPostCode"
            as={InputUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Számlázási irányítószám" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.billingPostCode} />}
            InputLabelProps={{ shrink: Boolean(values.billingPostCode) }}
          />
          <Field
            name="billingCity"
            as={InputUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Számlázási város" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.billingCity} />}
            InputLabelProps={{ shrink: Boolean(values.billingCity) }}
          />
          <Field
            name="billingStreetAddress"
            as={InputUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Számlázási cím" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.billingStreetAddress} />}
            InputLabelProps={{ shrink: Boolean(values.billingStreetAddress) }}
          />
        </div>
        <div>
          <Field
            type="autocomplete"
            name="shippingAdressId"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Szállítási cím" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.shippingAdressId} />}
            InputLabelProps={{ shrink: Boolean(values.shippingAdressId) }}
            onChange={(_e, newVal) => {
              handleFieldChange('shippingAdressId', newVal);
              if (newVal) {
                const selected = shippingAdress.find((p) => p.id === newVal.value);
                setFieldValue('shippingCountry', selected?.country.name);
                setFieldValue('shippingProvince', selected?.province);
                setFieldValue('shippingPostCode', selected?.postCode);
                setFieldValue('shippingCity', selected?.city);
                setFieldValue('shippingStreetAddress', selected?.streetAddress);
              } else {
                setFieldValue('shippingCountry', '');
                setFieldValue('shippingProvince', '');
                setFieldValue('shippingPostCode', '');
                setFieldValue('shippingCity', '');
                setFieldValue('shippingStreetAddress', '');
              }
            }}
            selectedValue={values.shippingAdressId}
            selectedLabelValue={shippingAdress?.find((p) => p.id === values.shippingAdressId)?.name}
            dataset={generateAutocompleteItems(shippingAdress, 'name', 'id')}
          />
          <Field
            name="shippingCountry"
            as={InputUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Szállítási ország" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.shippingCountry} />}
            InputLabelProps={{ shrink: Boolean(values.shippingCountry) }}
          />
          <Field
            name="shippingProvince"
            as={InputUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Szállítási tartomány" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.shippingProvince} />}
            InputLabelProps={{ shrink: Boolean(values.shippingProvince) }}
          />
          <Field
            name="shippingPostCode"
            as={InputUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Szállítási irányítószám" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.shippingPostCode} />}
            InputLabelProps={{ shrink: Boolean(values.shippingPostCode) }}
          />
          <Field
            name="shippingCity"
            as={InputUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Szállítási város" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.shippingCity} />}
            InputLabelProps={{ shrink: Boolean(values.shippingCity) }}
          />
          <Field
            name="shippingStreetAddress"
            as={InputUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Szállítási cím" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.shippingStreetAddress} />}
            InputLabelProps={{ shrink: Boolean(values.shippingStreetAddress) }}
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="companyContactId"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Kapcsolattartó" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.companyContactId} />}
            InputLabelProps={{ shrink: Boolean(values.companyContactId) }}
            selectedValue={values.companyContactId}
            onChange={(_e, newVal) => handleFieldChange('companyContactId', newVal)}
            selectedLabelValue={companyContacts.find((cc) => cc.value === values.companyContactId)?.label}
            dataset={companyContacts}
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="deliveryMethodId"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Szállítási mód" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.deliveryMethodId} />}
            InputLabelProps={{ shrink: Boolean(values.deliveryMethodId) }}
            onChange={(_e, newVal) => handleFieldChange('deliveryMethodId', newVal)}
            selectedValue={values.deliveryMethodId}
            selectedLabelValue={autocompleteData?.deliveryMethod?.find((p) => p.id === values.deliveryMethodId)?.name}
            dataset={autocompleteOptions?.deliveryMethod}
          />
        </div>
        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="incotermCode"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Fuvarparitás" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.incotermCode} />}
            InputLabelProps={{ shrink: Boolean(values.incotermCode) }}
            onChange={(_e, newVal) => handleFieldChange('incotermCode', newVal)}
            selectedValue={values.incotermCode}
            selectedLabelValue={autocompleteData?.incoterm?.find((p) => p.id === values.incotermCode)?.name}
            dataset={autocompleteOptions?.incoterm}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="whsCode"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Raktár" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.whsCode} />}
            InputLabelProps={{ shrink: Boolean(values.whsCode) }}
            onChange={(_e, newVal) => handleFieldChange('whsCode', newVal)}
            selectedValue={values.whsCode}
            selectedLabelValue={autocompleteData?.warehouse?.find((p) => p.code === values.whsCode)?.code}
            dataset={autocompleteOptions?.warehouse}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumenLogistics;
