import { useState, useEffect } from 'react';

import useUsers from '../context/UsersContext';
import useMenus from '../context/MenuContext';
import masterDataService from '../service/masterData.service';
import { generateAutocompleteItems } from '../utils/helper';
import useConfig from '../context/ConfigContext';
import useParam from '../context/ParamContext';

export const useProduction = () => {
  const { config, getConfig } = useConfig();
  const { params, getParam } = useParam();
  const [refetchTable, setRefetchTable] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    priority: [],
    prodStatus: [],
  });
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [canEdit, setCanEdit] = useState(true);
  const [autocompleteData, setAutocompleteData] = useState({});
  const [submenu, setSubmenu] = useState(null);

  useEffect(() => {
    getMenus();
    getConfig();
    getParam();
    if (!Object.keys(user).length) getUser();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('production');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (submenu) {
      initDefaultValues();
    }
  }, [submenu]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .priorityToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .companyToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .usersToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .projectToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceOneToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .operationToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .quantityUnitToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .itemsToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .productionStatusToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          priority: resp[0],
          company: resp[1],
          user: resp[2],
          project: resp[3],
          resourceOne: resp[4],
          operation: resp[5],
          quantityUnit: resp[6],
          items: resp[7],
          prodStatus: resp[8],
        };
        setAutocompleteData(aData);
        const filteredCompany = aData.company?.filter((company) => company.customer);

        const acOptions = {
          priority: generateAutocompleteItems(aData.priority.priorityListItems, 'itemName', 'itemId'),
          company: generateAutocompleteItems(filteredCompany, 'name', 'id'),
          user: generateAutocompleteItems(aData.user, 'userName', 'userId'),
          project: generateAutocompleteItems(aData.project, 'projectName', 'projectId'),
          resourceOne: generateAutocompleteItems(aData.resourceOne, 'name', 'id'),
          operation: generateAutocompleteItems(aData.operation, ['code', 'operationName'], 'operationId'),
          quantityUnit: generateAutocompleteItems(aData.quantityUnit, 'name', 'id'),
          items: generateAutocompleteItems(aData.items, ['itemCode', 'itemName'], 'itemCode'),
          prodStatus: generateAutocompleteItems(aData.prodStatus, 'name', 'code'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  return {
    refetchTable,
    setRefetchTable,
    viewField,
    setViewField,
    selectedValues,
    setSelectedValues,
    operatorsDisabled,
    setOperatorsDisabled,
    rowSelectionModel,
    setRowSelectionModel,
    autocompleteOptions,
    setAutoCompleteOptions,
    menus,
    user,
    canEdit,
    config,
    autocompleteData,
    params,
  };
};
