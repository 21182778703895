import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, AutoCompleteSelectUI } from '../Interface';
import { generateAutocompleteItems } from '../../utils/helper';

export const ConversionFields = ({ viewField, autocompleteOptions, autoCompleteData }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();

  const findedQtyUnitGroup =
    values.fromQtyUnit && autoCompleteData.quantityUnit.find((qtyUnit) => qtyUnit.id === values.fromQtyUnit).group;

  const filteredToQtyUnit =
    findedQtyUnitGroup && autoCompleteData.quantityUnit.filter((qtyUnit) => qtyUnit.group === findedQtyUnitGroup);

  const transformed = generateAutocompleteItems(filteredToQtyUnit ?? [], 'name', 'id');

  return (
    <div className="grid grid-cols-3 mx-4 mb-5 gap-5">
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="fromQtyUnit"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Mennyiségi egység miről" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.fromQtyUnit} />}
          onChange={(_e, newVal) => {
            setFieldValue('fromQtyUnit', newVal?.value ?? null).then(() => {
              validateField('fromQtyUnit');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.fromQtyUnit !== '' }}
          selectedValue={values.fromQtyUnit}
          dataset={autocompleteOptions.quantityUnit}
        />
      </div>
      <div className="md:col-span-1 col-span-4">
        <Field
          type="number"
          name="changeNum"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Váltószám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.changeNum} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.changeNum && values.changeNum !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="toQtyUnit"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Mennyiség egység mire" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.toQtyUnit} />}
          onChange={(_e, newVal) => {
            setFieldValue('toQtyUnit', newVal?.value ?? null).then(() => {
              validateField('toQtyUnit');
            });
          }}
          disabled={viewField || !values.fromQtyUnit}
          InputLabelProps={{ shrink: values.toQtyUnit !== '' }}
          selectedValue={values.toQtyUnit}
          dataset={transformed}
        />
      </div>
    </div>
  );
};
