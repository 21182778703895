import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'templateItem';

const templateItemService = {
  createTemplateItem: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  getTemplateItems: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/templateItems`,
      data,
    });
  },

  updateTemplateItem: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteTemplateItem: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },
};

export default templateItemService;
