import { useState, useMemo, useEffect } from 'react';
import TabsUI from '../Interface/TabsUI';

import TodoContent from './TaskListTodoContent';
import useParam from '../../context/ParamContext';
import masterDataService from '../../service/masterData.service';
import useMenus from '../../context/MenuContext';
import { generateAutocompleteItems } from '../../utils/helper';

const TaskListTodoPage = () => {
  const { menus, getMenus, getMenuSubMenuId } = useMenus();
  const { getParam } = useParam();
  const [activeTab, setActiveTab] = useState(0);
  const [submenu, setSubmenu] = useState(null);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState({
    status: [],
  });

  useEffect(() => {
    getMenus();
    getParam();
  }, []);

  useEffect(() => {
    if (submenu) initDefaultValues();
  }, [submenu]);

  useEffect(() => {
    setSubmenu(getMenuSubMenuId('simpleTask'));
  }, [menus]);

  const initDefaultValues = () => {
    masterDataService
      .statusToForm(submenu)
      .then((data) => {
        const aData = {
          status: data,
        };

        const acOptions = {
          status: generateAutocompleteItems(aData.status.statusItems, 'itemName', 'itemId'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {});
  };

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const TodoContentMemo = useMemo(() => (p) => <TodoContent activeTab={activeTab} {...p} />, [activeTab]);

  const todoTabs = [
    {
      id: 0,
      name: 'Saját',
      content: <TodoContentMemo type={'own'} tabId={0} autoCompleteOptions={autoCompleteOptions} />,
    },
    {
      id: 1,
      name: 'Nyitott',
      content: <TodoContentMemo type={'opened'} tabId={1} autoCompleteOptions={autoCompleteOptions} />,
    },
    {
      id: 2,
      name: 'Összes',
      content: <TodoContentMemo type={'all'} tabId={2} autoCompleteOptions={autoCompleteOptions} />,
    },
  ];

  return (
    <div className="gap-4 bg-gray-100 min-h-full p-4">
      <div className="flex justify-center w-full mr-auto ml-auto">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {todoTabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
};

export default TaskListTodoPage;
