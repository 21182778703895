import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, AutoCompleteSelectUI, CheckboxUI } from '../Interface';

const FormFields = ({ viewField, autoCompleteOptions, selectedValues, params }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="code"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Művelet kódja" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.code} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.code !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="operationName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Művelet neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.operationName} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.operationName !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="resourceOneId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Gép" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.resourceOneId} />}
          onChange={(_e, newVal) => {
            setFieldValue('resourceOneId', newVal?.value ?? null).then(() => {
              validateField('resourceOneId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.resourceOneId !== '' }}
          selectedValue={values.resourceOneId}
          selectedLabelValue={selectedValues?.resourceOne?.name}
          dataset={autoCompleteOptions.resourceOne}
        />
      </div>
      {params.OPERATION_MULTI_MAN_FIELD === 'Y' && (
        <div className="col-span-4 md:col-span-1">
          <Field
            type="number"
            name="multiManOperation"
            as={InputUI}
            fullWidth
            label={<FormLabelUI text="Többemberes művelet" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.multiManOperation} />}
            disabled={viewField}
            InputLabelProps={{ shrink: values.multiManOperation && values.multiManOperation !== '' }}
          />
        </div>
      )}
      {params.OPERATION_GROUP_REPORTING_FIELD === 'Y' && (
        <div className="md:col-span-1 col-span-4 pt-3">
          <Field
            name="groupReporting"
            type="checkbox"
            as={CheckboxUI}
            fullWidth
            label={<FormLabelUI text="Csoportos lejelentés" />}
            disabled={viewField}
          />
        </div>
      )}
      {params.OPERATION_VERIFICATION_FIELD === 'Y' && (
        <div className="md:col-span-1 col-span-4 pt-3">
          <Field
            name="verificationRequired"
            type="checkbox"
            as={CheckboxUI}
            fullWidth
            label={<FormLabelUI text="Ellenőrzés szükséges" />}
            disabled={viewField}
          />
        </div>
      )}
    </div>
  );
};

export default FormFields;
