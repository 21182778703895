import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import { AutoCompleteSelectUI, DatePickerUI, FormErrorUI, FormLabelUI, InputUI } from '../Interface';
import companyService from '../../service/company.service';
import { generateAutocompleteItems } from '../../utils/helper';
import useConfig from '../../context/ConfigContext';
import { DocumentDirection } from '../../config/DocumentDirection';
import { DocStatusList } from '../../config/document';
import exchangeRateService from '../../service/exchangeRate.service';

const DocumentFields = ({
  viewField,
  autocompleteOptions,
  selectedValues,
  autocompleteData,
  documentData,
  selectedCompanyState,
  companyContactsState,
}) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();
  const { config, getConfig } = useConfig();
  const { selectedCompany, setSelectedCompany } = selectedCompanyState;
  const { setCompanyContacts } = companyContactsState;

  const getCompanyData = async (companyId) => {
    const selCom = await companyService.getCompanyById(companyId);
    setSelectedCompany(selCom);
  };

  const getDailyRate = async () => {
    const rateFilter = { currencyCode: values.currencyCode, rateDate: values.docDate };
    setFieldValue('exchangeRate', '');
    const rate = (await exchangeRateService.getDailyRate(rateFilter)) ?? null;
    if (rate) {
      setFieldValue('exchangeRate', parseFloat(rate.rate));
    }
  };

  useEffect(() => {
    if (!Object.keys(config).length) getConfig();
  }, []);

  useEffect(() => {
    if (values.companyId) {
      const selectedCompany = autocompleteData.company.find((i) => i.id === values.companyId);

      setFieldValue('companyCode', selectedCompany?.code);
      setFieldValue('companyName', selectedCompany?.name);

      getCompanyData(values.companyId);
    } else {
      setSelectedCompany(null);
      setCompanyContacts([]);
    }
  }, [values.companyId]);

  useEffect(() => {
    if (!selectedCompany) {
      setFieldValue('companyContactId', null);
      setFieldValue('companyCode', '');
      setFieldValue('companyName', '');
      setFieldValue('companyContactId', null);
      setFieldValue('currencyCode', null);
      setFieldValue('paymentMethodId', null);
      setFieldValue('salesOrPurchasePersonId', null);
      setFieldValue('deliveryMethodId', null);
      setFieldValue('incotermCode', null);
      setFieldValue('priceListId', null);
      setFieldValue('langCode', null);
      setFieldValue('hunTaxNumber', null);
      setFieldValue('euTaxNumber', null);
      setFieldValue('foreignTaxNumber', null);
      setFieldValue('billingAdressId', null);
      setFieldValue('billingCountry', '');
      setFieldValue('billingProvince', '');
      setFieldValue('billingPostCode', '');
      setFieldValue('billingCity', '');
      setFieldValue('billingStreetAddress', '');
      setFieldValue('shippingAdressId', null);
      setFieldValue('shippingCountry', '');
      setFieldValue('shippingProvince', '');
      setFieldValue('shippingPostCode', '');
      setFieldValue('shippingCity', '');
      setFieldValue('shippingStreetAddress', '');
      return;
    }

    if (!Object.keys(selectedValues).length) {
      setCompanyContacts(generateAutocompleteItems(selectedCompany.companyContact, 'name', 'id'));

      const defContact = selectedCompany.companyContact.find((cc) => cc.default);
      setFieldValue('companyContactId', defContact?.id ?? selectedCompany?.companyContact[0]?.id ?? null);

      setFieldValue('langCode', selectedCompany.languageCode);
      setFieldValue('hunTaxNumber', selectedCompany.hunTaxNumber);
      setFieldValue('euTaxNumber', selectedCompany.euTaxNumber);
      setFieldValue('foreignTaxNumber', selectedCompany.foreignTaxNumber);
      setFieldValue('billingAdressId', null);
      setFieldValue('billingCountry', '');
      setFieldValue('billingProvince', '');
      setFieldValue('billingPostCode', '');
      setFieldValue('billingCity', '');
      setFieldValue('billingStreetAddress', '');
      setFieldValue('shippingAdressId', null);
      setFieldValue('shippingCountry', '');
      setFieldValue('shippingProvince', '');
      setFieldValue('shippingPostCode', '');
      setFieldValue('shippingCity', '');
      setFieldValue('shippingStreetAddress', '');

      if (documentData.direction === DocumentDirection.INCOMING) {
        const crncy = selectedCompany.supplierCurrencyCode ?? config.currencyCode;
        setFieldValue('currencyCode', crncy);
        setFieldValue('paymentMethodId', selectedCompany.supplierPaymentMethodId);
        setFieldValue('salesOrPurchasePersonId', selectedCompany.purchasePersonId);
        setFieldValue('deliveryMethodId', selectedCompany.supplierDeliveryMethodId);
        setFieldValue('incotermCode', selectedCompany.supplierIncotermCode);
        setFieldValue('priceListId', selectedCompany.supplierPriceListId);
      } else if (documentData.direction === DocumentDirection.OUTGOING) {
        const crncy = selectedCompany.customerCurrencyCode ?? config.currencyCode;
        setFieldValue('currencyCode', crncy);
        setFieldValue('paymentMethodId', selectedCompany.customerPaymentMethodId);
        setFieldValue('salesOrPurchasePersonId', selectedCompany.salesPersonId);
        setFieldValue('deliveryMethodId', selectedCompany.customerDeliveryMethodId);
        setFieldValue('incotermCode', selectedCompany.customerIncotermCode);
        setFieldValue('priceListId', selectedCompany.customerPriceListId);
      } else {
        setFieldValue('currencyCode', config.currencyCode);
      }
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (values.currencyCode && values.currencyCode !== config.currencyCode) {
      getDailyRate();
    } else {
      setFieldValue('exchangeRate', '');
    }
  }, [values.currencyCode]);

  const handleFieldChange = async (field, newVal) => {
    const selected = newVal?.value ?? null;
    await setFieldValue(field, selected);
    await validateField(field);
  };
  return (
    <>
      <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
        <div className="col-span-4 md:col-span-1">
          <Field
            type="text"
            name="docStatus"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={true}
            label={<FormLabelUI text="Státusz" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.docStatus} />}
            InputLabelProps={{ shrink: Boolean(values.docStatus) }}
            selectedValue={values.docStatus}
            selectedLabelValue={DocStatusList.find((i) => i.value === values.docStatus)?.label}
            dataset={DocStatusList}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          <Field
            type="date"
            name="docDate"
            component={DatePickerUI}
            value={values.docDate}
            fullWidth
            label={<FormLabelUI text="Teljesítés dátuma" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.docDate} />}
            onChange={(date) => handleFieldChange('docDate', { value: date })}
            disabled={viewField}
            InputLabelProps={{ shrink: Boolean(values.docDate) }}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          <Field
            type="date"
            name="issueDate"
            component={DatePickerUI}
            value={values.issueDate}
            fullWidth
            label={<FormLabelUI text="Kiállítás dátuma" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.issueDate} />}
            onChange={(date) => handleFieldChange('issueDate', { value: date })}
            disabled={viewField}
            InputLabelProps={{ shrink: Boolean(values.issueDate) }}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          <Field
            type="date"
            name="dueDate"
            component={DatePickerUI}
            value={values.dueDate}
            fullWidth
            label={<FormLabelUI text="Határidő" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.dueDate} />}
            onChange={(date) => handleFieldChange('dueDate', { value: date })}
            disabled={viewField}
            InputLabelProps={{ shrink: Boolean(values.dueDate) }}
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="companyId"
            component={AutoCompleteSelectUI}
            fullWidth
            label={<FormLabelUI text={documentData.direction === DocumentDirection.INCOMING ? 'Szállító' : 'Vevő'} />}
            variant="standard"
            helperText={<FormErrorUI message={errors.companyId} />}
            onChange={async (_e, newVal) => handleFieldChange('companyId', newVal)}
            disabled={viewField}
            InputLabelProps={{ shrink: Boolean(values.companyId) }}
            selectedValue={values.companyId}
            selectedLabelValue={selectedValues?.company?.name}
            dataset={autocompleteOptions?.company}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          <Field
            type="text"
            name="companyCode"
            as={InputUI}
            fullWidth
            disabled={true}
            label={<FormLabelUI text="Partner kód" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.companyCode} />}
            InputLabelProps={{ shrink: Boolean(values.companyCode) }}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          <Field
            type="text"
            name="companyName"
            as={InputUI}
            fullWidth
            disabled={true}
            label={<FormLabelUI text="Partner név" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.companyName} />}
            InputLabelProps={{ shrink: Boolean(values.companyName) }}
          />
        </div>
        <div className="col-span-4 md:col-span-1">
          <Field
            type="text"
            name="reference"
            as={InputUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Hivatkozási szám" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.reference} />}
            InputLabelProps={{ shrink: Boolean(values.reference) }}
          />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-x-4 gap-y-4 mx-4 mb-5">
        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="priceListId"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Árlista" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.priceListId} />}
            InputLabelProps={{ shrink: Boolean(values.priceListId) }}
            onChange={(_e, newVal) => handleFieldChange('priceListId', newVal)}
            selectedValue={values.priceListId}
            selectedLabelValue={autocompleteData?.priceList?.find((p) => p.id === values.priceListId)?.name}
            dataset={autocompleteOptions?.priceList}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          <Field
            type="autocomplete"
            name="currencyCode"
            component={AutoCompleteSelectUI}
            fullWidth
            disabled={viewField}
            label={<FormLabelUI text="Pénznem" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.currencyCode} />}
            InputLabelProps={{ shrink: Boolean(values.currencyCode) }}
            selectedValue={values.currencyCode}
            selectedLabelValue={autocompleteData?.currency?.name}
            onChange={(_e, newVal) => handleFieldChange('currencyCode', newVal)}
            dataset={autocompleteOptions?.currency}
          />
        </div>

        <div className="col-span-4 md:col-span-1">
          {config?.currencyCode !== values.currencyCode && (
            <Field
              type="number"
              name="exchangeRate"
              as={InputUI}
              fullWidth
              disabled={viewField}
              label={<FormLabelUI text="Árfolyam" />}
              variant="standard"
              helperText={<FormErrorUI message={errors.exchangeRate} />}
              InputLabelProps={{ shrink: Boolean(values.exchangeRate) }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DocumentFields;
