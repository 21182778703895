import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, SelectUI, CheckboxUI } from '../Interface';
import { FromTypeList, InstantPaymentTypeList, PaymentDeadlineBasisTypeList } from '../../config/paymentMethod';

export const PaymentMethodFields = ({ viewField }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();

  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5 items-center">
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Fizetési mód" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name && values.name !== '' }}
        />
      </div>

      <div className="col-span-4 md:col-span-1">
        <Field
          name="paymentDeadlineBasis"
          component={SelectUI}
          fullWidth
          option={PaymentDeadlineBasisTypeList}
          value={values.paymentDeadlineBasis ?? ''}
          variant="standard"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('paymentDeadlineBasis', value ?? null).then(() => {
              validateField('paymentDeadlineBasis');
            });
          }}
          label={<FormLabelUI text="Fizetési határidő alapja" />}
          helperText={<FormErrorUI message={errors.paymentDeadlineBasis} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.paymentDeadlineBasis !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          name="from"
          component={SelectUI}
          fullWidth
          option={FromTypeList}
          onClear={() => {
            setFieldValue('from', '');
          }}
          value={values.from ?? ''}
          variant="standard"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('from', value ?? null).then(() => {
              validateField('from');
            });
          }}
          label={<FormLabelUI text="TÓL" />}
          helperText={<FormErrorUI message={errors.from} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.from !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="day"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Nap" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.day} />}
          disabled={viewField}
          InputLabelProps={{ shrink: (values.day && values.day !== '') || values.day === 0 }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          name="instantPayment"
          component={SelectUI}
          fullWidth
          option={InstantPaymentTypeList}
          value={values.instantPayment ?? false}
          variant="standard"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('instantPayment', value ?? null).then(() => {
              validateField('instantPayment');
            });
          }}
          label={<FormLabelUI text="Azonnali fizetés" />}
          helperText={<FormErrorUI message={errors.instantPayment} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.instantPayment !== '' }}
        />
      </div>
    </div>
  );
};
