import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { CheckboxUI, ConfirmDialogUI, LinearStaticProgressUI } from '../../Interface';
import dayjs from 'dayjs';
import productionService from '../../../service/production.service';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import { hourToMin, minToHour } from '../../../utils/helper';

dayjs.extend(relativeTime);
dayjs.extend(duration);

const OpWidgetJobmonitorCard2 = ({
  data,
  userId,
  setRefetch,
  findedGroups,
  checkedIds,
  handleCheckboxChange,
  filteredReports,
  rows,
  params,
}) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogData, setConfirmDialogData] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(null);
  const [timer, setTimer] = useState(null);

  const startedOperation = data?.productionReport?.find(
    (report) => report.startDate && !report.endDate && report.userId === userId
  );

  const grouppedReport = findedGroups?.find((group) => group.id === data?.id);

  useEffect(() => {
    if (startedOperation?.startDate) {
      if (timer) {
        clearInterval(timer);
      }

      const interval = setInterval(() => {
        const start = dayjs(startedOperation.startDate);
        const now = dayjs();
        const diff = dayjs.duration(now.diff(start));

        setElapsedTime(
          `${diff.hours().toString().padStart(2, '0')}:${diff.minutes().toString().padStart(2, '0')}:${diff.seconds().toString().padStart(2, '0')}`
        );
      }, 1000);

      setTimer(interval);

      return () => clearInterval(interval);
    } else {
      if (timer) {
        clearInterval(timer);
        setTimer(null);
      }
      setElapsedTime(null);
    }
  }, [startedOperation]);

  const { totalReportedQty, totalReportedTimeSpent, totalReportedTimeSpentInMin } = data.productionReport.reduce(
    (acc, report) => {
      if (report.prodOperationId === data.id) {
        acc.totalReportedQty += Number(report.quantity);
        acc.totalReportedTimeSpent += Number(report.timeSpent);
        acc.totalReportedTimeSpentInMin += Number(report.timeSpentInMin);
      }
      return acc;
    },
    { totalReportedQty: 0, totalReportedTimeSpent: 0, totalReportedTimeSpentInMin: 0 }
  );

  const reported =
    params?.QUANTITY_UNIT_HOUR_CODE === data.quantityUnit.code ? totalReportedTimeSpent : totalReportedTimeSpentInMin;

  const preparedness = Math.round((reported / data.plannedQty) * 100);

  const handleUpdateStatus = (productionStatusId, ids) => {
    return productionService.updateStatus({
      id: ids && ids.length > 0 ? ids : data.id,
      productionStatusId,
    });
  };

  const handleStartOperation = (prodOperationId) => {
    const operation = rows?.find((op) => op.id === prodOperationId);

    let operationIds = [prodOperationId];

    if (operation.groupReportingId) {
      const groupedOperations = rows?.filter((op) => op.groupReportingId === operation.groupReportingId);
      operationIds = groupedOperations.map((op) => op.id);
    }

    const values = {
      userId: userId,
      createdBy: userId,
      startDate: dayjs(),
      date: dayjs(),
      id: checkedIds.length > 0 ? checkedIds : operationIds.length > 1 ? operationIds : prodOperationId,
      endDate: null,
    };

    const startOperation = productionService.createProductionReport(values);
    Promise.all([
      handleUpdateStatus(
        'INPROGRESS',
        checkedIds.length > 0 ? checkedIds : operationIds.length > 1 ? operationIds : data.id
      ),
      startOperation,
    ]).then(() => {
      setRefetch(true);
    });
  };

  const handleStopOperation = (productionReportId) => {
    const operation = rows?.find((row) => row.id === startedOperation.prodOperationId);

    let productionReportIds = [];

    if (operation?.groupReportingId) {
      const groupedOperations = rows.filter((row) => row.groupReportingId === operation.groupReportingId);

      productionReportIds = groupedOperations
        .flatMap((op) =>
          (op.productionReport || []).filter(
            (report) => report.startDate && !report.endDate && report.userId === userId
          )
        )
        .map((report) => report.id);
    }

    const values = {
      endDate: dayjs(),
      id:
        filteredReports && filteredReports.length > 0
          ? filteredReports
          : productionReportIds && productionReportIds.length > 0
            ? productionReportIds
            : productionReportId,
    };
    return productionService.updateProductionReport(values).then(() => {
      setRefetch(true);
    });
  };

  const handleFinishOperation = () => {
    const operation = rows?.find((op) => op.id === data.id);

    let operationIds = [data.id];

    if (operation?.groupReportingId) {
      const groupedOperations = rows?.filter((op) => op?.groupReportingId === operation?.groupReportingId);
      operationIds = groupedOperations.map((op) => op.id);
    }

    Promise.all([
      handleUpdateStatus(
        'COMPLETED',
        checkedIds?.length > 0 ? checkedIds : operationIds && operationIds?.length > 0 ? operationIds : data.id
      ),
      handleStopOperation(startedOperation.id),
    ]).then(() => {
      setRefetch(true);
    });
  };

  const handleInterruptOperation = () => {
    const operation = rows?.find((op) => op.id === data.id);

    let operationIds = [data.id];

    if (operation?.groupReportingId) {
      const groupedOperations = rows?.filter((op) => op?.groupReportingId === operation?.groupReportingId);
      operationIds = groupedOperations.map((op) => op.id);
    }

    Promise.all([
      handleUpdateStatus(
        'INTERRUPTED',
        checkedIds?.length > 0 ? checkedIds : operationIds && operationIds?.length > 0 ? operationIds : data.id
      ),
      handleStopOperation(startedOperation.id),
    ]).then(() => {
      setRefetch(true);
    });
  };

  const openConfirmDialog = () => {
    if (startedOperation) {
      setConfirmDialogData({
        message: 'Mit szeretne csinálni?',
        confirmButtonText: 'Kész',
        otherButtonText: 'Megszakít',
        handleChangeElement: () => {
          setShowConfirmDialog(false);
          handleFinishOperation();
        },
        handleChangeOtherElement: () => {
          handleInterruptOperation();
          setShowConfirmDialog(false);
        },
      });
    } else {
      setConfirmDialogData({
        message: 'Biztosan el szeretné indítani a gyártási műveletet?',
        confirmButtonText: 'Igen',
        handleChangeElement: () => {
          handleStartOperation(data.id);
          setShowConfirmDialog(false);
        },
      });
    }
    setShowConfirmDialog(true);
  };

  return (
    <>
      <Box
        className="rounded-md py-2 px-4 shadow-md max-w-[450px] min-w-[450px] cursor-pointer"
        sx={{
          backgroundColor: startedOperation
            ? '#B6EFCE'
            : data.productionStatusId === 'INTERRUPTED'
              ? '#FFD2D1'
              : 'white',
          '&:hover': {
            background: startedOperation
              ? '#75E69E'
              : data.productionStatusId === 'INTERRUPTED'
                ? 'rgba(254, 205, 211, 0.7)'
                : 'rgba(229, 231, 235, 0.7)',
          },
        }}
      >
        <div>
          <div className="flex justify-between mb-2">
            {startedOperation?.startDate && (
              <Typography sx={{ fontWeight: 'bold' }}>
                Elindítva: {dayjs(startedOperation.startDate).format('YYYY-MM-DD HH:mm')}
              </Typography>
            )}
            <Typography sx={{ fontWeight: 'bold', color: 'blue' }}> {elapsedTime}</Typography>
          </div>
          <div className="mb-2">
            {grouppedReport && (
              <CheckboxUI
                label="Csoportos lejelentés"
                sx={{ margin: 0, padding: '0rem 0.5rem 0rem 0rem' }}
                checked={checkedIds.includes(data.id)}
                onChange={(event) => handleCheckboxChange(event.target.checked)}
              />
            )}
          </div>
          <div onClick={() => openConfirmDialog()}>
            <div>
              <Typography
                sx={{ fontWeight: 'bold' }}
              >{`${data.production.workingNumber} - ${data.production?.reference}`}</Typography>
            </div>
            <div className="flex items-center gap-3 flex-wrap justify-between mb-2">
              <div>
                <Typography>{data.production.articleNumber}</Typography>
                <Typography>{data.production.articleName}</Typography>
                <Typography>{data.production?.finishedItemCode}</Typography>
              </div>
              <Typography>
                {params?.QUANTITY_UNIT_HOUR_CODE === data.quantityUnit.code
                  ? `${Math.round(totalReportedTimeSpent * 100) / 100} / ${data.plannedQty} ${data.quantityUnit.name}`
                  : `${Math.round(totalReportedTimeSpentInMin * 100) / 100} / ${data.plannedQty} ${data.quantityUnit.name}`}
              </Typography>
            </div>
            <div>
              <Typography>{data.production?.project?.projectName}</Typography>
              <Typography>{data.production?.company?.name}</Typography>
            </div>
            <div className="my-2">
              <LinearStaticProgressUI
                bgColor={startedOperation ? '#4ade80' : 'rgba(113, 113, 122, 0.5)'}
                value={preparedness}
              />
            </div>
            <div className="flex justify-between">
              <Typography>{data.operation.operationName}</Typography>
              <Typography>{data.productionStatus?.name}</Typography>
            </div>
          </div>
        </div>
      </Box>
      <ConfirmDialogUI
        message={confirmDialogData?.message}
        confirmButtonText={confirmDialogData?.confirmButtonText}
        otherButtonText={confirmDialogData?.otherButtonText}
        handleChangeElement={confirmDialogData?.handleChangeElement}
        handleChangeOtherElement={confirmDialogData?.handleChangeOtherElement}
        show={showConfirmDialog}
        onHide={() => setShowConfirmDialog(false)}
        largeButtons={true}
        maxWidth="md"
      />
    </>
  );
};

export default OpWidgetJobmonitorCard2;
