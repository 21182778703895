import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';

import { GridRowModes, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { dataGridColumns } from '../../../utils/helper';
import {
  DataGridUI,
  DataGridIdentifiers,
  FormErrorUI,
  InputUI,
  AddButtonUi,
  OrderArrowsUI,
  AutoCompleteSelectUI,
} from '../../Interface';

const EditToolbar = (props) => {
  const { setRowModesModel, disabled, push, defaultField } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    const newVisOrder = (values.taskListTemplateItem?.length || 0) + 1;

    defaultField.id = uuidv4();
    defaultField.visOrder = newVisOrder;
    await push(defaultField);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultField.id]: { mode: GridRowModes.Edit },
    }));

    const fieldsToValidate = [
      `taskListTemplateItem.${values.taskListTemplateItem.length ?? 0}.name`,
      `taskListTemplateItem.${values.taskListTemplateItem.length ?? 0}.responsiblePersonId`,
      `taskListTemplateItem.${values.taskListTemplateItem.length ?? 0}.duration`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
    }
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
};

const TaskListTemplateItem = ({ disabled, defaultField, autoCompleteOptions }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();

  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.taskListTemplateItem.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    const fieldsToValidate = [
      `taskListTemplateItem.${valueIndex}.name`,
      `taskListTemplateItem.${valueIndex}.responsiblePersonId`,
      `taskListTemplateItem.${valueIndex}.duration`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
      if (errors?.taskListTemplateItem?.[valueIndex]?.[field.split('.').pop()]) {
        return;
      }
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleOrderChange = (id, currentOrder, direction) => {
    const newOrder = direction === 'up' ? currentOrder - 1 : currentOrder + 1;

    if (newOrder < 1 || newOrder > values.taskListTemplateItem.length) {
      return;
    }

    const updatedList = [...values.taskListTemplateItem]
      .map((item) => {
        if (item.id === id) {
          return { ...item, visOrder: newOrder };
        } else if (item.visOrder === newOrder) {
          return { ...item, visOrder: currentOrder };
        }
        return item;
      })
      .sort((a, b) => a.visOrder - b.visOrder);

    setFieldValue('taskListTemplateItem', updatedList);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridColumns([
      {
        field: 'name',
        headerName: 'Elemek',
        flex: 1,
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`taskListTemplateItem.${valueIndex}.name`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.taskListTemplateItem?.[valueIndex]?.name} />}
              onChange={(e) => {
                setFieldValue(`taskListTemplateItem.${valueIndex}.name`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`taskListTemplateItem.${valueIndex}.name`)}
            />
          );
        },
      },
      {
        field: 'duration',
        headerName: 'Időintervallum (nap)',
        flex: 1,
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`taskListTemplateItem.${valueIndex}.duration`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.taskListTemplateItem?.[valueIndex]?.duration} />}
              onChange={(e) => {
                setFieldValue(`taskListTemplateItem.${valueIndex}.duration`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`taskListTemplateItem.${valueIndex}.duration`)}
            />
          );
        },
      },
      {
        field: 'responsiblePersonId',
        headerName: 'Felelős',
        flex: 1,
        editable: true,
        type: 'singleSelect',
        valueOptions: autoCompleteOptions.user,
        renderCell: (data) => {
          const findedRespPerson = autoCompleteOptions?.user.find(
            (item) => item.value === data.row?.responsiblePersonId
          );
          return findedRespPerson?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedRespPerson = autoCompleteOptions.user.find((item) => item.value === data.row.responsiblePersonId);

          return (
            <Field
              name={`taskListTemplateItem.${valueIndex}.responsiblePersonId`}
              component={AutoCompleteSelectUI}
              helperText={<FormErrorUI message={errors?.taskListTemplateItem?.[valueIndex]?.responsiblePersonId} />}
              selectedValue={values.taskListTemplateItem?.[valueIndex]?.responsiblePersonId}
              selectedLabelValue={findedRespPerson?.label}
              onChange={(_e, newVal) => {
                setFieldValue(`taskListTemplateItem.${valueIndex}.responsiblePersonId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              dataset={autoCompleteOptions.user}
              onBlur={async () => await validateField(`taskListTemplateItem.${valueIndex}.responsiblePersonId`)}
            />
          );
        },
      },
      {
        field: 'visOrder',
        headerName: 'Sorrend',
        renderCell: (params) => {
          const handleUpClick = () => handleOrderChange(params.row.id, params.row.visOrder, 'up');
          const handleDownClick = () => handleOrderChange(params.row.id, params.row.visOrder, 'down');
          return <OrderArrowsUI onUpClick={handleUpClick} onDownClick={handleDownClick} disabled={disabled} />;
        },
        sortable: false,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        flex: 0.25,
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg mb-5 px-3">
          <FieldArray name="taskListTemplateItem">
            {({ remove, push }) => (
              <DataGridUI
                datagridIdentifier={DataGridIdentifiers.taskListTemplateItem}
                sx={{ height: 380 }}
                columns={columns(setFieldValue, validateField, errors, remove)}
                rows={values?.taskListTemplateItem ?? []}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultField,
                    setRowModesModel,
                  },
                }}
                onCellDoubleClick={(_params, event) => {
                  event.defaultMuiPrevented = true;
                }}
              />
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default TaskListTemplateItem;
