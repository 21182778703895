import { useEffect, useState } from 'react';
import useMenus from '../context/MenuContext';

export const useStatus = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const [refetchTable, setRefetchTable] = useState(true);
  const [viewField, setViewField] = useState(true);
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [canEdit, setCanEdit] = useState(true);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState({
    submenus: [],
  });

  useEffect(() => {
    getMenus();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('Status');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  return {
    refetchTable,
    setRefetchTable,
    viewField,
    setViewField,
    selectedValues,
    setSelectedValues,
    operatorsDisabled,
    setOperatorsDisabled,
    canEdit,
    autoCompleteOptions,
  };
};
