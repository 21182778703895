import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, AutoCompleteSelectUI } from '../Interface';
import { DependenceTypeList } from '../../config/config';

const GeneralSettingFields = ({ viewField, autoCompleteOptions, formData }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();
  return (
    <div className="grid grid-cols-6 gap-x-4 mx-4 mb-5">
      <div className="md:col-span-3 xl:col-span-1 col-span-6">
        <Field
          type="number"
          name="decimalPlaces"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Mennyiségi tizedesjegyek száma" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.decimalPlaces} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.decimalPlaces && values.decimalPlaces !== '' }}
        />
      </div>
      <div className="md:col-span-3 xl:col-span-1 col-span-6">
        <Field
          type="autocomplete"
          name="currencyCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Saját pénznem" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.currencyCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('currencyCode', newVal?.value ?? null).then(() => {
              validateField('currencyCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.currencyCode !== '' }}
          selectedValue={values.currencyCode}
          selectedLabelValue={formData?.currency?.name}
          dataset={autoCompleteOptions.currency}
        />
      </div>
      <div className="md:col-span-3 xl:col-span-1 col-span-6">
        <Field
          type="autocomplete"
          name="defaultWarehouse"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Alapértelmezett raktár" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.defaultWarehouse} />}
          onChange={(_e, newVal) => {
            setFieldValue('defaultWarehouse', newVal?.value ?? null).then(() => {
              validateField('defaultWarehouse');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.defaultWarehouse !== '' }}
          selectedValue={values.defaultWarehouse}
          selectedLabelValue={formData?.warehouse?.name}
          dataset={autoCompleteOptions.warehouse}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="defaultDependence"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Alapértelmezett függőség" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.defaultDependence} />}
          onChange={(_e, newVal) => {
            setFieldValue('defaultDependence', newVal?.value ?? null).then(() => {
              validateField('defaultDependence');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.defaultDependence !== '' }}
          selectedValue={values.defaultDependence}
          dataset={DependenceTypeList}
        />
      </div>
    </div>
  );
};

export default GeneralSettingFields;
