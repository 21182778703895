import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/hu';

const DateTimePickerUI = (props) => {
  const { helperText, variant } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="hu">
      <DateTimePicker
        {...props}
        views={['year', 'day', 'hours', 'minutes']}
        slotProps={{
          textField: {
            variant: variant ?? 'standard',
            helperText: helperText,
          },
          popper: {
            style: {
              zIndex: 9999,
            },
          },
          field: { clearable: true, onClear: () => {} },
        }}
        sx={{ width: '100%' }}
      />
    </LocalizationProvider>
  );
};

export default DateTimePickerUI;
