import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import masterDataService from '../../service/masterData.service';
import { generateAutocompleteItems } from '../../utils/helper';
import usePages from '../../context/PageContext';
import useMenus from '../../context/MenuContext';
import useUsers from '../../context/UsersContext';
import useParam from '../../context/ParamContext';
import productionService from '../../service/production.service';
import jobMonitorService from '../../service/jobMonitor.service';

import { AutoCompleteSelectUI, DebouncedInputUI, FormLabelUI, SwitchUI } from '../Interface';
import JobMonitorCard from './JobMonitorCard';
import ProductDialog from './ProductDialog';
import useSaveView from '../../hooks/useSaveView';
import useView from '../../hooks/useView';
import JobMonitorResources from './JobMonitorResources';
import resourceOneService from '../../service/resources/resourceOne.service';

export const JobMonitorTaskAssigmentPage = () => {
  const navigate = useNavigate();
  const { getUser, user } = useUsers();
  const { getParam, params } = useParam();
  const saveView = useSaveView(user);
  const jobMonitorTaskAssigmentFilter = useView('jobMonitorTaskAssigmentFilter', user);
  const { menus, getMenus, getMenuSubMenuId, getSubMenuNamesByUrls } = useMenus();
  const { setPages } = usePages();
  const [prodOperations, setProdOperations] = useState({ rows: [], rowCount: 0 });
  const [submenu, setSubmenu] = useState(null);
  const [previousJobs, setPreviousJobs] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [production, setProduction] = useState({});
  const [productDialogIsOpen, setProductDialogIsOpen] = useState(false);
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    resourceOne: [],
    operations: [],
  });
  const [autoCompleteData, setAutoCompleteData] = useState({});
  const [resources, setResources] = useState([]);
  const [selectedResources, setSelectedResources] = useState({ id: [] });
  const [refetchResources, setRefetchResources] = useState(false);
  const [query, setQuery] = useState({
    filter: {
      filterModel: {
        items: [],
        logicOperator: 'and',
      },
    },
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  useEffect(() => {
    getMenus();
    getParam();
    if (!Object.keys(user).length) getUser();
  }, []);

  useEffect(() => {
    if (Object.keys(user).length > 0 && query.filter.filterModel.items.length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (selectedResources?.id?.length > 0 && autoCompleteData?.resourceOne) {
      getResourcesByGroupId();
    } else {
      setResources([]);
    }
  }, [selectedResources?.id, autoCompleteData?.resourceOne, refetchResources]);

  useEffect(() => {
    if (submenu) {
      initDefaultValues();
    }
  }, [submenu]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('production');
    setSubmenu(submenuId);
  }, [menus]);

  useEffect(() => {
    if (Object.keys(jobMonitorTaskAssigmentFilter).length > 0) {
      if ('previousJobs' in jobMonitorTaskAssigmentFilter) {
        updateQueryForStatusId(jobMonitorTaskAssigmentFilter.previousJobs);
        setPreviousJobs(jobMonitorTaskAssigmentFilter.previousJobs);
      } else {
        updateQueryForStatusId(false);
      }
      if ('resourceOneId' in jobMonitorTaskAssigmentFilter) {
        if (jobMonitorTaskAssigmentFilter.resourceOneId?.length > 0) {
          setSelectedResources((prev) => ({
            ...prev,
            id: jobMonitorTaskAssigmentFilter.resourceOneId,
          }));
        }
      }
      const filters = Object.entries(jobMonitorTaskAssigmentFilter).map(([field, value]) => ({ field, value }));
      if (filters.length > 0) {
        handleFilterChange(filters);
      }
    } else {
      updateQueryForStatusId(false);
    }
  }, [jobMonitorTaskAssigmentFilter, params]);

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['productionreport']);
  }, [menus]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceOneToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .operationToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          resourceOne: resp[0],
          operations: resp[1],
        };

        setAutoCompleteData(aData);
        const filteredResourceOne = aData.resourceOne?.filter((r1) => r1.name.startsWith('PUFFER'));

        const acOptions = {
          resourceOne: generateAutocompleteItems(filteredResourceOne, 'name', 'id'),
          operations: generateAutocompleteItems(aData.operations, 'operationName', 'operationId'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {});
  };

  const getResourcesByGroupId = () => {
    const resourceOneId = selectedResources?.id?.map((r) => r.value) ?? [];

    const matchedResources = autoCompleteData?.resourceOne?.filter((r1) => resourceOneId.includes(r1.id)) ?? [];

    const groupId = matchedResources.map((r) => r.groupId).filter(Boolean);

    const data = {
      resourceOneId,
      groupId,
    };

    if (groupId.length) {
      resourceOneService.getResourcesByGroupId(data).then((data) => {
        setResources(data);
        setRefetchResources(false);
      });
    }
  };

  const getList = () => {
    jobMonitorService
      .getProductionOperations(query)
      .then((data) => {
        setProdOperations((prev) => ({
          rows: query.pagination.paginationModel.page === 0 ? data.rows : [...prev.rows, ...data.rows],
          rowCount: data.rowCount,
        }));
        setHasMore(prodOperations.rows.length < data.rowCount);
      })
      .finally(() => {});
  };

  const updateQueryForStatusId = (usePreviousJobs) => {
    if (!params || !params.JOBMONITOR_STATUSES) {
      return;
    }

    const statusConfig = JSON.parse(params.JOBMONITOR_STATUSES);
    const statusIds = usePreviousJobs ? statusConfig.forPreviousWork : statusConfig.noPreviousWork;
    const newItems = query.filter.filterModel.items
      .filter((item) => item.field !== 'productionStatusId')
      .concat({
        field: 'productionStatusId',
        operator: 'isAnyOf',
        value: statusIds,
      });

    if (JSON.stringify(newItems) !== JSON.stringify(query.filter.filterModel.items)) {
      setQuery((prev) => ({
        ...prev,
        filter: {
          ...prev.filter,
          filterModel: {
            items: newItems,
          },
        },
        pagination: {
          paginationModel: {
            pageSize: prev.pagination.paginationModel.pageSize,
            page: 0,
          },
        },
      }));
    }
  };

  const handleFilterChange = (field, value) => {
    setQuery((prev) => {
      let newItems = prev.filter.filterModel.items;

      if (Array.isArray(field)) {
        newItems = newItems.filter((item) => !field.some((f) => f.field === item.field));

        const validFilters = field
          .filter((f) => f.value !== null && f.value !== undefined && (!Array.isArray(f.value) || f.value.length > 0))

          .map((f) => {
            const rawValue = f.value?.value ?? f.value;
            const processedValue = Array.isArray(rawValue) ? rawValue.map((v) => v.value ?? v) : rawValue;

            return {
              field: f.field,
              operator: Array.isArray(processedValue) ? 'isAnyOf' : 'is',
              value: processedValue,
            };
          });

        newItems = newItems.concat(validFilters);
      } else {
        newItems = newItems.filter((item) => item.field !== field);
        if (value !== null && value !== undefined) {
          const processedValue = Array.isArray(value) ? value.map((v) => v.value ?? v) : value;

          newItems.push({
            field,
            operator: Array.isArray(processedValue) ? 'isAnyOf' : 'is',
            value: processedValue,
          });
        }
      }

      return {
        ...prev,
        filter: {
          filterModel: {
            items: newItems,
          },
        },
      };
    });
  };

  const handleNavigate = (prodOperationId) => {
    setPages({ subMenuName: submenuNames });
    navigate(`/app/productionReport/${prodOperationId}`);
  };

  const fetchMoreData = () => {
    setQuery((prev) => ({
      ...prev,
      pagination: {
        paginationModel: {
          ...prev.pagination.paginationModel,
          page: prev.pagination.paginationModel.page + 1,
        },
      },
    }));
  };

  const getProductionById = (id) => {
    productionService.getProductionById(id).then((data) => {
      setProduction(data);
      setProductDialogIsOpen(true);
    });
  };

  const handleDropAndResourceUpdate = (resourceOneId, jobItem) => {
    productionService
      .updateProdOperationResource(jobItem.id, resourceOneId)
      .then(() => {
        setProdOperations((prev) => ({
          ...prev,
          rows: prev.rows.filter((row) => row.id !== jobItem.id),
        }));
        setRefetchResources(true);
      })
      .finally(() => {});
  };

  const onDragStart = (event, jobItem) => {
    event.dataTransfer.setData('jobItem', JSON.stringify(jobItem));
  };

  const onDragOver = (event) => {
    event.preventDefault();
  };

  const onDrop = (event, resourceOneId) => {
    event.preventDefault();

    const jobItem = JSON.parse(event.dataTransfer.getData('jobItem'));

    if (jobItem.id !== resourceOneId) {
      handleDropAndResourceUpdate(resourceOneId, jobItem);
    }
  };

  return (
    <div className="bg-[#CDDFF7] px-6">
      <div className="flex justify-between gap-5 flex-wrap">
        <div className="flex gap-5 flex-wrap mb-5">
          <div className="w-[250px]">
            <AutoCompleteSelectUI
              label={<FormLabelUI text="Művelet kereső" />}
              dataset={autocompleteOptions.operations}
              onChange={(_e, newVal, reason) => {
                handleFilterChange('operationId', reason === 'clear' ? null : newVal?.value);
                saveView('jobMonitorTaskAssigmentFilter', { operationId: newVal });
              }}
              selectedValue={query.filter.filterModel.items.find((item) => item.field === 'operationId')?.value}
              selectedLabelValue={jobMonitorTaskAssigmentFilter?.operationId?.label}
            />
          </div>
          <div className="w-[450px]">
            <AutoCompleteSelectUI
              limitTags={2}
              dataset={autocompleteOptions.resourceOne}
              label={<FormLabelUI text="Erőforrás kereső" />}
              onChange={(_e, newVal, reason) => {
                handleFilterChange('resourceOneId', reason === 'clear' ? null : newVal);
                saveView('jobMonitorTaskAssigmentFilter', { resourceOneId: newVal });
                setSelectedResources((prev) => ({
                  ...prev,
                  id: newVal,
                }));
              }}
              selectedValue={selectedResources?.id}
              freeSolo
              multiple
              getOptionLabel={(option) => option.label}
            />
          </div>
        </div>
        <div className="flex gap-5 flex-wrap">
          <div className="w-[250px]">
            <DebouncedInputUI
              label="Keresés a gyártásban"
              debounceMs={800}
              setQuickFilterSearchValue={(newVal) => {
                handleFilterChange('workingNumber', newVal);
                saveView('jobMonitorTaskAssigmentFilter', { workingNumber: newVal });
              }}
              quickFilterSearchValue={
                query.filter.filterModel.items.find((item) => item.field === 'workingNumber')?.value
              }
            />
          </div>
          <div className="pt-4">
            <SwitchUI
              label="Korábbi JOB-ok"
              checked={previousJobs}
              onClick={() => {
                setPreviousJobs(!previousJobs);
                saveView('jobMonitorTaskAssigmentFilter', { previousJobs: !previousJobs });
              }}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-3">
        <div
          id="scrollableDiv"
          className="col-span-4 min-h-[calc(100vh-135px)]  max-h-[calc(100vh-135px)] overflow-y-scroll custom-scrollbar"
        >
          <InfiniteScroll
            dataLength={prodOperations.rows.length}
            next={fetchMoreData}
            hasMore={hasMore}
            scrollableTarget="scrollableDiv"
          >
            {prodOperations?.rows.length > 0 &&
              prodOperations.rows.map((operation) => (
                <JobMonitorCard
                  key={operation.id}
                  data={operation}
                  handleNavigate={handleNavigate}
                  getProductionById={getProductionById}
                  setProdOperations={setProdOperations}
                  params={params}
                  previousJobs={previousJobs}
                  onDragStart={onDragStart}
                />
              ))}
          </InfiniteScroll>
        </div>

        <div className="col-span-8 min-h-[calc(100vh-135px)] max-h-[calc(100vh-135px)] overflow-x-auto overflow-hidden custom-scrollbar">
          <div className="flex justify-start items-start gap-1 pt-0">
            {resources?.map((resource) => (
              <div className="min-w-[350px] max-w-[350px]">
                <JobMonitorResources
                  key={resource.id}
                  resource={resource}
                  getProductionById={getProductionById}
                  onDragStart={onDragStart}
                  onDragOver={onDragOver}
                  onDrop={onDrop}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <ProductDialog
        open={productDialogIsOpen}
        handleClose={() => setProductDialogIsOpen(false)}
        production={production}
      />
    </div>
  );
};
