import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Formik, Form } from 'formik';

import Yup from '../../utils/yup';
import statusService from '../../service/status.service';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import { FormHeaderUI, FormButtonPanelUI } from '../Interface';
import FormFields from './FormFields';
import FormTabs from './FormTabs';

export const StatusForm = ({ statusState, dialog }) => {
  const {
    selectedValues,
    setSelectedValues,
    operatorsDisabled,
    viewField,
    setRefetchTable,
    setOperatorsDisabled,
    setViewField,
    canEdit,
  } = statusState;

  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [statusList, setstatusList] = useState([]);
  const [initValues, setInitValues] = useState({});

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultValues, selectedValues)) {
        setstatusList(selectedValues?.statusItems ?? []);
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        valuesToForm();
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const defaultValues = {
    selectListTitle: '',
    subMenuId: null,
  };

  const validationSchema = Yup.object().shape({
    selectListTitle: Yup.string().required(),
    subMenuId: Yup.string().required(),
  });

  const valuesToForm = () => {
    const { selectListTitle, subMenuId } = selectedValues;

    setInitValues({
      selectListTitle,
      subMenuId,
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = (values) => {
    const updData = {
      ...values,
    };

    statusService.updateStatus(updData, selectedValues.statusId).then((_status) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setSelectedValues({});
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
      valuesToForm();

      if (dialog) {
        dialog.setDataChanged(true);
        dialog.handleClose();
      }
    });
  };

  const handleInsertObject = (values) => {
    const insertObject = {
      ...values,
    };

    statusService
      .createStatus(insertObject)
      .then(() => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setSelectedValues({});
        setRefetchTable(true);
        setEditButtonvisible(true);
        setAddField(true);
        setViewField(true);
        valuesToForm();

        if (dialog) {
          dialog.setDataChanged(true);
          dialog.handleClose();
        }
      })
      .finally(() => {
        //@TODO loader
      });
  };

  const onAddClick = () => {
    setSelectedValues({});
    setInitValues(defaultValues);
    setViewField(false);
    setAddField(false);
    setstatusList([]);
  };

  return (
    <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white sm:rounded-lg">
      <div className="h-full overflow-auto custom-scrollbar">
        <FormHeaderUI
          addClick={!dialog ? onAddClick : null}
          addDisabled={!viewField || !canEdit}
          modClick={() => {
            setViewField(false);
            setEditButtonvisible(false);
          }}
          modDisabled={!viewField || operatorsDisabled || !canEdit}
        />

        <Formik
          initialValues={initValues}
          validationSchema={validationSchema}
          validateOnChange={false}
          onSubmit={handleSubmit}
          enableReinitialize
          //validateOnMount={false}
        >
          {({ values, setFieldValue, errors, validateField, resetForm }) => (
            <Form>
              <FormFields
                viewField={viewField}
                selectedValues={selectedValues}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                validateField={validateField}
              />
              {/*<FormFields values={{ initValues, setInitValues }} viewField={viewField} submenuId={id} />*/}

              {addField && (
                <FormTabs
                  statusId={selectedValues?.statusId}
                  disabled={viewField}
                  statusList={statusList}
                  setstatusList={setstatusList}
                  setRefetchTable={setRefetchTable}
                  viewField={viewField}
                  selectedValues={selectedValues}
                  dialog={dialog}
                />
              )}

              {(!editButtonVisible || !addField || dialog) && (
                <FormButtonPanelUI
                  onCancelClick={() => {
                    setViewField(true);
                    setEditButtonvisible(true);
                    setAddField(true);
                    resetForm();
                  }}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
