import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _, { first } from 'lodash';
import dayjs from 'dayjs';
import Yup from '../../utils/yup';
import utc from 'dayjs/plugin/utc';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { IconButton } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import useMenus from '../../context/MenuContext';
import masterDataService from '../../service/masterData.service';
import useUsers from '../../context/UsersContext';
import { generateAutocompleteItems } from '../../utils/helper';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI, FormBase, DialogUI } from '../Interface';
import sourcelistService from '../../service/sourcelist.service';
import SourceListFields from './SourceListFields';
import SourceListReports from './SourceListReports';
import SourceListTabs from './SourceListTabs';
import SourceListTree from './SourceListTree';
import useConfig from '../../context/ConfigContext';

const SourceListPage = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const { config, getConfig } = useConfig();
  const [refetchTable, setRefetchTable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [showDialog, setShowDialog] = useState(false);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autocompleteData, setAutoCompleteData] = useState({});
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    quantityUnit: [],
    items: [],
    templateItems: [],
  });

  const def = {
    itemCode: null,
    quantityUnit: null,
    baseQuantity: '',
    item: {},
    sourceListOperation: [],
    sourceListItem: [],
  };

  const defualtOperation = {
    operationId: null,
    dependence: config?.defaultDependence,
    quantityUnitId: config?.defOperationQtyUnit,
    firstResource: null,
    firstResourceNeededQuantity: '',
    firstResourceId: null,
  };

  const defaultItem = {
    itemCode: null,
    neededQuantity: 1,
  };

  useEffect(() => {
    getMenus();
    getConfig();
    if (!Object.keys(user).length) getUser();
    initDefaultValues();

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('item');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .quantityUnitToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .itemsToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .operationToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );
    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceOneToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .itemGroupToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .templateItemsToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          quantityUnit: resp[0],
          items: resp[1],
          operation: resp[2],
          resource: resp[3],
          itemGroup: resp[4],
          templateItems: resp[5],
        };

        setAutoCompleteData(aData);

        const acOptions = {
          quantityUnit: generateAutocompleteItems(aData.quantityUnit, 'name', 'id'),
          items: generateAutocompleteItems(aData.items, ['itemCode', 'itemName'], 'itemCode'),
          operation: generateAutocompleteItems(aData.operation, ['code', 'operationName'], 'operationId'),
          resource: generateAutocompleteItems(aData.resource, 'name', 'id'),
          itemGroup: generateAutocompleteItems(aData.itemGroup, 'name', 'id'),
          templateItems: generateAutocompleteItems(aData.templateItems, 'name', 'id'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const getItemsToForm = () => {
    masterDataService.itemsToForm().then((data) => {
      setAutoCompleteData((prev) => ({ ...prev, items: data }));
      setAutoCompleteOptions((prev) => ({
        ...prev,
        items: generateAutocompleteItems(data, ['itemCode', 'itemName'], 'itemCode'),
      }));
    });
  };

  const validationSchema = Yup.object().shape({
    itemCode: Yup.string().required(),
    baseQuantity: Yup.number().required().positive(),
    sourceListOperation: Yup.array().of(
      Yup.object().shape({
        operationId: Yup.number().required(),
        dependence: Yup.string().nullable(),
        firstResource: Yup.string().nullable(),
        quantityUnitId: Yup.string().required(),
        firstResourceNeededQuantity: Yup.number().positive(),
      })
    ),
    sourceListItem: Yup.array().of(
      Yup.object().shape({
        itemCode: Yup.string().required(),
        neededQuantity: Yup.number().required().positive(),
      })
    ),
  });

  const valuesToForm = () => {
    const { id, itemCode, baseQuantity, item, sourceListOperation, sourceListItem } = selectedValues;

    const withFirstRes = sourceListOperation.map((so) => ({
      ...so,
      firstResource: so.sourceListOperationMachine?.[0]?.resourceId ?? null,
      firstResourceNeededQuantity: so.sourceListOperationMachine?.[0]?.neededQuantity ?? '',
      firstResourceId: so.sourceListOperationMachine?.[0]?.id ?? null,
      quantityUnitId: so.sourceListOperationMachine?.[0]?.quantityUnitId ?? null,
    }));

    setInitValues({
      id,
      itemCode,
      baseQuantity,
      quantityUnitId: item.quantityUnitId,
      item: item,
      sourceListOperation: withFirstRes,
      sourceListItem,
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    sourcelistService.updateSourcelist(updData, selectedValues.id).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    sourcelistService
      .createSourcelist(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setSelectedValues(data);
        setRowSelectionModel(data.id);
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    sourcelistService.deleteSourcelist(deleteData, selectedValues.itemCode).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
      setInitValues(defaultValues);
    });
  };

  const handleImportTemplateItem = (templateItemId, values, setFieldValue) => {
    const newVisOrder = (values.sourceListOperation?.length || 0) + 1;

    const foundedTemplateItemOperations = autocompleteData.templateItems.find(
      (item) => item.id === templateItemId
    )?.templateItemOperation;

    const importedItems = foundedTemplateItemOperations.map((item, index) => ({
      id: uuidv4(),
      visOrder: newVisOrder + index,
      operationId: item.operationId,
      dependence: config?.defaultDependence,
      quantityUnitId: item?.quantityUnitId,
      firstResource: item.operation.resourceOneId,
      firstResourceNeededQuantity: item.neededQty,
    }));

    setFieldValue('sourceListOperation', [...values.sourceListOperation, ...importedItems]);
  };

  return (
    <>
      <FormBase
        name="SourceList"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <div className="flex items-center flex-wrap justify-between">
                <FormHeaderUI
                  addClick={() => {
                    setInitValues(defaultValues);
                    setSelectedValues(defaultValues);
                    setViewField(false);
                    setAddField(false);
                  }}
                  addDisabled={!viewField || !canEdit}
                  modClick={() => {
                    setViewField(false);
                    setEditButtonvisible(false);
                  }}
                  modDisabled={!viewField || operatorsDisabled || !canEdit}
                  deleteClick={() => {
                    setShowDeleteDialog(true);
                  }}
                  deleteDisabled={!viewField || operatorsDisabled || !canEdit}
                  copyClick={() => {
                    setViewField(false);
                    setAddField(false);
                  }}
                  copyDisabled={!viewField || operatorsDisabled || !canEdit}
                />
                <div>
                  <IconButton disabled={operatorsDisabled} onClick={() => setShowDialog(true)}>
                    <AccountTreeIcon sx={{ fontSize: '1.8rem' }} />
                  </IconButton>
                </div>
              </div>

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ resetForm }) => (
                  <Form>
                    <SourceListFields
                      viewField={viewField}
                      selectedValues={selectedValues}
                      autocompleteOptions={autocompleteOptions}
                      autocompleteData={autocompleteData}
                      handleImportTemplateItem={handleImportTemplateItem}
                    />

                    <SourceListTabs
                      disabled={viewField}
                      defaultField={defualtOperation}
                      defaultItem={defaultItem}
                      autocompleteOptions={autocompleteOptions}
                      setRefetchTable={setRefetchTable}
                      selectedValuesState={{ selectedValues, setSelectedValues }}
                      autocompleteData={autocompleteData}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);
                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <SourceListReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            autocompleteOptions={autocompleteOptions}
            getItemsToForm={getItemsToForm}
          />
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
      <DialogUI
        open={showDialog}
        headerContent={'Fa nézet'}
        fullWidth={true}
        onClose={() => setShowDialog(false)}
        closeBtn={true}
        maxWidth="md"
      >
        <SourceListTree itemCode={selectedValues.itemCode} />
      </DialogUI>
    </>
  );
};

export default SourceListPage;
