import React from 'react';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import AssistantIcon from '@mui/icons-material/Assistant';
import BookmarkIcon from '@mui/icons-material/Bookmark';

import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export const Widget4Page = () => {
  const navigate = useNavigate();

  const cards = [
    {
      id: uuidv4(),
      title: 'Projekt feladat tervező',
      icon: <AssistantIcon sx={{ fontSize: '3.5rem' }} />,
      navigate: 'taskListResourcePlanner',
    },
    {
      id: uuidv4(),
      title: 'Projekt',
      icon: <ArrowDropDownCircleIcon sx={{ fontSize: '3.5rem' }} />,
      navigate: 'Project',
    },
    {
      id: uuidv4(),
      title: 'Teendőim',
      icon: <AddBoxIcon sx={{ fontSize: '3.5rem' }} />,
      navigate: 'mytodo',
    },
    {
      id: uuidv4(),
      title: 'Feladat teendők',
      icon: <BookmarkIcon sx={{ fontSize: '3.5rem' }} />,
      navigate: 'taskListTodo',
    },
    {
      id: uuidv4(),
      title: 'Dashboard',
      icon: <AirplaneTicketIcon sx={{ fontSize: '3.5rem' }} />,
      navigate: 'dashboard/1',
    },
  ];

  const handleClick = (navigateTo) => {
    navigate(`/app/${navigateTo}`, { state: { from: 'widget4' } });
  };

  return (
    <div className="bg-gray-100 px-6 pt-5" style={{ minHeight: 'calc(100vh - 65px)' }}>
      <div className="flex gap-5 flex-wrap justify-center items-center">
        {cards.map((card) => (
          <div
            key={card.id}
            className="h-[250px] w-[350px] bg-[#bbf7d04d] text-[#16a34a] cursor-pointer flex flex-col items-center justify-center px-2 text-center hover:bg-[#bbf7d0] transition  duration-300 ease-in-out hover:-translate-y-1 hover:scale-110"
            onClick={() => handleClick(card.navigate)}
          >
            <div className="text-2xl mb-2">{card.title}</div>
            <div className="text-[#16a34a]">{card.icon}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
