import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, CheckboxUI } from '../Interface';

export const CurrencyFields = ({ viewField, selectedValues, addField }) => {
  const { values, errors } = useFormikContext();

  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="code"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Pénznem kódja" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.code} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.code && values.code !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="isoCode"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Pénznem ISO kódja" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.isoCode} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.isoCode && values.isoCode !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Pénznem megnevezése" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name && values.name !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="number"
          name="priceRounding"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Kerekítés - ár" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.priceRounding} />}
          disabled={viewField}
          InputLabelProps={{
            shrink: (values.priceRounding && values.priceRounding !== '') || values.priceRounding === 0,
          }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="number"
          name="amountRounding"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Kerekítés - összeg" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.amountRounding} />}
          disabled={viewField}
          InputLabelProps={{
            shrink: (values.amountRounding && values.amountRounding !== '') || values.amountRounding === 0,
          }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="number"
          name="amountDifference"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Fizetési összeg eltérés" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.amountDifference} />}
          disabled={viewField}
          InputLabelProps={{
            shrink: (values.amountDifference && values.amountDifference !== '') || values.amountDifference === 0,
          }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          name="active"
          type="checkbox"
          as={CheckboxUI}
          fullWidth
          label={<FormLabelUI text="Aktív" />}
          disabled={viewField}
        />
      </div>
    </div>
  );
};

export default CurrencyFields;
