import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Paper,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';

import useUsers from '../../context/UsersContext';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import formPrintTemplateService from '../../service/formPrintTemplate.service';
import { PrintableForms } from '../../config/formsWithPrint';
import { DeleteDialogUI, FileUploadDialogUI } from '../Interface';

const FormPrintTemplate = () => {
  const { getUser, user } = useUsers();
  const [templates, setTemplates] = useState([]);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [selectedFormUrl, setSelectedFormUrl] = useState(null);
  const [removeElement, setRemoveElement] = useState(null);
  const [deleteDialog, setDeletDialog] = useState(false);

  const getTemplates = () => {
    formPrintTemplateService.getTemplates().then((data) => {
      const mapped = PrintableForms.map((pf) => ({
        ...pf,
        template: data.find((d) => d.form.url === pf.url),
      }));
      setTemplates(mapped);
    });
  };

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
    getTemplates();
  }, []);

  const handleEdit = (url) => {
    setUploadDialog(true);
    setSelectedFormUrl(url);
  };

  const handleCloseUploadDialog = () => {
    setUploadDialog(false);
  };

  const handleDownload = (filename) => {
    console.log(`Download file: ${filename}`);
  };

  const handleUpload = (files) => {
    if (!selectedFormUrl) {
      return addNotification({
        content: 'Nincs kiválasztott elem!',
        type: NotificationType.ERROR,
      });
    }

    const uploadPromises = files.map((file) => formPrintTemplateService.uploadFile(file, selectedFormUrl, user.userId));

    Promise.all(uploadPromises)
      .then(() => {
        getTemplates();
      })
      .catch((error) => {
        console.error('Fájl feltöltési hiba:', error);
      });
  };

  const handleDelete = (data) => {
    setRemoveElement(data);
    setDeletDialog(true);
  };

  const hideDeleteDialog = () => {
    setDeletDialog(false);
  };

  const handleRemoveElement = (data) => {
    formPrintTemplateService.deleteFile(removeElement, user.userId).then((data) => {
      hideDeleteDialog();
      getTemplates();
    });
  };

  return (
    <>
      <Box sx={{ bgcolor: 'grey.100', height: '100vh', width: '100vw', position: 'fixed', top: 0 }}>
        <Container maxWidth="md" sx={{ mt: 8 }}>
          <Paper elevation={3} sx={{ py: 5, px: 3, width: '100%', mx: 'auto', borderRadius: 2 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Form</TableCell>
                    <TableCell>Riport</TableCell>
                    <TableCell align="center">Műveletek</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {templates.map((row) => (
                    <TableRow key={row.url}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.template?.name}</TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => handleEdit(row.url)} aria-label="edit">
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDelete(row.url)} aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDownload(row.url)} aria-label="download">
                          <DownloadIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Container>
      </Box>
      <FileUploadDialogUI
        open={uploadDialog}
        handleClose={handleCloseUploadDialog}
        handleUpload={(files) => {
          handleUpload(files);
          handleCloseUploadDialog();
        }}
      />
      <DeleteDialogUI handleRemoveElement={handleRemoveElement} show={deleteDialog} onHide={() => hideDeleteDialog()} />
    </>
  );
};

export default FormPrintTemplate;
