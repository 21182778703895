import React from 'react';
import { Field, useFormikContext } from 'formik';
import { BpTypeList } from '../../config/company';
import { AutoCompleteSelectUI, CheckboxUI, FormErrorUI, FormLabelUI, InputUI, SelectUI, SwitchUI } from '../Interface';

const FormFields = ({ viewField, autocompleteOptions, customerGroups, supplierGroups, autocompleteData }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();

  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-3 mx-4 mb-5">
      <div className="col-span-1">
        <Field
          type="text"
          name="code"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Partner kód" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.code} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.code && values.code !== '' }}
        />
      </div>
      <div className="col-span-2">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Partner neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name && values.name !== '' }}
        />
      </div>
      <div className="col-span-1">
        <Field
          type="text"
          name="shortName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Partner rövid neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.shortName} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.shortName && values.shortName !== '' }}
        />
      </div>
      <div className="col-span-4">
        <Field
          type="text"
          name="supplier"
          as={SwitchUI}
          fullWidth
          label={<FormLabelUI text="Szállító" />}
          checked={values.supplier ?? false}
          onClick={(e) => setFieldValue('supplier', !e.target.checked)}
          disabled={viewField}
        />
        <Field
          type="text"
          name="customer"
          as={SwitchUI}
          fullWidth
          label={<FormLabelUI text="Vevő" />}
          checked={values.customer ?? false}
          onClick={(e) => setFieldValue('customer', !e.target.checked)}
          disabled={viewField}
        />
        <Field
          type="text"
          name="lead"
          as={SwitchUI}
          fullWidth
          label={<FormLabelUI text="Lead" />}
          checked={values.lead ?? false}
          onClick={(e) => setFieldValue('lead', !e.target.checked)}
          disabled={viewField || values.customer}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="supplierGroupId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Szállító csoport" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.supplierGroupId} />}
          onChange={(_e, newVal) => {
            setFieldValue('supplierGroupId', newVal?.value ?? null).then(() => {
              validateField('supplierGroupId');
            });
            const findedItem = autocompleteData.companyGroup.find((i) => i.id === newVal?.value);
            setFieldValue('supplierPriceListId', findedItem?.priceListId ?? null);
            setFieldValue('supplierPaymentMethodId', findedItem?.paymentMethodId ?? null);
            setFieldValue('supplierCurrencyCode', findedItem?.currencyCode ?? null);
          }}
          disabled={viewField || !values.supplier}
          InputLabelProps={{ shrink: values.supplierGroupId !== '' }}
          selectedValue={values.supplierGroupId}
          dataset={supplierGroups}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="customerGroupId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Vevő csoport" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.customerGroupId} />}
          onChange={(_e, newVal) => {
            setFieldValue('customerGroupId', newVal?.value ?? null).then(() => {
              validateField('customerGroupId');
            });
            const findedItem = autocompleteData.companyGroup.find((i) => i.id === newVal?.value);
            setFieldValue('customerPriceListId', findedItem?.priceListId ?? null);
            setFieldValue('customerPaymentMethodId', findedItem?.paymentMethodId ?? null);
            setFieldValue('customerCurrencyCode', findedItem?.currencyCode ?? null);
          }}
          disabled={viewField || !values.customer}
          InputLabelProps={{ shrink: values.customerGroupId !== '' }}
          selectedValue={values.customerGroupId}
          dataset={customerGroups}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          name="bpType"
          component={SelectUI}
          fullWidth
          label={<FormLabelUI text="ÜP típus" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.bpType} />}
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('bpType', value ?? null).then(() => {
              validateField('bpType');
            });
          }}
          value={values.bpType ?? ''}
          disabled={viewField}
          option={BpTypeList}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="languageCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Nyelv" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.languageCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('languageCode', newVal?.value ?? null).then(() => {
              validateField('languageCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.languageCode !== '' }}
          selectedValue={values.languageCode}
          dataset={autocompleteOptions.language}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="website"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Weboldal" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.website} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.website && values.website !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="email"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Email cím" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.email} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.email && values.email !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="phoneNumber"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Telefonszám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.phoneNumber} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.phoneNumber && values.phoneNumber !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="GLN"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="GLN" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.GLN} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.GLN && values.GLN !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="hunTaxNumber"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Magyar adószám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.hunTaxNumber} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.hunTaxNumber && values.hunTaxNumber !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="euTaxNumber"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="EU adószám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.euTaxNumber} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.euTaxNumber && values.euTaxNumber !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="foreignTaxNumber"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Külföldi adószám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.foreignTaxNumber} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.foreignTaxNumber && values.foreignTaxNumber !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="statusId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Státusz" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.statusId} />}
          onChange={(_e, newVal) => {
            setFieldValue('statusId', newVal?.value ?? null).then(() => {
              validateField('statusId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.statusId !== '' }}
          selectedValue={values.statusId}
          dataset={autocompleteOptions.status}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="other"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Egyéb" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.other} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.other && values.other !== '' }}
        />
      </div>
      <div className="md:col-span-1 col-span-4 pt-2">
        <Field
          name="active"
          type="checkbox"
          as={CheckboxUI}
          fullWidth
          label={<FormLabelUI text="Aktív" />}
          disabled={viewField}
        />
      </div>
    </div>
  );
};

export default FormFields;
