export const PriceFormField = {
  NET: 'NET',
  GROSS: 'GROSS',
};

export const PriceFieldType = [
  { label: 'Nettó', value: PriceFormField.NET },
  { label: 'Bruttó', value: PriceFormField.GROSS },
];

export const PriceListDirection = {
  INCOMING: 'INCOMING',
  OUTGOING: 'OUTGOING',
};

export const PriceListDirectionField = [
  { label: 'Kimenő', value: PriceListDirection.OUTGOING },
  { label: 'Bejövő', value: PriceListDirection.INCOMING },
];
