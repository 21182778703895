export const CategoryOptionType = {
  B: 'B',
  K: 'K',
};
export const CategoryOption = [
  { value: CategoryOptionType.B, label: 'Előzetesen felszámított ÁFA' },
  { value: CategoryOptionType.K, label: 'Fizetendő ÁFA' },
];

export const AreaOption = [
  { value: 'BE', label: 'Belföld' },
  { value: 'EU', label: 'EU-n belül' },
  { value: 'EK', label: 'EU-n kívül' },
  { value: 'BEKU', label: 'Belföld/külföld' },
  { value: 'EUEK', label: 'EU-n belül/EU-n kívül' },
];

export const SubjectOption = [
  { value: 'T', label: 'Termék' },
  { value: 'S', label: 'Szolgáltatás' },
  { value: 'TS', label: 'Termék/szolgáltatás' },
];

export const TypeOption = [
  { value: 'Általános', label: 'Általános' },
  { value: 'Adómentes', label: 'Adómentes' },
  { value: 'Belföldi fordított adózás', label: 'Belföldi fordított adózás' },
  { value: 'Különbözet szerinti adózás', label: 'Különbözet szerinti adózás' },
  { value: 'Áfa törvény hatályán kívüli', label: 'Áfa törvény hatályán kívüli' },
];
