import React, { useState, useEffect } from 'react';
import { CheckboxUI } from '../Interface';
import operationService from '../../service/operation.service';

const OperationUserLinks = ({ operationUserLink, selectedValues, disabled, setRefetchTable }) => {
  const [links, setLinks] = useState(operationUserLink);

  useEffect(() => {
    setLinks(operationUserLink);
  }, [operationUserLink]);

  const updateOperationUserLink = (userId, isChecked) => {
    const updatedLinks = links.map((link) => (link.userId === userId ? { ...link, checked: isChecked } : link));
    setLinks(updatedLinks);

    operationService.updateOperationUserLink(selectedValues.operationId, userId).then(() => {
      setRefetchTable(true);
    });
  };

  return (
    <div>
      {links.map((userLink) => (
        <div key={userLink.userId}>
          <CheckboxUI
            sx={{ margin: 0, padding: '0.2rem' }}
            disabled={disabled}
            onChange={(e) => updateOperationUserLink(userLink.userId, e.target.checked)}
            checked={userLink.checked || false}
          />
          {userLink.nickName}
        </div>
      ))}
    </div>
  );
};

export default OperationUserLinks;
