export const ItemGroupType = {
  STOCKARTICLE: 'STOCKARTICLE',
  SERVICE: 'SERVICE',
};

export const ItemGroupTypeList = [
  { label: 'Készletcikk', value: ItemGroupType.STOCKARTICLE },
  { label: 'Szolgáltatás', value: ItemGroupType.SERVICE },
];

export const StockArticleType = {
  MANUFACTUREDPRODUCT: 'MANUFACTUREDPRODUCT',
  COMMERCIALPRODUCT: 'COMMERCIALPRODUCT',
  MATERIAL: 'MATERIAL',
  FOREIGNGOODS: 'FOREIGNGOODS',
};

export const StockArticleTypeList = [
  { label: 'Gyártott termék', value: StockArticleType.MANUFACTUREDPRODUCT },
  { label: 'Kereskedelmi termék', value: StockArticleType.COMMERCIALPRODUCT },
  { label: 'Alapanyag', value: StockArticleType.MATERIAL },
  { label: 'Idegen áru', value: StockArticleType.FOREIGNGOODS },
];
