import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, CheckboxUI } from '../Interface';

export const DeliveryMethodFields = ({ viewField }) => {
  const { values, errors } = useFormikContext();

  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5 items-center">
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Megnevezés" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name && values.name !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="other"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Egyéb" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.other} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.other && values.other !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          name="active"
          type="checkbox"
          as={CheckboxUI}
          fullWidth
          label={<FormLabelUI text="Aktív" />}
          disabled={viewField}
        />
      </div>
    </div>
  );
};
