import React, { useState, useEffect } from 'react';

import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';

import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import { useLoader } from '../../provider/LoaderProvider';
import useUsers from '../../context/UsersContext';
import { DeleteDialogUI, FormBase, FormButtonPanelUI, FormHeaderUI } from '../Interface';
import useMenus from '../../context/MenuContext';
import PlanAbsenceReports from './PlanAbsenceReports';
import PlanAbsenceFields from './PlanAbsenceFields';
import planAbsenceService from '../../service/planAbsence.service';
import _ from 'lodash';
import masterDataService from '../../service/masterData.service';
import { generateAutocompleteItems } from '../../utils/helper';

export const PlanAbsencePage = () => {
  const { getUser, user } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { showLoader, hideLoader } = useLoader();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [submenu, setSubmenu] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    type: [],
    resourceOne: [],
  });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const def = {
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()),
    resourceOneId: null,
    typeId: null,
  };

  useEffect(() => {
    setDefaultValues(def);
    if (!Object.keys(user).length) getUser();
    getMenus();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('planAbsence');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required(),
    endDate: Yup.string().required(),
    resourceOneId: Yup.string().required(),
    typeId: Yup.string().required(),
  });

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          typeId: autocompleteData?.type?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          typeId: autocompleteData?.type?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (submenu) initDefaultValues();
  }, [submenu]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .typeToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceOneToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          type: resp[0],
          resourceOne: resp[1],
        };
        setAutocompleteData(aData);
        const filteredResourceOne = aData.resourceOne?.filter((r1) => r1.classification?.name === 'Felvett');

        const acOptions = {
          type: generateAutocompleteItems(aData.type.typeItems, 'itemName', 'itemId'),
          resourceOne: generateAutocompleteItems(filteredResourceOne, 'name', 'id'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {});
  };

  const valuesToForm = () => {
    const { startDate, endDate, resourceOneId, typeId } = selectedValues;

    setInitValues({
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      resourceOneId,
      typeId,
    });
  };

  const handleInsertObject = (values) => {
    showLoader();
    const insertObject = {
      ...values,
      startDate: dayjs(values.startDate).startOf('day').add(12, 'hours').toISOString(),
      endDate: dayjs(values.endDate).startOf('day').add(12, 'hours').toISOString(),
      createdBy: user.userId,
    };

    planAbsenceService
      .createPlanAbsence(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data);
        setRowSelectionModel(data.id);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      startDate: dayjs(values.startDate).startOf('day').add(12, 'hours').toISOString(),
      endDate: dayjs(values.endDate).startOf('day').add(12, 'hours').toISOString(),
      updatedBy: user.userId,
    };

    planAbsenceService.updatePlanAbsence(updData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    planAbsenceService.deletePlanAbsence(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
    });
  };

  return (
    <>
      <FormBase
        name="PlanAbsence"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                addClick={() => {
                  setSelectedValues(defaultSelectedValues);
                  setViewField(false);
                  setAddField(false);
                }}
                addDisabled={!viewField || !canEdit}
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled || !canEdit}
                deleteClick={() => {
                  setShowDeleteDialog(true);
                }}
                deleteDisabled={!viewField || operatorsDisabled || !canEdit}
                copyClick={() => {
                  setViewField(false);
                  setAddField(false);
                }}
                copyDisabled={!viewField || operatorsDisabled || !canEdit}
              />

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, errors, resetForm, setFieldValue, validateField }) => (
                  <Form>
                    <PlanAbsenceFields
                      values={values}
                      errors={errors}
                      disabled={viewField}
                      setFieldValue={setFieldValue}
                      validateField={validateField}
                      selectedValues={selectedValues}
                      autocompleteOptions={autocompleteOptions}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);
                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <>
            <PlanAbsenceReports
              refetch={{ refetchTable, setRefetchTable }}
              values={{ selectedValues, setSelectedValues }}
              viewField={viewField}
              operatorsDisabled={operatorsDisabled}
              autocompleteOptions={autocompleteOptions}
              rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            />
          </>
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
