import React, { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import _ from 'lodash';
import useSaveView from '../../hooks/useSaveView';
import AutoCompleteSelectUI from '../Interface/AutoCompleteSelectUI';
import TodoCard from './TaskListTodoCard';
import { generateFilter } from '../../utils/helper';
import useView from '../../hooks/useView';
import useUsers from '../../context/UsersContext';
import { DebouncedInputUI, FormLabelUI } from '../Interface';
import taskListService from '../../service/taskList.service';

const TaskListTodoContent = ({ activeTab, type, tabId, autoCompleteOptions }) => {
  const { getUser, user } = useUsers();
  const saveView = useSaveView(user);
  const taskListTodoFilter = useView('taskListTodoFilter', user);

  const [taskListTodo, setTaskListTodo] = useState([]);
  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 20,
        page: 0,
      },
    },
  });
  const [formVal, setFormVal] = useState({
    statusId: [],
    name: '',
  });

  const [defaultFilterFields, setDefaultFilterFields] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const conditions = {
    like: ['name'],
  };

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
  }, []);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      const newFields = [
        {
          field: 'deletedBy',
          value: null,
        },
      ];
      if (type === 'own') {
        newFields.push({ field: 'responsiblePersonId', value: user?.userId });
      } else if (type === 'opened') {
        newFields.push({ field: 'responsiblePersonId', value: null });
        //@TODO telephely, szerep stb majd itt kell kezelni
      }

      setDefaultFilterFields(newFields);
    }
  }, [activeTab, user]);

  useEffect(() => {
    if (defaultFilterFields.length > 0) {
      //@TODO átnézni
      const newStatus = formVal.statusId.map((sIds) => sIds.value);
      const newFormVal = { ...formVal, statusId: newStatus };
      const newFilter = generateFilter(newFormVal, conditions);
      if (!_.isEqual(query.filter, [...defaultFilterFields, ...newFilter])) {
        setTaskListTodo([]);

        setQuery((prev) => ({
          ...prev,
          filter: [...defaultFilterFields, ...newFilter],
          pagination: {
            ...prev.pagination,
            paginationModel: { ...prev.pagination.paginationModel, page: 0 },
          },
        }));
      }
    }
  }, [formVal, defaultFilterFields]);

  useEffect(() => {
    if (Object.keys(taskListTodoFilter).length > 0) {
      setFormVal({
        statusId: taskListTodoFilter.status || [],
      });
    }
  }, [taskListTodoFilter]);

  const getList = useCallback(() => {
    taskListService
      .getTaskList(query)
      .then((data) => {
        if (query.pagination.paginationModel.page === 0) {
          setTaskListTodo(data.rows);
        } else {
          setTaskListTodo((prev) => [...prev, ...data.rows]);
        }
        setHasMore(taskListTodo.length + data.rows.length < data.rowCount);
      })
      .finally(() => {});
  }, [query]);

  useEffect(() => {
    if (activeTab === tabId && query.filter.length > 0) {
      getList();
    }
  }, [query, activeTab, tabId, getList]);

  const fetchMoreData = () => {
    setQuery((prev) => ({
      ...prev,
      pagination: {
        paginationModel: {
          ...prev.pagination.paginationModel,
          page: prev.pagination.paginationModel.page + 1,
        },
      },
    }));
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-4 mx-4 md:w-3/4 lg:1/2 mx-auto">
        <div className="col-span-4 md:col-span-1">
          <DebouncedInputUI
            label={<FormLabelUI text="Keresés" />}
            debounceMs={800}
            setQuickFilterSearchValue={(newValue) => {
              setFormVal((prevState) => ({
                ...prevState,
                name: newValue,
              }));
            }}
            quickFilterSearchValue={formVal.name}
          />
        </div>
        <div className="col-span-4 md:col-span-1">
          <AutoCompleteSelectUI
            label={<FormLabelUI text="Státusz" />}
            onChange={(_event, newVal, _reason) => {
              setFormVal((prev) => ({
                ...prev,
                statusId: newVal,
              }));
              saveView('taskListTodoFilter', { status: newVal });
            }}
            selectedValue={formVal.statusId}
            disabled={false}
            freeSolo
            isOptionEqualToValue={(option, value) => option.value === value.value}
            multiple
            dataset={autoCompleteOptions.status}
          />
        </div>
      </div>
      <InfiniteScroll
        dataLength={taskListTodo.length}
        next={fetchMoreData}
        hasMore={hasMore}
        endMessage={
          <div className="flex justify-center">
            <p className="text-xl">Az összes feladatot megtekintette.</p>
          </div>
        }
      >
        <div className="py-10 flex flex-wrap gap-7 justify-center">
          {taskListTodo.map((task, id) => {
            return (
              <TodoCard
                key={id}
                task={task}
                setTaskListTodo={setTaskListTodo}
                autoCompleteOptions={autoCompleteOptions}
                user={user}
              />
            );
          })}
        </div>
      </InfiniteScroll>
    </>
  );
};

export default TaskListTodoContent;
