import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'operationGroup';

const operationGroupService = {
  getOperationGroupLinkRow: (operationGroupId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/operationGroupLinkRow/${operationGroupId}`,
    });
  },
  getRows: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/rows`,
      data,
    });
  },

  updateData: (data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}`,
      data,
    });
  },

  createData: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  deleteData: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  updateOperationGroupLink: (operationId, operationGroupId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/operationGroupLink/${operationId}/${operationGroupId}`,
    });
  },

  updateOperationGroupUserLink: (operationGroupId, userId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/operationGroupUserLink/${operationGroupId}/${userId}`,
    });
  },
};
export default operationGroupService;
