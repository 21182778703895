import { useState, useEffect } from 'react';
import _, { isEqual } from 'lodash';
import { Formik, Form } from 'formik';
import Yup from '../../utils/yup';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import companyService from '../../service/company.service';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import useMenus from '../../context/MenuContext';
import useParam from '../../context/ParamContext';
import useUsers from '../../context/UsersContext';
import { getDynVals } from '../../utils/DynHelper';

import FormTabs from './FormTabs';
import CompanyReports from './CompanyReports';
import FormFields from './FormFields';
import { FormHeaderUI, FormButtonPanelUI, DeleteDialogUI, FormBase } from '../Interface';
import masterDataService from '../../service/masterData.service';
import { generateAutocompleteItems } from '../../utils/helper';
import { BpType } from '../../config/company';
import { GroupType } from '../../config/companyGroup';
import { CategoryOptionType } from '../../config/vatCodes';

export const CompanyPage = () => {
  const { user, getUser } = useUsers();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getParam, params } = useParam();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [dynProps, setDynProps] = useState([]);

  const [submenu, setSubmenu] = useState(null);
  const [refetchTable, setRefetchTable] = useState(false);
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [selectedValues, setSelectedValues] = useState({});
  const [initValues, setInitValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [canEdit, setCanEdit] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [autocompleteData, setAutocompleteData] = useState({});
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    status: [],
    companyGroup: [],
    language: [],
    priceList: [],
    paymentMethod: [],
    currency: [],
    vatCode: [],
    incoterm: [],
    salesOrPurchasePerson: [],
    deliveryMethod: [],
    country: [],
  });
  const [loading, setLoading] = useState(true);
  const [dynLoading, setDynLoading] = useState(true);
  const isLoading = loading || dynLoading;

  const defaultContact = {
    id: null,
    isNew: true,
    name: '',
    role: '',
    phoneNumber: '',
    email: '',
    phoneNumber2: '',
    comment: '',
    active: true,
    other: '',
    default: false,
    createdBy: user.userId,
  };

  const defaultAddress = {
    id: null,
    isNew: true,
    name: '',
    adressType: '',
    countryCode: '',
    province: '',
    postCode: '',
    city: '',
    streetAddress: '',
    GLN: '',
    active: true,
    other: '',
    default: false,
    createdBy: user.userId,
  };

  const def = {
    code: '',
    name: '',
    shortName: '',
    supplier: false,
    customer: false,
    lead: false,
    supplierGroupId: null,
    customerGroupId: null,
    bpType: BpType.COMPANY,
    languageCode: '',
    active: true,
    website: '',
    email: '',
    phoneNumber: '',
    GLN: '',
    hunTaxNumber: '',
    euTaxNumber: '',
    description: '',
    statusId: null,
    supplierPriceListId: null,
    supplierPaymentMethodId: null,
    supplierCurrencyCode: '',
    supplierVatCode: '',
    supplierDeliveryMethodId: null,
    supplierIncotermCode: '',
    purchasePersonId: null,
    customerPriceListId: null,
    customerPaymentMethodId: null,
    customerCurrencyCode: '',
    customerVatCode: '',
    customerDeliveryMethodId: null,
    customerIncotermCode: '',
    salesPersonId: null,
    contact: [],
    address: [],
  };

  useEffect(() => {
    getMenus();
    getParam();
    if (!Object.keys(user).length) getUser();

    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          statusId: autocompleteData?.status?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          status: autocompleteData?.status?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    if (submenu) {
      initDefaultValues();
    }
  }, [submenu]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('company');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .companyGroupToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .languageToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .priceListToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .paymentMethodToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .currencyToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .vatCodeToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .incotermToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .salesOrPurchasePersonToForm()
          .then((data) => {
            resolve(data.filter((d) => !d.deletedBy));
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .deliveryMethodToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .countryToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          status: resp[0],
          companyGroup: resp[1],
          language: resp[2],
          priceList: resp[3],
          paymentMethod: resp[4],
          currency: resp[5],
          vatCode: resp[6],
          incoterm: resp[7],
          salesOrPurchasePerson: resp[8],
          deliveryMethod: resp[9],
          country: resp[10],
        };
        setAutocompleteData(aData);

        const acOptions = {
          status: generateAutocompleteItems(aData.status.statusItems, 'itemName', 'itemId'),
          companyGroup: generateAutocompleteItems(aData.companyGroup, 'name', 'id'),
          language: generateAutocompleteItems(aData.language, 'name', 'code'),
          priceList: generateAutocompleteItems(aData.priceList, 'name', 'id'),
          paymentMethod: generateAutocompleteItems(aData.paymentMethod, 'name', 'id'),
          currency: generateAutocompleteItems(aData.currency, 'code', 'code'),
          vatCode: generateAutocompleteItems(aData.vatCode, 'code', 'code'),
          incoterm: generateAutocompleteItems(aData.incoterm, 'name', 'code'),
          salesOrPurchasePerson: generateAutocompleteItems(aData.salesOrPurchasePerson, 'name', 'id'),
          deliveryMethod: generateAutocompleteItems(aData.deliveryMethod, 'name', 'id'),
          country: generateAutocompleteItems(aData.country, 'name', 'code'),
        };
        setAutoCompleteOptions(acOptions);
        setLoading(false);
      })
      .finally(() => {
        //hideLoader();
      });
  };
  const validationSchema = Yup.object().shape({
    code: Yup.string().nullable(),
    name: Yup.string().required(),
    shortName: Yup.string().nullable(),
    supplier: Yup.boolean(),
    customer: Yup.boolean(),
    lead: Yup.boolean(),
    supplierGroupId: Yup.string()
      .when('supplier', {
        is: (supplier) => supplier,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      })
      .nullable(),
    customerGroupId: Yup.string()
      .when('customer', {
        is: (customer) => customer,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema,
      })
      .nullable(),
    bpType: Yup.string().required(),
    languageCode: Yup.string().required(),
    active: Yup.boolean(),
    website: Yup.string().nullable(),
    email: Yup.string().nullable(),
    phoneNumber: Yup.string().nullable(),
    GLN: Yup.string().nullable(),
    hunTaxNumber: Yup.string().nullable(),
    euTaxNumber: Yup.string().nullable(),
    description: Yup.string().nullable(),
    statusId: Yup.string().nullable(),
    supplierPriceListId: Yup.string().nullable(),
    supplierPaymentMethodId: Yup.string().nullable(),
    supplierCurrencyCode: Yup.string().nullable(),
    supplierVatCode: Yup.string().nullable(),
    supplierDeliveryMethodId: Yup.string().nullable(),
    supplierIncotermCode: Yup.string().nullable(),
    purchasePersonId: Yup.string().nullable(),
    customerPriceListId: Yup.string().nullable(),
    customerPaymentMethodId: Yup.string().nullable(),
    customerCurrencyCode: Yup.string().nullable(),
    customerVatCode: Yup.string().nullable(),
    customerDeliveryMethodId: Yup.string().nullable(),
    customerIncotermCode: Yup.string().nullable(),
    salesPersonId: Yup.string().nullable(),
    contact: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(),
        role: Yup.string().required(),
        phoneNumber: Yup.string().nullable(),
        email: Yup.string().nullable(),
        phoneNumber2: Yup.string().nullable(),
        comment: Yup.string().nullable(),
        active: Yup.boolean(),
        other: Yup.string().nullable(),
        default: Yup.boolean(),
      })
    ),
    address: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(),
        adressType: Yup.string().required(),
        countryCode: Yup.string().required(),
        province: Yup.string().nullable(),
        postCode: Yup.string().required(),
        city: Yup.string().required(),
        streetAddress: Yup.string().required(),
        GLN: Yup.string().nullable(),
        active: Yup.boolean(),
        other: Yup.string().nullable(),
        default: Yup.boolean(),
      })
    ),
  });

  const valuesToForm = () => {
    const {
      code,
      name,
      shortName,
      supplier,
      customer,
      lead,
      supplierGroupId,
      customerGroupId,
      bpType,
      languageCode,
      active,
      website,
      email,
      phoneNumber,
      GLN,
      hunTaxNumber,
      euTaxNumber,
      foreignTaxNumber,
      other,
      description,
      statusId,
      supplierPriceListId,
      supplierPaymentMethodId,
      supplierCurrencyCode,
      supplierVatCode,
      supplierDeliveryMethodId,
      supplierIncotermCode,
      purchasePersonId,
      customerPriceListId,
      customerPaymentMethodId,
      customerCurrencyCode,
      customerVatCode,
      customerDeliveryMethodId,
      customerIncotermCode,
      salesPersonId,
      companyAdress,
      companyContact,
    } = selectedValues;

    setInitValues({
      code,
      name,
      shortName,
      supplier,
      customer,
      lead,
      supplierGroupId,
      customerGroupId,
      bpType,
      languageCode,
      active,
      website,
      email,
      phoneNumber,
      GLN,
      hunTaxNumber,
      euTaxNumber,
      foreignTaxNumber,
      other,
      description,
      statusId,
      supplierPriceListId,
      supplierPaymentMethodId,
      supplierCurrencyCode,
      supplierVatCode,
      supplierDeliveryMethodId,
      supplierIncotermCode,
      purchasePersonId,
      customerPriceListId,
      customerPaymentMethodId,
      customerCurrencyCode,
      customerVatCode,
      customerDeliveryMethodId,
      customerIncotermCode,
      salesPersonId,
      dynamicFields: getDynVals(dynProps, selectedValues.dynamicProperties),
      contact: companyContact?.map((pc) => ({
        id: pc.id,
        name: pc.name,
        role: pc.role,
        phoneNumber: pc.phoneNumber,
        email: pc.email,
        phoneNumber2: pc.phoneNumber2,
        comment: pc.comment,
        active: pc.active,
        other: pc.other,
        default: pc.default,
      })),

      address: companyAdress?.map((pc) => ({
        id: pc.id,
        name: pc.name,
        adressType: pc.adressType,
        countryCode: pc.countryCode,
        province: pc.province,
        postCode: pc.postCode,
        city: pc.city,
        streetAddress: pc.streetAddress,
        GLN: pc.GLN,
        active: pc.active,
        other: pc.other,
        default: pc.default,
      })),
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    companyService.updateCompany(updData, selectedValues.id).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    companyService
      .createCompany(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
        setSelectedValues(data);

        setRowSelectionModel(data.id);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    companyService.deleteCompany(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultValues);
    });
  };

  const handleTemplate = () => {
    companyService.getExcelTemplate().then(() => {
      addNotification({
        content: 'A letöltés elindult!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const handleExport = () => {
    companyService.getExcelData().then(() => {
      addNotification({
        content: 'A letöltés elindult!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const handleImport = (files) => {
    companyService.importExcel(files, user.userId).then(() => {
      setRefetchTable(true);
      addNotification({
        content: 'Az import sikeres!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const customerGroups = generateAutocompleteItems(
    autocompleteData.companyGroup?.filter((group) => group.groupType === GroupType.CUSTOMERGROUP),
    'name',
    'id'
  );
  const supplierGroups = generateAutocompleteItems(
    autocompleteData.companyGroup?.filter((group) => group.groupType === GroupType.SUPPLIERGROUP),
    'name',
    'id'
  );

  const customerVatCode = generateAutocompleteItems(
    autocompleteData.vatCode?.filter((v) => v.category === CategoryOptionType.K),
    'code',
    'code'
  );
  const supplierVatCode = generateAutocompleteItems(
    autocompleteData.vatCode?.filter((v) => v.category === CategoryOptionType.B),
    'code',
    'code'
  );

  const salesPerson = generateAutocompleteItems(
    autocompleteData.salesOrPurchasePerson?.filter((s) => s.salesPerson === true),
    'name',
    'id'
  );
  const purchasePerson = generateAutocompleteItems(
    autocompleteData.salesOrPurchasePerson?.filter((p) => p.purchaser === true),
    'name',
    'id'
  );

  const validate = (values) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false });
      return {};
    } catch (err) {
      const errors = err.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        return acc;
      }, {});

      const hasAddressError = err.inner.some((e) => e.path.startsWith('address'));
      const hasContactError = err.inner.some((e) => e.path.startsWith('contact'));

      const onlyAddressAndContactErrors = err.inner.every(
        (e) => e.path.startsWith('address') || e.path.startsWith('contact')
      );

      if (onlyAddressAndContactErrors) {
        if (hasAddressError) {
          addNotification({
            content: 'Kitöltetlen kötelező mezők az üzleti partner címekben!',
            type: NotificationType.ERROR,
          });
        }

        if (hasContactError) {
          addNotification({
            content: 'Kitöltetlen kötelező mezők az üzleti partner kapcsolattartókban!',
            type: NotificationType.ERROR,
          });
        }
      }

      return errors;
    }
  };

  return (
    <>
      <FormBase
        name="Company"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                addClick={() => {
                  setSelectedValues(defaultSelectedValues);
                  setViewField(false);
                  setAddField(false);
                }}
                addDisabled={!viewField || !canEdit}
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled || !canEdit}
                deleteClick={() => {
                  setShowDeleteDialog(true);
                }}
                deleteDisabled={!viewField || operatorsDisabled || !canEdit}
                copyClick={() => {
                  setViewField(false);
                  setAddField(false);
                }}
                copyDisabled={!viewField || operatorsDisabled || !canEdit}
              />

              <Formik
                validate={validate}
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
                validateOnBlur={false}
                validateOnMount={false}
              >
                {({ values, errors, resetForm, setFieldValue, validateField }) => (
                  <Form>
                    <FormFields
                      viewField={viewField}
                      selectedValues={selectedValues}
                      autocompleteOptions={autocompleteOptions}
                      customerGroups={customerGroups}
                      supplierGroups={supplierGroups}
                      autocompleteData={autocompleteData}
                    />

                    <FormTabs
                      values={values}
                      errors={errors}
                      disabled={viewField}
                      setFieldValue={setFieldValue}
                      selectedValues={selectedValues}
                      validateField={validateField}
                      submenuId={submenu}
                      params={params}
                      user={user}
                      setRefetchTable={setRefetchTable}
                      setDefaultValues={setDefaultValues}
                      dynProperties={{ dynProps, setDynProps }}
                      defaultContact={defaultContact}
                      defaultAddress={defaultAddress}
                      dynLoadingState={{ dynLoading, setDynLoading }}
                      setSelectedValues={setSelectedValues}
                      handleImport={handleImport}
                      handleExport={handleExport}
                      handleTemplate={handleTemplate}
                      autocompleteOptions={autocompleteOptions}
                      customerVatCode={customerVatCode}
                      supplierVatCode={supplierVatCode}
                      salesPerson={salesPerson}
                      purchasePerson={purchasePerson}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);
                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <>
            {!isLoading && (
              <CompanyReports
                refetch={{ refetchTable, setRefetchTable }}
                values={{ selectedValues, setSelectedValues }}
                viewField={viewField}
                operatorsDisabled={operatorsDisabled}
                rowSelection={{ rowSelectionModel, setRowSelectionModel }}
                dynProps={dynProps}
                autocompleteOptions={autocompleteOptions}
                customerGroups={customerGroups}
                supplierGroups={supplierGroups}
                customerVatCode={customerVatCode}
                supplierVatCode={supplierVatCode}
                salesPerson={salesPerson}
                purchasePerson={purchasePerson}
              />
            )}
          </>
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
