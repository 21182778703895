import { useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid-premium';
import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';

import { dataGridPremiumColumns } from '../../../utils/helper';
import {
  DataGridIdentifiers,
  FormErrorUI,
  AddButtonUi,
  AutoCompleteSelectUI,
  DatagridPremiumUi,
  InputUI,
} from '../../Interface';
import { formatNumber } from '../../../utils/currencyHelper';

const EditToolbar = (props) => {
  const { setRowModesModel, disabled, push, defaultItem } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    defaultItem.id = uuidv4();
    await push(defaultItem);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultItem.id]: { mode: GridRowModes.Edit },
    }));

    const fieldsToValidate = [
      `productionItem.${values.productionItem.length ?? 0}.itemCode`,
      `productionItem.${values.productionItem.length ?? 0}.quantity`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
    }
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
};

const ProductionItem = ({ disabled, defaultItem, autocompleteOptions, selectedValues, calcItemBaseRate }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();
  const [lastChangedIndex, setLastChangedIndex] = useState(null);

  const [rowModesModel, setRowModesModel] = useState({});

  useEffect(() => {
    if (lastChangedIndex !== null) {
      calcItemBaseRate(lastChangedIndex);
    }
  }, [values?.productionItem?.[lastChangedIndex]?.quantity, lastChangedIndex]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.productionItem.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    const fieldsToValidate = [`productionItem.${valueIndex}.itemCode`, `productionItem.${valueIndex}.quantity`];

    for (const field of fieldsToValidate) {
      await validateField(field);

      if (errors?.productionItem?.[valueIndex]?.[field.split('.').pop()]) {
        return;
      }
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridPremiumColumns([
      {
        field: 'itemCode',
        headerName: 'Cikk',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions.items.find(
            (u) => u.value === values.productionItem?.[valueIndex]?.itemCode
          );

          return findedItem?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions.items.find(
            (u) => u.value === values.productionItem?.[valueIndex]?.itemCode
          );
          return (
            <Field
              name={`productionItem.${valueIndex}.itemCode`}
              type="text"
              variant="standard"
              as={AutoCompleteSelectUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.productionItem?.[valueIndex]?.itemCode} />}
              onChange={(_e, newVal) => {
                setFieldValue(`productionItem.${valueIndex}.itemCode`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              onBlur={async () => await validateField(`productionItem.${valueIndex}.itemCode`)}
              value={findedItem}
              isOptionEqualToValue={(option, value) => option.value === value}
              dataset={autocompleteOptions.items}
            />
          );
        },
      },
      {
        field: 'baseRate',
        headerName: 'Alaphányad',
        type: 'string',
        sortable: false,
        renderCell: (data) => formatNumber(data.row.baseRate),
      },
      {
        field: 'quantity',
        headerName: 'Mennyiség',
        type: 'string',
        editable: true,
        sortable: false,
        valueGetter: (data, row) => {
          const valueIndex = getValueIndex(values, row.id);
          return values?.productionItem?.[valueIndex]?.quantity;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`productionItem.${valueIndex}.quantity`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.productionItem?.[valueIndex]?.quantity} />}
              onChange={(e) => {
                setFieldValue(`productionItem.${valueIndex}.quantity`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
                setLastChangedIndex(valueIndex);
              }}
              onBlur={async () => await validateField(`productionItem.${valueIndex}.quantity`)}
            />
          );
        },
      },
      {
        field: 'qtyunit',
        headerName: 'Me.',
        type: 'string',
        valueGetter: (data, row) => {
          const itemData = selectedValues?.productionItem?.find((i) => i.itemCode === row.itemCode);

          return itemData?.item?.quantityUnit?.name;
        },
      },
      {
        field: 'description',
        headerName: 'Megjegyzés',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`productionItem.${valueIndex}.description`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.productionItem?.[valueIndex]?.description} />}
              onChange={(e) => {
                setFieldValue(`productionItem.${valueIndex}.description`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`productionItem.${valueIndex}.description`)}
            />
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <FieldArray name="productionItem">
            {({ remove, push }) => (
              <DatagridPremiumUi
                datagridIdentifier={DataGridIdentifiers.ProductionItem}
                height={480}
                columns={columns(setFieldValue, validateField, errors, remove)}
                rows={values?.productionItem ?? []}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultItem,
                    setRowModesModel,
                  },
                }}
                onCellDoubleClick={(_params, event) => {
                  event.defaultMuiPrevented = true;
                }}
                headerFilters={false}
                getRowHeight={() => 'auto'}
              />
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default ProductionItem;
