import { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { GridRowModes, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from '@mui/x-data-grid';
import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { multiply, subtract, round } from 'mathjs';

import { dataGridColumns } from '../../utils/helper';

import { DataGridUI, DataGridIdentifiers, FormErrorUI, InputUI, AutoCompleteSelectUI, AddButtonUi } from '../Interface';

function EditToolbar(props) {
  const { setRowModesModel, disabled, push, defaultField } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    defaultField.id = uuidv4();
    await push(defaultField);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultField.id]: { mode: GridRowModes.Edit },
    }));

    const fieldsToValidate = [
      `documentLine.${values.documentLine.length ?? 0}.itemCode`,
      `documentLine.${values.documentLine.length ?? 0}.qty`,
      `documentLine.${values.documentLine.length ?? 0}.qtyUnit`,
      `documentLine.${values.documentLine.length ?? 0}.netUnitPrice`,
      `documentLine.${values.documentLine.length ?? 0}.grossUnitPrice`,
      `documentLine.${values.documentLine.length ?? 0}.vat`,
      `documentLine.${values.documentLine.length ?? 0}.warehouseCode`,
      `documentLine.${values.documentLine.length ?? 0}.comment`,
      `documentLine.${values.documentLine.length ?? 0}.projectId`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
    }
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
}

const DocumentLine = ({ disabled, defaultField, autocompleteOptions, autocompleteData, documentData }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();

  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.documentLine.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    const fieldsToValidate = [
      `documentLine.${valueIndex}.itemCode`,
      `documentLine.${valueIndex}.qty`,
      `documentLine.${valueIndex}.qtyUnit`,
      `documentLine.${valueIndex}.netUnitPrice`,
      `documentLine.${valueIndex}.grossUnitPrice`,
      `documentLine.${valueIndex}.vat`,
      `documentLine.${valueIndex}.warehouseCode`,
      `documentLine.${valueIndex}.comment`,
      `documentLine.${valueIndex}.projectId`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
      if (errors?.documentLine?.[valueIndex]?.[field.split('.').pop()]) {
        return;
      }
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridColumns([
      {
        field: 'itemCode',
        headerName: 'Cikk',
        editable: true,
        sortable: false,
        flex: 1,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions.item.find(
            (u) => u.value === values.documentLine?.[valueIndex]?.itemCode
          );

          return findedItem?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions.item.find(
            (u) => u.value === values.documentLine?.[valueIndex]?.itemCode
          );
          return (
            <Field
              name={`documentLine.${valueIndex}.itemCode`}
              component={AutoCompleteSelectUI}
              helperText={<FormErrorUI message={errors?.documentLine?.[valueIndex]?.itemCode} />}
              selectedValue={values.documentLine?.[valueIndex]?.itemCode}
              value={findedItem}
              onChange={(_e, newVal) => {
                setFieldValue(`documentLine.${valueIndex}.itemCode`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });

                const selectedItem = autocompleteData.item.find((i) => i.itemCode === newVal.value);
                if (selectedItem) {
                  setFieldValue(`documentLine.${valueIndex}.qtyUnit`, selectedItem?.quantityUnitId ?? null);
                  data.api.setEditCellValue({
                    id: data.id,
                    field: 'qtyUnit',
                    value: selectedItem?.quantityUnitId ?? null,
                  });
                }
              }}
              dataset={autocompleteOptions.item}
              onBlur={async () => await validateField(`documentLine.${valueIndex}.itemCode`)}
            />
          );
        },
      },
      {
        field: 'qty',
        headerName: 'Mennyiség',
        flex: 0.5,
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`documentLine.${valueIndex}.qty`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.documentLine?.[valueIndex]?.qty} />}
              onChange={(e) => {
                setFieldValue(`documentLine.${valueIndex}.qty`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`documentLine.${valueIndex}.qty`)}
            />
          );
        },
      },
      {
        field: 'qtyUnit',
        headerName: 'ME',
        editable: false,
        sortable: false,
        flex: 0.5,
        renderCell: (data) => {
          const findedQtyUnit = autocompleteOptions?.qtyUnit.find((item) => item.value === data.row?.qtyUnit);
          return findedQtyUnit?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedQtyUnit = autocompleteOptions.qtyUnit.find((item) => item.value === data.row.qtyUnit);

          return (
            <Field
              name={`documentLine.${valueIndex}.qtyUnit`}
              component={AutoCompleteSelectUI}
              disabled
              helperText={<FormErrorUI message={errors?.documentLine?.[valueIndex]?.qtyUnit} />}
              selectedValue={values.documentLine?.[valueIndex]?.qtyUnit}
              selectedLabelValue={findedQtyUnit?.label}
              onChange={(_e, newVal) => {
                setFieldValue(`documentLine.${valueIndex}.qtyUnit`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal.value ?? null,
                });
              }}
              dataset={autocompleteOptions.qtyUnit}
              onBlur={async () => await validateField(`documentLine.${valueIndex}.qtyUnit`)}
            />
          );
        },
      },
      {
        field: 'netUnitPrice',
        headerName: 'Nettó egységár',
        flex: 1,
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const selectedItem = autocompleteData.item.find((item) => item.itemCode === data.row.itemCode);
          const vatString =
            selectedItem && selectedItem.vatId
              ? autocompleteData.vat.find((vat) => vat.id === selectedItem.vatId).vat
              : null;
          const vatValue = vatString ? parseFloat(vatString) : null;

          return (
            <Field
              name={`documentLine.${valueIndex}.netUnitPrice`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.documentLine?.[valueIndex]?.netUnitPrice} />}
              onChange={(e) => {
                const netPrice = e.target.value;
                let grossPrice = null,
                  vatAmount = null;

                if (vatValue !== null) {
                  grossPrice = multiply(netPrice, vatValue);
                  vatAmount = subtract(grossPrice, netPrice);

                  grossPrice = round(grossPrice);
                  vatAmount = round(vatAmount);
                }

                setFieldValue(`documentLine.${valueIndex}.netUnitPrice`, netPrice ?? '');
                setFieldValue(`documentLine.${valueIndex}.grossUnitPrice`, grossPrice ?? '');
                setFieldValue(`documentLine.${valueIndex}.vat`, vatAmount ?? '');

                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: netPrice,
                });
                data.api.setEditCellValue({
                  id: data.id,
                  field: 'grossUnitPrice',
                  value: grossPrice,
                });
                data.api.setEditCellValue({
                  id: data.id,
                  field: 'vat',
                  value: vatAmount,
                });

                validateField(`documentLine.${valueIndex}.grossUnitPrice`);
                validateField(`documentLine.${valueIndex}.vat`);
              }}
              onBlur={async () => await validateField(`documentLine.${valueIndex}.netUnitPrice`)}
            />
          );
        },
      },

      {
        field: 'vat',
        headerName: 'Áfa',
        flex: 0.5,
        editable: false,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          return (
            <Field
              name={`documentLine.${valueIndex}.vat`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              disabled
              helperText={<FormErrorUI message={errors?.documentLine?.[valueIndex]?.vat} />}
            />
          );
        },
      },
      {
        field: 'grossUnitPrice',
        headerName: 'Bruttó egységár',
        flex: 1,
        editable: false,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`documentLine.${valueIndex}.grossUnitPrice`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              disabled
              helperText={<FormErrorUI message={errors?.documentLine?.[valueIndex]?.grossUnitPrice} />}
            />
          );
        },
      },
      ...(documentData?.warehouseManagement
        ? [
            {
              field: 'warehouseCode',
              headerName: 'Raktár',
              editable: true,
              sortable: false,
              flex: 1,
              renderCell: (data) => {
                const valueIndex = getValueIndex(values, data.id);
                const findedItem = autocompleteOptions.warehouse.find(
                  (u) => u.value === values.documentLine?.[valueIndex]?.warehouseCode
                );

                return findedItem?.label;
              },
              renderEditCell: (data) => {
                const valueIndex = getValueIndex(values, data.id);
                const findedItem = autocompleteOptions.warehouse.find(
                  (u) => u.value === values.documentLine?.[valueIndex]?.warehouseCode
                );
                return (
                  <Field
                    name={`documentLine.${valueIndex}.warehouseCode`}
                    component={AutoCompleteSelectUI}
                    helperText={<FormErrorUI message={errors?.documentLine?.[valueIndex]?.warehouseCode} />}
                    selectedValue={values.documentLine?.[valueIndex]?.warehouseCode}
                    value={findedItem}
                    onChange={(_e, newVal) => {
                      setFieldValue(`documentLine.${valueIndex}.warehouseCode`, newVal?.value ?? null);
                      data.api.setEditCellValue({
                        id: data.id,
                        field: data.field,
                        value: newVal?.value ?? null,
                      });
                    }}
                    dataset={autocompleteOptions.warehouse}
                    onBlur={async () => await validateField(`documentLine.${valueIndex}.warehouseCode`)}
                  />
                );
              },
            },
          ]
        : []),
      {
        field: 'comment',
        headerName: 'Megjegyzés',
        flex: 1,
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`documentLine.${valueIndex}.comment`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.documentLine?.[valueIndex]?.comment} />}
              onChange={(e) => {
                setFieldValue(`documentLine.${valueIndex}.comment`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`documentLine.${valueIndex}.comment`)}
            />
          );
        },
      },
      {
        field: 'projectId',
        headerName: 'Projekt',
        editable: true,
        sortable: false,
        flex: 1,
        renderCell: (data) => {
          const findedProject = autocompleteOptions?.project.find((item) => item.value === data.row?.projectId);
          return findedProject?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedProject = autocompleteOptions.project.find((item) => item.value === data.row.projectId);

          return (
            <Field
              name={`documentLine.${valueIndex}.projectId`}
              component={AutoCompleteSelectUI}
              helperText={<FormErrorUI message={errors?.documentLine?.[valueIndex]?.projectId} />}
              selectedValue={values.documentLine?.[valueIndex]?.projectId}
              selectedLabelValue={findedProject?.label}
              onChange={(_e, newVal) => {
                setFieldValue(`documentLine.${valueIndex}.projectId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal.value ?? null,
                });
              }}
              dataset={autocompleteOptions.project}
              onBlur={async () => await validateField(`documentLine.${valueIndex}.projectId`)}
            />
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        flex: 0.25,
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => {
                  const valueIndex = getValueIndex(values, id);
                  return remove(valueIndex);
                }}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <FieldArray name="documentLine">
            {({ remove, push }) => (
              <DataGridUI
                datagridIdentifier={DataGridIdentifiers.documentLine}
                sx={{ height: 410 }}
                columns={columns(setFieldValue, validateField, errors, remove)}
                rows={values?.documentLine ?? []}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultField,
                    setRowModesModel,
                  },
                }}
              />
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default DocumentLine;
