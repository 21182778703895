import { useState } from 'react';
import {
  GridRowModes,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid-premium';
import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

import { dataGridPremiumColumns } from '../../utils/helper';

import {
  DataGridIdentifiers,
  FormErrorUI,
  InputUI,
  SelectUI,
  AddButtonUi,
  CheckboxUI,
  AutoCompleteSelectUI,
  DatagridPremiumUi,
} from '../Interface';
import { AddressTypeList } from '../../config/company';

function EditToolbar(props) {
  const { setRowModesModel, disabled, push, defaultAddress } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    defaultAddress.id = uuidv4();
    await push(defaultAddress);

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [defaultAddress.id]: { mode: GridRowModes.Edit },
    }));

    const fieldsToValidate = [
      `address.${values.address.length ?? 0}.name`,
      `address.${values.address.length ?? 0}.adressType`,
      `address.${values.address.length ?? 0}.countryCode`,
      `address.${values.address.length ?? 0}.province`,
      `address.${values.address.length ?? 0}.postCode`,
      `address.${values.address.length ?? 0}.city`,
      `address.${values.address.length ?? 0}.streetAddress`,
      `address.${values.address.length ?? 0}.GLN`,
      `address.${values.address.length ?? 0}.other`,
      `address.${values.address.length ?? 0}.default`,
      `address.${values.address.length ?? 0}.active`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
    }
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
}

const CompanyAddress = ({ autocompleteOptions, disabled, defaultAddress }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();

  const [rowModesModel, setRowModesModel] = useState({});

  const handleDefaultChange = (id, checked, adressType, dataApi) => {
    const currentIndex = values.address.findIndex((c) => c.id === id);
    if (checked) {
      values.address.forEach((address, index) => {
        if (address.id !== id && address.adressType === adressType && address.default) {
          setFieldValue(`address.${index}.default`, false);
          dataApi.setEditCellValue({
            id: address.id,
            field: 'default',
            value: false,
          });
        }
      });
      setFieldValue(`address.${currentIndex}.default`, true);
      dataApi.setEditCellValue({
        id: id,
        field: 'default',
        value: true,
      });
    } else {
      setFieldValue(`address.${currentIndex}.default`, false);
      dataApi.setEditCellValue({
        id: id,
        field: 'default',
        value: false,
      });
    }
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const getValueIndex = (values, id) => {
    return values.address.findIndex((rc) => rc.id === id);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = async (id) => {
    const valueIndex = getValueIndex(values, id);

    const fieldsToValidate = [
      `address.${valueIndex}.name`,
      `address.${valueIndex}.adressType`,
      `address.${valueIndex}.countryCode`,
      `address.${valueIndex}.province`,
      `address.${valueIndex}.postCode`,
      `address.${valueIndex}.city`,
      `address.${valueIndex}.streetAddress`,
      `address.${valueIndex}.GLN`,
      `address.${valueIndex}.other`,
      `address.${valueIndex}.default`,
      `address.${valueIndex}.active`,
    ];

    for (const field of fieldsToValidate) {
      await validateField(field);
      if (errors?.address?.[valueIndex]?.[field.split('.').pop()]) {
        return;
      }
    }

    setRowModesModel((prevModel) => ({
      ...prevModel,
      [id]: { mode: GridRowModes.View },
    }));
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = (setFieldValue, validateField, errors, remove) => {
    return dataGridPremiumColumns([
      {
        field: 'adressType',
        headerName: 'Címfajta',
        type: 'string',
        editable: true,
        renderCell: (data) => <SelectUI value={data.row.adressType} option={AddressTypeList} disabled />,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`address.${valueIndex}.adressType`}
              component={SelectUI}
              variant="standard"
              fullWidth
              helperText={<FormErrorUI message={errors?.address?.[valueIndex]?.adressType} />}
              onChange={(e) => {
                const value = e.target.value;
                setFieldValue(`address.${valueIndex}.adressType`, value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: value,
                });
              }}
              option={AddressTypeList}
              value={values.address?.[valueIndex]?.adressType}
              onBlur={async () => await validateField(`address.${valueIndex}.adressType`)}
            />
          );
        },
      },
      {
        field: 'name',
        headerName: 'Cím megnevezése',
        type: 'string',
        editable: true,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`address.${valueIndex}.name`}
              as={InputUI}
              variant="standard"
              fullWidth
              helperText={<FormErrorUI message={errors?.address?.[valueIndex]?.name} />}
              onChange={(e) => {
                setFieldValue(`address.${valueIndex}.name`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`address.${valueIndex}.name`)}
            />
          );
        },
      },
      {
        field: 'countryCode',
        headerName: 'Ország',
        editable: true,
        type: 'singleSelect',
        valueOptions: autocompleteOptions.country,
        renderCell: (data) => {
          const findedCountry = autocompleteOptions?.country.find((item) => item.value === data.row?.countryCode);
          return findedCountry?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedCountry = autocompleteOptions.country.find((item) => item.value === data.row.countryCode);

          return (
            <Field
              name={`address.${valueIndex}.countryCode`}
              component={AutoCompleteSelectUI}
              helperText={<FormErrorUI message={errors?.address?.[valueIndex]?.countryCode} />}
              selectedValue={values.address?.[valueIndex]?.countryCode}
              selectedLabelValue={findedCountry?.label}
              onChange={(_e, newVal) => {
                setFieldValue(`address.${valueIndex}.countryCode`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal.value ?? null,
                });
              }}
              dataset={autocompleteOptions.country}
              onBlur={async () => await validateField(`address.${valueIndex}.countryCode`)}
            />
          );
        },
      },
      {
        field: 'province',
        headerName: 'Megye',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`address.${valueIndex}.province`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.address?.[valueIndex]?.province} />}
              onChange={(e) => {
                setFieldValue(`address.${valueIndex}.province`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`address.${valueIndex}.province`)}
            />
          );
        },
      },
      {
        field: 'postCode',
        headerName: 'Irányítószám',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`address.${valueIndex}.postCode`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.address?.[valueIndex]?.postCode} />}
              onChange={(e) => {
                setFieldValue(`address.${valueIndex}.postCode`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`address.${valueIndex}.postCode`)}
            />
          );
        },
      },
      {
        field: 'city',
        headerName: 'Város',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`address.${valueIndex}.city`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.address?.[valueIndex]?.city} />}
              onChange={(e) => {
                setFieldValue(`address.${valueIndex}.city`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`address.${valueIndex}.city`)}
            />
          );
        },
      },
      {
        field: 'streetAddress',
        headerName: 'Utca, házszám',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`address.${valueIndex}.streetAddress`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.address?.[valueIndex]?.streetAddress} />}
              onChange={(e) => {
                setFieldValue(`address.${valueIndex}.streetAddress`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`address.${valueIndex}.streetAddress`)}
            />
          );
        },
      },
      {
        field: 'GLN',
        headerName: 'GLN',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`address.${valueIndex}.GLN`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.address?.[valueIndex]?.GLN} />}
              onChange={(e) => {
                setFieldValue(`address.${valueIndex}.GLN`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`address.${valueIndex}.GLN`)}
            />
          );
        },
      },
      {
        field: 'other',
        headerName: 'Egyéb',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`address.${valueIndex}.other`}
              type="text"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.address?.[valueIndex]?.other} />}
              onChange={(e) => {
                setFieldValue(`address.${valueIndex}.other`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`address.${valueIndex}.other`)}
            />
          );
        },
      },
      {
        field: 'default',
        headerName: 'Alaértelmezett',
        editable: true,
        type: 'boolean',
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          return (
            <Field
              name={`address.${valueIndex}.default`}
              component={CheckboxUI}
              helperText={<FormErrorUI message={errors?.address?.[valueIndex]?.default} />}
              checked={values.address?.[valueIndex]?.default}
              variant="standard"
              onChange={(e) =>
                handleDefaultChange(data.id, e.target.checked, values.address?.[valueIndex]?.adressType, data.api)
              }
              onBlur={async () => await validateField(`address.${valueIndex}.default`)}
            />
          );
        },
      },
      {
        field: 'active',
        headerName: 'Aktív',
        type: 'boolean',
        editable: true,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          return (
            <Field
              name={`address.${valueIndex}.active`}
              component={CheckboxUI}
              helperText={<FormErrorUI message={errors?.address?.[valueIndex]?.active} />}
              checked={values.address?.[valueIndex]?.active}
              variant="standard"
              onChange={(e) => {
                setFieldValue(`address.${valueIndex}.active`, e.target.checked ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.checked,
                });
              }}
              onBlur={async () => await validateField(`address.${valueIndex}.active`)}
            />
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Szerk.',
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<CheckIcon />}
                label="Save"
                onClick={() => handleSaveClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={handleEditClick(id)}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <FieldArray name="address">
            {({ remove, push }) => (
              <DatagridPremiumUi
                datagridIdentifier={DataGridIdentifiers.companyAddress}
                height={400}
                columns={columns(setFieldValue, validateField, errors, remove)}
                rows={values?.address ?? []}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultAddress,
                    setRowModesModel,
                  },
                }}
                onCellDoubleClick={(_params, event) => {
                  event.defaultMuiPrevented = true;
                }}
                getRowHeight={() => 'auto'}
              />
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default CompanyAddress;
