import request from '../utils/Request';
import { Methods } from '../config';
import { create, get } from 'lodash';

const baseURL = 'prodgenerator';

const prodgeneratorService = {
  getOrders: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/orders`,
      data,
    });
  },

  getsourcelistByItemCode: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/sourcelistByItemCode`,
      data,
    });
  },

  createProduction: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/createProduction`,
      data,
    });
  },
};
export default prodgeneratorService;
