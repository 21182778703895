import React, { useState, useEffect, useMemo } from 'react';
import { multiply, divide } from 'mathjs';
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid-premium';
import FactoryIcon from '@mui/icons-material/Factory';
import { Button } from '@mui/material';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import prodgeneratorService from '../../service/prodgenerator.service';
import useUsers from '../../context/UsersContext';
import useConfig from '../../context/ConfigContext';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';

const ProductionGeneratorNewProdTable = ({
  removedItemState,
  addedItemState,
  sourceDataState,
  refetchState,
  selectedItemState,
  selectedRowsState,
}) => {
  const { getUser, user } = useUsers();
  const { config, getConfig } = useConfig();

  const { removedItem, setRemovedItem } = removedItemState;
  const { addedItem, setAddedItem } = addedItemState;
  const { sourceData, setSourceData } = sourceDataState;
  const { setRefetchTable } = refetchState;
  const { setSelectedItem } = selectedItemState;
  const { setSelectedRows } = selectedRowsState;

  const [newProds, setNewProds] = useState([]);

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
    if (!Object.keys(config).length) getConfig();
  }, []);

  useEffect(() => {
    if (removedItem) {
      setNewProds((prev) =>
        prev.filter((prod) => {
          return (
            prod.baseDocType !== removedItem.docTypeId ||
            prod.baseId !== removedItem.docId ||
            prod.baseLine !== removedItem.lineNum
          );
        })
      );
    }
  }, [removedItem]);

  useEffect(() => {
    if (
      !addedItem?.qty ||
      !sourceData?.length ||
      sourceData[0]?.rootItemCode !== addedItem.itemCode ||
      newProds.some((np) => np.baseId === addedItem.docId && np.baseLine === addedItem.lineNum)
    )
      return;

    const generatedProds = [];

    for (const source of sourceData) {
      const inProd = generatedProds.find((prod) => {
        const x = prod.level === source.level - 1 && prod.articleNumber === source.parentItemCode;
        return x;
      });

      const prodQty =
        source.level === 1
          ? parseFloat(addedItem.qty)
          : multiply(inProd?.productionQuantity ?? 1, divide(source.neededQuantity ?? 1, source.rootBaseQuantity ?? 1));

      const reference = [addedItem.reference, addedItem.itemReference].filter(Boolean).join(' - ');

      const sd = {
        baseId: addedItem.docId,
        baseLine: addedItem.lineNum,
        baseDocNum: addedItem.docNum,
        baseDocType: addedItem.docTypeId,
        companyId: addedItem.companyId,
        endDate: addedItem.dueDate,
        articleNumber: source.relatedItemCode,
        articleName: source.relatedItemName,
        productionQuantity: prodQty,
        projectId: addedItem.projectId,
        me: source.reletedQtyUnitId,
        createdBy: user.userId,
        description: addedItem.iternalNote,
        reference,
        finishedItemCode: source.rootItemCode,
        level: source.level,
        productionOperation:
          source.sourceList?.sourceListOperation?.map((o) => ({
            operationId: o.operationId,
            plannedQty: multiply(o.sourceListOperationMachine?.[0].neededQuantity, prodQty),
            quantityUnitId: o.sourceListOperationMachine[0].quantityUnitId,
            resourceOneId: o.sourceListOperationMachine?.[0].resourceId,
            baseRate: o.sourceListOperationMachine?.[0].neededQuantity,
          })) ?? [],
        productionItem:
          source.sourceList?.sourceListItem?.map((i) => ({
            itemCode: i.itemCode,
            quantity: multiply(i.neededQuantity, prodQty),
            baseRate: i.neededQuantity,
          })) ?? [],
      };

      generatedProds.push(sd);
    }

    setNewProds((prev) => [...prev, ...generatedProds]);
  }, [addedItem, sourceData]);

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'baseDocNum', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(user).length) {
      setLoading(false);
    }
  }, [user]);

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'baseDocNum',
        headerName: 'Vere',
        type: 'string',
      },
      {
        field: 'baseLine',
        headerName: 'Sor',
        type: 'string',
      },
      {
        field: 'finishedItemCode',
        headerName: 'Késztermék',
        type: 'string',
      },
      {
        field: 'articleNumber',
        headerName: 'Cikk',
        type: 'string',
      },
      {
        field: 'articleName',
        headerName: 'Megnevezés',
        type: 'string',
      },
      {
        field: 'productionQuantity',
        headerName: 'Mennyiség',
        type: 'string',
      },
      /*{
        //@TODO
        field: 'me',
        headerName: 'ME',
        type: 'string',
      },*/
      {
        field: 'level',
        headerName: 'Szint',
        type: 'string',
      },
      {
        field: 'projectId',
        headerName: 'Projekt',
        type: 'string',
      },

      {
        field: 'description',
        headerName: 'Leírás',
        type: 'string',
      },
      {
        field: 'reference',
        headerName: 'Referencia',
        type: 'string',
      },
    ]);
  }, []);

  const CustomToolbar = ({ newProds, handleGenClick }) => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Button
          size="small"
          sx={{ ml: 'auto' }}
          startIcon={<FactoryIcon />}
          onClick={() => {
            handleGenClick();
          }}
          disabled={newProds.length === 0}
        >
          Generálás
        </Button>
      </GridToolbarContainer>
    );
  };

  const handleGenClick = () => {
    addNotification({
      content: 'A gyártások generálása elkezdődött!',
      type: NotificationType.INFO,
    });

    prodgeneratorService.createProduction(newProds).then((data) => {
      setNewProds([]);
      setSourceData([]);
      setAddedItem(null);
      setRemovedItem(null);
      addNotification({
        content: 'A gyártások generálása sikeres!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setSelectedItem(null);
      setSelectedRows([]);
    });
  };

  return (
    <>
      {!loading && (
        <DatagridPremiumUi
          datagridIdentifier={DataGridIdentifiers.ProductionGeneratorNewProdTable}
          datagridInitialState={initialState}
          height={400}
          rows={newProds}
          columns={[...columns]}
          paginationAndfilterMode="client"
          getRowId={(row) => `${row.baseId}_${row.baseLine}_${row.baseDocType}_${row.articleNumber}`}
          disableRowSelectionOnClick
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              newProds,
              handleGenClick,
            },
          }}
        />
      )}
    </>
  );
};

export default ProductionGeneratorNewProdTable;
