export const DependenceType = {
  PARALLELTHEPREVIOUS: 'PARALLELTHEPREVIOUS',
  AFTERPREVIOUS: 'AFTERPREVIOUS',
  INDEPENDENT: null,
};

export const DependenceTypeList = [
  { label: 'Előzővel párhuzamos', value: DependenceType.PARALLELTHEPREVIOUS },
  { label: 'Előző után', value: DependenceType.AFTERPREVIOUS },
  { label: 'Független', value: DependenceType.INDEPENDENT },
];
