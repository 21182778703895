import React from 'react';

import { StatusForm } from './StatusForm';
import StatusReports from './StatusReports';
import { useStatus } from '../../hooks/useStatus';

export const StatusMain = () => {
  const statusState = useStatus();

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-6 h-screen gap-2 p-4 bg-gray-100 mt-[-4rem] pt-[5rem]">
      <StatusForm statusState={statusState} />
      <div className="col-span-1 lg:col-span-1 xl:col-span-3 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="h-full overflow-auto custom-scrollbar">
          <StatusReports statusState={statusState} />
        </div>
      </div>
    </div>
  );
};
