import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'incoterm';

const incotermService = {
  getRows: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/rows`,
      data,
    });
  },

  updateData: (data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}`,
      data,
    });
  },

  createData: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  deleteData: (data, incotermCode) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${incotermCode}`,
      data,
    });
  },
};
export default incotermService;
