import React, { useEffect } from 'react';
import { DialogUI } from '../Interface';
import { useStatus } from '../../hooks/useStatus';
import { StatusForm } from './StatusForm';

const StatusDialog = ({ open, handleClose, setDataChanged, data }) => {
  const statusState = useStatus();

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      statusState.setSelectedValues(data);
    }
  }, [data, statusState]);

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent="Státusz-kezelés"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1300px',
          },
        },
      }}
    >
      <div className="mt-5">
        <StatusForm
          dialog={{
            handleClose: handleClose,
            setDataChanged: setDataChanged,
          }}
          statusState={statusState}
        />
      </div>
    </DialogUI>
  );
};

export default StatusDialog;
