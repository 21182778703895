import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { ButtonUI, CheckboxUI, ConfirmDialogUI, LinearStaticProgressUI } from '../../Interface';
import dayjs from 'dayjs';
import productionService from '../../../service/production.service';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import { hourToMin, minToHour } from '../../../utils/helper';

dayjs.extend(relativeTime);
dayjs.extend(duration);

const OpWidgetJobmonitorCard = ({ data, userId, setRefetch, meo, params }) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogData, setConfirmDialogData] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(null);
  const [timer, setTimer] = useState(null);

  const startedOperation = data?.productionReport?.find(
    (report) => report.startDate && !report.endDate && report.userId === userId
  );

  useEffect(() => {
    if (startedOperation?.startDate) {
      if (timer) {
        clearInterval(timer);
      }

      const interval = setInterval(() => {
        const start = dayjs(startedOperation.startDate);
        const now = dayjs();
        const diff = dayjs.duration(now.diff(start));

        setElapsedTime(
          `${diff.hours().toString().padStart(2, '0')}:${diff.minutes().toString().padStart(2, '0')}:${diff.seconds().toString().padStart(2, '0')}`
        );
      }, 1000);

      setTimer(interval);

      return () => clearInterval(interval);
    } else {
      if (timer) {
        clearInterval(timer);
        setTimer(null);
      }
      setElapsedTime(null);
    }
  }, [startedOperation]);

  const { totalReportedQty, totalReportedTimeSpent, totalReportedTimeSpentInMin } = data.productionReport.reduce(
    (acc, report) => {
      if (report.prodOperationId === data.id) {
        acc.totalReportedQty += Number(report.quantity);
        acc.totalReportedTimeSpent += Number(report.timeSpent);
        acc.totalReportedTimeSpentInMin += Number(report.timeSpentInMin);
      }
      return acc;
    },
    { totalReportedQty: 0, totalReportedTimeSpent: 0, totalReportedTimeSpentInMin: 0 }
  );

  const reported =
    params?.QUANTITY_UNIT_HOUR_CODE === data.quantityUnit.code ? totalReportedTimeSpent : totalReportedTimeSpentInMin;

  const preparedness = Math.round((reported / data.plannedQty) * 100);

  const handleStartOperation = (prodOperationId) => {
    const values = {
      userId: userId,
      createdBy: userId,
      startDate: dayjs(),
      date: dayjs(),
      id: prodOperationId,
      endDate: null,
    };

    const startOperation = productionService.createProductionReport(values);

    Promise.all([handleUpdateStatus('INPROGRESS'), startOperation]).then(() => {
      setRefetch(true);
    });
  };

  const handleStopOperation = (id) => {
    const values = { endDate: dayjs(), id };
    return productionService.updateProductionReport(values);
  };

  const handleUpdateStatus = (productionStatusId) => {
    return productionService.updateStatus({
      id: data.id,
      productionStatusId,
    });
  };

  const handleVerifyOperation = () => {
    handleUpdateStatus('VERIFIED');
    setRefetch(true);
  };

  const handleDiscardedOperation = () => {
    handleUpdateStatus('DISCARDED');
    setRefetch(true);
  };

  const handleFinishOperation = () => {
    Promise.all([handleUpdateStatus('COMPLETED'), handleStopOperation(startedOperation.id)]).then(() => {
      setRefetch(true);
    });
  };

  const handleInterruptOperation = () => {
    Promise.all([handleUpdateStatus('INTERRUPTED'), handleStopOperation(startedOperation.id)]).then(() => {
      setRefetch(true);
    });
  };

  const openConfirmDialog = () => {
    if (startedOperation) {
      setConfirmDialogData({
        message: 'Mit szeretne csinálni?',
        confirmButtonText: 'Kész',
        otherButtonText: 'Megszakít',
        handleChangeElement: () => {
          setShowConfirmDialog(false);
          handleFinishOperation();
        },
        handleChangeOtherElement: () => {
          handleInterruptOperation(startedOperation.id);
          setShowConfirmDialog(false);
        },
      });
    } else {
      setConfirmDialogData({
        message: 'Biztosan el szeretné indítani a gyártási műveletet?',
        confirmButtonText: 'Igen',
        handleChangeElement: () => {
          handleStartOperation(data.id);
          setShowConfirmDialog(false);
        },
      });
    }
    setShowConfirmDialog(true);
  };

  return (
    <>
      <Box
        className="rounded-md py-2 px-4 shadow-md max-w-[450px] min-w-[450px] cursor-pointer"
        sx={{
          backgroundColor: startedOperation
            ? '#B6EFCE'
            : data.productionStatusId === 'INTERRUPTED'
              ? '#FFD2D1'
              : data.productionStatusId === 'DISCARDED'
                ? 'rgba(248, 113, 113, 0.7)'
                : 'white',
          '&:hover': {
            background: startedOperation
              ? '#75E69E'
              : data.productionStatusId === 'INTERRUPTED'
                ? 'rgba(254, 205, 211, 0.7)'
                : data.productionStatusId === 'DISCARDED'
                  ? 'rgba(248, 113, 113, 1)'
                  : 'rgba(229, 231, 235, 0.7)',
          },
        }}
      >
        <div>
          <div className="flex justify-end gap-3">
            {meo && (
              <>
                <ButtonUI text="Ellenőrzés" size="sm" className="bg-success" onClick={handleVerifyOperation} />
                <ButtonUI text="Visszadobás" size="sm" className="bg-danger" onClick={handleDiscardedOperation} />
              </>
            )}
          </div>
          <div className="flex justify-between mb-2">
            {startedOperation?.startDate && (
              <Typography sx={{ fontWeight: 'bold' }}>
                Elindítva: {dayjs(startedOperation.startDate).format('YYYY-MM-DD HH:mm')}
              </Typography>
            )}
            <Typography sx={{ fontWeight: 'bold', color: 'blue' }}> {elapsedTime}</Typography>
          </div>
          <div onClick={() => openConfirmDialog()}>
            <div className="flex items-center gap-3 flex-wrap">
              <Typography sx={{ fontSize: '1.1rem', fontWeight: 'bold' }}>{data.production.articleName}</Typography>
              <Typography>
                {params?.QUANTITY_UNIT_HOUR_CODE === data.quantityUnit.code
                  ? `${Math.round(totalReportedTimeSpent * 100) / 100} / ${data.plannedQty} ${data.quantityUnit.name}`
                  : `${Math.round(totalReportedTimeSpentInMin * 100) / 100} / ${data.plannedQty} ${data.quantityUnit.name}`}
              </Typography>
            </div>
            <div>
              <Typography>{data.production?.project?.projectName}</Typography>
              <Typography>{data.production?.company?.name}</Typography>
            </div>
            <div className="my-2">
              <LinearStaticProgressUI
                bgColor={startedOperation ? '#4ade80' : 'rgba(113, 113, 122, 0.5)'}
                value={preparedness}
              />
            </div>
            <div className="flex justify-between">
              <Typography>{data.operation.operationName}</Typography>
              <Typography>{data.productionStatus?.name}</Typography>
            </div>
          </div>
        </div>
      </Box>
      <ConfirmDialogUI
        message={confirmDialogData?.message}
        confirmButtonText={confirmDialogData?.confirmButtonText}
        otherButtonText={confirmDialogData?.otherButtonText}
        handleChangeElement={confirmDialogData?.handleChangeElement}
        handleChangeOtherElement={confirmDialogData?.handleChangeOtherElement}
        show={showConfirmDialog}
        onHide={() => setShowConfirmDialog(false)}
        largeButtons={true}
        maxWidth="md"
      />
    </>
  );
};

export default OpWidgetJobmonitorCard;
