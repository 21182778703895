import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import dayjs from 'dayjs';
import Yup from '../../../utils/yup';
import utc from 'dayjs/plugin/utc';
import { bignumber, divide, multiply } from 'mathjs';
import { v4 as uuidv4 } from 'uuid';

import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';
import productionService from '../../../service/production.service';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI, ConfirmDialogUI, ButtonUI } from '../../Interface';
import ProductionFields from './ProductionFields';
import ProductionTabs from './ProductionTabs';
import sourcelistService from '../../../service/sourcelist.service';

export const ProductionPage = ({ productionState, dialog }) => {
  const {
    selectedValues,
    setSelectedValues,
    viewField,
    setViewField,
    operatorsDisabled,
    setOperatorsDisabled,
    autocompleteOptions,
    setRefetchTable,
    setRowSelectionModel,
    user,
    canEdit,
    submenu,
    autocompleteData,
    config,
    params,
  } = productionState;
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmDialogForStatus, setShowConfirmDialogForStatus] = useState(false);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [initValues, setInitValues] = useState({});

  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});

  const [sourceData, setSourceData] = useState({});

  const defaultOperation = {
    id: null,
    isNew: true,
    operationId: null,
    resourceOneId: null,
    startDate: dayjs(new Date()),
    dependence: config?.defaultDependence,
    description: '',
    createdBy: user.userId,
    label: '',
    baseRate: 1,
    plannedQty: 1,
    quantityUnitId: config?.defOperationQtyUnit,
    productionReport: [],
    productionStatusId:
      autocompleteData?.prodStatus?.find((defStatus) => defStatus.defaultValue === true)?.code ?? null,
  };

  const defaultItem = {
    id: null,
    isNew: true,
    itemCode: null,
    quantity: 1,
    createdBy: user.userId,
    baseRate: 1,
    description: '',
  };

  const def = {
    workingNumber: '',
    baseDocNum: '',
    projectId: null,
    articleNumber: '',
    articleName: '',
    productionQuantity: 1,
    me: null,
    startDate: dayjs(new Date()),
    endDate: dayjs(new Date()).add(1, 'hour'),
    productionStatusId: null,
    priorityId: null,
    companyId: null,
    description: '',
    productionOperation: [],
    productionItem: [],
    userId: null,
    baseId: '',
    baseLine: '',
    finishedItemCode: null,
    reference: '',
  };

  useEffect(() => {
    setDefaultValues(def);
  }, []);

  useEffect(() => {
    if (Object.keys(autocompleteData).length > 0) {
      setDefaultValues((prev) => {
        return {
          ...prev,
          productionStatusId:
            autocompleteData?.prodStatus?.find((defStatus) => defStatus.defaultValue === true)?.code ?? null,
          priorityId: autocompleteData?.priority?.defaultValue ?? null,
        };
      });

      setDefaultSelectedValues((prev) => {
        return {
          ...prev,
          priority: autocompleteData?.priority?.defItem,
        };
      });
    }
  }, [autocompleteData]);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setDefaultValues((prev) => {
        return { ...prev, userId: user?.userId };
      });
    }
  }, [user]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!_.isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    }
  }, [selectedValues]);

  const validationSchema = Yup.object().shape({
    workingNumber: Yup.string().nullable(),
    baseDocNum: Yup.string(),
    projectId: Yup.string().nullable(),
    articleNumber: Yup.string().required(),
    articleName: Yup.string().required(),
    productionQuantity: Yup.number().nullable().positive(),
    me: Yup.string().nullable().required(),
    startDate: Yup.string(),
    endDate: Yup.string(),
    productionStatusId: Yup.string().nullable(),
    priorityId: Yup.string().nullable(),
    companyId: Yup.string().nullable(),
    description: Yup.string().nullable(),
    baseId: Yup.string().nullable(),
    baseLine: Yup.string().nullable(),
    finishedItemCode: Yup.string().nullable(),
    reference: Yup.string().nullable(),
    productionOperation: Yup.array().of(
      Yup.object().shape({
        operationId: Yup.string().required(),
        resourceOneId: Yup.string().required(),
        productionStatusId: Yup.string().required(),
        startDate: Yup.string().required(),
        dependence: Yup.string().nullable(),
        description: Yup.string().nullable(),
        quantityUnitId: Yup.string().required(),
        plannedQty: Yup.number().required().positive(),
      })
    ),
    productionItem: Yup.array().of(
      Yup.object().shape({
        itemCode: Yup.string().required(),
        quantity: Yup.number().required().positive(),
        description: Yup.string().nullable(),
      })
    ),
  });

  const valuesToForm = () => {
    const {
      workingNumber,
      baseDocNum,
      projectId,
      articleNumber,
      articleName,
      productionQuantity,
      me,
      startDate,
      endDate,
      productionStatusId,
      priorityId,
      userId,
      companyId,
      description,
      productionOperation,
      productionItem,
      baseId,
      baseLine,
      finishedItemCode,
      reference,
    } = selectedValues;

    setInitValues({
      workingNumber,
      baseDocNum,
      projectId,
      articleNumber,
      articleName,
      productionQuantity,
      me,
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      productionStatusId,
      priorityId,
      userId,
      companyId,
      description,
      baseId,
      baseLine,
      finishedItemCode,
      reference,
      productionOperation:
        productionOperation &&
        productionOperation.map((po) => ({
          id: po.id,
          operationId: po.operationId,
          resourceOneId: po.resourceOneId,
          startDate: po.startDate,
          dependence: po.dependence,
          visOrder: po.visOrder,
          description: po.description,
          createdBy: po.user?.userId,
          label: po.label,
          baseRate: po.baseRate,
          productionReport: po.productionReport,
          productionStatusId: po.productionStatusId,
          plannedQty: po.plannedQty,
          quantityUnitId: po.quantityUnitId,
        })),
      productionItem:
        productionItem &&
        productionItem.map((po) => ({
          id: po.id,
          itemCode: po.itemCode,
          quantity: po.quantity,
          baseRate: po.baseRate,
          description: po.description,
        })),
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField || !dialog.isUpdate) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      baseId: parseInt(values.baseId),
      baseLine: parseInt(values.baseLine),
      updatedBy: user.userId,
    };

    productionService.updateProduction(updData, selectedValues.id).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      if (dialog) {
        dialog.setProductionChanged(true);
        dialog.handleClose();
      }
      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      baseId: parseInt(values.baseId),
      baseLine: parseInt(values.baseLine),
      createdBy: user.userId,
    };

    productionService
      .createProduction(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });
        setRowSelectionModel(data.id);
        setSelectedValues(data);
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);

        if (dialog) {
          dialog.setProductionChanged(true);
          dialog.handleClose();
          setSelectedValues(defaultSelectedValues);
        }
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    productionService.deleteProduction(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      if (dialog) {
        dialog.setProductionChanged(true);
        dialog.handleClose();
      }
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
    });
  };

  const getSourceData = async (itemCode) => {
    if (!itemCode) {
      setSourceData({});
      return;
    }

    const sd = await sourcelistService.getSourcelistByItemCode(itemCode);
    setSourceData(sd);
    if (
      (Object.keys(sd).length > 0 && !viewField) ||
      (Object.keys(sd).length > 0 && dialog && dialog?.isUpdate && !viewField) ||
      (Object.keys(sd).length > 0 && dialog && !dialog?.isUpdate && !dialog.isView)
    ) {
      setShowConfirmDialog(true);
    }
  };

  const addSourceLine = (setFieldValue, values) => {
    for (const op of sourceData.sourceListOperation) {
      if (op.sourceListOperationMachine.length === 0) {
        const findOperation = autocompleteOptions.operation.find((f) => f.value === op.operationId);
        addNotification({
          content: `Nincs megadva a művelethez tartozó erőforrás! (${findOperation.label})`,
          type: NotificationType.ERROR,
        });
        return;
      }
    }

    const newOperationLines = sourceData.sourceListOperation.map((mp) => {
      const calculatedRate = divide(mp.sourceListOperationMachine[0].neededQuantity, sourceData.baseQuantity);
      return {
        id: uuidv4(),
        isNew: true,
        operationId: mp.operationId,
        visOrder: mp.visOrder,
        dependence: mp.dependence,
        resourceOneId: mp.sourceListOperationMachine[0].resourceId,
        startDate: dayjs(new Date()),
        plannedQty: multiply(calculatedRate, values.productionQuantity ?? 1),
        quantityUnitId: mp.sourceListOperationMachine[0].quantityUnitId,
        description: '',
        createdBy: user.userId,
        label: '',
        baseRate: calculatedRate,
        productionStatusId:
          autocompleteData?.prodStatus?.find((defStatus) => defStatus.defaultValue === true)?.code ?? null,
      };
    });

    setFieldValue('productionOperation', newOperationLines);

    const newItemLines = sourceData.sourceListItem.map((mp) => {
      const calculatedRate = divide(mp.neededQuantity, sourceData.baseQuantity);
      return {
        id: uuidv4(),
        isNew: true,
        itemCode: mp.itemCode,
        quantity: multiply(calculatedRate, values.productionQuantity ?? 1),
        baseRate: calculatedRate,
      };
    });

    setFieldValue('productionItem', newItemLines);

    setShowConfirmDialog(false);
  };

  const onAddClick = () => {
    setSelectedValues(defaultSelectedValues);
    setInitValues(defaultValues);
    setViewField(false);
    setAddField(false);
  };

  const onModifyClick = () => {
    setViewField(false);
    setEditButtonvisible(false);
  };

  const onDeleteClick = () => {
    setShowDeleteDialog(true);
  };

  const onCopyClick = () => {
    setViewField(false);
    setAddField(false);
  };

  const printClick = () => {
    addNotification({
      content: 'Nyomtatás elindult!',
      type: NotificationType.INFO,
    });

    productionService.printDocument({
      docId: selectedValues.id,
    });
  };

  const onProductionStatusChange = (setFieldValue, values) => {
    const prodOperation = values.productionOperation.map((prodOp) => {
      return {
        ...prodOp,
        productionStatusId: values.productionStatusId,
      };
    });
    setFieldValue('productionOperation', prodOperation);
    setShowConfirmDialogForStatus(false);
  };

  return (
    <>
      <>
        <div className="h-full overflow-auto custom-scrollbar">
          <div className="flex items-center flex-wrap">
            <FormHeaderUI
              addClick={!dialog ? onAddClick : null}
              addDisabled={!viewField || !canEdit}
              modClick={!dialog || dialog.isUpdate ? onModifyClick : null}
              modDisabled={!viewField || operatorsDisabled || !canEdit}
              deleteClick={!dialog || dialog.isUpdate ? onDeleteClick : null}
              deleteDisabled={!viewField || operatorsDisabled || !canEdit}
              copyClick={!dialog || dialog.isUpdate ? onCopyClick : null}
              copyDisabled={!viewField || operatorsDisabled || !canEdit}
            />
            {!dialog && (
              <ButtonUI
                text="Nyomtatás"
                size="sm"
                className="bg-labelColor py-3"
                onClick={() => printClick()}
                disabled={!viewField || operatorsDisabled}
              />
            )}
          </div>

          <Formik
            initialValues={initValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue, errors, validateField, resetForm }) => {
              const calcOperationQty = async (index) => {
                const op = values.productionOperation?.[index];
                const result = multiply(
                  bignumber(op.baseRate ?? 1),
                  bignumber(values.productionQuantity ?? 1)
                ).toNumber();

                await setFieldValue(`productionOperation.${index}.plannedQty`, result);
              };

              const calcItemQty = async (index) => {
                const item = values.productionItem?.[index];
                const result = multiply(
                  bignumber(item.baseRate ?? 1),
                  bignumber(values.productionQuantity ?? 1)
                ).toNumber();

                await setFieldValue(`productionItem.${index}.quantity`, result);
              };

              const calculateLineQty = async () => {
                if (values.productionOperation && values.productionOperation.length > 0) {
                  for (const [i] of values.productionOperation.entries()) {
                    await calcOperationQty(i);
                  }
                }

                if (values.productionItem && values.productionItem.length > 0) {
                  for (const [i] of values.productionItem.entries()) {
                    await calcItemQty(i);
                  }
                }
              };

              const calcOperationBaseRate = (index) => {
                const opLine = values.productionOperation[index];
                const newBaseRate = divide(opLine?.plannedQty ?? 1, values.productionQuantity ?? 1);
                setFieldValue(`productionOperation.${index}.baseRate`, newBaseRate);
              };

              const calcItemBaseRate = (index) => {
                const itemLine = values.productionItem[index];
                const newBaseRate = divide(itemLine?.quantity ?? 1, values.productionQuantity ?? 1);
                setFieldValue(`productionItem.${index}.baseRate`, newBaseRate);
              };

              return (
                <Form>
                  <ProductionFields
                    viewField={(!dialog || dialog?.isUpdate || dialog.isView) && viewField}
                    submenuId={submenu}
                    selectedValues={selectedValues}
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    validateField={validateField}
                    autocompleteOptions={autocompleteOptions}
                    autocompleteData={autocompleteData}
                    getSourceData={getSourceData}
                    calculateLineQty={calculateLineQty}
                    setShowConfirmDialogForStatus={setShowConfirmDialogForStatus}
                  />
                  <ProductionTabs
                    disabled={(!dialog || dialog?.isUpdate || dialog.isView) && viewField}
                    submenuId={submenu}
                    setFieldValue={setFieldValue}
                    selectedValues={selectedValues}
                    setDefaultValues={setDefaultValues}
                    user={user}
                    setRefetchTable={setRefetchTable}
                    defaultField={defaultOperation}
                    defaultItem={defaultItem}
                    values={values}
                    errors={errors}
                    autocompleteOptions={autocompleteOptions}
                    autocompleteData={autocompleteData}
                    sourceData={sourceData}
                    calcOperationBaseRate={calcOperationBaseRate}
                    calcItemBaseRate={calcItemBaseRate}
                    params={params}
                  />
                  <FormButtonPanelUI
                    onCancelClick={() => {
                      setViewField(true);
                      setEditButtonvisible(true);
                      setAddField(true);
                      resetForm();
                      if (dialog) {
                        dialog.handleClose();
                      }
                    }}
                    disabled={
                      dialog
                        ? dialog.isUpdate && ((editButtonVisible && addField) || (!editButtonVisible && !addField))
                        : (editButtonVisible && addField) || (!editButtonVisible && !addField)
                    }
                  />
                  <ConfirmDialogUI
                    handleChangeElement={() => {
                      addSourceLine(setFieldValue, values);
                    }}
                    show={showConfirmDialog}
                    onHide={() => {
                      setShowConfirmDialog(false);
                      setSourceData({});
                    }}
                    message={'A válaszott cikkhez tartozik forrásjegyzék. Importálja a forrásjegyzéket?'}
                  />
                  <ConfirmDialogUI
                    handleChangeElement={() => {
                      onProductionStatusChange(setFieldValue, values);
                    }}
                    show={showConfirmDialogForStatus}
                    onHide={() => {
                      setShowConfirmDialogForStatus(false);
                    }}
                    confirmButtonText="Igen"
                    message={'Átállítja a megadott státuszra a tétel sorok státuszát is?'}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </>

      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
