import React from 'react';
import { Field } from 'formik';

import {
  InputUI,
  AutoCompleteSelectUI,
  FormErrorUI,
  FormLabelUI,
  RadioGroupUI,
  CheckboxUI,
  SelectUI,
} from '../Interface';
import { ItemGroupType, ItemGroupTypeList, StockArticleTypeList } from '../../config/itemGroup';

export const ItemFields = ({
  viewField,
  selectedValues,
  values,
  validateField,
  setFieldValue,
  errors,
  autocompleteOptions,
  addField,
  autoCompleteData,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="itemCode"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Cikkszám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.itemCode} />}
          disabled={viewField || addField}
          InputLabelProps={{ shrink: values.itemCode && values.itemCode !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="itemName"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Cikknév" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.itemName} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.itemName && values.itemName !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="itemGroupId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Cikkcsoport" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.itemGroupId} />}
          onChange={(_e, newVal) => {
            setFieldValue('itemGroupId', newVal?.value ?? null).then(() => {
              validateField('itemGroupId');
            });
            const findedGroup = autoCompleteData.itemGroup.find((i) => i.id === newVal?.value);
            const findedME = autoCompleteData.quantityUnit.find((i) => findedGroup?.quantityUnitId === i?.id);
            setFieldValue('weight', findedME?.weight ?? '');
            setFieldValue('quantityUnitId', findedGroup?.quantityUnitId ?? null);
            setFieldValue('type', findedGroup?.type ?? null);
            setFieldValue('stockItemType', findedGroup?.stockItemType ?? null);
            setFieldValue('inPurchase', findedGroup?.inPurchase ?? null);
            setFieldValue('inSales', findedGroup?.inSales ?? null);
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.itemGroupId !== '' }}
          selectedValue={values.itemGroupId}
          selectedLabelValue={selectedValues?.itemGroup?.name}
          dataset={autocompleteOptions?.itemGroup}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="quantityUnitId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Mennyiségi egység" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.quantityUnitId} />}
          onChange={(_e, newVal) => {
            setFieldValue('quantityUnitId', newVal?.value ?? null).then(() => {
              validateField('quantityUnitId');
            });
            const findedME = autoCompleteData.quantityUnit.find((i) => i.id === newVal?.value);
            setFieldValue('weight', findedME?.weight ?? '');
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.quantityUnitId !== '' }}
          selectedValue={values.quantityUnitId}
          selectedLabelValue={selectedValues?.quantityUnit?.name}
          dataset={autocompleteOptions?.quantityUnit}
        />
      </div>
      <div className="col-span-2">
        <Field
          type="radio"
          name="type"
          as={RadioGroupUI}
          row
          label={<FormLabelUI text="Típus" />}
          helperText={<FormErrorUI message={errors.type} />}
          disabled
          value={values.type}
          option={ItemGroupTypeList}
        />
      </div>
      <div className={values.type === ItemGroupType.STOCKARTICLE ? 'col-span-2 pt-3' : 'hidden'}>
        <Field
          name="stockItemType"
          component={SelectUI}
          fullWidth
          option={StockArticleTypeList}
          value={values.stockItemType ?? ''}
          variant="standard"
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('stockItemType', value ?? null).then(() => {
              validateField('stockItemType');
            });
          }}
          label={<FormLabelUI text="Készletcikk fajta" />}
          helperText={<FormErrorUI message={errors.stockItemType} />}
          disabled
        />
      </div>
      <div className="col-span-4 md:col-span-4">
        <Field
          name="inPurchase"
          as={CheckboxUI}
          fullWidth
          checked={values.inPurchase ?? false}
          variant="standard"
          onChange={(e) => {
            setFieldValue('inPurchase', e.target.checked ?? null).then(() => {
              validateField('inPurchase');
            });
          }}
          label={<FormLabelUI text="Beszerzésben részt vesz" />}
          helperText={<FormErrorUI message={errors.inPurchase} />}
          disabled={viewField}
        />
        <Field
          name="inSales"
          as={CheckboxUI}
          fullWidth
          checked={values.inSales ?? false}
          variant="standard"
          onChange={(e) => {
            setFieldValue('inSales', e.target.checked ?? null).then(() => {
              validateField('inSales');
            });
          }}
          label={<FormLabelUI text="Értékesítésben részt vesz" />}
          helperText={<FormErrorUI message={errors.inSales} />}
          disabled={viewField}
        />
      </div>
      <div className={values.type === ItemGroupType.STOCKARTICLE ? 'col-span-4 md:col-span-1' : 'hidden'}>
        <Field
          type="number"
          name="weight"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Súly (kg)" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.weight} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.weight && values.weight !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-1">
        <Field
          type="autocomplete"
          name="kshClassificationId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="KSH besorolás" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.kshClassificationId} />}
          onChange={(_e, newVal) => {
            setFieldValue('kshClassificationId', newVal?.value ?? null).then(() => {
              validateField('kshClassificationId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.kshClassificationId !== '' }}
          selectedValue={values.kshClassificationId}
          selectedLabelValue={selectedValues?.kshClassification?.name}
          dataset={autocompleteOptions?.kshClassification}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="other"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Egyéb" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.other} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.other && values.other !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          name="active"
          type="checkbox"
          as={CheckboxUI}
          fullWidth
          label={<FormLabelUI text="Aktív" />}
          disabled={viewField}
        />
      </div>
    </div>
  );
};
