import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'newDocType';

const documentTypeService = {
  getDocumentType: (id) => {
    return request({
      resource: `${baseURL}/${id}`,
    });
  },

  getDocumentTypes: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/documenttypes`,
      data,
    });
  },

  allDocumentTypes: () => {
    return request({
      resource: `${baseURL}/documents/allDocumentTypes`,
    });
  },

  updateDocumentType: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  uploadFile: (file, id, userId) => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('userId', userId);

    return request({
      method: Methods.POST,
      resource: `${baseURL}/uploadFile/${id}`,
      formData: true,
      data: formData,
    });
  },

  deleteFile: (id, userId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/deleteFile/${id}`,
      data: { userId },
    });
  },
};

export default documentTypeService;
