export const opWidgetFilter = {
  ALLMYTODAY: 'ALLMYTODAY',
  ALLTODAY: 'ALLTODAY',
  ALLMY: 'ALLMY',
  ALL: 'ALL',
};

export const opWidgetFilterList = [
  { label: 'Összes mai saját', value: opWidgetFilter.ALLMYTODAY },
  { label: 'Összes mai', value: opWidgetFilter.ALLTODAY },
  { label: 'Összes saját', value: opWidgetFilter.ALLMY },
  { label: 'Összes', value: opWidgetFilter.ALL },
];
