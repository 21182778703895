import React, { useState } from 'react';
import { Box } from '@mui/material';
import DialogUI from './DialogUI';
import ButtonUI from './ButtonUI';
import FileUploadUI from './FileUploadUI';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';

const FileUploadDialogUI = ({ open, handleClose, handleUpload, accept, disabled, ...props }) => {
  const [files, setFiles] = useState([]);

  const handleFilesChange = (newFiles) => {
    setFiles(newFiles);
  };

  const handleUploadClick = () => {
    const hasOversizedFiles = files.some((fileObj) => fileObj.isOversized);

    if (hasOversizedFiles) {
      addNotification({
        content: 'Található egy túl nagy fájl a listában!',
        type: NotificationType.ERROR,
      });
      return;
    }

    const validFiles = files.map((fileObj) => fileObj.file);
    handleUpload(validFiles);
  };

  return (
    <DialogUI
      open={open}
      onClose={handleClose}
      headerContent={'Fájl feltöltése'}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
          },
        },
      }}
    >
      <FileUploadUI accept={accept} disabled={disabled} onFilesChange={handleFilesChange} />

      <Box display="flex" gap={3} justifyContent="center">
        <ButtonUI
          text="Feltöltés"
          className="bg-success flex gap-2 items-center"
          type="button"
          onClick={() => handleUploadClick(files)}
        />

        <ButtonUI
          text="Mégse"
          className="bg-danger flex items-center gap-2"
          type="button"
          onClick={() => handleClose()}
        >
          Mégse
        </ButtonUI>
      </Box>
    </DialogUI>
  );
};

export default FileUploadDialogUI;
