import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _, { isEqual } from 'lodash';
import dayjs from 'dayjs';
import Yup from '../../utils/yup';
import utc from 'dayjs/plugin/utc';

import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import useMenus from '../../context/MenuContext';
import masterDataService from '../../service/masterData.service';
import useUsers from '../../context/UsersContext';
import { generateAutocompleteItems } from '../../utils/helper';
import { DeleteDialogUI, FormButtonPanelUI, FormHeaderUI, FormBase } from '../Interface';
import itemService from '../../service/item.service';
import { ItemFields } from './ItemFields';
import ItemReports from './ItemReports';
import ItemTabs from './ItemTabs';
import useConfig from '../../context/ConfigContext';
import { ItemGroupType } from '../../config/itemGroup';

export const ItemPage = () => {
  const { config, getConfig } = useConfig();
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [refetchTable, setRefetchTable] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [canEdit, setCanEdit] = useState(true);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autoCompleteData, setAutoCompleteData] = useState({});
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    quantityUnit: [],
    itemGroup: [],
    kshClassification: [],
  });

  const def = {
    itemCode: '',
    itemName: '',
    itemGroupId: null,
    type: '',
    stockItemType: '',
    inPurchase: false,
    inSales: false,
    weight: '',
    kshClassificationId: null,
    other: '',
    active: true,
    quantityUnitId: null,
    itemPrice: [],
  };

  useEffect(() => {
    getMenus();
    getConfig();
    if (!Object.keys(user).length) getUser();

    setDefaultValues(def);
    setDefaultSelectedValues(def);
    initDefaultValues();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('item');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    setSelectedValues(defaultSelectedValues);
  }, [defaultSelectedValues]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .quantityUnitToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .itemGroupToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .kshClassificationToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          quantityUnit: resp[0],
          itemGroup: resp[1],
          kshClassification: resp[2],
        };
        setAutoCompleteData(aData);

        const acOptions = {
          quantityUnit: generateAutocompleteItems(aData.quantityUnit, 'name', 'id'),
          itemGroup: generateAutocompleteItems(aData.itemGroup, 'name', 'id'),
          kshClassification: generateAutocompleteItems(aData.kshClassification, 'number', 'id'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  const validationSchema = Yup.object().shape({
    itemCode: Yup.string().required(),
    itemName: Yup.string().required(),
    itemGroupId: Yup.string().required(),
    type: Yup.string().nullable(),
    stockItemType: Yup.string().nullable(),
    inPurchase: Yup.boolean(),
    inSales: Yup.boolean(),
    weight: Yup.number()
      .nullable()
      .when('type', {
        is: (type) => type === ItemGroupType.STOCKARTICLE,
        then: (schema) =>
          schema.test(
            'is-decimal',
            `Max. ${config.decimalPlaces} tizedes.`,
            (value) =>
              value === undefined || new RegExp(`^\\d+(\\.\\d{1,${config.decimalPlaces}})?$`).test(String(value))
          ),
        otherwise: (schema) => schema,
      }),
    kshClassificationId: Yup.string().nullable(),
    other: Yup.string().nullable(),
    active: Yup.boolean(),
    quantityUnitId: Yup.string().required(),
  });

  const valuesToForm = () => {
    const {
      itemCode,
      itemName,
      quantityUnitId,
      itemGroupId,
      type,
      stockItemType,
      inPurchase,
      inSales,
      weight,
      kshClassificationId,
      other,
      active,
      itemPrice,
    } = selectedValues;

    setInitValues({
      itemCode,
      itemName,
      quantityUnitId,
      itemGroupId,
      type,
      stockItemType,
      inPurchase,
      inSales,
      weight: weight ?? def.weight,
      kshClassificationId,
      other,
      active,
      itemPrice:
        itemPrice &&
        itemPrice.map((iP) => ({
          id: iP.id,
          priceListId: iP.priceListId,
          price: iP.price ?? '',
        })),
    });
  };

  const handleSubmit = (values, { resetForm }) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values, resetForm);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
      weight: parseFloat(values.weight),
    };

    itemService.updateItem(updData, selectedValues.itemCode).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
      weight: parseFloat(values.weight),
    };

    itemService
      .createItem(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setSelectedValues(data);
        setRowSelectionModel(data.itemCode);
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  dayjs.extend(utc);

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    itemService.deleteItem(deleteData, selectedValues.itemCode).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues(defaultSelectedValues);
    });
  };

  const handleTemplate = () => {
    itemService.getExcelTemplate().then(() => {
      addNotification({
        content: 'A letöltés elindult!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const handleExport = () => {
    itemService.getExcelData().then(() => {
      addNotification({
        content: 'A letöltés elindult!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  const handleImport = (files) => {
    itemService.importExcel(files, user.userId).then(() => {
      setRefetchTable(true);
      addNotification({
        content: 'Az import sikeres!',
        type: NotificationType.SUCCESS,
      });
    });
  };

  return (
    <>
      <FormBase
        name="Item"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                addClick={() => {
                  setSelectedValues(defaultSelectedValues);
                  setViewField(false);
                  setAddField(false);
                }}
                addDisabled={!viewField || !canEdit}
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled || !canEdit}
                deleteClick={() => {
                  setShowDeleteDialog(true);
                }}
                deleteDisabled={!viewField || operatorsDisabled || !canEdit}
                copyClick={() => {
                  setViewField(false);
                  setAddField(false);
                }}
                copyDisabled={!viewField || operatorsDisabled || !canEdit}
              />

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ values, setFieldValue, errors, validateField, resetForm }) => (
                  <Form>
                    <ItemFields
                      viewField={viewField}
                      selectedValues={selectedValues}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      validateField={validateField}
                      autocompleteOptions={autocompleteOptions}
                      addField={addField}
                      autoCompleteData={autoCompleteData}
                      config={config}
                    />

                    <ItemTabs
                      selectedValues={selectedValues}
                      viewField={viewField}
                      setDefaultValues={setDefaultValues}
                      handleImport={handleImport}
                      handleExport={handleExport}
                      handleTemplate={handleTemplate}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);

                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <ItemReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            autocompleteOptions={autocompleteOptions}
          />
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
