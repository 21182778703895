import { useState, useEffect } from 'react';

import { AutoCompleteSelectUI, DataGridUI, DataGridIdentifiers, DebouncedInputUI, FormLabelUI } from '../Interface';
import { dataGridColumns, generateFilter } from '../../utils/helper';
import userService from '../../service/user.service';

export default function UserReports({ refetch, values, viewField, operatorsDisabled }) {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const [filterFormState, setFilterFormState] = useState({
    userId: '',
    userName: '',
    pinCode: '',
    roleId: '',
    archive: '',
    nickName: '',
    activeId: '',
  });

  const conditions = {
    like: ['userName', 'pinCode'],
  };

  useEffect(() => {
    const defaultFilter = [
      {
        field: 'deletedBy',
        value: null,
      },
    ];
    const newFilter = generateFilter(filterFormState, conditions);
    setQuery((prev) => {
      return { ...prev, filter: [...defaultFilter, ...newFilter] };
    });
  }, [filterFormState]);

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  useEffect(() => {
    if (Object.keys(query).length > 0) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (refetchTable && query.filter?.length > 0) {
      getList();
    }
  }, [refetchTable]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    userService.getUsers(query).then((data) => {
      setReports(data);
      setRefetchTable(false);
    });
  };

  const showActive = (params) => {
    const { activeId } = params.row;
    if (activeId === 1) {
      return 'Igen';
    } else if (activeId === 2) {
      return 'Nem';
    } else {
      return 'Nincs adat';
    }
  };

  const columns = dataGridColumns([
    {
      field: 'nickName',
      headerName: 'Név',
      flex: 1,
    },
    {
      field: 'pinCode',
      headerName: 'PIN kód',
      flex: 1,
    },
    {
      field: 'roleName',
      headerName: 'Jogosultsági csoport',
      flex: 1,
      valueGetter: (data) => {
        return data.row.roles?.roleName;
      },
    },
    {
      field: 'activeId',
      headerName: 'Aktív',
      flex: 1,
      renderCell: (params) => {
        return showActive(params);
      },
    },
  ]);

  return (
    <div className="col-span-4 flex flex-col overflow-hidden bg-white shadow sm:rounded-lg">
      <div className="col h-full flex-col overflow-hidden bg-white p-2 shadow sm:rounded-lg">
        <div className="flex h-full overflow-hidden border-t border-gray-200">
          <div className="w-full overflow-x-auto xxl:overflow-x-hidden">
            <div className="sticky top-0 bg-white">
              <div className="pt-2 ">
                <form>
                  <div className="grid grid-cols-4 gap-x-4 mx-4">
                    <div className="col-span-2 md:col-span-1">
                      <DebouncedInputUI
                        label={<FormLabelUI text="Felhasználónév" />}
                        debounceMs={800}
                        setQuickFilterSearchValue={(newValue) => {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            userName: newValue,
                          }));
                        }}
                        quickFilterSearchValue={filterFormState.userName}
                      />
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <DebouncedInputUI
                        label={<FormLabelUI text="PIN kód" />}
                        debounceMs={800}
                        setQuickFilterSearchValue={(newValue) => {
                          setFilterFormState((prevState) => ({
                            ...prevState,
                            pinCode: newValue,
                          }));
                        }}
                        quickFilterSearchValue={filterFormState.pinCode}
                      />
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <AutoCompleteSelectUI
                        id="GroupId"
                        label={<FormLabelUI text="Jogosultsági csoport" />}
                        listType={{ id: 'roleId', name: 'roleName' }}
                        onChange={(_e, newVal, reason) => {
                          if (reason === 'clear') {
                            setFilterFormState((prevState) => ({
                              ...prevState,
                              roleId: '',
                            }));
                          } else {
                            setFilterFormState((prevState) => ({
                              ...prevState,
                              roleId: newVal.value,
                            }));
                          }
                        }}
                        selectedValue={filterFormState.roleId}
                        table="roles"
                      />
                    </div>
                    <div className="col-span-2 md:col-span-1">
                      <AutoCompleteSelectUI
                        id="GroupId"
                        label={<FormLabelUI text="Aktív" />}
                        table="WebUserArchive"
                        listType={{ id: 'archiveId', name: 'arhiveName' }}
                        onChange={(_e, newVal, reason) => {
                          if (reason === 'clear') {
                            setFilterFormState((prevState) => ({
                              ...prevState,
                              activeId: '',
                            }));
                          } else {
                            setFilterFormState((prevState) => ({
                              ...prevState,
                              activeId: newVal.value,
                            }));
                          }
                        }}
                        selectedValue={filterFormState.activeId}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="inline-block min-w-full border-b border-gray-200 align-middle mt-4 overflow-y-auto overflow-x-auto ">
              <div className="z-40 min-w-full h-[100%]">
                <DataGridUI
                  datagridIdentifier={DataGridIdentifiers.UserReports}
                  initialState={query}
                  rows={reports.rows}
                  rowCount={reports.rowCount}
                  columns={columns}
                  onRowClick={(rowData) => {
                    if (!viewField) return;
                    setSelectedValues(rowData.row);
                  }}
                  paginationMode="server"
                  onPaginationModelChange={onPaginitonChange}
                  sortingMode="server"
                  onSortModelChange={handleSortModelChange}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  rowSelectionModel={rowSelectionModel}
                  disableRowSelectionOnClick={!viewField}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
