import React, { useState } from 'react';
import { DeleteDialogUI, FileTabUI, FormErrorUI, FormLabelUI, InputUI, TabsUI } from '../Interface';
import DynamicPropertyMain from '../DynamicProperty/DynamicPropertyMain';
import { Field } from 'formik';
import supplierComplaintService from '../../service/supplierComplaint.service';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';

const DescriptionPanel = ({ disabled, errors, values }) => {
  return (
    <div>
      <div className="col-span-full relative">
        <div className="mt-2">
          <Field
            type="text"
            name="description"
            as={InputUI}
            className={`${disabled ? 'rounded-md' : ''} resize-none h-36, p-2`}
            fullWidth
            label={<FormLabelUI text="Megjegyzés" />}
            variant="standard"
            helperText={<FormErrorUI message={errors.description} />}
            disabled={disabled}
            InputLabelProps={{ shrink: values.description !== '' }}
            multiline
            rows={10}
          />
        </div>
      </div>
    </div>
  );
};

const ComplaintTabs = (props) => {
  const { user, selectedValues, setSelectedValues, disabled, setRefetchTable } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [removeElement, setRemoveElement] = useState(null);
  const [deleteDialog, setDeletDialog] = useState(false);

  const handleUpload = (files) => {
    if (!selectedValues.id) {
      return addNotification({
        content: 'Nincs kiválasztott elem!',
        type: NotificationType.ERROR,
      });
    }

    const uploadPromises = files.map((file) =>
      supplierComplaintService.uploadFile(file, selectedValues.id, user.userId).then((data) => {
        setSelectedValues((prev) => ({ ...prev, complaintAttachment: data }));
      })
    );

    Promise.all(uploadPromises)
      .then(() => {
        setRefetchTable(true);
      })
      .catch((error) => {
        console.error('Fájl feltöltési hiba:', error);
      });
  };

  const handleDelete = (data) => {
    setRemoveElement(data.id);
    setDeletDialog(true);
  };

  const hideDeleteDialog = () => {
    setDeletDialog(false);
    setRefetchTable(null);
  };

  const handleRemoveElement = (data) => {
    supplierComplaintService.deleteFile(removeElement, user.userId).then((data) => {
      setSelectedValues((prev) => ({ ...prev, complaintAttachment: data }));
      hideDeleteDialog();
    });
  };

  const tabs = [
    {
      id: 0,
      name: 'Tulajdonságok',
      content: <DynamicPropertyMain {...props} />,
    },
    {
      id: 1,
      name: 'Csatolmányok',
      content: (
        <FileTabUI
          disabled={disabled || !selectedValues.id}
          savedFiles={selectedValues.complaintAttachment ?? []}
          handleUpload={handleUpload}
          handleDelete={handleDelete}
        />
      ),
    },
    {
      id: 2,
      name: 'Megjegyzés',
      content: <DescriptionPanel {...props} />,
    },
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
      <DeleteDialogUI handleRemoveElement={handleRemoveElement} show={deleteDialog} onHide={() => hideDeleteDialog()} />
    </div>
  );
};

export default ComplaintTabs;
