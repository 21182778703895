import React, { useState, useEffect } from 'react';
import { NotificationType } from '../../config';
import addNotification from '../../utils/addNotification';
import sourcelistService from '../../service/sourcelist.service';
import { ColorPicker } from '../../config/ColorPicker';
import { Formik, Form, Field } from 'formik';
import Yup from '../../utils/yup';
import useUsers from '../../context/UsersContext';

import { ButtonUI, DialogUI, FormErrorUI, FormLabelUI, AutoCompleteSelectUI, InputUI } from '../Interface';

const SourceListMachineDialog = ({
  show,
  onHide,
  setRefetchTable,
  handleOpen,
  selectedItem,
  autocompleteOptions,
  machineOperation,
  selectedValuesState,
}) => {
  const [formData, setFormData] = useState({});
  const isEditMode = Object.keys(machineOperation).length > 0;

  const { setSelectedValues } = selectedValuesState;

  const defaultValues = {
    resourceId: null,
    neededQuantity: '',
  };

  const { getUser, user } = useUsers();

  const validationSchema = Yup.object().shape({
    resourceId: Yup.string().required(),
    neededQuantity: Yup.number().required().positive(),
  });

  useEffect(() => {
    if (isEditMode) {
      setFormData({
        resourceId: machineOperation.resourceId,
        neededQuantity: machineOperation.neededQuantity,
      });
    } else {
      setFormData(defaultValues);
    }
  }, [machineOperation, isEditMode]);

  useEffect(() => {
    setFormData(defaultValues);
    if (!Object.keys(user).length) getUser();
  }, []);

  const handleAddMachine = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
      sourceListOperationId: selectedItem.id,
    };

    try {
      const data = await sourcelistService.createOperationMachine(insertObject);
      //setTypeList((prevList) => [...prevList, data]);

      setSelectedValues((prev) => {
        let newState = JSON.parse(JSON.stringify(prev));
        const findedIndex = newState.sourceListOperation.findIndex((o) => o.id === selectedItem.id);

        const newSourceListOperationMachine = [
          ...newState.sourceListOperation?.[findedIndex].sourceListOperationMachine,
        ];
        newSourceListOperationMachine.push(data);

        newState.sourceListOperation[findedIndex].sourceListOperationMachine = newSourceListOperationMachine;

        return newState;
      });
      onHide();
      setRefetchTable(true);
    } catch (error) {
      console.error('Hiba történt:', error);
    }
  };

  const updateMachine = async (values) => {
    const insertObject = {
      ...values,
      updatedBy: user.userId,
      id: machineOperation.id,
    };
    try {
      const updatedItem = await sourcelistService.updateOperationMachine(insertObject, machineOperation.id);
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setSelectedValues((prev) => {
        let newState = JSON.parse(JSON.stringify(prev));
        const findedIndex = newState.sourceListOperation.findIndex((o) => o.id === selectedItem.id);

        const newSourceListOperationMachine = newState.sourceListOperation?.[findedIndex].sourceListOperationMachine;

        const machineIndex = newSourceListOperationMachine.findIndex((m) => m.id === updatedItem.id);

        newState.sourceListOperation[findedIndex].sourceListOperationMachine[machineIndex] = updatedItem;

        return newState;
      });
      onHide();
      setRefetchTable(true);
    } catch (error) {
      console.error('Hiba történt:', error);
    }
  };

  const handleCloseDialog = () => {
    onHide();
  };

  const handleSave = (values) => {
    if (Object.keys(machineOperation).length > 0) {
      updateMachine(values);
    } else {
      handleAddMachine(values);
    }
  };

  return (
    <DialogUI
      open={show}
      handleOpen={handleOpen}
      headerContent={isEditMode ? 'Elem szerkesztése' : 'Új elem felvitele a listába'}
      fullWidth={true}
    >
      <Formik
        initialValues={formData}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSave(values);
          setSubmitting(false);
        }}
        validateOnChange={false}
      >
        {({ isSubmitting, errors, values, setFieldValue, validateField }) => (
          <Form>
            <div className="relative my-3">
              <div className="grid grid-cols-6 gap-x-4 ">
                <div className="col-span-4 ">
                  <Field
                    type="autocomplete"
                    name="resourceId"
                    component={AutoCompleteSelectUI}
                    fullWidth
                    label={<FormLabelUI text="Erőforrás" />}
                    variant="standard"
                    helperText={<FormErrorUI message={errors.resourceId} />}
                    InputLabelProps={{ shrink: values.resourceId !== '' }}
                    onChange={(_e, newVal) => {
                      setFieldValue('resourceId', newVal?.value ?? null).then(() => {
                        validateField('resourceId');
                      });
                    }}
                    selectedValue={values.resourceId}
                    dataset={autocompleteOptions?.resource}
                  />
                </div>
                <div className=" col-span-2">
                  <Field
                    type="number"
                    as={InputUI}
                    variant="standard"
                    name="neededQuantity"
                    id="neededQuantity"
                    label={<FormLabelUI text="Szükséges menny." />}
                    helperText={<FormErrorUI message={errors.neededQuantity} />}
                  />
                </div>
              </div>
            </div>
            <div className="px-4 mt-8 flex justify-center gap-3">
              <ButtonUI
                type="submit"
                text={isEditMode ? 'Mentés' : 'Hozzáadás'}
                className="bg-success"
                disabled={isSubmitting}
              ></ButtonUI>
              <ButtonUI
                text="Mégse"
                className=""
                color="blue-gray"
                variant="gradient"
                onClick={handleCloseDialog}
                disabled={isSubmitting}
              ></ButtonUI>
            </div>
          </Form>
        )}
      </Formik>
    </DialogUI>
  );
};

export default SourceListMachineDialog;
