import { DialogUI, ButtonUI } from '.';

export default function ConfirmDialogUI({
  handleChangeElement,
  handleChangeOtherElement,
  handleOpen,
  show,
  onHide,
  message,
  title,
  confirmButtonText,
  otherButtonText,
  showConfirmButton = true,
  largeButtons,
  maxWidth,
}) {
  return (
    <DialogUI
      open={show}
      handleOpen={handleOpen}
      onClose={onHide}
      headerContent={title ?? 'Művelet megerősítése'}
      fullWidth={true}
      maxWidth={maxWidth}
    >
      <div className="relative">
        <div className="mt-2 flex gap-10 items-center justify-center z-50">
          <div className="relative">
            <div className="relative mt-5">
              <p className={`${largeButtons ? 'text-3xl' : 'text-2xl'} text-labelColor`}>
                {message ?? `Megerősíti a műveletet?`}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${largeButtons ? 'justify-evenly' : 'justify-center'} px-4 mt-8 flex gap-3`}>
        {showConfirmButton && (
          <ButtonUI
            text={confirmButtonText ?? 'Megerősítés'}
            className={`${largeButtons ? 'w-[200px] h-[200px] text-2xl bg-greenAccent' : 'bg-success'}`}
            onClick={handleChangeElement}
          ></ButtonUI>
        )}
        {otherButtonText && (
          <ButtonUI
            text={otherButtonText}
            className={`${largeButtons ? 'w-[200px] h-[200px] text-2xl' : ''} bg-warning`}
            onClick={handleChangeOtherElement}
          />
        )}
        <ButtonUI
          text="Mégse"
          className={`${largeButtons ? 'text-2xl w-[200px] h-[200px]' : ''}`}
          color="blue-gray"
          variant="gradient"
          onClick={onHide}
        ></ButtonUI>
      </div>
    </DialogUI>
  );
}
