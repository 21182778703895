export const BpType = {
  COMPANY: 'COMPANY',
  PRIVATE: 'PRIVATE',
};

export const BpTypeList = [
  { label: 'Cég', value: BpType.COMPANY },
  { label: 'Magánszemély', value: BpType.PRIVATE },
];

export const AddressType = {
  SHIPPING: 'SHIPPING',
  BILLING: 'BILLING',
};

export const AddressTypeList = [
  { label: 'Szállítási cím', value: AddressType.SHIPPING },
  { label: 'Számlacím', value: AddressType.BILLING },
];
