import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { isEqual } from 'lodash';
import Yup from '../../utils/yup';

import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import useMenus from '../../context/MenuContext';
import useUsers from '../../context/UsersContext';
import { FormButtonPanelUI, FormHeaderUI, FormBase, DeleteDialogUI } from '../Interface';
import { ConversionFields } from './ConversionFields';
import { ConversionReports } from './ConversionReports';
import conversionService from '../../service/conversion.service';
import masterDataService from '../../service/masterData.service';
import { generateAutocompleteItems } from '../../utils/helper';

export const ConversionPage = () => {
  const { menus, getMenus, getMenuSubMenuId, getCanEdit } = useMenus();
  const { getUser, user } = useUsers();
  const [refetchTable, setRefetchTable] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [canEdit, setCanEdit] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [defaultValues, setDefaultValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [initValues, setInitValues] = useState({});
  const [defaultSelectedValues, setDefaultSelectedValues] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [autocompleteOptions, setAutocompleteOptions] = useState({
    quantityUnit: [],
  });
  const [autoCompleteData, setAutoCompleteData] = useState({});

  const def = {
    fromQtyUnit: null,
    changeNum: '',
    toQtyUnit: '',
  };

  useEffect(() => {
    getMenus();
    if (!Object.keys(user).length) getUser();

    setDefaultValues(def);
    setDefaultSelectedValues(def);
    initDefaultValues();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('conversion');
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (!isEqual(defaultSelectedValues, selectedValues)) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
        setInitValues(defaultValues);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const initDefaultValues = () => {
    masterDataService
      .quantityUnitToForm()
      .then((data) => {
        const aData = {
          quantityUnit: data,
        };
        setAutoCompleteData(aData);

        const acOptions = {
          quantityUnit: generateAutocompleteItems(aData.quantityUnit, 'name', 'id'),
        };

        setAutocompleteOptions(acOptions);
      })
      .finally(() => {});
  };

  const validationSchema = Yup.object().shape({
    fromQtyUnit: Yup.string().required(),
    changeNum: Yup.number().required(),
    toQtyUnit: Yup.string().required(),
  });

  const valuesToForm = () => {
    const { id, fromQtyUnit, changeNum, toQtyUnit } = selectedValues;

    setInitValues({
      id,
      fromQtyUnit,
      changeNum,
      toQtyUnit,
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    } else if (!addField) {
      handleInsertObject(values);
    }
  };

  const updateObject = async (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    conversionService.updateData(updData).then((data) => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });

      setSelectedValues(data);
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleInsertObject = async (values) => {
    const insertObject = {
      ...values,
      createdBy: user.userId,
    };

    conversionService
      .createData(insertObject)
      .then((data) => {
        addNotification({
          content: 'Sikeres mentés!',
          type: NotificationType.SUCCESS,
        });

        setSelectedValues(data);
        setRowSelectionModel(data.id);
        setRefetchTable(true);
        setAddField(true);
        setViewField(true);
      })
      .finally(() => {
        //@TODO loader
      });
  };

  const handleRemoveElement = async () => {
    const deleteData = {
      deletedBy: user.userId,
    };
    conversionService.deleteData(deleteData, selectedValues.id).then(() => {
      addNotification({
        content: 'Sikeres törlés!',
        type: NotificationType.WARNING,
      });
      setRefetchTable(true);
      setShowDeleteDialog(false);
      setSelectedValues({});
      setInitValues(def);
    });
  };

  return (
    <>
      <FormBase
        name="Conversion"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                addClick={() => {
                  setSelectedValues(defaultSelectedValues);
                  setViewField(false);
                  setAddField(false);
                }}
                addDisabled={!viewField || !canEdit}
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled || !canEdit}
                deleteClick={() => {
                  setShowDeleteDialog(true);
                }}
                deleteDisabled={!viewField || operatorsDisabled || !canEdit}
                copyClick={() => {
                  setViewField(false);
                  setAddField(false);
                }}
                copyDisabled={!viewField || operatorsDisabled || !canEdit}
              />

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ resetForm }) => (
                  <Form>
                    <ConversionFields
                      viewField={viewField}
                      selectedValues={selectedValues}
                      autocompleteOptions={autocompleteOptions}
                      autoCompleteData={autoCompleteData}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);

                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <ConversionReports
            refetch={{ refetchTable, setRefetchTable }}
            values={{ selectedValues, setSelectedValues }}
            viewField={viewField}
            operatorsDisabled={operatorsDisabled}
            rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            autocompleteOptions={autocompleteOptions}
          />
        }
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
      />
    </>
  );
};
