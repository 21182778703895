import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'companyLocation';

const companyLocationService = {
  createCompanyLocation: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  getCompanyLocationReport: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/locations`,
      data,
    });
  },

  updateCompanyLocation: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteCompanyLocation: (data, id) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}`,
      data,
    });
  },
};

export default companyLocationService;
