import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { DataGridUI, DataGridIdentifiers, FormHeaderUI, DeleteDialogUI } from '../Interface';
import { dataGridColumns } from '../../utils/helper';
import machineActiveService from '../../service/machineActive.service';
import { AutoCompleteSelectUI } from '../Interface';

import ActiveTimeItems from './ActiveTimeItems';
import MachineActiveDialog from './MachineActiveDialog';

const MachineActive = ({ isAdditional, canEdit }) => {
  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [selectedValues, setSelectedValues] = useState({});
  const [machineActiveDialog, setMachineActiveDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [resourceId, setResourceId] = useState(null);
  const [query, setQuery] = useState({
    filter: [],
    sorting: {
      sortModel: [{ field: 'fromDate', sort: 'desc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const defaultFilter = [
    {
      field: 'archive',
      value: 'N',
    },
    {
      field: 'isAdditional',
      value: isAdditional,
    },
  ];

  const columns = dataGridColumns([
    {
      field: 'fromDate',
      headerName: 'Kezdete',
      valueGetter: (data) => {
        return dayjs(data.row.fromDate).format('YYYY.MM.DD');
      },
      flex: 1,
    },
    {
      field: 'toDate',
      headerName: 'Vége',
      flex: 1,
      valueGetter: (data) => {
        return dayjs(data.row.toDate).format('YYYY.MM.DD');
      },
    },
  ]);

  useEffect(() => {
    setQuery((prev) => {
      return { ...prev, filter: defaultFilter };
    });
  }, []);

  useEffect(() => {
    if (Object.keys(query).length > 0 && resourceId) {
      getList();
    }
  }, [query]);

  useEffect(() => {
    if (resourceId) {
      setQuery((prev) => {
        const newFilter = [
          ...defaultFilter,
          ...[
            {
              field: 'resourceId',
              value: resourceId,
            },
          ],
        ];
        return {
          ...prev,
          filter: newFilter,
        };
      });
    } else {
      setReports({ rows: [], rowCount: 0 });
      setSelectedValues({});
      setQuery((prev) => {
        return { ...prev, filter: defaultFilter };
      });
    }
  }, [resourceId]);

  useEffect(() => {
    setReports({ rows: [], rowCount: 0 });
    setSelectedValues({});
    setQuery((prev) => {
      return { ...prev, filter: defaultFilter };
    });
  }, [isAdditional]);

  const onPaginitonChange = (paginationModel) => {
    setQuery((prev) => {
      return { ...prev, pagination: { paginationModel } };
    });
  };

  const handleSortModelChange = (sortModel) => {
    setQuery((prev) => {
      const paginationModel = {
        pageSize: prev.pagination.paginationModel.pageSize,
        page: 0,
      };

      return {
        ...prev,
        sorting: { sortModel },
        pagination: { paginationModel },
      };
    });
  };

  const getList = () => {
    machineActiveService.getMachineActive(query).then((data) => {
      setReports(data);
    });
  };

  const handleRemoveElement = () => {
    machineActiveService.deleteMachineActive(selectedValues.id).then(() => {
      getList();
      setSelectedValues({});
      setDeleteDialog(false);
    });
  };

  return (
    <>
      <div className="grid grid-cols-1 xl:grid-cols-6 grid-rows-[auto_1fr] gap-y-4 xl:gap-4 bg-gray-100 p-4 min-h-full">
        <div className="col-span-4 md:col-span-2 w-full overflow-x-auto overflow-y-auto  h-full">
          <div className="flex flex-col h-full overflow-x-auto bg-white shadow sm:rounded-lg p-2">
            <div className="flex-1 h-full ">
              <FormHeaderUI
                addClick={() => {
                  setMachineActiveDialog(true);
                  setSelectedValues({});
                }}
                addDisabled={!resourceId || !canEdit}
                modClick={() => {
                  setMachineActiveDialog(true);
                }}
                modDisabled={!selectedValues?.id || !canEdit}
                deleteClick={() => {
                  setDeleteDialog(true);
                }}
                deleteDisabled={!selectedValues?.id || !canEdit}
              />
              <AutoCompleteSelectUI
                className="p-2"
                id="Resources"
                label={<span className="text-labelColor">Gép</span>}
                table="QNT_Resource"
                listType={{ id: 'resourceId', name: 'resourceName' }}
                onChange={(_e, newVal, reason) => {
                  if (reason === 'clear') {
                    setResourceId(null);
                  } else {
                    setResourceId(newVal.value);
                  }
                }}
                conditions={[`QNT_Resource.visiblity = 'Y'`]}
                selectedValue={resourceId}
              />
              <DataGridUI
                datagridIdentifier={DataGridIdentifiers.MachineActive}
                initialState={query}
                rows={reports.rows}
                rowCount={reports.rowCount}
                columns={columns}
                onRowClick={(rowData) => {
                  setSelectedValues(rowData.row);
                }}
                paginationMode="server"
                onPaginationModelChange={onPaginitonChange}
                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                disableRowSelectionOnClick={false}
              />
            </div>
          </div>
        </div>
        {selectedValues?.id && <ActiveTimeItems id={selectedValues.id} isMachine={true} />}
      </div>
      <MachineActiveDialog
        show={machineActiveDialog}
        onHide={() => setMachineActiveDialog(false)}
        selectedValues={selectedValues}
        setSelectedValues={setSelectedValues}
        refetch={() => getList()}
        isAdditional={isAdditional}
        resourceId={resourceId}
      />
      <DeleteDialogUI
        handleRemoveElement={handleRemoveElement}
        show={deleteDialog}
        onHide={() => setDeleteDialog(false)}
      />
    </>
  );
};

export default MachineActive;
