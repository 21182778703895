import React from 'react';
import { Field } from 'formik';
import { AutoCompleteSelectUI, FormErrorUI, FormLabelUI } from '../Interface';

const CompanyCustomerFields = ({
  viewField,
  values,
  errors,
  setFieldValue,
  validateField,
  autocompleteOptions,
  customerVatCode,
  salesPerson,
}) => {
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-3 mx-4 mb-5">
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="customerPriceListId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Árlista" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.customerPriceListId} />}
          onChange={(_e, newVal) => {
            setFieldValue('customerPriceListId', newVal?.value ?? null).then(() => {
              validateField('customerPriceListId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.customerPriceListId !== '' }}
          selectedValue={values.customerPriceListId}
          dataset={autocompleteOptions.priceList}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="customerPaymentMethodId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Fizetési mód" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.customerPaymentMethodId} />}
          onChange={(_e, newVal) => {
            setFieldValue('customerPaymentMethodId', newVal?.value ?? null).then(() => {
              validateField('customerPaymentMethodId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.customerPaymentMethodId !== '' }}
          selectedValue={values.customerPaymentMethodId}
          dataset={autocompleteOptions.paymentMethod}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="customerCurrencyCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Pénznem" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.customerCurrencyCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('customerCurrencyCode', newVal?.value ?? null).then(() => {
              validateField('customerCurrencyCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.customerCurrencyCode !== '' }}
          selectedValue={values.customerCurrencyCode}
          dataset={autocompleteOptions.currency}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="customerVatCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Áfakulcs" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.customerVatCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('customerVatCode', newVal?.value ?? null).then(() => {
              validateField('customerVatCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.customerVatCode !== '' }}
          selectedValue={values.customerVatCode}
          dataset={customerVatCode}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="customerDeliveryMethodId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Szállítási mód" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.customerDeliveryMethodId} />}
          onChange={(_e, newVal) => {
            setFieldValue('customerDeliveryMethodId', newVal?.value ?? null).then(() => {
              validateField('customerDeliveryMethodId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.customerDeliveryMethodId !== '' }}
          selectedValue={values.customerDeliveryMethodId}
          dataset={autocompleteOptions.deliveryMethod}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="customerIncotermCode"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Fuvarparitás" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.customerIncotermCode} />}
          onChange={(_e, newVal) => {
            setFieldValue('customerIncotermCode', newVal?.value ?? null).then(() => {
              validateField('customerIncotermCode');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.customerIncotermCode !== '' }}
          selectedValue={values.customerIncotermCode}
          dataset={autocompleteOptions.incoterm}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="salesPersonId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Értékesítő" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.salesPersonId} />}
          onChange={(_e, newVal) => {
            setFieldValue('salesPersonId', newVal?.value ?? null).then(() => {
              validateField('salesPersonId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.salesPersonId !== '' }}
          selectedValue={values.salesPersonId}
          dataset={salesPerson}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
    </div>
  );
};

export default CompanyCustomerFields;
