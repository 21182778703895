import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'classification';

const classificationService = {
  getClassifications: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/classifications`,
      data,
    });
  },

  createClassification: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateClassification: (data, classificationId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${classificationId}`,
      data,
    });
  },

  createClassificationItem: (data, id) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/classificationItem/${id}`,
      data,
    });
  },

  deleteClassificationItem: (data, classificationItemId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/classificationItem/${classificationItemId}`,
      data,
    });
  },

  updateClassificationItem: (data, classificationId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/classificationItem/${classificationId}`,
      data,
    });
  },

  updateDefaultValue: (id, itemId) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/defaultValue/${id}/${itemId}`,
    });
  },

  orderClassificationItem: (id, data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/orderClassificationItem/${id}`,
      data,
    });
  },
};

export default classificationService;
