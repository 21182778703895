import request from '../utils/Request';
import { Methods } from '../config';
import { downloadFile } from '../utils/download';

const baseURL = 'newDoc';

const documentService = {
  getDocuments: (data, docTypeId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/documents/${docTypeId}`,
      data,
    });
  },

  createDocument: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}`,
      data,
    });
  },

  updateDocument: (data, id) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/${id}`,
      data,
    });
  },

  deleteDocument: (data, id, docTypeId) => {
    return request({
      method: Methods.DELETE,
      resource: `${baseURL}/${id}/${docTypeId}`,
      data,
    });
  },

  printDocument: async (data) => {
    const pdf = await request({
      responseType: 'blob',
      method: Methods.POST,
      resource: `${baseURL}/print`,
      data,
    });

    return downloadFile(pdf, pdf.filename);
  },
};

export default documentService;
