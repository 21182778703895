import React, { useEffect, useState } from 'react';
import useParam from '../../context/ParamContext';

const ChipContent = ({ eventInfo }) => {
  const { params } = useParam();
  const [chipObj, setChipObj] = useState(null);

  useEffect(() => {
    const type = eventInfo.event._def.extendedProps.eventData.type;
    let paramKey = 'TASKGANTT_CHIP_CONTENT';
    if (type === 'PRODUCTION') {
      paramKey = 'TASKGANTT_PRODUCTION_CHIP_CONTENT';
    }

    if (params[paramKey]) {
      const parsedObj = JSON.parse(params[paramKey]);
      setChipObj(parsedObj);
    }
  }, [params]);

  const task = eventInfo.event._def.extendedProps.eventData;

  const getFieldValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => {
      if (Array.isArray(acc)) {
        return acc.map((item) => item[part]);
      } else {
        return acc ? acc[part] : null;
      }
    }, obj);
  };

  const setChipContent = () => {
    if (!chipObj || !task) {
      return null;
    }

    const productionPrefix = task.type === 'PRODUCTION' ? 'GY: ' : '';

    return (
      // @TODO style-t basszuk ki sos munka vót
      <span className="truncate block" style={{ marginTop: '2px', marginBottom: '2px' }}>
        {productionPrefix}
        {chipObj.map((item, index) => {
          const { label } = item;
          let taskValue = getFieldValue(task, label);

          return (
            <span key={index}>
              {index > 0 && ' - '}
              {taskValue}
            </span>
          );
        })}
      </span>
    );
  };

  return <div>{setChipContent()}</div>;
};

export default ChipContent;
