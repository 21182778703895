import { useEffect, useMemo, useState } from 'react';

import masterDataService from '../service/masterData.service';
import useMenus from '../context/MenuContext';
import useParam from '../context/ParamContext';
import useUsers from '../context/UsersContext';
import { generateAutocompleteItems } from '../utils/helper';

export const useSimpleTask = () => {
  const { menus, getMenus, getMenuSubMenuId, getSubMenuNamesByUrls, getCanEdit } = useMenus();
  const { getParam, params } = useParam();
  const { getUser, user } = useUsers();
  const [refetchTable, setRefetchTable] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [submenu, setSubmenu] = useState(null);
  const [canEdit, setCanEdit] = useState(true);
  const [loading, setLoading] = useState(true);

  const [autocompleteData, setAutocompleteData] = useState({});
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    type: [],
    status: [],
    resourceGroup: [],
    classification: [],
  });

  const initDefaultValues = () => {
    const promiseArray = [];

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .typeToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .statusToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .groupToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .projectToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .usersToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .priorityToForm(submenu)
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceOneToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .resourceFiveToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    promiseArray.push(
      new Promise((resolve, reject) => {
        masterDataService
          .companyLocationToForm()
          .then((data) => {
            resolve(data);
          })
          .catch((e) => {
            reject(e);
          });
      })
    );

    Promise.all(promiseArray)
      .then((resp) => {
        const aData = {
          type: resp[0],
          status: resp[1],
          resourceGroup: resp[2],
          project: resp[3],
          user: resp[4],
          priority: resp[5],
          resourceOne: resp[6],
          machine: resp[7],
          companylocation: resp[8],
        };
        setAutocompleteData(aData);

        const acOptions = {
          type: generateAutocompleteItems(aData.type.typeItems, 'itemName', 'itemId'),
          status: generateAutocompleteItems(aData.status.statusItems, 'itemName', 'itemId'),
          resourceGroup: generateAutocompleteItems(aData.resourceGroup.projectGroupListitems, 'itemName', 'itemId'),
          project: generateAutocompleteItems(aData.project, 'projectName', 'projectId'),
          user: generateAutocompleteItems(aData.user, 'userName', 'userId'),
          priority: generateAutocompleteItems(aData.priority.priorityListItems, 'itemName', 'itemId'),
          resourceOne: generateAutocompleteItems(aData.resourceOne, 'name', 'id'),
          machine: generateAutocompleteItems(aData.machine, 'machineName', 'machineId'),
          companylocation: generateAutocompleteItems(aData.companylocation, 'name', 'id'),
        };
        setAutoCompleteOptions(acOptions);
        setLoading(false);
      })
      .finally(() => {
        //hideLoader();
      });
  };

  useEffect(() => {
    getMenus();
    getParam();
    if (!Object.keys(user).length) getUser();
  }, []);

  useEffect(() => {
    const submenuId = getMenuSubMenuId('simpleTask');
    setSubmenu(submenuId);
    setCanEdit(getCanEdit(submenuId));
  }, [menus]);

  useEffect(() => {
    submenu && initDefaultValues();
  }, [submenu]);

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['resourceOne', 'resourceTwo', 'resourceThree', 'resourceFour', 'machine']);
  }, [menus]);

  return {
    refetchTable,
    setRefetchTable,
    viewField,
    setViewField,
    selectedValues,
    setSelectedValues,
    operatorsDisabled,
    setOperatorsDisabled,
    submenu,
    submenuNames,
    params,
    canEdit,
    autocompleteData,
    autocompleteOptions,
    loading,
  };
};
