import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'productionStatus';

const productionStatusService = {
  getRows: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/rows`,
      data,
    });
  },

  updateData: (data) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}`,
      data,
    });
  },

  updateDefaultValue: (code) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/defaultValue/${code}`,
    });
  },
};
export default productionStatusService;
