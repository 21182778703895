import request from '../utils/Request';
import { Methods } from '../config';

const baseURL = 'app';

const appService = {
  getMenuData: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getMenuData`,
    });
  },

  getUserPermissions: (roleId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getUserPermissions/${roleId}`,
    });
  },

  getRole: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getRole`,
    });
  },

  addRole: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/addRole`,
      data,
    });
  },

  getRoleInformation: (roleId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getRoleInformation?roleId=${roleId}`,
    });
  },

  getUserSubMenuPermissions: (roleId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getUserSubMenuPermissions?roleId=${roleId}`,
    });
  },

  getBiPermissions: (roleId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/biPermissions/${roleId}`,
    });
  },

  getDashboardPermissions: (roleId) => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/dashboardPermissions/${roleId}`,
    });
  },

  getAllSubMenus: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getAllSubMenus`,
    });
  },

  updateSubMenuPermission: (roleId, selectedSubMenu, permission, canEdit) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/updateSubMenuPermission?roleId=${roleId}&subMenuId=${selectedSubMenu}&hasPermission=${permission}`,
      data: { canEdit },
    });
  },

  updateBiRolePermission: (roleId, selectedBiReport, permission) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/updateBiPermission?roleId=${roleId}&biReportId=${selectedBiReport}&hasPermission=${permission}`,
    });
  },

  updateDashboardRolePermission: (roleId, selectedDashboardReport, permission) => {
    return request({
      method: Methods.PUT,
      resource: `${baseURL}/updateDashboardPermission?roleId=${roleId}&dashboardId=${selectedDashboardReport}&hasPermission=${permission}`,
    });
  },

  getJoinedData: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/getJoinedData`,
      data,
    });
  },

  addItem: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/addItem`,
      data,
    });
  },

  setItem: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/setItem`,
      data,
    });
  },

  updateTable: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/updateTable`,
      data,
    });
  },

  deleteItems: (data) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/deleteItems`,
      data,
    });
  },

  getStatus: (menuId) => {
    //@TODO backend
    return request({
      method: Methods.GET,
      resource: `${baseURL}/status/${menuId}`,
    });
  },

  getPriority: (menuId) => {
    //@TODO backend
    return request({
      method: Methods.GET,
      resource: `${baseURL}/priority/${menuId}`,
    });
  },

  getParams: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/getParams`,
    });
  },

  getConfig: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/config`,
    });
  },

  updateUserView: (data, userId) => {
    return request({
      method: Methods.PATCH,
      resource: `${baseURL}/userview/${userId}`,
      data,
    });
  },

  getKanbanMessages: (addressedTo) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/kanbanMessages/${addressedTo}`,
    });
  },

  getStatusBySubMenuId: (subMenuId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/statusItems/${subMenuId}`,
    });
  },

  getTypeBySubMenuId: (subMenuId) => {
    return request({
      method: Methods.POST,
      resource: `${baseURL}/typeItems/${subMenuId}`,
    });
  },

  getResponsiblePersons: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/responsiblePersons`,
    });
  },

  getEmployees: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/employees`,
    });
  },

  getVehicles: () => {
    return request({
      method: Methods.GET,
      resource: `${baseURL}/vehicles`,
    });
  },
};

export default appService;
