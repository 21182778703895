import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, SelectUI } from '../Interface';
import { QuantityUnitGroupList } from '../../config/quantityUnit';

export const QuantityUnitFields = ({ viewField }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();

  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="code"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Mennyiségi egység" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.code} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.code && values.code !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Megnevezés" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name && values.name !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="number"
          name="weight"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Súly kg" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.weight} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.weight && values.weight !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="navCode"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="NAV hozzárendelés" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.navCode} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.navCode && values.navCode !== '' }}
        />
      </div>

      <div className="col-span-4 md:col-span-2">
        <Field
          name="group"
          component={SelectUI}
          fullWidth
          label={<FormLabelUI text="Mennyiségi egység csoport" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.group} />}
          onChange={(e) => {
            const value = e.target.value;
            setFieldValue('group', value ?? null).then(() => {
              validateField('group');
            });
          }}
          value={values.group ?? ''}
          disabled={viewField}
          option={QuantityUnitGroupList}
        />
      </div>
    </div>
  );
};

export default QuantityUnitFields;
