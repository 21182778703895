import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import _ from 'lodash';

import Yup from '../../../utils/yup';
import addNotification from '../../../utils/addNotification';
import { NotificationType } from '../../../config';
import useUsers from '../../../context/UsersContext';
import documentTypeService from '../../../service/newDocumentType.service';
import { FormHeaderUI, FormButtonPanelUI, FormBase } from '../../Interface';
import DocumentTypeFields from './DocumentTypeFields';
import DocumentTypeReports from './DocumentTypeReports';
import DocumentTypeTabs from './DocumentTypeTabs';

const NewDocumentTypePage = () => {
  const { getUser, user } = useUsers();
  const [initValues, setInitValues] = useState({});
  const [refetchTable, setRefetchTable] = useState(false);
  const [viewField, setViewField] = useState(true);
  const [editButtonVisible, setEditButtonvisible] = useState(true);
  const [addField, setAddField] = useState(true);
  const [selectedValues, setSelectedValues] = useState({});
  const [operatorsDisabled, setOperatorsDisabled] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const defaultValues = {
    documentTypeId: '',
    name: '',
    direction: '',
    inventoryAccounting: false,
    active: false,
  };

  useEffect(() => {
    if (!Object.keys(user).length) getUser();

    setInitValues(defaultValues);
  }, []);

  useEffect(() => {
    if (Object.keys(selectedValues).length > 0) {
      if (selectedValues) {
        valuesToForm();
        setOperatorsDisabled(false);
      } else {
        setOperatorsDisabled(true);
      }
    } else {
      setOperatorsDisabled(true);
      setInitValues(defaultValues);
    }
  }, [selectedValues]);

  const valuesToForm = () => {
    const { documentTypeId, name, direction, inventoryAccounting, active } = selectedValues;

    setInitValues({
      documentTypeId,
      name,
      direction,
      inventoryAccounting,
      active,
    });
  };

  const validationSchema = Yup.object().shape({
    documentTypeId: Yup.string().required(),
    name: Yup.string().required(),
    direction: Yup.string().required(),
    inventoryAccounting: Yup.boolean(),
    active: Yup.boolean(),
  });

  const updateObject = (values) => {
    const updData = {
      ...values,
      updatedBy: user.userId,
    };

    documentTypeService.updateDocumentType(updData, selectedValues.documentTypeId).then(() => {
      addNotification({
        content: 'Sikeres módosítás!',
        type: NotificationType.SUCCESS,
      });
      setRefetchTable(true);
      setEditButtonvisible(true);
      setViewField(true);
    });
  };

  const handleSubmit = (values) => {
    if (!editButtonVisible) {
      updateObject(values);
    }
  };

  return (
    <>
      <FormBase
        name="DocumentType"
        leftPanelContent={
          <>
            <div className="h-full overflow-auto custom-scrollbar">
              <FormHeaderUI
                modClick={() => {
                  setViewField(false);
                  setEditButtonvisible(false);
                }}
                modDisabled={!viewField || operatorsDisabled}
              />

              <Formik
                initialValues={initValues}
                validationSchema={validationSchema}
                validateOnChange={false}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ resetForm }) => (
                  <Form>
                    <DocumentTypeFields viewField={viewField} selectedValues={selectedValues} />

                    <DocumentTypeTabs
                      user={user}
                      selectedValues={selectedValues}
                      setSelectedValues={setSelectedValues}
                      disabled={viewField}
                      setRefetchTable={setRefetchTable}
                    />

                    <FormButtonPanelUI
                      onCancelClick={() => {
                        setViewField(true);
                        setEditButtonvisible(true);
                        setAddField(true);
                        resetForm();
                      }}
                      disabled={editButtonVisible && addField}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </>
        }
        rightPanelContent={
          <>
            <DocumentTypeReports
              refetch={{ refetchTable, setRefetchTable }}
              values={{ selectedValues, setSelectedValues }}
              viewField={viewField}
              operatorsDisabled={operatorsDisabled}
              rowSelection={{ rowSelectionModel, setRowSelectionModel }}
            />
          </>
        }
      />
    </>
  );
};

export default NewDocumentTypePage;
