import React, { useEffect, useMemo, useState } from 'react';
import expiryMonitoringService from '../../service/expiryMonitoring.service';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import { dataGridPremiumColumns, generateAutocompleteItems } from '../../utils/helper';
import dayjs from 'dayjs';
import useUsers from '../../context/UsersContext';
import masterDataService from '../../service/masterData.service';

export const ExpiryMonitoringPage = () => {
  const { getUser, user } = useUsers();
  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [autocompleteOptions, setAutoCompleteOptions] = useState({
    submenus: [],
  });

  useEffect(() => {
    initDefaultValues();
  }, []);

  const initDefaultValues = () => {
    masterDataService
      .formTypeSubmenus()
      .then((data) => {
        const aData = {
          submenus: data,
        };

        const acOptions = {
          submenus: generateAutocompleteItems(aData.submenus, 'subMenuName', 'subMenuId'),
        };
        setAutoCompleteOptions(acOptions);
      })
      .finally(() => {});
  };

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
  }, []);

  const getList = (gridState) => {
    expiryMonitoringService.getExpiringFields(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'formId',
        headerName: 'Felület',
        type: 'string',
        sortable: false,
        renderCell: (params) => {
          return params.row.view;
        },
        type: 'singleSelect',
        valueOptions: autocompleteOptions.submenus,
      },
      {
        field: 'lineName',
        headerName: 'Név',
        type: 'string',
        sortable: false,
        filterable: false,
      },
      {
        field: 'propertyId',
        headerName: 'Tulajdonság',
        type: 'string',
        renderCell: (data) => {
          return data?.row?.propertyName;
        },
      },
      {
        field: 'dateVal',
        headerName: 'Lejárat dátuma',
        type: 'date',
        valueGetter: (_data, row) => {
          return new Date(row.dateVal);
        },
        renderCell: (data) => {
          return dayjs(data.row.dateVal).format('YYYY.MM.DD');
        },
      },
      {
        field: 'expiryAlertDays',
        headerName: 'Jelzés',
        sortable: false,
        type: 'number',
        renderCell: (params) => {
          return `${params.row.expiryAlertDays} nappal előtte`;
        },
      },
    ]);
  }, [autocompleteOptions]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.expiryMonitoring}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      rowCount={reports.rowCount}
      columns={[...columns]}
      paginationAndfilterMode="server"
    />
  );
};
