import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-premium';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import prodgeneratorService from '../../service/prodgenerator.service';
import useUsers from '../../context/UsersContext';
import useConfig from '../../context/ConfigContext';

const ProductionGeneratorTable = ({
  selectedRowsState,
  selectedItemState,
  removedItemState,
  addedItemState,
  refetchState,
}) => {
  const { getUser, user } = useUsers();
  const { config, getConfig } = useConfig();

  const { selectedRows, setSelectedRows } = selectedRowsState;
  const { setSelectedItem } = selectedItemState;
  const { setRemovedItem } = removedItemState;
  const { setAddedItem } = addedItemState;
  const { refetchTable, setRefetchTable } = refetchState;

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
    if (!Object.keys(config).length) getConfig();
  }, []);

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'dueDate', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 100,
        page: 0,
      },
    },
    density: 'compact',
  };

  const [loading, setLoading] = useState(true);
  const [gridState, setGridState] = useState(initialState);

  useEffect(() => {
    getList(gridState);
  }, [gridState]);

  useEffect(() => {
    if (Object.keys(user).length) {
      setLoading(false);
    }
  }, [user]);

  const getList = (gridState) => {
    prodgeneratorService.getOrders(gridState).then((res) => {
      setReports(res);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        renderHeader: () => <></>,
      },
      {
        field: 'dueDate',
        headerName: 'Határidő',
        type: 'date',
        valueGetter: (value) => new Date(value),
        valueFormatter: (value) => dayjs(value).format('YYYY.MM.DD.'),
      },
      {
        field: 'docNum',
        headerName: 'Bizonylatszám',
        type: 'string',
      },
      {
        field: 'lineNum',
        headerName: 'Sor',
        type: 'string',
      },
      {
        field: 'itemCode',
        headerName: 'Cikk',
        type: 'string',
      },
      {
        field: 'itemName',
        headerName: 'Cikk neve',
        type: 'string',
      },
      {
        field: 'qty',
        headerName: 'Rendelt menny.',
        type: 'number',
      },
      {
        field: 'quantityUnitCode',
        headerName: 'Me.kód',
        type: 'string',
      },
      {
        field: 'reference',
        headerName: 'Rend hiv. szám',
        type: 'string',
      },
      {
        field: 'itemReference',
        headerName: 'Tétel hiv. szám',
        type: 'string',
      },
      {
        field: 'companyCode',
        headerName: 'Partner kód',
        type: 'string',
      },
      {
        field: 'companyName',
        headerName: 'Partner név',
        type: 'string',
      },
      {
        field: 'onHand',
        headerName: 'Készleten',
        type: 'number',
      },
      {
        field: 'missingTree',
        headerName: 'Hiányos darabjegyzék',
        type: 'boolean',
      },
      {
        field: 'missingProdOrder',
        headerName: 'Hiányos gyártás',
        type: 'boolean',
      },
      {
        field: 'hasProduction',
        headerName: 'Készült hozzá gyártás',
        type: 'boolean',
      },
    ]);
  }, []);

  const getRowClassName = (params) => {
    let classNames = '';

    if (params.row.missingTree) classNames += 'datagrid-line-missingTree ';
    if (params.row.missingProdOrder) classNames += 'datagrid-line-missingProdOrder ';
    if (params.row.hasProduction && !params.row.missingTree) classNames += 'datagrid-line-hasProduction ';

    return classNames.trim();
  };

  return (
    <>
      {!loading && (
        <DatagridPremiumUi
          datagridIdentifier={DataGridIdentifiers.ProductionGeneratorTable}
          datagridInitialState={initialState}
          dataLoader={getList}
          height={400}
          rows={reports.rows}
          refetch={{ refetchTable, setRefetchTable }}
          rowCount={reports.rowCount}
          columns={[...columns]}
          paginationAndfilterMode="server"
          onRowClick={(params) => {
            setSelectedItem(params.row.itemCode);
          }}
          onGridStateChange={setGridState}
          getRowId={(row) => row.generatedNum}
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            const added = newRowSelectionModel.find((id) => !selectedRows.includes(id));
            const removed = selectedRows.find((id) => !newRowSelectionModel.includes(id));

            if (added) {
              const findedRow = reports.rows.find((row) => row.generatedNum === added);
              setSelectedItem(findedRow.itemCode);
              setAddedItem(findedRow);
              setRemovedItem(null);
            }
            if (removed) {
              const removedRow = reports.rows.find((row) => row.generatedNum === removed);
              setRemovedItem(removedRow);
              setAddedItem(null);
            }

            setSelectedRows(newRowSelectionModel);
          }}
          rowSelectionModel={selectedRows}
          getRowClassName={getRowClassName}
          isRowSelectable={(params) => !params.row.hasProduction}
          disableRowSelectionOnClick
          keepNonExistentRowsSelected
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
          }}
        />
      )}
    </>
  );
};

export default ProductionGeneratorTable;
