import React from 'react';
import JobMonitorCard from './JobMonitorCard';

const JobMonitorResources = ({ resource, getProductionById, onDragStart, onDragOver, onDrop }) => {
  const extendedRows = resource.productionOperation.flatMap((operation) => {
    const multiResources =
      operation.productionOperationMultiResource?.map((multiResource) => ({
        ...operation,
        isMultiResource: true,
        multiResourceId: multiResource.id,
      })) || [];

    return [operation, ...multiResources];
  });

  return (
    <div
      className="flex flex-col max-h-[calc(100vh-140px)] min-h-[calc(100vh-140px)] bg-gray-100 overflow-y-auto custom-scrollbar"
      onDragOver={(event) => onDragOver(event)}
      onDrop={(event) => onDrop(event, resource.id)}
    >
      <div>
        <div className={`bg-white pl-2 text-center text-xl font-bold relative py-3 text-labelColor`}>
          {resource.name}
        </div>
        <div className="p-2">
          {extendedRows.map((row) => (
            <JobMonitorCard
              data={row}
              getProductionById={getProductionById}
              onDragStart={onDragStart}
              type="RESOURCECOLUMN"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default JobMonitorResources;
