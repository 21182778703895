import React, { useEffect, useState } from 'react';
import { ButtonUI, DialogUI } from '../Interface';
import { Box } from '@mui/material';

const OperationWidgetDialog = ({ open, handleClose, filterState }) => {
  const { filter, setFilter } = filterState;
  const [pinCode, setPinCode] = useState('');

  useEffect(() => {
    if (!filter.pinCode) {
      clearAll();
    }
  }, [filter.pinCode]);

  const handleNumberClick = (number) => {
    const newPinCode = pinCode + number;
    if (newPinCode.length <= 4) {
      setPinCode(newPinCode);
    }
    if (newPinCode.length === 4) {
      setFilter((prev) => ({
        ...prev,
        pinCode: newPinCode,
      }));
    }
  };

  const clearAll = () => {
    setPinCode('');
  };

  const deleteLast = () => {
    setPinCode((prev) => prev.slice(0, -1));
  };

  const numberKeys = [];
  for (let i = 0; i < 9; i++) {
    numberKeys.push(
      <ButtonUI
        key={i}
        onClick={() => handleNumberClick(i.toString())}
        text={i}
        className="bg-[#3A4756] py-5 text-3xl rounded"
      />
    );
  }

  numberKeys.push(
    <ButtonUI key="clear" onClick={clearAll} text="Törlés" className="bg-[#3A4756] py-4 text-2xl rounded" />
  );

  numberKeys.push(
    <ButtonUI key="9" onClick={() => handleNumberClick('9')} text="9" className="bg-[#3A4756] py-4 text-2xl rounded" />
  );

  numberKeys.push(
    <ButtonUI key="delete" onClick={deleteLast} text="⌫" className="bg-[#3A4756] py-4 text-2xl rounded" />
  );

  return (
    <DialogUI
      open={open}
      handleClose={handleClose}
      headerContent="Bejelentkezés"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '500px',
          },
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: '10px' }}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Box
            key={index}
            sx={{
              width: '70px',
              height: '70px',
              background: '#3A4756',
              color: 'white',
              borderRadius: '8px',
              margin: '5px',
              textAlign: 'center',
              paddingTop: '0.8rem',
              fontSize: '2rem',
            }}
          >
            {pinCode[index] || ''}
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '0.5rem',
          marginTop: '2rem',
        }}
      >
        {numberKeys}
      </Box>
    </DialogUI>
  );
};

export default OperationWidgetDialog;
