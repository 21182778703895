import React, { useState, useEffect } from 'react';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi, DialogUI } from '../Interface';
import sourcelistService from '../../service/sourcelist.service';
import { Button } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-premium';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SourceListImportDialog from './SourceListImportDialog';

const CustomToolbar = ({ handleUploadClick }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <Button
        size="small"
        sx={{ ml: 'auto' }}
        startIcon={<FileUploadOutlinedIcon />}
        onClick={() => {
          handleUploadClick();
        }}
      >
        Import
      </Button>
    </GridToolbarContainer>
  );
};

const SourceListReports = ({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autocompleteOptions,
  getItemsToForm,
}) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'itemCode', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    sourcelistService.getSourcelists(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = dataGridPremiumColumns([
    {
      field: 'itemCode',
      type: 'string',
      headerName: 'Cikkszám',
    },
    {
      field: 'itemName',
      type: 'string',
      headerName: 'Cikknév',
      filterable: false,
      sortable: false,
      renderCell: (data) => {
        return data.row.item?.itemName;
      },
    },
    {
      field: 'baseQuantity',
      type: 'number',
      headerName: 'Mennyiség',
    },
    {
      field: 'quantityUnitId',
      type: 'string',
      headerName: 'Me.',
      filterable: false,
      sortable: false,
      renderCell: (data) => {
        return data.row.item.quantityUnit?.code;
      },
    },
  ]);

  const handleUploadClick = () => {
    setUploadDialogOpen(true);
  };

  return (
    <>
      <DatagridPremiumUi
        datagridIdentifier={DataGridIdentifiers.sourceList}
        datagridInitialState={initialState}
        dataLoader={getList}
        rows={reports.rows}
        refetch={{ refetchTable, setRefetchTable }}
        rowCount={reports.rowCount}
        columns={[...columns]}
        onRowClick={(rowData) => {
          if (!viewField) return;
          setSelectedValues(rowData.row);
        }}
        getRowId={(row) => row.itemCode}
        paginationAndfilterMode="server"
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        slots={{
          toolbar: CustomToolbar,
        }}
        slotProps={{
          toolbar: {
            handleUploadClick,
          },
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick={!viewField}
      />

      <DialogUI
        open={uploadDialogOpen}
        headerContent={'Importálás'}
        fullWidth={true}
        onClose={() => setUploadDialogOpen(false)}
        maxWidth="md"
      >
        <SourceListImportDialog
          autocompleteOptions={autocompleteOptions}
          onClose={() => setUploadDialogOpen(false)}
          refetch={refetch}
          getItemsToForm={getItemsToForm}
        />
      </DialogUI>
    </>
  );
};

export default SourceListReports;
