import React, { useState, useEffect, useMemo } from 'react';

import { dataGridPremiumColumns } from '../../utils/helper';
import { DataGridIdentifiers, DatagridPremiumUi } from '../Interface';
import priceListService from '../../service/priceList.service';
import { PriceFieldType, PriceListDirectionField } from '../../config/priceList';

export const PriceListReports = ({
  refetch,
  values,
  viewField,
  operatorsDisabled,
  rowSelection,
  autoCompleteOptions,
}) => {
  const { refetchTable, setRefetchTable } = refetch;
  const { setSelectedValues } = values;
  const { rowSelectionModel, setRowSelectionModel } = rowSelection;

  const [reports, setReports] = useState({ rows: [], rowCount: 0 });

  const initialState = {
    filter: { filterModel: { items: [], logicOperator: 'and' } },
    sorting: {
      sortModel: [{ field: 'name', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
    density: 'compact',
  };

  useEffect(() => {
    if (operatorsDisabled) {
      setRowSelectionModel([]);
    }
  }, [operatorsDisabled]);

  const getList = (gridState) => {
    priceListService.getItems(gridState).then((data) => {
      setReports(data);
    });
  };

  const columns = useMemo(() => {
    return dataGridPremiumColumns([
      {
        field: 'name',
        headerName: 'Árlista neve',
        type: 'string',
      },
      {
        field: 'currencyCode',
        headerName: 'Árlista pénzneme',
        type: 'singleSelect',
        valueOptions: autoCompleteOptions.currency,
      },
      {
        field: 'direction',
        headerName: 'Irány',
        type: 'singleSelect',
        valueOptions: PriceListDirectionField,
      },
      {
        field: 'priceForm',
        headerName: 'Árforma',
        type: 'singleSelect',
        valueOptions: PriceFieldType,
      },
    ]);
  }, [autoCompleteOptions]);

  return (
    <DatagridPremiumUi
      datagridIdentifier={DataGridIdentifiers.priceList}
      datagridInitialState={initialState}
      dataLoader={getList}
      rows={reports.rows}
      refetch={{ refetchTable, setRefetchTable }}
      rowCount={reports.rowCount}
      columns={[...columns]}
      onRowClick={(rowData) => {
        if (!viewField) return;
        setSelectedValues(rowData.row);
      }}
      paginationAndfilterMode="server"
      onRowSelectionModelChange={(newRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      disableRowSelectionOnClick={!viewField}
    />
  );
};
