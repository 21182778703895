import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import BuildIcon from '@mui/icons-material/Build';
import PostAddIcon from '@mui/icons-material/PostAdd';

import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import sourcelistService from '../../service/sourcelist.service';
import { InputUI } from '../Interface';
import useUsers from '../../context/UsersContext';
import addNotification from '../../utils/addNotification';
import { NotificationType } from '../../config';
import { v4 as uuidv4 } from 'uuid';

const renderTree = (node, handleInputChange, handleInputBlur, formVals) => {
  const baseId = node.id;

  return (
    <TreeItem
      key={`${baseId}-${node.itemCode}_${node.lineId}`}
      itemId={`${baseId}-${node.itemCode}_${node.lineId}`}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PostAddIcon style={{ marginRight: 5 }} fontSize="small" />
          {node.itemCode} - {node.item.itemName}
        </div>
      }
    >
      {node.sourceListOperation?.map((o) => {
        const machineId = o.sourceListOperationMachine[0].id;
        const machineQty = o.sourceListOperationMachine[0].neededQuantity;

        return (
          <TreeItem
            key={`${baseId}-${o.id}`}
            itemId={`${baseId}-${o.id}`}
            label={
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <BuildIcon fontSize="small" />
                  <span>{o.operation.operationName}</span>
                  <InputUI
                    type="number"
                    value={formVals[machineId]}
                    onChange={(e) => handleInputChange(machineId, e.target.value)}
                    onBlur={(e) => handleInputBlur(machineId, e.target.value, machineQty)}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                    sx={{ width: '80px' }}
                    inputProps={{ sx: { padding: '2px', textAlign: 'center' } }}
                  />
                </Box>
              </>
            }
          />
        );
      })}

      {node.subParts?.map((subPart) => {
        return renderTree(subPart, handleInputChange, handleInputBlur, formVals);
      })}
    </TreeItem>
  );
};

const SourceListTree = ({ itemCode }) => {
  const { getUser, user } = useUsers();

  const [treeData, setTreeData] = useState(null);
  const [expandedItems, setExpandedItems] = useState([]);
  const [formVals, setFormVals] = useState({});
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (!Object.keys(user).length) getUser();
  }, []);

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  const handleExpandClick = () => {
    setExpandedItems((prevExpanded) => {
      if (prevExpanded.length === 0 && treeData) {
        return getAllItemIds(treeData);
      } else {
        return [];
      }
    });
  };

  const getAllItemIds = (node, ids = []) => {
    if (!node) return ids;
    ids.push(node.itemCode);
    node.subParts?.forEach((subPart) => getAllItemIds(subPart, ids));
    return ids;
  };

  const getTreeData = () => {
    sourcelistService.getTreeData(itemCode).then((data) => {
      setTreeData(data);
      setExpandedItems(getAllItemIds(data));
      const initialFormVals = {};
      const initializeFormVals = (node) => {
        node.sourceListOperation?.forEach((o) => {
          const machineId = o.sourceListOperationMachine[0].id;
          const machineQty = o.sourceListOperationMachine[0].neededQuantity;
          initialFormVals[machineId] = machineQty;
        });
        node.subParts?.forEach(initializeFormVals);
      };
      initializeFormVals(data);
      setFormVals(initialFormVals);
    });
  };

  const handleInputChange = (id, newValue) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setFormVals((prevFormVals) => ({
      ...prevFormVals,
      [id]: newValue,
    }));

    if (!newValue.trim()) {
      return;
    }

    const formattedValue = parseFloat(newValue.replace(',', '.'));
    if (isNaN(formattedValue)) {
      addNotification({
        content: `Nem elfogadható szám: ${newValue}`,
        type: NotificationType.WARNING,
      });
      return;
    }

    timeoutRef.current = setTimeout(() => {
      sourcelistService
        .updateMachineNeededQty({
          sourceListOperationMachineId: id,
          neededQuantity: formattedValue,
          updatedBy: user.userId,
        })
        .then(() => {
          addNotification({
            content: `Sikeres mentés: ${formattedValue}`,
            type: NotificationType.INFO,
          });
        });
    }, 500);
  };

  const handleInputBlur = (id, currentValue, defaultValue) => {
    if (!currentValue.trim()) {
      addNotification({
        content: `Az elhagyott mező kiindulási állapotát visszatöltöttük: ${defaultValue}`,
        type: NotificationType.WARNING,
      });
      handleInputChange(id, defaultValue.toString());
    }
  };

  useEffect(() => {
    if (itemCode) {
      getTreeData();
    } else {
      setTreeData(null);
    }
  }, [itemCode]);

  return (
    <Stack spacing={0}>
      <div>
        <Button onClick={handleExpandClick}>{expandedItems.length === 0 ? 'Kinyit' : 'Becsuk'}</Button>
      </div>
      <Box sx={{ minHeight: 180, minWidth: 250 }}>
        {treeData && (
          <SimpleTreeView expandedItems={expandedItems} onExpandedItemsChange={handleExpandedItemsChange}>
            {renderTree(treeData, handleInputChange, handleInputBlur, formVals)}
          </SimpleTreeView>
        )}
      </Box>
    </Stack>
  );
};

export default SourceListTree;
