import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import jobMonitorService from '../../../service/jobMonitor.service';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import useParam from '../../../context/ParamContext';
import OpWidgetJobmonitorCard from './OpWidgetJobmonitorCard';
import usePages from '../../../context/PageContext';
import useMenus from '../../../context/MenuContext';
import { IconButton } from '@mui/material';

export const OpWidgetJobmonitorPage = () => {
  const { operationId, userId } = useParams();
  const { setPages } = usePages();
  const location = useLocation();
  const navigate = useNavigate();
  const { menus, getSubMenuNamesByUrls } = useMenus();
  const { getParam, params } = useParam();
  const [prodOperations, setProdOperations] = useState({ rows: [], rowCount: 0 });
  const [hasMore, setHasMore] = useState(true);
  const [allFiltersApplied, setAllFiltersApplied] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [query, setQuery] = useState({
    filter: {
      filterModel: {
        items: [],
        logicOperator: 'and',
      },
    },
    sorting: {
      sortModel: [{ field: 'startDate', sort: 'asc' }],
    },
    pagination: {
      paginationModel: {
        pageSize: 25,
        page: 0,
      },
    },
  });

  const parsedUserId = userId ? parseInt(userId) : null;
  const parsedOperationId = operationId ? parseInt(operationId) : null;

  useEffect(() => {
    getParam();
  }, []);

  useEffect(() => {
    if (allFiltersApplied && query.filter.filterModel.items.length > 0) {
      getList();
    }
  }, [allFiltersApplied, query, refetch]);

  useEffect(() => {
    if (parsedOperationId) {
      Promise.all([handleFilterChange('operationId', 'is', parsedOperationId), updateQueryForStatusId()]).then(() => {
        setAllFiltersApplied(true);
      });
    } else {
      handleFilterChange('productionStatusId', 'is', 'COMPLETED');
      setAllFiltersApplied(true);
    }
  }, [parsedOperationId, params]);

  const getList = () => {
    jobMonitorService
      .getProductionOperations(query)
      .then((data) => {
        setProdOperations((prev) => ({
          rows: query.pagination.paginationModel.page === 0 ? data.rows : [...prev.rows, ...data.rows],
          rowCount: data.rowCount,
        }));
        setHasMore(prodOperations.rows.length < data.rowCount);
        setRefetch(false);
      })
      .finally(() => {});
  };

  const handleFilterChange = (field, operator, value) => {
    setQuery((prev) => {
      let newItems = prev.filter.filterModel.items;

      if (Array.isArray(field)) {
        newItems = newItems.filter((item) => !field.some((f) => f.field === item.field));

        const validFilters = field
          .filter((f) => f.value !== null && f.value !== undefined)
          .map((f) => ({
            field: f.field,
            operator: operator,
            value: f.value?.value ?? f.value,
          }));
        newItems = newItems.concat(validFilters);
      } else {
        newItems = newItems.filter((item) => item.field !== field);
        if (value !== null && value !== undefined) {
          newItems.push({ field, operator, value });
        }
      }

      return {
        ...prev,
        filter: {
          filterModel: {
            items: newItems,
          },
        },
      };
    });
  };

  const updateQueryForStatusId = () => {
    if (!params || !params.JOBMONITOR_STATUSES) {
      return;
    }

    const statusConfig = JSON.parse(params.JOBMONITOR_STATUSES);
    const statusIds = statusConfig.noPreviousWork;
    handleFilterChange('productionStatusId', 'isAnyOf', statusIds);
  };

  const fetchMoreData = () => {
    setQuery((prev) => ({
      ...prev,
      pagination: {
        paginationModel: {
          ...prev.pagination.paginationModel,
          page: prev.pagination.paginationModel.page + 1,
        },
      },
    }));
  };

  const submenuNames = useMemo(() => {
    return getSubMenuNamesByUrls(['operationWidget']);
  }, [menus]);

  const handleBack = () => {
    setPages({ subMenuName: submenuNames[0] });
    navigate(`/app/operationWidget`, { state: { userId: location?.state?.userId } });
  };

  return (
    <div className="bg-[#CDDFF7] min-h-full px-6 py-2">
      <div className="pb-5 px-3 flex gap-5">
        {(parsedOperationId || location.state.meo) && (
          <IconButton size="small" onClick={handleBack}>
            <div className="text-sm font-medium py-2 px-3 rounded flex items-center justify-center gap-1 bg-ganttHoverButtonColor text-white mt-2">
              <KeyboardBackspaceIcon sx={{ fontSize: '2rem' }} />
              <span className="text-2xl">Vissza</span>
            </div>
          </IconButton>
        )}
      </div>
      <InfiniteScroll dataLength={prodOperations.rows.length} next={fetchMoreData} hasMore={hasMore}>
        <div className="flex gap-5 justify-center flex-wrap">
          {prodOperations?.rows.length > 0 &&
            prodOperations.rows.map((operation) => (
              <OpWidgetJobmonitorCard
                key={operation.id}
                data={operation}
                setRefetch={setRefetch}
                userId={parsedUserId}
                meo={location.state.meo}
                params={params}
              />
            ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};
