import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, CheckboxUI, SwitchUI } from '../Interface';

export const SalesOrPurchaseFields = ({ viewField }) => {
  const { values, errors, setFieldValue } = useFormikContext();

  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5 items-center">
      <div className="md:col-span-4 col-span-4">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Név" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name && values.name !== '' }}
        />
      </div>
      <div className="md:col-span-4 col-span-4">
        <Field
          type="text"
          name="purchaser"
          as={SwitchUI}
          fullWidth
          label={<FormLabelUI text="Beszerző" />}
          checked={values.purchaser ?? false}
          onClick={(e) => setFieldValue('purchaser', !e.target.checked)}
          disabled={viewField}
        />
        <Field
          type="text"
          name="salesPerson"
          as={SwitchUI}
          fullWidth
          label={<FormLabelUI text="Értékesítő" />}
          onClick={(e) => setFieldValue('salesPerson', !e.target.checked)}
          checked={values.salesPerson ?? false}
          disabled={viewField}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="email"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Email cím" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.email} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.email && values.email !== '' }}
        />
      </div>
      <div className="md:col-span-1 col-span-4">
        <Field
          type="text"
          name="phoneNumber"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Telefonszám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.phoneNumber} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.phoneNumber && values.phoneNumber !== '' }}
        />
      </div>
      <div className="md:col-span-1 col-span-4">
        <Field
          type="text"
          name="other"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Egyéb" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.other} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.other && values.other !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          name="active"
          type="checkbox"
          as={CheckboxUI}
          fullWidth
          label={<FormLabelUI text="Aktív" />}
          disabled={viewField}
        />
      </div>
    </div>
  );
};
