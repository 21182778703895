export const DocumentType = {
  SalesQuotation: 20,
  CustomerOrder: 21,
  Delivery: 22,
  Return: 23,
  DownPaymentRequest: 24,
  DownPayment: 25,
  ARInvoice: 26,
  PurchaseRequest: 30,
  PurchaseQuotation: 10,
  PurchaseOrder: 11,
  PurchaseDelivery: 12,
  GoodsReturn: 13,
  APDownPaymentRequest: 14,
  PurchaseAdvanceInvoice: 15,
  APInvoice: 16,
  InventoryTransfer: 31,
  GoodsReceipt: 32,
  GoodsIssue: 33,
  InventoryCounting: 34,
  InventoryCountingDif: 35,
  ReceiptFromProduction: 36,
  IssueForProduction: 37,
  ProductionOrder: 38,
};

export const DocumentTypeRoutes = {
  [DocumentType.SalesQuotation]: 'SalesQuotation',
  [DocumentType.CustomerOrder]: 'CustomerOrder',
  [DocumentType.Delivery]: 'DeliveryNote',
  [DocumentType.Return]: 'Return',
  [DocumentType.DownPaymentRequest]: 'DownPaymentRequest',
  [DocumentType.DownPayment]: 'DownPayment',
  [DocumentType.ARInvoice]: 'ARInvoice',
  [DocumentType.PurchaseRequest]: 'PurchaseRequest',
  [DocumentType.PurchaseQuotation]: 'PurchaseQuotation',
  [DocumentType.PurchaseOrder]: 'PurchaseOrder',
  [DocumentType.PurchaseDelivery]: 'PurchaseDeliveryNote',
  [DocumentType.GoodsReturn]: 'GoodsReturn',
  [DocumentType.APDownPaymentRequest]: 'APDownPaymentRequest',
  [DocumentType.PurchaseAdvanceInvoice]: 'PurchaseAdvanceInvoice',
  [DocumentType.APInvoice]: 'APInvoice',
  [DocumentType.InventoryTransfer]: 'InventoryTransfer',
  [DocumentType.GoodsReceipt]: 'GoodsReceipt',
  [DocumentType.GoodsIssue]: 'GoodsIssue',
  [DocumentType.InventoryCounting]: 'InventoryCounting',
  [DocumentType.InventoryCountingDif]: 'InventoryCountingDif',
  [DocumentType.ReceiptFromProduction]: 'ReceiptFromProduction',
  [DocumentType.IssueForProduction]: 'IssueForProduction',
  [DocumentType.ProductionOrder]: 'ProductionOrder',
};
