export const QuantityUnitGroup = {
  OTHER: 'OTHER',
  TIME: 'TIME',
  WEIGHT: 'WEIGHT',
  LENGTH: 'LENGTH',
  AREA: 'AREA',
  VOLUME: 'VOLUME',
  SPACESCALE: 'SPACESCALE',
};

export const QuantityUnitGroupList = [
  { label: 'Egyéb', value: QuantityUnitGroup.OTHER },
  { label: 'Idő', value: QuantityUnitGroup.TIME },
  { label: 'Súly', value: QuantityUnitGroup.WEIGHT },
  { label: 'Hosszúság', value: QuantityUnitGroup.LENGTH },
  { label: 'Terület', value: QuantityUnitGroup.AREA },
  { label: 'Térfogat', value: QuantityUnitGroup.VOLUME },
  { label: 'Űrmérték', value: QuantityUnitGroup.SPACESCALE },
];
