import { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { GridRowModes, GridToolbarContainer, GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid-premium';
import { Field, FieldArray, useFormikContext } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { multiply, round, divide, add } from 'mathjs';

import { dataGridPremiumColumns } from '../../utils/helper';
import {
  DataGridIdentifiers,
  FormErrorUI,
  InputUI,
  AutoCompleteSelectUI,
  AddButtonUi,
  DatagridPremiumUi,
} from '../Interface';
import useConfig from '../../context/ConfigContext';
import { formatCurrency } from '../../utils/currencyHelper';
import priceListService from '../../service/priceList.service';

const EditToolbar = (props) => {
  const { apiRef, disabled, push, defaultField, documentData, selectedCompany, autocompleteData } = props;
  const { values, validateField } = useFormikContext();

  const handleClick = async () => {
    defaultField.id = uuidv4();
    defaultField.vatCode = selectedCompany
      ? documentData.direction === DocumentType.INCOMING
        ? selectedCompany?.supplierVatCode
        : selectedCompany?.customerVatCode
      : null;
    const findedVat = autocompleteData.vatCode.find((u) => u.code === defaultField.vatCode);
    defaultField.vatPercent = findedVat?.percentage ?? 0;
    defaultField.projectId = values.projectId;
    defaultField.costCenterCode = values.costCenterCode;

    await push(defaultField);
    apiRef.current.startRowEditMode({ id: defaultField.id });

    const fieldsToValidate = [
      `docLine.${values.docLine.length ?? 0}.itemCode`,
      `docLine.${values.docLine.length ?? 0}.itemCodeInBp`,
      `docLine.${values.docLine.length ?? 0}.qty`,
      `docLine.${values.docLine.length ?? 0}.quantityUnitId`,
      `docLine.${values.docLine.length ?? 0}.whsCode`,
      `docLine.${values.docLine.length ?? 0}.toWhsCode`,
      `docLine.${values.docLine.length ?? 0}.unitPrice`,
      `docLine.${values.docLine.length ?? 0}.vatCode`,
      `docLine.${values.docLine.length ?? 0}.netPrice`,
      `docLine.${values.docLine.length ?? 0}.grossPrice`,
      `docLine.${values.docLine.length ?? 0}.dueDate`,
      `docLine.${values.docLine.length ?? 0}.weight`,
      `docLine.${values.docLine.length ?? 0}.projectId`,
      `docLine.${values.docLine.length ?? 0}.costCenterCode`,
      `docLine.${values.docLine.length ?? 0}.itemReference`,
      `docLine.${values.docLine.length ?? 0}.internalNote`,
      `docLine.${values.docLine.length ?? 0}.bpNote`,
    ];

    for (const field of fieldsToValidate) {
      validateField(field);
    }
  };

  return (
    <GridToolbarContainer>
      <AddButtonUi disabled={disabled} onClick={handleClick} />
    </GridToolbarContainer>
  );
};

const DocumentLine = ({
  disabled,
  defaultField,
  autocompleteOptions,
  autocompleteData,
  documentData,
  selectedCompanyState,
  viewField,
}) => {
  const apiRef = useGridApiRef();
  const { values, validateField, setFieldValue, errors } = useFormikContext();
  const { config, getConfig } = useConfig();
  const { selectedCompany } = selectedCompanyState;

  const docTotal = () => {
    const lines = values.docLine ?? [];

    const totalNetPrice = lines.reduce((acc, line) => acc + parseFloat(line.netPrice ?? 0), 0);
    const totalVatValue = lines.reduce((acc, line) => acc + parseFloat(line.vatValue ?? 0), 0);
    const totalGrossPrice = lines.reduce((acc, line) => acc + parseFloat(line.grossPrice ?? 0), 0);
    const totalNetPriceInOwnCur = lines.reduce((acc, line) => acc + parseFloat(line.netPriceInOwnCur ?? 0), 0);
    const totalVatValueInOwnCur = lines.reduce((acc, line) => acc + parseFloat(line.vatValueInOwnCur ?? 0), 0);
    const totalGrossPriceInOwnCur = lines.reduce((acc, line) => acc + parseFloat(line.grossPriceInOwnCur ?? 0), 0);

    setFieldValue('sumInDocCur', totalGrossPrice);
    setFieldValue('netInDocCur', totalNetPrice);
    setFieldValue('VATInDocCur', totalVatValue);
    //setFieldValue('roundingInDocCur', );

    setFieldValue('sumInOwnCur', totalGrossPriceInOwnCur);
    setFieldValue('netInOwnCur', totalNetPriceInOwnCur);
    setFieldValue('VATInOwnCur', totalVatValueInOwnCur);
    //setFieldValue('roundingInOwnCur', );
  };

  useEffect(() => {
    docTotal();
  }, [values.docLine, values.exchangeRate]);

  useEffect(() => {
    if (!Object.keys(config).length) getConfig();
  }, [getConfig, config]);

  const getValueIndex = (values, id) => {
    return values?.docLine?.findIndex((rc) => rc.id === id);
  };

  const columns = (setFieldValue, validateField, errors, remove, calculatePrice, calculateWeight) => {
    return dataGridPremiumColumns([
      {
        field: 'itemCode',
        headerName: 'Cikk',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions?.item.find((u) => u.value === values.docLine?.[valueIndex]?.itemCode);

          return findedItem?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions?.item.find((u) => u.value === values.docLine?.[valueIndex]?.itemCode);
          return (
            <Field
              name={`docLine.${valueIndex}.itemCode`}
              component={AutoCompleteSelectUI}
              helperText={<FormErrorUI message={errors?.docLine?.[valueIndex]?.itemCode} />}
              value={findedItem}
              onChange={async (_e, newVal) => {
                setFieldValue(`docLine.${valueIndex}.itemCode`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });

                const selectedItem = autocompleteData.item.find((i) => i.itemCode === newVal?.value);
                if (selectedItem) {
                  setFieldValue(`docLine.${valueIndex}.itemName`, selectedItem?.itemName ?? null);
                  setFieldValue(`docLine.${valueIndex}.quantityUnitId`, selectedItem?.quantityUnitId ?? null);
                  const findedQtyUnit = autocompleteData.qtyUnit.find((u) => u.id === selectedItem?.quantityUnitId);
                  setFieldValue(`docLine.${valueIndex}.quantityUnitCode`, findedQtyUnit?.code ?? null);
                  setFieldValue(`docLine.${valueIndex}.quantityUnitName`, findedQtyUnit?.name ?? null);
                  setFieldValue(`docLine.${valueIndex}.weightChangeNumber`, selectedItem?.weight ?? null);

                  calculateWeight(valueIndex, values.docLine[valueIndex].qty, selectedItem?.weight ?? null);

                  if (values.priceListId) {
                    const pl = await priceListService.getItemPrice({
                      itemCode: newVal?.value,
                      priceListId: values.priceListId,
                    });

                    if (pl.price) {
                      setFieldValue(`docLine.${valueIndex}.unitPrice`, pl.price);
                      calculatePrice(
                        valueIndex,
                        values.docLine[valueIndex].qty,
                        pl.price,
                        values.docLine[valueIndex].vatPercent
                      );
                    }
                  }
                } else {
                  setFieldValue(`docLine.${valueIndex}.itemName`, null);
                  setFieldValue(`docLine.${valueIndex}.quantityUnitId`, null);
                  setFieldValue(`docLine.${valueIndex}.quantityUnitCode`, null);
                  setFieldValue(`docLine.${valueIndex}.quantityUnitName`, null);
                  setFieldValue(`docLine.${valueIndex}.weightChangeNumber`, null);
                  setFieldValue(`docLine.${valueIndex}.unitPrice`, null);
                }
              }}
              dataset={autocompleteOptions?.item}
              onBlur={async () => await validateField(`docLine.${valueIndex}.itemCode`)}
            />
          );
        },
      },

      {
        field: 'itemCodeInBp',
        headerName: 'ÜP cikkszám',
        type: 'string',
        editable: true,
        sortable: false,

        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`docLine.${valueIndex}.itemCodeInBp`}
              as={InputUI}
              type="text"
              variant="standard"
              fullWidth
              helperText={<FormErrorUI message={errors?.docLine?.[valueIndex]?.itemCodeInBp} />}
              onChange={(e) => {
                setFieldValue(`docLine.${valueIndex}.itemCodeInBp`, e.target.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`docLine.${valueIndex}.itemCodeInBp`)}
            />
          );
        },
      },
      {
        field: 'qty',
        headerName: 'Mennyiség',
        type: 'number',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`docLine.${valueIndex}.qty`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.docLine?.[valueIndex]?.qty} />}
              onChange={(e) => {
                setFieldValue(`docLine.${valueIndex}.qty`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });

                calculatePrice(
                  valueIndex,
                  e.target.value ?? '0',
                  values.docLine[valueIndex].unitPrice,
                  values.docLine[valueIndex].vatPercent
                );

                calculateWeight(valueIndex, e.target.value ?? '0', values.docLine[valueIndex].weightChangeNumber);
              }}
              onBlur={async () => await validateField(`docLine.${valueIndex}.qty`)}
            />
          );
        },
      },
      {
        field: 'quantityUnitCode',
        headerName: 'ME',
        type: 'string',
        editable: false,
        sortable: false,
      },
      {
        field: 'cplQty',
        headerName: 'Nyitott',
        type: 'number',
        editable: false,
        sortable: false,
      },
      {
        field: 'opnQty',
        headerName: 'Lezárt',
        type: 'number',
        editable: false,
        sortable: false,
      },
      {
        field: 'whsCode',
        headerName: 'Raktár',
        type: 'string',
        editable: true,
        sortable: false,
        aggregable: false,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions?.warehouse.find((u) => {
            const docLineCode = values.docLine?.[valueIndex]?.whsCode;
            return u.value === (docLineCode || values.whsCode);
          });

          return findedItem?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions?.warehouse.find((u) => {
            const docLineCode = values.docLine?.[valueIndex]?.code;
            return u.value === (docLineCode || values.whsCode);
          });
          return (
            <Field
              name={`docLine.${valueIndex}.whsCode`}
              as={AutoCompleteSelectUI}
              helperText={<FormErrorUI message={errors?.docLine?.[valueIndex]?.whsCode} />}
              selectedValue={values.docLine?.[valueIndex]?.whsCode}
              value={findedItem}
              onChange={(_e, newVal) => {
                setFieldValue(`docLine.${valueIndex}.whsCode`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              dataset={autocompleteOptions?.warehouse}
              onBlur={async () => await validateField(`docLine.${valueIndex}.whsCode`)}
            />
          );
        },
      },
      {
        field: 'unitPrice',
        headerName: 'Egységár',
        type: 'number',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`docLine.${valueIndex}.unitPrice`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.docLine?.[valueIndex]?.unitPrice} />}
              onChange={(e) => {
                setFieldValue(`docLine.${valueIndex}.unitPrice`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });

                calculatePrice(
                  valueIndex,
                  values.docLine[valueIndex].qty,
                  e.target.value ?? '0',
                  values.docLine[valueIndex].vatPercent
                );
              }}
              onBlur={async () => await validateField(`docLine.${valueIndex}.unitPrice`)}
            />
          );
        },
      },

      {
        field: 'currencyCode',
        headerName: 'Ár pénznem',
        type: 'string',
        editable: false,
        sortable: false,
        renderCell: () => {
          const currFromPl = autocompleteOptions?.priceList.find((u) => u.id === values.priceListId);
          return currFromPl?.currencyCode ?? values.currencyCode;
        },
      },

      {
        field: 'vatCode',
        headerName: 'Áfakód',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions?.vatCode.find((u) => {
            const docLineCode = values.docLine?.[valueIndex]?.vatCode;
            return u.value === (docLineCode || values.vatCode);
          });

          const findedVat = autocompleteData.vatCode.find((u) => u.code === findedItem?.value);
          //setFieldValue(`docLine.${valueIndex}.vatPercent`, findedVat?.percentage ?? null);

          return findedItem?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions?.vatCode.find((u) => {
            const docLineCode = values.docLine?.[valueIndex]?.vatCode;
            return u.value === (docLineCode || values.vatCode);
          });
          return (
            <Field
              name={`docLine.${valueIndex}.vatCode`}
              as={AutoCompleteSelectUI}
              helperText={<FormErrorUI message={errors?.docLine?.[valueIndex]?.vatCode} />}
              selectedValue={values.docLine?.[valueIndex]?.vatCode}
              value={findedItem}
              onChange={(_e, newVal) => {
                setFieldValue(`docLine.${valueIndex}.vatCode`, newVal?.value ?? null);
                const findedVat = autocompleteData.vatCode.find((u) => u.code === newVal?.value);
                setFieldValue(`docLine.${valueIndex}.vatPercent`, findedVat?.percentage ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
                calculatePrice(
                  valueIndex,
                  values.docLine[valueIndex].qty,
                  values.docLine[valueIndex].unitPrice,
                  findedVat?.percentage ?? '0'
                );
              }}
              dataset={autocompleteOptions?.vatCode}
              onBlur={async () => await validateField(`docLine.${valueIndex}.vatCode`)}
            />
          );
        },
      },

      {
        field: 'vatPercent',
        headerName: 'Áfa %',
        type: 'number',
        editable: false,
        sortable: false,
      },

      {
        field: 'netPrice',
        headerName: 'Nettó érték',
        type: 'number',
        editable: false,
        sortable: false,
        valueGetter: (value) => parseFloat(value),
        valueFormatter: (params) => {
          return formatCurrency(params);
        },
      },

      {
        field: 'vatValue',
        headerName: 'Áfa érték',
        type: 'number',
        editable: false,
        sortable: false,
        valueGetter: (value) => parseFloat(value),
        valueFormatter: (params) => {
          return formatCurrency(params);
        },
      },

      {
        field: 'grossPrice',
        headerName: 'Bruttó érték',
        type: 'number',
        editable: false,
        sortable: false,
        valueGetter: (value) => parseFloat(value),
        valueFormatter: (params) => {
          return formatCurrency(params);
        },
      },

      {
        field: 'netPriceInOwnCur',
        headerName: 'Nettó saját pénznemben',
        type: 'number',
        editable: false,
        sortable: false,
        valueGetter: (value) => parseFloat(value),
        valueFormatter: (params) => {
          return formatCurrency(params);
        },
      },

      {
        field: 'vatValueInOwnCur',
        headerName: 'Áfa saját pénznemben',
        type: 'number',
        editable: false,
        sortable: false,
        valueGetter: (value) => parseFloat(value),
        valueFormatter: (params) => {
          return formatCurrency(params);
        },
      },

      {
        field: 'grossPriceInOwnCur',
        headerName: 'Bruttó saját pénznemben',
        type: 'number',
        editable: false,
        sortable: false,
        valueGetter: (value) => parseFloat(value),
        valueFormatter: (params) => {
          return formatCurrency(params);
        },
      },

      {
        field: 'weightChangeNumber',
        headerName: 'Súly váltó',
        type: 'number',
        editable: false,
        sortable: false,
      },

      {
        field: 'weight',
        headerName: 'Súly',
        type: 'number',
        editable: true,
        sortable: false,
        valueGetter: (value) => parseFloat(value),
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);

          return (
            <Field
              name={`docLine.${valueIndex}.weight`}
              type="number"
              variant="standard"
              as={InputUI}
              fullWidth
              helperText={<FormErrorUI message={errors?.docLine?.[valueIndex]?.weight} />}
              onChange={(e) => {
                setFieldValue(`docLine.${valueIndex}.weight`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`docLine.${valueIndex}.unitPrice`)}
            />
          );
        },
      },

      {
        field: 'projectId',
        headerName: 'Projekt',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions?.project.find(
            (u) => u.value === values.docLine?.[valueIndex]?.projectId
          );
          return findedItem?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions?.project.find(
            (u) => u.value === values.docLine?.[valueIndex]?.projectId
          );
          return (
            <Field
              name={`docLine.${valueIndex}.projectId`}
              as={AutoCompleteSelectUI}
              helperText={<FormErrorUI message={errors?.docLine?.[valueIndex]?.projectId} />}
              selectedValue={values.docLine?.[valueIndex]?.projectId}
              value={findedItem}
              onChange={(_e, newVal) => {
                setFieldValue(`docLine.${valueIndex}.projectId`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              dataset={autocompleteOptions?.project}
              onBlur={async () => await validateField(`docLine.${valueIndex}.projectId`)}
            />
          );
        },
      },

      {
        field: 'costCenterCode',
        headerName: 'Költséghely',
        type: 'string',
        editable: true,
        sortable: false,
        renderCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions?.costCenter.find(
            (u) => u.value === values.docLine?.[valueIndex]?.costCenterCode
          );
          return findedItem?.label;
        },
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          const findedItem = autocompleteOptions?.costCenter.find(
            (u) => u.value === values.docLine?.[valueIndex]?.costCenterCode
          );
          return (
            <Field
              name={`docLine.${valueIndex}.costCenterCode`}
              as={AutoCompleteSelectUI}
              helperText={<FormErrorUI message={errors?.docLine?.[valueIndex]?.costCenterCode} />}
              selectedValue={values.docLine?.[valueIndex]?.costCenterCode}
              value={findedItem}
              onChange={(_e, newVal) => {
                setFieldValue(`docLine.${valueIndex}.costCenterCode`, newVal?.value ?? null);
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: newVal?.value ?? null,
                });
              }}
              dataset={autocompleteOptions?.costCenter}
              onBlur={async () => await validateField(`docLine.${valueIndex}.costCenterCode`)}
            />
          );
        },
      },

      {
        field: 'itemReference',
        headerName: 'Tétel hivatkozás',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          return (
            <Field
              name={`docLine.${valueIndex}.itemReference`}
              as={InputUI}
              type="text"
              variant="standard"
              fullWidth
              helperText={<FormErrorUI message={errors?.docLine?.[valueIndex]?.itemReference} />}
              onChange={(e) => {
                setFieldValue(`docLine.${valueIndex}.itemReference`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`docLine.${valueIndex}.itemReference`)}
            />
          );
        },
      },

      {
        field: 'internalNote',
        headerName: 'Belső megjegyzés',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          return (
            <Field
              name={`docLine.${valueIndex}.internalNote`}
              as={InputUI}
              type="text"
              variant="standard"
              fullWidth
              helperText={<FormErrorUI message={errors?.docLine?.[valueIndex]?.internalNote} />}
              onChange={(e) => {
                setFieldValue(`docLine.${valueIndex}.internalNote`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`docLine.${valueIndex}.internalNote`)}
            />
          );
        },
      },
      {
        field: 'bpNote',
        headerName: 'Üp megjegyzés',
        type: 'string',
        editable: true,
        sortable: false,
        renderEditCell: (data) => {
          const valueIndex = getValueIndex(values, data.id);
          return (
            <Field
              name={`docLine.${valueIndex}.bpNote`}
              as={InputUI}
              type="text"
              variant="standard"
              fullWidth
              helperText={<FormErrorUI message={errors?.docLine?.[valueIndex]?.bpNote} />}
              onChange={(e) => {
                setFieldValue(`docLine.${valueIndex}.bpNote`, e.target.value ?? '');
                data.api.setEditCellValue({
                  id: data.id,
                  field: data.field,
                  value: e.target.value,
                });
              }}
              onBlur={async () => await validateField(`docLine.${valueIndex}.bpNote`)}
            />
          );
        },
      },

      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        cellClassName: 'actions',
        sortable: false,
        getActions: ({ id, row }) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => {
                const valueIndex = getValueIndex(values, id);
                return remove(valueIndex);
              }}
              color="inherit"
              disabled={disabled}
            />,
          ];
        },
      },
    ]);
  };

  const calculatePrice = (valueIndex, _qty, _unitPrice, _vatPercent) => {
    const qty = parseFloat(_qty ?? '0');
    const unitPrice = parseFloat(_unitPrice ?? '0');
    const vatPercent = parseFloat(_vatPercent ?? '0');

    const docCur = autocompleteData.currency.find((c) => c.code === values.currencyCode);
    const ownCur = autocompleteData.currency.find((c) => c.code === config.currencyCode);

    const netPrice = round(multiply(unitPrice, qty), docCur?.amountRounding ?? 0);
    const vatValue =
      vatPercent > 0 ? round(multiply(netPrice, divide(vatPercent, 100)), docCur?.amountRounding ?? 0) : 0;
    const grossPrice = round(add(netPrice, vatValue), docCur?.amountRounding ?? 0);

    setFieldValue(`docLine.${valueIndex}.netPrice`, netPrice);
    setFieldValue(`docLine.${valueIndex}.vatValue`, vatValue);
    setFieldValue(`docLine.${valueIndex}.grossPrice`, grossPrice);

    const exchangeRate = Number(values.exchangeRate);
    const netPriceInOwnCur = round(
      multiply(netPrice, exchangeRate > 0 ? exchangeRate : 1),
      ownCur?.amountRounding ?? 0
    );
    const vatValueInOwnCur = round(
      multiply(vatValue, exchangeRate > 0 ? exchangeRate : 1),
      ownCur?.amountRounding ?? 0
    );
    const grossPriceInOwnCur = round(
      multiply(grossPrice, exchangeRate > 0 ? exchangeRate : 1),
      ownCur?.amountRounding ?? 0
    );
    setFieldValue(`docLine.${valueIndex}.netPriceInOwnCur`, netPriceInOwnCur);
    setFieldValue(`docLine.${valueIndex}.vatValueInOwnCur`, vatValueInOwnCur);
    setFieldValue(`docLine.${valueIndex}.grossPriceInOwnCur`, grossPriceInOwnCur);

    setTimeout(() => {
      docTotal();
    }, 800);
  };

  const calculateWeight = (valueIndex, _qty, _weightChangeNumber) => {
    const qty = parseFloat(_qty ?? '0');
    const weightChangeNumber = parseFloat(_weightChangeNumber ?? '0');

    const weight = round(multiply(qty, weightChangeNumber), 2);
    setFieldValue(`docLine.${valueIndex}.weight`, weight);

    setTimeout(() => {
      docTotal();
    }, 800);
  };

  return (
    <div className="overflow-x-auto">
      <div className="align-middle">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <FieldArray name="docLine">
            {({ remove, push }) => (
              <DatagridPremiumUi
                apiRef={apiRef}
                datagridIdentifier={DataGridIdentifiers.docLine}
                height={480}
                columns={columns(setFieldValue, validateField, errors, remove, calculatePrice, calculateWeight)}
                rows={values?.docLine ?? []}
                editMode="row"
                slots={{
                  toolbar: EditToolbar,
                }}
                slotProps={{
                  toolbar: {
                    disabled,
                    push,
                    defaultField,
                    apiRef,
                    documentData,
                    selectedCompany,
                    autocompleteData,
                  },
                }}
                onCellDoubleClick={(_params, event) => {
                  event.defaultMuiPrevented = true;
                }}
                onCellClick={(params, event) => {
                  if (viewField) return;
                  const rowMode = apiRef.current.getRowMode(params.row.id);
                  if (rowMode === GridRowModes.View) apiRef.current.startRowEditMode({ id: params.row.id });
                }}
                headerFilters={false}
                getRowHeight={() => 'auto'}
              />
            )}
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

export default DocumentLine;
