import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, DatePickerUI, AutoCompleteSelectUI, FormErrorUI, FormLabelUI } from '../Interface';

const CorrectionFields = ({ viewField, autocompleteOptions, selectedValues }) => {
  const { values, validateField, setFieldValue, errors } = useFormikContext();
  console.log(errors);
  return (
    <div className="grid grid-cols-4 gap-x-4 gap-y-2 mx-4">
      <div className="col-span-4 md:col-span-1">
        <Field
          type="text"
          name="prefixedId"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Azonosító szám" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.prefixedId} />}
          disabled
          InputLabelProps={{ shrink: values.prefixedId ? values.prefixedId !== '' : '' }}
          value={values?.prefixedId ?? ''}
        />
      </div>
      <div className="col-span-4 md:col-span-3">
        <Field
          type="text"
          name="area"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Terület" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.area} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.area ? values.area !== '' : '' }}
          value={values?.area ?? ''}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="origin"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Eredet" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.origin} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.origin ? values.origin !== '' : '' }}
          value={values?.origin ?? ''}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="error"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Hiba" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.error} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.error ? values.error !== '' : '' }}
          value={values?.error ?? ''}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="causeOfError"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Hibaok" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.causeOfError} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.causeOfError ? values.causeOfError !== '' : '' }}
          value={values?.causeOfError ?? ''}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="action"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Intézkedés" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.action} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.action ? values.action !== '' : '' }}
          value={values?.action ?? ''}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="autocomplete"
          name="responsibleId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Felelős" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.responsibleId} />}
          onChange={(_e, newVal) => {
            setFieldValue('responsibleId', newVal?.value ?? null).then(() => {
              validateField('responsibleId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.responsibleId !== '' }}
          selectedValue={values.responsibleId}
          dataset={autocompleteOptions.users}
          isOptionEqualToValue={(option, value) => option.value === value}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="date"
          name="deadline"
          component={DatePickerUI}
          value={values.deadline}
          fullWidth
          label={<FormLabelUI text="Határidő" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.deadline} />}
          onChange={(date) => {
            setFieldValue('deadline', date).then(() => {
              validateField('deadline');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.deadline !== '' }}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="documentation"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Dokumentáció" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.documentation} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.documentation ? values.documentation !== '' : '' }}
          value={values?.documentation ?? ''}
        />
      </div>
      <div className="col-span-4 md:col-span-2">
        <Field
          type="text"
          name="evaluation"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Értékelés" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.evaluation} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.evaluation ? values.evaluation !== '' : '' }}
          value={values?.evaluation ?? ''}
        />
      </div>
    </div>
  );
};

export default CorrectionFields;
