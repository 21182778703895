import React, { useEffect } from 'react';
import ItemPriceField from './ItemPriceField';
import { useFormikContext } from 'formik';

const ItemPriceTable = ({ rows, viewField, setDefaultValues }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setDefaultValues((prev) => ({
      ...prev,
      itemPrice: rows.map((row) => ({
        id: row.id,
        priceListId: row.priceListId,
        price: null,
      })),
    }));

    setFieldValue(
      'itemPrice',
      rows.map((row) => ({
        id: row.id,
        priceListId: row.priceListId,
        price: null,
      }))
    );
  }, [rows, setFieldValue]);

  return (
    <div className="max-h-[400px] overflow-y-scroll overflow-x-hidden custom-scrollbar">
      <table className="border w-full">
        <thead className="bg-tableHeaderColor">
          <tr className="grid grid-cols-4">
            <th className="border p-3 col-span-2">Árlista neve</th>
            <th className="border p-3">Ár</th>
            <th className="border p-3">Pénznem kódja</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={row.id} className={index % 2 === 0 ? 'bg-gray-100 grid grid-cols-4' : 'grid grid-cols-4'}>
              <td className="border py-1 col-span-2">{row.name}</td>
              <td className="border py-1 relative">
                <div className="relative w-full">
                  <ItemPriceField row={row} disabled={viewField} index={index} />
                </div>
              </td>
              <td className="border py-1 text-center">{row.currency.code}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ItemPriceTable;
