import React from 'react';
import { Field, useFormikContext } from 'formik';

import { InputUI, FormErrorUI, FormLabelUI, AutoCompleteSelectUI } from '../Interface';

export const WarehouseFields = ({ viewField, addField, selectedValues, autocompleteOptions }) => {
  const { values, errors, setFieldValue, validateField } = useFormikContext();

  return (
    <div className="grid grid-cols-4 gap-x-4 mx-4 mb-5">
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="code"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Raktárkód" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.code} />}
          disabled={viewField || addField}
          InputLabelProps={{ shrink: values.code && values.code !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="text"
          name="name"
          as={InputUI}
          fullWidth
          label={<FormLabelUI text="Raktár neve" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.name} />}
          disabled={viewField}
          InputLabelProps={{ shrink: values.name && values.name !== '' }}
        />
      </div>
      <div className="md:col-span-2 col-span-4">
        <Field
          type="autocomplete"
          name="companyLocationId"
          component={AutoCompleteSelectUI}
          fullWidth
          label={<FormLabelUI text="Telephely" />}
          variant="standard"
          helperText={<FormErrorUI message={errors.companyLocationId} />}
          onChange={(_e, newVal) => {
            setFieldValue('companyLocationId', newVal?.value ?? null).then(() => {
              validateField('companyLocationId');
            });
          }}
          disabled={viewField}
          InputLabelProps={{ shrink: values.companyLocationId !== '' }}
          selectedValue={values.companyLocationId}
          selectedLabelValue={selectedValues?.companyLocation?.name}
          dataset={autocompleteOptions?.companyLocation}
        />
      </div>
    </div>
  );
};
