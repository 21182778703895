export const GroupType = {
  CUSTOMERGROUP: 'CUSTOMERGROUP',
  SUPPLIERGROUP: 'SUPPLIERGROUP',
};

export const GroupTypeList = [
  { label: 'Vevői csoport', value: GroupType.CUSTOMERGROUP },
  { label: 'Szállító csoport', value: GroupType.SUPPLIERGROUP },
];

export const AreaType = {
  BE: 'BE',
  EU: 'EU',
  EK: 'EK',
};

export const AreaTypeList = [
  { label: 'Belföld', value: AreaType.BE },
  { label: 'EU', value: AreaType.EU },
  { label: 'EU-n kívüli', value: AreaType.EK },
];
