import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { RichTreeView, TreeItem2 } from '@mui/x-tree-view';

import prodgeneratorService from '../../service/prodgenerator.service';

const ProductionGeneratorTree = ({ selectedItemState, sourceDataState }) => {
  const { sourceData, setSourceData } = sourceDataState;
  const [treeData, setTreeData] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);
  const { selectedItem } = selectedItemState;

  const getTreeData = () => {
    prodgeneratorService.getsourcelistByItemCode({ itemCode: selectedItem }).then((data) => {
      setTreeData(data.treeView);
      setSourceData(data.sourceData);
      setExpandedItems(getAllItemIds(data.treeView));
    });
  };

  const getAllItemIds = (items, ids = []) => {
    if (!items.length) return ids;
    items.forEach((item) => {
      ids.push(item.id);
      if (item.children) getAllItemIds(item.children, ids);
    });
    return ids;
  };

  const handleExpandedItemsChange = (event, itemIds) => {
    setExpandedItems(itemIds);
  };

  useEffect(() => {
    if (selectedItem) {
      getTreeData();
    } else {
      setTreeData([]);
      setExpandedItems([]);
    }
  }, [selectedItem]);

  const getItemLabel = (item) => item.relatedItemCode;

  const CustomTreeItem = React.forwardRef(function CustomTreeItem(props, ref) {
    const findedItem = sourceData.find((item) => item.id === props.itemId);

    return (
      <TreeItem2
        {...props}
        ref={ref}
        sx={{ color: Object.keys(findedItem?.sourceList ?? {}).length > 0 ? 'inherit' : 'red' }}
      />
    );
  });

  return (
    <Box sx={{ minHeight: 210, minWidth: 250 }} p={1}>
      <RichTreeView
        items={treeData}
        getItemLabel={getItemLabel}
        expandedItems={expandedItems}
        onExpandedItemsChange={handleExpandedItemsChange}
        slots={{
          item: CustomTreeItem,
        }}
      />
    </Box>
  );
};

export default ProductionGeneratorTree;
