import React, { useState } from 'react';
import { TabsUI } from '../Interface';
import OperationGroupLinks from './OperationGroupLinks';
import OperationUserLinks from './OperationUserLinks';

const OperationTabs = (props) => {
  const { addField, params } = props;
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      id: 0,
      name: 'Művelet csoportok',
      content: addField && <OperationGroupLinks {...props} />,
    },
    ...(params.OPERATION_AND_OP_GROUP_USER_LINK === undefined ||
    params.OPERATION_AND_OP_GROUP_USER_LINK === '' ||
    params.OPERATION_AND_OP_GROUP_USER_LINK === 'OPERATION'
      ? [
          {
            id: 0,
            name: 'Felhasználók',
            content: addField && <OperationUserLinks {...props} />,
          },
        ]
      : []),
  ];

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="my-4 mt-6">
      <div className="px-3">
        <TabsUI activeTab={activeTab} handleTabClick={handleTabClick}>
          {tabs.map((tab, index) => (
            <div key={index} name={tab.name}>
              {tab.content}
            </div>
          ))}
        </TabsUI>
      </div>
    </div>
  );
};

export default OperationTabs;
